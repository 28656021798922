import React, { useState } from 'react';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { Card, Grid, FormControl, Tooltip } from "@mui/material";
import { CircularProgress, Backdrop } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import * as AiIcons from "react-icons/ai";
import Swal from 'sweetalert2';
import axios from 'axios';
import { APIData, org } from 'authentication/APIData';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

function HolidayForm() {
    const [values, setValues] = useState({
        id: 0,
        description: '',
        holiday_date: '',
        holiday_name: '',
        org: org
    });
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
        setErrors({ ...errors, [prop]: '' });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const errors = {};
        const today = new Date().toISOString().split('T')[0];

        // Holiday Name should not contain numbers
        if (!values.holiday_name.trim()) {
            errors.holiday_name = 'Holiday name is required';
        } else if (/\d/.test(values.holiday_name)) {
            errors.holiday_name = 'Holiday name should not contain numbers';
        }

        // Holiday Date should not be in the past
        if (!values.holiday_date) {
            errors.holiday_date = 'Holiday date is required';
        } else if (values.holiday_date < today) {
            errors.holiday_date = 'Holiday date cannot be in the past';
        }

        // Description should be less than 255 characters
        if (!values.description.trim()) {
            errors.description = 'Description is required';
        } else if (values.description.length > 255) {
            errors.description = 'Description should be less than 255 characters';
        }

        if (Object.keys(errors).length === 0) {
            setLoading(true);
            try {
                await axios.post(APIData.api + 'holidays/', values, { headers: APIData.headers });
                Swal.fire("Success", "Holiday Added Successfully", "success");
                setTimeout(() => {
                    navigate("/holiday-list");
                }, 1500);
            } catch (err) {
                Swal.fire("Error", err.message || "An error occurred", "error");
            } finally {
                setLoading(false);
            }
        } else {
            setErrors(errors);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Tooltip title="Close" arrow>
                                <Link to="/holiday-list">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography mb={2} variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Add Holiday Details
                            </SoftTypography>
                        </SoftBox>
                        
                        <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Holiday Name</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="holiday_name"
                                                value={values.holiday_name}
                                                onChange={handleChange('holiday_name')}
                                                error={!!errors.holiday_name}
                                                required
                                            />
                                        </FormControl>
                                        {errors.holiday_name && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.holiday_name}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Holiday Date</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                type="date"
                                                id="holiday_date"
                                                value={values.holiday_date}
                                                onChange={handleChange('holiday_date')}
                                                error={!!errors.holiday_date}
                                                required
                                            />
                                        </FormControl>
                                        {errors.holiday_date && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.holiday_date}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Description</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="description"
                                                value={values.description}
                                                onChange={handleChange('description')}
                                                error={!!errors.description}
                                                multiline
                                                rows={4}
                                                required
                                            />
                                        </FormControl>
                                        {errors.description && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.description}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox display="flex" justifyContent="center" mt={4} mb={2}>
                                        <SoftButton 
                                            type="submit" 
                                            variant="gradient" 
                                            color="info" 
                                            fullWidth 
                                            disabled={loading}
                                        >
                                            {loading ? "Submitting..." : "Submit"}
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default HolidayForm;
