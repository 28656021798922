import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import React, { useState, useEffect } from 'react';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import { APIData, org } from 'authentication/APIData';
import SoftButton from 'components/SoftButton';
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from 'sweetalert2';
import DefaultProjectCard2 from "examples/Cards/ProjectCards/DefaultProjectCard/index2";
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import axios from 'axios';
import NotificationsIcon from '@mui/icons-material/Notifications';

function AssetList() {
  const [assetList, setAssestListData] = useState([]);
  const navigate = useNavigate();
  const [userdata, setUserData] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(parseInt(accessLevel));
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);

  const shouldShowActions = () => {
    if (permission === 1100 || permission === 1000) {
      return false;
    }
    return isSuperAdmin || permission === 1111;
  };

  const ShowActions = () => {
    if (permission === 1100 || permission === 1110) {
      return true;
    }
    return isSuperAdmin || permission === 1111;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let url = `${APIData.api}assetgroup/?org=${org}`;
        const response = await fetch(url, { headers: APIData.headers });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setAssestListData(data);
      } catch (error) {
        Swal.fire({
          title: 'Error!',
          text: `Failed to fetch "${org}" details`,
          icon: 'error',
          confirmButtonText: 'OK'
        });
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
    setUserData(userData_Local);
  }, []);

  const handleNewAssetClick = () => {
    navigate("/add-asset-group");
  };

  const handleMyAssetClick = () => {
    navigate("/my-assets");
  };

  const handleViewAssetClick = (asset_group_id) => {
    navigate(`/single-group-asset-list/${asset_group_id}`);
  };

  const handleDeleteAssetClick = (asset_group_id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'It will delete entire assets in this list',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const url = `${APIData.api}assetgroup/id?assetGroupId=${asset_group_id}`;
          const response = await fetch(url, {
            method: 'DELETE',
            headers: APIData.headers
          });

          if (response.ok) {
            Swal.fire({
              title: 'Deleted!',
              text: 'The asset group has been deleted.',
              icon: 'success',
              confirmButtonText: 'OK'
            });

            setAssestListData(assetList.filter(asset => asset.asset_group_id !== asset_group_id));
          } else {
            throw new Error('Failed to delete asset group.');
          }
        } catch (error) {
          Swal.fire({
            title: 'Error!',
            text: 'There was an issue deleting the asset group.',
            icon: 'error',
            confirmButtonText: 'OK'
          });
        }
      }
    });
  };

  const handleEditAssetClick = (asset_group_id) => {
    setSelectedAssetId(asset_group_id);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedImage(null);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setSelectedImage(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageUpload = async () => {
    const imageInput = document.getElementById('image-input');
    const file = imageInput.files[0];

    if (!file) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please choose an image file!',
      });
      return;
    }

    if (file.size > 1000000) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "File too large",
        footer: "Max file size is 1MB"
      });
      return;
    }

    const imageForm = new FormData();
    imageForm.append('id', selectedAssetId);
    imageForm.append('file', file);

    const imageUploadUrl = `${APIData.api}assetgroup/${selectedAssetId}/image`;

    try {
      await axios.post(imageUploadUrl, imageForm, { headers: APIData.headers });

      Swal.fire({
        icon: "success",
        title: "Asset Group Image Updated Successfully",
        showConfirmButton: false,
        timer: 1500
      });

      setAssestListData((prevList) =>
        prevList.map((asset) =>
          asset.asset_group_id === selectedAssetId
            ? { ...asset, asset_group_imageUrl: URL.createObjectURL(file) }
            : asset
        )
      );

      handleCloseDialog();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "There was an issue uploading the image.",
        confirmButtonText: "OK"
      });
    }
  };

  const handleBellClick = () => {
    navigate("/assets-requests");
  };


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mb={3}>
        <SoftBox pt={2} px={2}>
          <SoftBox mb={0.5} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <SoftTypography variant="h5" fontWeight="medium">Stores</SoftTypography>
            <SoftBox style={{ display: 'flex', alignItems: 'center' }}>
              {shouldShowActions() ? <Tooltip title="Add new asset group" arrow>
                <SoftButton sx={{ ml: 2 }} onClick={handleNewAssetClick}>+ Add</SoftButton>
              </Tooltip> : ""}
              {permission === 1110 && <Tooltip title="Add new asset group" arrow>
                <SoftButton sx={{ ml: 2 }} onClick={handleNewAssetClick}>+ Add</SoftButton>
              </Tooltip>}
              <Tooltip title="Add new asset group" arrow>
                <SoftButton sx={{ ml: 2 }} onClick={handleMyAssetClick}>My Assets</SoftButton>
              </Tooltip>
              {shouldShowActions() ? <Tooltip title="Assets Requests" arrow>
                <SoftButton sx={{ ml: 2 }} onClick={handleBellClick}>
                  Verify Requests
                </SoftButton>
              </Tooltip> : ""}
            </SoftBox>
          </SoftBox>
          <SoftBox mb={1}>
            <SoftTypography variant="h6" fontWeight="regular" color="text">All Assets</SoftTypography>
          </SoftBox>
        </SoftBox>

        <SoftBox p={2}>
          {assetList.length === 0 ? (
            <SoftTypography variant="h6" fontWeight="regular" color="text" align="center">
              No asset group available
            </SoftTypography>
          ) : (
            <Grid container alignItems="flex-start" spacing={3}>
              {
                assetList.map((asset) => (
                  <Grid item xs={12} md={6} xl={3} key={asset.asset_group_id}>
                    <DefaultProjectCard2
                      image={asset.asset_group_imageUrl}
                      label={`Group ID: ${asset.asset_group_id}`}
                      title={asset.asset_group_name}
                      description={`Available: ${asset.available_quantity}`}
                      onViewAssetClick={() => handleViewAssetClick(asset.asset_group_id)}
                      onDeleteClick={() => handleDeleteAssetClick(asset.asset_group_id)}
                      onEditClick={() => handleEditAssetClick(asset.asset_group_id)} // Pass the edit handler
                      viewButtonLabel="View"
                      deleteButtonLabel="Remove"
                      editButtonLabel="Edit"
                      buttonSize="small"

                      showViewButton={ShowActions()}
                      showDeleteButton={shouldShowActions()}
                      showEditButton={true}

                    />
                  </Grid>
                ))
              }
            </Grid>
          )}
        </SoftBox>
      </SoftBox>

      <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle>Upload New Image</DialogTitle>
        <DialogContent extAlign="center">
          <input
            type="file"
            id="image-input"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }}
          />
          <label htmlFor="image-input" style={{ cursor: 'pointer', display: 'block', marginBottom: '10px', padding: '10px', border: '1px solid #ccc', textAlign: 'center' }}>
            Choose Image
          </label>
          {selectedImage && <img src={selectedImage} alt="Preview" style={{ maxWidth: '100%', marginBottom: '10px' }} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">Cancel</Button>
          <Button onClick={handleImageUpload} color="primary">Submit</Button>
        </DialogActions>
      </Dialog>

      <Footer />
    </DashboardLayout>
  );
}

export default AssetList;
