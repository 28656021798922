import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Box, Typography, Button, Card, Grid, Container, ToggleButton, ToggleButtonGroup, } from "@mui/material";
import PropTypes from 'prop-types';
import Swal from "sweetalert2";
import StationaryCard from "examples/Cards/StationaryCards/stationaryCard";
import { org } from "authentication/APIData";
import { APIData } from "authentication/APIData";

const VerifyRequests = () => {
  const [stationary, setStationary] = useState([]);
  const [returnStationary, setReturnStationary] = useState([]);
  const [view, setView] = useState("approve");
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);


  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(parseInt(accessLevel));
  }, []);

  const fetchStationaryToApprove = () => {
    const url = `${APIData.api}emp/stationary/status?status=Requested&org=${org}`;
    axios.get(url, { headers: APIData.headers })
      .then((resp) => {
        setStationary(resp.data);
      })
      .catch(() => {
        toast("Unable to allocate");
      });
  };

  const fetchStationaryToReturn = () => {
    const url = `${APIData.api}emp/stationary/status?status=Return Requested&org=${org}`;
    axios.get(url, { headers: APIData.headers })
      .then((resp) => {
        setReturnStationary(resp.data);
      })
      .catch(() => {
        toast("No Return Request");
      });
  };

  const allocateStationary = (status, stationaryId) => {
    const url = `${APIData.api}emp/stationary?id=${stationaryId}&status=${status}&issuedBy=${currentUser}`;
    axios.put(url, {}, { headers: APIData.headers })
      .then((response) => {
        if (response.status == 200 || response.status == 201) {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: `Successfully ${status} the stationary`,
            confirmButtonText: 'Okay'
          });
        }
        fetchStationaryToApprove();
        fetchStationaryToReturn();
      })
      .catch((error) => {
        if (error.response.data == "Insufficient Resources") {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: "Can't approve the request due to insufficient resources",
            confirmButtonText: 'Okay'
          });
        }
      });
  };

  const handleViewChange = (event, newView) => {
    if (newView) {
      setView(newView);
    }
  };

  useEffect(() => {
    fetchStationaryToApprove();
    fetchStationaryToReturn();
  }, []);

  return (
    <Container>
      <Box display="flex" justifyContent="center" marginBottom="1rem">
        <ToggleButtonGroup value={view} exclusive onChange={handleViewChange}>
          <ToggleButton value="approve" sx={{ border: '1px solid #007a99' }}>
            Approve Requests
          </ToggleButton>
          {/* <ToggleButton value="return" sx={{ border: '1px solid #007a99' }}>
            Return Requests
          </ToggleButton> */}
        </ToggleButtonGroup>
      </Box>

      {view === "approve" ? (
        <>
          <Grid container spacing={2} justifyContent="center">
            {stationary.length === 0 ? (
              <Typography variant="h6" color="#00a3cc">No Allocation Requests</Typography>
            ) : (
              stationary.map(item => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <StationaryCard
                    key={item.id}
                    stationaryId={item.stationaryId}
                    stationaryName={item.stationaryName}
                    status={item.status}
                    statusColor={
                      item.status === "Approved" ? "success" :
                        item.status === "Rejected" ? "error" :
                          item.status === "Requested" ? "warning" : "warning"
                    }
                    count={item.count}
                    empId={item.empId}
                    empEmail={item.empEmail}
                    onApprove={() => allocateStationary("Approved", item.id)}
                    onReject={() => allocateStationary("Rejected", item.id)}
                    showApprovalButtons={item.empId !== currentUser}
                    Description= {item.empId === currentUser && (
                      <Typography variant="body2" color="textSecondary" sx={{ marginTop: 1 }}>
                        You cannot approve or reject your own request.
                      </Typography>
                    )}
                  />
                 
                </Grid>
              ))
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid container spacing={2} justifyContent="center">

          </Grid>
        </>
      )}
    </Container>
  );
};

const Detail = ({ label, value }) => (
  <Box display="flex" justifyContent="space-between" marginBottom="0.5rem">
    <Typography variant="body1" fontWeight="600" color="#003380">{label}:</Typography>
    <Typography variant="body1" fontWeight="500" color="#001433">{value}</Typography>
  </Box>
);

Detail.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default VerifyRequests;

