import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Container, Paper, Dialog, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import { toast } from 'react-toastify';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import TabTemplate from './TabTemplet';
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import SoftButton from 'components/SoftButton';

const initialState = {

    header1: "",
    header10: "",
    header11: "",
    header12: "",
    header13: "",
    header14: "",
    header15: '',
    header16: "",
    header17: "",
    header18: "",
    header19: "",
    header2: "",
    header20: "",
    header21: "",
    header3: "",
    header4: "",
    header5: "",
    header6: "",
    header7: "",
    header8: "",
    header9: "",
    id: 0,
    org: org,
    para1: "",
    para10: "",
    para11: "",
    para12: "",
    para13: "",
    para14: "",
    para15: "",
    para16: "",
    para17: "",
    para18: "",
    para19: "",
    para2: "",
    para20: "",
    para21: "",
    para22: "",
    para3: "",
    para4: "",
    para5: "",
    para6: "",
    para7: "",
    para8: "",
    para9: "",
    temp_name: "TAB",
    title: ""

}

function TabTempEdit() {
    const [formData, setFormData] = useState(initialState);
    const [orgdetails, setOrgdetails] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleBack = () => {
        if (location.state?.returnToStepper) {
            navigate(location.state.returnPath);
        } else {
            navigate(-1);
        }
    };


    useEffect(() => {
        fetchOrgDetails();
    },);

    const fetchOrgDetails = () => {
        axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
            .then((response) => setOrgdetails(response.data))
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(APIData.api + `db-template/`, formData, { headers: APIData.headers })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Update Error",
                    text: error.response.data
                });

            })
        console.log('Form Data Submitted:', formData);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
              <PageLayout>
              <SoftButton onClick={handleBack}>
                Back
            </SoftButton>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h5" gutterBottom>
                        TAB Template Form
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <SoftTypography variant="h6">Title</SoftTypography>
                            <SoftInput
                                    fullWidth
                                    name={'title'}
                                    label={`title`}
                                    value={formData.title}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para1'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para1}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para2'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para2}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para3'}
                                    multiline={true}
                                    rows={2}
                                    value={formData.para3}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para5'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para5}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> 
                             <Grid item xs={12}>
                             <SoftTypography variant="h6">Header 1</SoftTypography>
                            <SoftInput
                                    fullWidth
                                    name={'header1'}
                                    label={`Header1`}
                                    value={formData.header1}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para6'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para6}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> 
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header 2</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header2'}
                                    label={`Header2`}
                                    value={formData.header2}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header 3</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header3'}
                                    label={`Header3`}
                                    value={formData.header3}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                             <Grid item xs={12}>
                             <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para7'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para7}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> 
                            
                            
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para8'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para8}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> 
                            
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header 4</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header4'}
                                    label={`Header4`}
                                    value={formData.header4}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para9'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para9}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para10'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para10}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para11'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para11}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header 5</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header5'}
                                    label={`Header5`}
                                    value={formData.header5}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para12'}
                                    multiline={true}
                                    rows={2}
                                    value={formData.para12}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para13'}
                                    multiline={true}
                                    rows={2}
                                    value={formData.para13}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header 6</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header6'}
                                    
                                    value={formData.header6}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para14'}
                                    multiline={true}
                                    rows={2}
                                    value={formData.para14}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para15'}
                                    multiline={true}
                                        rows={2}
                                    value={formData.para15}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header 7</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header7'}
                                
                                    value={formData.header7}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para16'}
                                    multiline={true}
                                    rows={2}
                                    value={formData.para16}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header 8</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header8'}
                                   
                                    value={formData.header8}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para17'}
                                    multiline={true}
                                    rows={2}
                                    value={formData.para17}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            
                            
                            <Grid item xs={12}>
                            <Button
                                    variant="contained"
                                    style={{ marginBottom: '16px', width: "100%" }}
                                    onClick={handleClickOpen}
                                >
                                    View Template in Full Screen
                                </Button>
                                <Button type="submit" variant="contained" color="primary" style={{ width: '100%' }}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} sm={4}>

                    <Typography variant="h5" gutterBottom>
                    Adaptive Template
                    </Typography>
                    <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                        {orgdetails ? (
                            <div style={styles.container}>
                            <div style={styles.header}>
                            <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                                    <div style={styles.companyDetails}>
                                        <h3>{orgdetails.orgName}</h3>
                                        <p>+91 {orgdetails.orgMob}</p>
                                        <p>{orgdetails.orgEmailId}</p>
                                        <p>{orgdetails.orgUrl}</p>
                                </div>
                            </div>
                            <h1 style={styles.title}>{formData.title}</h1>
                            
                            <section>
                                <p style={styles.section}>{formData.para1} <strong>20/08/2024</strong> {formData.para2}</p>
        
                                <p style={styles.section}><strong>Care4Edu Solutions Pvt Ltd</strong>, {formData.para3} <strong>No. 228, Krishna Temple Road, Doddabommasandra, Vidyaranyapura Post, Bengaluru - 560097</strong></p>
        
                                <p style={styles.section}>AND</p>
        
                                <p style={styles.section}><strong>Adhithyan P A</strong>, an individual residing at Vidyaranyapura {formData.para5}</p>
        
                                <h2 style={styles.subTitle}>{formData.header1}</h2>
                                <p>{formData.para6}</p>
        
                                <h2 style={styles.subTitle}>{formData.header2}</h2>
                                <h3 style={styles.sectionTitle}>{formData.header3}</h3>
                                <p style={styles.section}>1.1 {formData.para7} <strong>20/08/2024</strong>.</p>
                                <p style={styles.section}>1.2 {formData.para8}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header4}</h3>
                                <p style={styles.section}>2.1 {formData.para9}.</p>
                                <p style={styles.section}>2.2 {formData.para10}</p>
                                <p style={styles.section}>2.3 {formData.para11}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header5}</h3>
                                <p style={styles.section}>4.1 {formData.para12}</p>
                                <p style={styles.section}>4.2 {formData.para13}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header6}</h3>
                                <p style={styles.section}>5.1 {formData.para14}</p>
                                <p style={styles.section}>5.2 {formData.para15}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header7}</h3>
                                <p>{formData.para16}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header8}</h3>
                                <p>{formData.para17}</p>
                            </section>
        
                            <div style={styles.footer}>
                                <div style={styles.signatureBlock}>
                                    <p>By: <strong>Rishi</strong></p>
                                    <p>Signature:</p>
                                    <p>Title: Superadmin</p>
                                    <p>Date: 20/08/2024</p>
                                </div>
        
                                <div style={styles.signatureBlock}>
                                    <p>By: <strong>Adhithyan P A</strong></p>
                                    <p>Signature:</p>
                                    <p>Title: Board Member</p>
                                    <p>Date: 20/08/2024</p>
                                </div>
                            </div>
                        </div>
                        ) : (
                            <Typography variant="body1">Loading organization details...</Typography>
                        )}
                    </Paper >
                </Grid>

                <Grid item xs={12} sm={4}>
                    <TabTemplate />
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Full Screen View
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: 'absolute', right: 16, top: 16 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                {orgdetails ? (
                            <div style={styles.container}>
                            <div style={styles.header}>
                            <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                                    <div style={styles.companyDetails}>
                                        <h3>{orgdetails.orgName}</h3>
                                        <p>+91 {orgdetails.orgMob}</p>
                                        <p>{orgdetails.orgEmailId}</p>
                                        <p>{orgdetails.orgUrl}</p>
                                </div>
                            </div>
                            <h1 style={styles.title}>{formData.title}</h1>
                            
                            <section>
                                <p style={styles.section}>{formData.para1} <strong>20/08/2024</strong> {formData.para2}</p>
        
                                <p style={styles.section}><strong>Care4Edu Solutions Pvt Ltd</strong>, {formData.para3} <strong>No. 228, Krishna Temple Road, Doddabommasandra, Vidyaranyapura Post, Bengaluru - 560097</strong></p>
        
                                <p style={styles.section}>AND</p>
        
                                <p style={styles.section}><strong>Adhithyan P A</strong>, an individual residing at Vidyaranyapura {formData.para5}</p>
        
                                <h2 style={styles.subTitle}>{formData.header1}</h2>
                                <p>{formData.para6}</p>
        
                                <h2 style={styles.subTitle}>{formData.header2}</h2>
                                <h3 style={styles.sectionTitle}>{formData.header3}</h3>
                                <p style={styles.section}>1.1 {formData.para7} <strong>20/08/2024</strong>.</p>
                                <p style={styles.section}>1.2 {formData.para8}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header4}</h3>
                                <p style={styles.section}>2.1 {formData.para9}.</p>
                                <p style={styles.section}>2.2 {formData.para10}</p>
                                <p style={styles.section}>2.3 {formData.para11}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header5}</h3>
                                <p style={styles.section}>4.1 {formData.para12}</p>
                                <p style={styles.section}>4.2 {formData.para13}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header6}</h3>
                                <p style={styles.section}>5.1 {formData.para14}</p>
                                <p style={styles.section}>5.2 {formData.para15}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header7}</h3>
                                <p>{formData.para16}</p>
        
                                <h3 style={styles.sectionTitle}>{formData.header8}</h3>
                                <p>{formData.para17}</p>
                            </section>
        
                            <div style={styles.footer}>
                                <div style={styles.signatureBlock}>
                                    <p>By: <strong>Rishi</strong></p>
                                    <p>Signature:</p>
                                    <p>Title: Superadmin</p>
                                    <p>Date: 20/08/2024</p>
                                </div>
        
                                <div style={styles.signatureBlock}>
                                    <p>By: <strong>Adhithyan P A</strong></p>
                                    <p>Signature:</p>
                                    <p>Title: Board Member</p>
                                    <p>Date: 20/08/2024</p>
                                </div>
                            </div>
                        </div>
                        ) : (
                            <Typography variant="body1">Loading organization details...</Typography>
                        )}
                </DialogContent>
            </Dialog>
            </PageLayout>
        </Container>
    );
};
export default TabTempEdit;


const styles = {
    container: {
        maxWidth: '750px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#fff',
    },
    header: {
        textAlign: 'left',
        marginBottom: '20px',
    },
    logo: {
        width: '100px',
        height: 'auto',
    },
    title: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',

    },
    companyDetails: {
        fontSize: '14px',
        lineHeight: '1.6',
    },
    section: {
        marginTop: '10px',
    },

    subTitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        marginTop: '20px',
    },
    sectionTitle: {
        fontSize: '13px',
        fontWeight: 'bold',
        marginTop: '15px',
        marginBottom: '5px',

    },
    footer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '40px',
    },
    signatureBlock: {
        textAlign: 'left',
        fontSize: '14px',
    }
};