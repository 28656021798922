import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Backdrop, } from '@mui/material';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Card } from "@mui/material";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import axios from 'axios';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import * as AiIcons from "react-icons/ai";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';

export const Salesonboard = () => {
    const [usersData, setUserData] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [commentInput, setCommentInput] = useState("");
    const navigate = useNavigate();
    const [availableMonths, setAvailableMonths] = useState([]);
    const months = [
        'JAN', 'FEB', 'MARCH', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];

    const salesTypes = [
        { value: 'LEAD', label: 'LEAD' },
        { value: 'PROSPECT', label: 'PROSPECT' },
        { value: 'OPPURTUNITY', label: 'OPPURTUNITY' }
    ];
    useEffect(() => {
        const currentDate = new Date();
        const currentMonthIndex = currentDate.getMonth();
        const futureMonths = months.slice(currentMonthIndex);
        const monthOptions = futureMonths.map(month => ({
            value: month,
            label: month
        }));
        setAvailableMonths(monthOptions);
        if (formData.month && !futureMonths.includes(formData.month)) {
            setFormData(prev => ({
                ...prev,
                month: ''
            }));
        }
    }, []);

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        setUserData(userData_Local);
    }, []);

    const username = usersData?.userName;

    const getCurrentDateTimeString = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const date = String(currentDate.getDate()).padStart(2, '0');
        const hours = String(currentDate.getHours()).padStart(2, '0');
        const minutes = String(currentDate.getMinutes()).padStart(2, '0');
        return `${year}-${month}-${date}`;
    };

    useEffect(() => {
        if (username) {
            setFormData(prevState => ({
                ...prevState,
                created_by: username,
                updated_by: username
            }));
        }
    }, [username]);

    const [formData, setFormData] = useState({
        customerName: '',
        customerEmail: '',
        org_name: '',
        customerMobileNum: '',
        customerAddress: '',
        customerGST: '',
        org: org,
        month: '',
        salesType: '',
        salesYear: getCurrentDateTimeString(),
        created_date_time: getCurrentDateTimeString(),
        updated_date_time: getCurrentDateTimeString(),
        comment: [],
        created_by: '',
        updated_by: ''
    });

    const handleCommentChange = (e) => {
        setCommentInput(e.target.value);
    };

    const validationRules = {
        customerEmail: (value) => {
            if (!value) return 'This field is required';
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) return 'Invalid email format';
            return '';
        },
        customerMobileNum: (value) => {
            if (!value.trim()) return "Organization Mobile is required";
            if (!/^\d{10}$/.test(value)) return "Mobile number must be 10 digits";
            if (parseInt(value[0]) < 6) return "Mobile number must start with 6 or greater";
            return '';
        },

        default: (value) => !value ? 'This field is required' : ''
    };

    const requiredFields = [
        'customerName',
        'customerEmail',
        'org_name',
        'customerMobileNum',
        'customerAddress',
        'month',
        'salesType',
        'salesYear',
    ];

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (requiredFields.includes(name)) {
            const validationRule = validationRules[name] || validationRules.default;
            const fieldError = validationRule(value);
            setErrors(prev => ({ ...prev, [name]: fieldError }));
        }
    };
    const handleSelectChange = (selectedOption, actionMeta) => {
        const name = actionMeta.name;
        const value = selectedOption ? selectedOption.value : '';

        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));

        const error = validationRules[name] ? validationRules[name](value) : '';
        setErrors(prev => ({
            ...prev,
            [name]: error
        }));
    };

    const validateFields = () => {
        const newErrors = {};

        requiredFields.forEach(field => {
            const validationRule = validationRules[field] || validationRules.default;
            const fieldError = validationRule(formData[field]);
            if (fieldError) {
                newErrors[field] = fieldError;
            }
        });

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        // console.log(formData);
        setErrors({});
        if (!validateFields()) {
            toast.error("Please fill in all required fields correctly.");
            setLoading(false);
            return;
        }
        const dataToSubmit = {
            customerName: formData.customerName,
            customerEmail: formData.customerEmail,
            org_name: formData.org_name,
            customerMobileNum: formData.customerMobileNum,
            customerAddress: formData.customerAddress,
            customerGST: formData.customerGST,
            org: formData.org,
            month: formData.month,
            salesType: formData.salesType,
            salesYear: formData.salesYear,
            created_date_time: formData.created_date_time,
            updated_date_time: formData.updated_date_time,
            created_by: username,
            updated_by: username,
            comment: commentInput ? [commentInput] : ['No comment']
        };

        console.log(dataToSubmit);

        try {
            const response = await axios.post(APIData.api + 'customer-sales', dataToSubmit, {
                headers: APIData.headers
            });

            console.log(response);

            Swal.fire({
                icon: "success",
                title: "Form data submitted successfully",
                showConfirmButton: false,
                timer: 1500
            });
            setTimeout(() => {
                navigate('/customerSalesOnboard');
            }, 1500);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data || "An error occurred",
            });
            console.error('Error submitting form data:', error);
        } finally {
            setLoading(false);
        }
    };


    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} sm={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/customerSalesOnboard">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Customer Sales Onboard
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Customer Name *</SoftTypography>
                                    <SoftInput
                                        placeholder="Customer Name"
                                        name="customerName"
                                        value={formData.customerName}
                                        onChange={handleChange}
                                        error={!!errors.customerName}
                                        required
                                    />
                                    {errors.customerName && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.customerName}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Customer Email *</SoftTypography>
                                    <SoftInput
                                        type="email"
                                        placeholder="Customer Email"
                                        name="customerEmail"
                                        value={formData.customerEmail}
                                        onChange={handleChange}
                                        error={!!errors.customerEmail}
                                        required
                                    />
                                    {errors.customerEmail && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.customerEmail}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Organization Name *</SoftTypography>
                                    <SoftInput
                                        placeholder="Organization Name"
                                        name="org_name"
                                        value={formData.org_name}
                                        onChange={handleChange}
                                        error={!!errors.org_name}
                                        required
                                    />
                                    {errors.org_name && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.org_name}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Phone Number *</SoftTypography>
                                    <SoftInput
                                        placeholder="Phone Number"
                                        name="customerMobileNum"
                                        value={formData.customerMobileNum}
                                        onChange={handleChange}
                                        error={!!errors.customerMobileNum}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        inputProps={{
                                            maxLength: 10,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                        }}
                                        required
                                    />
                                    {errors.customerMobileNum && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.customerMobileNum}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Customer Address *</SoftTypography>
                                    <SoftInput
                                        placeholder="Customer Address"
                                        name="customerAddress"
                                        value={formData.customerAddress}
                                        onChange={handleChange}
                                        error={!!errors.customerAddress}
                                        required
                                    />
                                    {errors.customerAddress && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.customerAddress}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Customer GST</SoftTypography>
                                    <SoftInput
                                        placeholder="Customer GST (Optional)"
                                        name="customerGST"
                                        value={formData.customerGST}
                                        onChange={handleChange}
                                    />
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Month *</SoftTypography>
                                    <CustomSelect
                                        options={availableMonths}
                                        placeholder="Select Month"
                                        name="month"
                                        onChange={handleSelectChange}
                                        value={formData.month}
                                        error={!!errors.month}
                                        required
                                    />
                                    {errors.month && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.month}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Sales Type *</SoftTypography>
                                    <CustomSelect
                                        options={salesTypes}
                                        placeholder="Select Sales Type"
                                        name="salesType"
                                        onChange={handleSelectChange}
                                        value={formData.salesType}
                                        error={!!errors.salesType}
                                        required
                                    />
                                    {errors.salesType && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.salesType}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Sales Year *</SoftTypography>
                                    <SoftInput
                                        placeholder="Sales Year"
                                        name="salesYear"
                                        value={formData.salesYear.substring(0, 4)}
                                        onChange={handleChange}
                                        error={!!errors.salesYear}
                                        required

                                    />
                                    {errors.salesYear && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.salesYear}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Comments</SoftTypography>
                                    <SoftInput
                                        placeholder="Add a comment"
                                        value={commentInput}
                                        onChange={handleCommentChange}
                                        multiline
                                        rows={3}
                                    />
                                </SoftBox>


                                <SoftBox mt={4} mb={1}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        Submit
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};