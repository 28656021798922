import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Backdrop, DialogContent, DialogTitle, Dialog, DialogActions, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Card } from "@mui/material";
import axios from 'axios';
import { APIData, org } from 'authentication/APIData';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import { Info } from 'lucide-react';

const templateEnums = {
    HR: 'CANDIDATE_NEXT_ROUND',
    TECHNICAL: 'CANDIDATE_NEXT_ROUND',
    FINANACE: 'PROCURMENT',
    SALES: 'EMPLOYEE_ASSESTS',
    ACADEMY: 'USER_LOGIN',
    EVENTS: 'FESTIVAL',
    ENROLLMENTS: 'JOB_APPLY',
    ORGCOMM: 'OTP',
    SUPPORT: 'TICKET',
};

const displayNames = {
    HR: 'Hr',
    TECHNICAL: 'TechnicalL',
    FINANACE: 'Finance',
    SALES: 'Sales',
    ACADEMY: 'Academy',
    EVENTS: 'Events',
    ENROLLMENTS: 'Enrollments',
    ORGCOMM: 'Communication',
    SUPPORT: 'Support',
};

const AddEmails = ({ onSubmit, submitRef }) => {
    const [loading, setLoading] = useState(true);
    const [formStates, setFormStates] = useState({
        HR: { emailId: '', email_error: '' },
        TECHNICAL: { emailId: '', email_error: '' },
        FINANACE: { emailId: '', email_error: '' },
        SALES: { emailId: '', email_error: '' },
        ACADEMY: { emailId: '', email_error: '' },
        EVENTS: { emailId: '', email_error: '' },
        ENROLLMENTS: { emailId: '', email_error: '' },
        ORGCOMM: { emailId: '', email_error: '' },
        SUPPORT: { emailId: '', email_error: '' },
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogFields, setDialogFields] = useState({ templates: '', subject: '', to: '', purpose: '' });
    const [dataExists, setDataExists] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        fetchEmpDetails();
        checkExistingData();
        window.scrollTo(0, 0);
    }, []);

    const fetchEmpDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${APIData.api}login-type/Details?org=${org}`, { headers: APIData.headers });
            const emails = {};
            const data = Array.isArray(response.data) ? response.data : [response.data];
            const superAdmin = data.find(user => user.role === 'SUPERADMIN');
            if (superAdmin) {
                Object.keys(templateEnums).forEach(key => {
                    emails[key] = superAdmin.email_id;
                });
            }
            setFormStates(prevStates => ({
                ...prevStates,
                ...Object.keys(emails).reduce((acc, key) => {
                    acc[key] = { ...prevStates[key], emailId: emails[key] || '' };
                    return acc;
                }, {}),
            }));
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    const checkExistingData = async () => {
        try {
            const response = await axios.get(`${APIData.api}asset-email/all?org=${org}`, { headers: APIData.headers });
            if (response.data && response.data.length > 0) {
                setDataExists(true);
            }
        } catch (error) {
            console.error(error);
        }
    };

    const validation = (formState) => {
        let email_error = '';
        if (!formState.emailId) {
            email_error = "Invalid Email Id";
        }
        return { email_error };
    };

    const changeHandler = (e, purpose) => {
        const { name, value } = e.target;
        setFormStates(prevStates => ({
            ...prevStates,
            [purpose]: {
                ...prevStates[purpose],
                [name]: value
            }
        }));
    };

    const validateAllForms = () => {
        let isValid = true;
        let updatedFormStates = { ...formStates };

        Object.keys(formStates).forEach((purpose) => {
            const { email_error } = validation(formStates[purpose]);
            if (email_error) {
                updatedFormStates[purpose] = {
                    ...formStates[purpose],
                    email_error
                };
                isValid = false;
            }
        });

        setFormStates(updatedFormStates);
        return isValid;
    };

    const handleCheck = (purpose) => {
        setDialogFields((prevState) => ({
            ...prevState,
            templates: templateEnums[purpose],
            purpose: purpose,
        }));
        setDialogOpen(true);
    };

    const handleDialogChange = (e) => {
        const { name, value } = e.target;
        setDialogFields(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const validateDialogFields = () => {
        let errors = {};
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (!dialogFields.subject) {
            errors.subject_error = "Subject is required";
        }

        if (!dialogFields.to) {
            errors.to_error = "To email is required";
        } else if (!emailPattern.test(dialogFields.to)) {
            errors.to_error = "Invalid email format";
        }

        setDialogFields(prevState => ({
            ...prevState,
            ...errors,
        }));

        return Object.keys(errors).length === 0;
    };

    const handleCheck2 = () => {
        setLoading(true);
        if (validateDialogFields()) {
            const formData = new FormData();
            formData.append('templates', dialogFields.templates);
            formData.append('subject', dialogFields.subject);
            formData.append('org', org);
            formData.append('to', dialogFields.to);
            formData.append('purpose', dialogFields.purpose);

            axios.post(`${APIData.api}EmailTemplates/send`, formData, {
                headers: {
                    ...APIData.headers,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(response => {
                    Swal.fire({
                        icon: "success",
                        title: `${dialogFields.purpose}: ${response.data}`,
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setDialogFields({
                        templates: '',
                        subject: '',
                        to: '',
                        purpose: '',
                    });
                    setDialogOpen(false);
                    setLoading(false);
                })
                .catch(error => {
                    Swal.fire({
                        icon: "error",
                        title: "Update Error",
                        text: `${dialogFields.purpose}: ${error}`
                    });
                    console.log(error);
                    setLoading(false);
                });
        } else {
            toast.error("Please enter the details in the form.");
        }
    };
    const getButtonTooltipText = (purpose) => {
        return `Test how ${displayNames[purpose]} department emails work by sending a sample email template`;
    };
    const submitAllForms = (e) => {
        e.preventDefault();
        setLoading(true);
        if (validateAllForms()) {
            const sendStateArray = Object.keys(formStates).map((purpose) => {
                const formState = formStates[purpose];
                return {
                    org: org,
                    id: 0,
                    emailId: formState.emailId,
                    client: "client",
                    password: "",
                    purpose: purpose,
                    createdDate: '',
                    updatedBy: '',
                    updatedDate: ''
                };
            });
            console.log(sendStateArray);

            axios.post(APIData.api + 'asset-email/create', sendStateArray, { headers: APIData.headers })
                .then(response => {
                    Swal.fire({
                        icon: "success",
                        title: "Successfully Updated",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    setLoading(false);
                    onSubmit(true);

                })
                .catch(error => {
                    console.error("Error response:", error);
                    Swal.fire({
                        icon: "error",
                        title: "Update Error",
                        showConfirmButton: false,
                        timer: 1500,
                        text: error.response.data
                        
                    });
                    setLoading(false);
                    onSubmit(true);
                });
        } else {
            setLoading(false);
            onSubmit(true);
        }
    };

    return (
        <Grid container spacing={0} justifyContent="center" alignItems="center" >
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* {dataExists ? (
                <SoftTypography variant="h6" color="green" >
                    You have already entered the details.
                </SoftTypography>
            ) : ( */}
                <Card sx={{ height: 'auto', }}>
                    <SoftBox pt={1} pb={3} px={3}>
                        <SoftBox component="form" onSubmit={submitAllForms}>
                            <Grid container spacing={2}>
                                {Object.keys(formStates).map((purpose) => (
                                    <Grid item xs={12} sm={6} md={3} key={purpose}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6">{displayNames[purpose]}</SoftTypography>
                                            <SoftTypography variant="h6" >Email id</SoftTypography>
                                            <SoftInput
                                                placeholder="Email ID"
                                                name="emailId"
                                                size="small"
                                                value={formStates[purpose].emailId}
                                                onChange={(e) => changeHandler(e, purpose)}
                                                error={!!formStates[purpose].email_error}
                                                required />
                                            {formStates[purpose].email_error && (
                                                <SoftTypography variant="caption" color="error">
                                                    {formStates[purpose].email_error}
                                                </SoftTypography>
                                            )}
                                            <SoftBox mt={2} display="flex" alignItems="center" gap={2}>
                                            <Tooltip title={getButtonTooltipText(purpose)} arrow>
                                                <div>
                                                    <SoftButton
                                                        variant="outlined"
                                                        color="primary"
                                                        size="small"
                                                        onClick={() => handleCheck(purpose)}
                                                        style={{ maxWidth: "30%" }}
                                                    >
                                                        Validate
                                                    </SoftButton>
                                                </div>
                                            </Tooltip>
                                    
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                            ))}
                            </Grid>
                            <SoftBox mt={4} mb={1} >
                                <Grid container justifyContent="center">
                                    <Grid item>
                                          <button
                                            type="submit"
                                            ref={submitRef}
                                            style={{ display: 'none' }}
                                        />
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </Card>
            
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>{dialogFields.purpose} Related Email Check</DialogTitle>
                <DialogContent>
                    <SoftBox mb={2}>
                        <SoftTypography variant="h6" >Subject</SoftTypography>
                        <SoftInput
                            autoFocus
                            margin="dense"
                            name="subject"
                            label="Subject"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={dialogFields.subject}
                            onChange={handleDialogChange}
                            error={!!dialogFields.subject_error}
                        />
                    </SoftBox>
                    <SoftBox mb={2}>
                        <SoftTypography variant="h6" >Email to be Sent</SoftTypography>
                        <SoftInput
                            margin="dense"
                            name="to"
                            label="To Email"
                            type="text"
                            fullWidth
                            variant="outlined"
                            value={dialogFields.to}
                            onChange={handleDialogChange}
                            error={!!dialogFields.to_error}
                        />
                    </SoftBox>
                </DialogContent>
                <DialogActions>
                    <SoftButton onClick={() => setDialogOpen(false)}>Cancel</SoftButton>
                    <SoftButton onClick={handleCheck2}>Send Mail</SoftButton>
                </DialogActions>
            </Dialog>
        </Grid>
    );
};

AddEmails.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitRef: PropTypes.object,
};
export default AddEmails;