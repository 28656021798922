import { Center, Stack, Text } from "@mantine/core";
import React, { useState, useEffect } from "react";
import { FcDeleteDatabase } from "react-icons/fc";
import { Link } from "react-router-dom";
import { MdDeleteSweep } from "react-icons/md";
import { org } from "authentication/APIData";
import { apiData } from "authentication/APIData";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

const DeletQuestion = () => {
  const isLoggedIn = localStorage.getItem("user") !== null;

  const [questions, setQuestions] = useState([]);
  const [expandedQuestion, setExpandedQuestion] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const username = "c4esathyaraj_raj";
    const password = "Welcome";
    const headers = new Headers();
    headers.set("Authorization", "Basic " + btoa(`${username}:${password}`));

    try {
      const response = await fetch(`${apiData.apiUrl}/mcq?org=${org}`, {
        method: "GET",
        headers: headers,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok.");
      }

      const data = await response.json();
      setQuestions(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleQuestionClick = (id) => {
    if (expandedQuestion === id) {
      setExpandedQuestion(null);
    } else {
      setExpandedQuestion(id);
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete the question?"
    );

    if (confirmDelete) {
      const username = "c4esathyaraj_raj";
      const password = "Welcome";
      const headers = new Headers();
      headers.set("Authorization", "Basic " + btoa(`${username}:${password}`));

      try {
        const response = await fetch(
          `${apiData.apiUrl}/mcq/id?id=${id}`,
          {
            method: "DELETE",
            headers: headers,
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete question");
        }

        fetchData();
      } catch (error) {
        console.error("Error deleting question:", error);
      }
    }
  };

  return (
    <>
    <DashboardLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "10px",
        }}
      >
        <Stack
          bg=""
          style={{
            borderRadius: "6px",
            boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
          }}
          p={10}
          w={"85%"}
          maw={{ xs: "95%", sm: "90%", md: "70%", xl: "50%" }}
        >
          <Center
            style={{ display: "flex", flexDirection: "column" }}
            c={"cyan.9"}
          >
            <Text size="1.5rem" fw={"600"} mb={"md"}>
               Questions
            </Text>
            <div style={{ marginBottom: "1rem" }}>
              {questions.map((question) => (
                <div
                  key={question.id}
                  style={{ borderBottom: "1px solid #ccc", padding: "1rem", display:"flex", gap:"1.2rem" }}
                >
                  <button
                    onClick={() => handleDelete(question.id)}
                    style={{
                      backgroundColor: "#E03131",
                      color: "#fff",
                      padding: "0.5rem",
                      border: "none",
                      borderRadius: "4px",
                      cursor: "pointer",
                    }}
                  >
                    <MdDeleteSweep />
                  </button>
                  <h4
                    onClick={() => handleQuestionClick(question.id)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "0.5rem",
                      cursor:"pointer"
                    }}
                  >
                    {question.question}
                  </h4>
                  {expandedQuestion === question.id && (
                    <ul style={{ listStyle: "none", padding: 0 }}>
                      <div style={{display:"flex",flexWrap:"wrap",flexDirection:"row",justifyContent:"center", alignItems:"center"}}> 
                      {question.options.map((option, index) => (
                        <li key={index} style={{ marginBottom: "0.5rem" }}>
                          <label style={{ marginLeft: "1rem" }}>
                            <input
                              type="radio"
                              name={`question-${question.id}`}
                              value={option}
                            />
                            {option}
                          </label>
                        </li>
                      ))}
                    </div>
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </Center>
        </Stack>
      </div>
      </DashboardLayout>
    </>
  );
};
export default DeletQuestion;
