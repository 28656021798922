import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { APIData } from 'authentication/APIData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from 'components/SoftButton';
import SoftInput from "components/SoftInput";
import { Card, Grid, Tooltip, FormControl, FormHelperText } from "@mui/material";
import * as AiIcons from "react-icons/ai";
import Swal from 'sweetalert2';
import axios from 'axios';
import { org } from 'authentication/APIData';

const EditEvent = () => {
    const { id } = useParams();
    const [eventDetails, setEventDetails] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [mobileImagePreview, setMobileImagePreview] = useState(null);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchEventDetails = async () => {
            const url = `${APIData.api}events/id?id=${id}`;
            try {
                const response = await fetch(url, { headers: APIData.headers });
                if (response.ok) {
                    const data = await response.json();
                    setEventDetails(data);
                    if (data.image) setImagePreview(data.image);
                    if (data.mobile_image) setMobileImagePreview(data.mobile_image);
                } else {
                    Swal.fire('Error', 'Failed to fetch event details', 'error');
                }
            } catch (error) {
                console.error('Error fetching event details:', error);
                Swal.fire('Error', 'Failed to fetch event details', 'error');
            }
        };

        fetchEventDetails();
    }, [id]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEventDetails(prevDetails => ({ ...prevDetails, [name]: value }));
        setErrors(prev => ({ ...prev, [name]: '' }));
    };

    const handleImageChange = (e, imageType) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "File too large. Max file size is 1MB.",
                });
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                console.log(reader.result);
                if (imageType === 'image') {
                    setImagePreview(reader.result);
                    setEventDetails(prev => ({ ...prev, image: reader.result }));
                } else {
                    setMobileImagePreview(reader.result);
                    setEventDetails(prev => ({ ...prev, mobile_image: reader.result }));
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSaveChanges = async (e) => {
        e.preventDefault();
        const newErrors = {};

        if (!eventDetails.event_name || /^\d+$/.test(eventDetails.event_name)) {
            newErrors.event_name = "Event name is required and must not be only numbers.";
        }
        if (!eventDetails.event_date || new Date(eventDetails.event_date) < new Date()) {
            newErrors.event_date = "Event date is required and must not be in the past.";
        }
        if (!eventDetails.location || /^\d+$/.test(eventDetails.location) || /^[!@#$%^&*(),.?":{}|<>]+$/.test(eventDetails.location) || eventDetails.location.length > 255) {
            newErrors.location = "Location is required, must not be only numbers or special characters, and must not exceed 255 characters.";
        }
        if (eventDetails.description && (/^\d+$/.test(eventDetails.description) || /^[!@#$%^&*(),.?":{}|<>]+$/.test(eventDetails.description) || eventDetails.description.length > 255)) {
            newErrors.description = "Description must not be only numbers or special characters, and must not exceed 255 characters.";
        }
        if (eventDetails.duration && !/(0[1-9]|1[0-2]):[0-5][0-9](am|pm) to (0[1-9]|1[0-2]):[0-5][0-9](am|pm)/.test(eventDetails.duration)) {
            newErrors.duration = "Duration must be in the format 'hh:mmam to hh:mmpm' (e.g., 10:00am to 12:00pm).";
        }
        if (eventDetails.organizer && (/^\d+$/.test(eventDetails.organizer) || /^[!@#$%^&*(),.?":{}|<>]+$/.test(eventDetails.organizer))) {
            newErrors.organizer = "Organizer must not contain numbers or special characters.";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length > 0) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please fix the following errors and try again:',
                footer: Object.values(newErrors).join('<br>')
            });
            return;
        }

        setLoading(true);

        const imageFormData = new FormData();
        imageFormData.append('id', id);
        imageFormData.append('event_name', eventDetails.event_name);
        imageFormData.append('event_date', eventDetails.event_date);
        imageFormData.append('location', eventDetails.location);
        imageFormData.append('organizer', eventDetails.organizer || '');
        imageFormData.append('description', eventDetails.description || '');
        imageFormData.append('duration', eventDetails.duration || '');

        if (imagePreview) imageFormData.append('image', imagePreview);
        if (mobileImagePreview) imageFormData.append('mobile_image', mobileImagePreview);

        const putUrl = `${APIData.api}events/?id=${id}&org=${org}`;

        try {
            const response = await axios.put(putUrl, imageFormData, {
                headers: { 
                    ...APIData.headers, 
                    'Content-Type': 'multipart/form-data' 
                },
            });

            if (response.status === 200 || response.status === 201 ) {
                Swal.fire({
                    icon: 'success',
                    title: 'Event details updated successfully',
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    navigate('/events');
                }, 1500);
            } else {
                Swal.fire('Error', 'Failed to update event details', 'error');
            }
        } catch (error) {
            console.error('Error updating event:', error);
            Swal.fire('Error', 'Failed to update event details', 'error');
        }
        setLoading(false);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Tooltip title="Close" arrow>
                                <Link to="/events">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Edit Event
                            </SoftTypography>
                        </SoftBox>
                        {eventDetails ? (
                            <SoftBox component="form" role="form" onSubmit={handleSaveChanges} px={3}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Event Name *</SoftTypography>
                                    <FormControl fullWidth error={!!errors.event_name}>
                                        <SoftInput
                                            placeholder="Event Name"
                                            name="event_name"
                                            value={eventDetails.event_name || ''}
                                            onChange={handleInputChange}
                                        />
                                        {errors.event_name && (
                                            <FormHelperText>{errors.event_name}</FormHelperText>
                                        )}
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Event Date *</SoftTypography>
                                    <FormControl fullWidth error={!!errors.event_date}>
                                        <SoftInput
                                            type="date"
                                            name="event_date"
                                            value={eventDetails.event_date || ''}
                                            onChange={handleInputChange}
                                        />
                                        {errors.event_date && (
                                            <FormHelperText>{errors.event_date}</FormHelperText>
                                        )}
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Duration</SoftTypography>
                                    <FormControl fullWidth>
                                        <SoftInput
                                            placeholder="e.g., 2 hours"
                                            name="duration"
                                            value={eventDetails.duration || ''}
                                            onChange={handleInputChange}
                                        />
                                        <SoftTypography variant="caption">{"Duration must be in the format 'hh:mmam to hh:mmpm' (e.g., 10:00am to 12:00pm)"}</SoftTypography>
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Location *</SoftTypography>
                                    <FormControl fullWidth error={!!errors.location}>
                                        <SoftInput
                                            placeholder="Location"
                                            name="location"
                                            value={eventDetails.location || ''}
                                            onChange={handleInputChange}
                                        />
                                        {errors.location && (
                                            <FormHelperText>{errors.location}</FormHelperText>
                                        )}
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Organizer</SoftTypography>
                                    <FormControl fullWidth>
                                        <SoftInput
                                            placeholder="Organizer"
                                            name="organizer"
                                            value={eventDetails.organizer || ''}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Description</SoftTypography>
                                    <FormControl fullWidth>
                                        <SoftInput
                                            multiline
                                            rows={4}
                                            placeholder="Enter event description..."
                                            name="description"
                                            value={eventDetails.description || ''}
                                            onChange={handleInputChange}
                                        />
                                    </FormControl>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                                                <input
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="desktop-image"
                                                    type="file"
                                                    onChange={(e) => handleImageChange(e, 'image')}
                                                />
                                                <label htmlFor="desktop-image">
                                                    <SoftButton variant="contained" component="span">
                                                        Upload Desktop Image
                                                    </SoftButton>
                                                </label>
                                                {imagePreview && (
                                                    <SoftBox mt={2}>
                                                        <img
                                                            src={imagePreview}
                                                            alt="Desktop Preview"
                                                            style={{ width: '200px', height: 'auto', objectFit: 'contain' }}
                                                        />
                                                    </SoftBox>
                                                )}
                                            </SoftBox>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                                                <input
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    id="mobile-image"
                                                    type="file"
                                                    onChange={(e) => handleImageChange(e, 'mobile_image')}
                                                />
                                                <label htmlFor="mobile-image">
                                                    <SoftButton variant="contained" component="span">
                                                        Upload Mobile Image
                                                    </SoftButton>
                                                </label>
                                                {mobileImagePreview && (
                                                    <SoftBox mt={2}>
                                                        <img
                                                            src={mobileImagePreview}
                                                            alt="Mobile Preview"
                                                            style={{ width: '200px', height: 'auto', objectFit: 'contain' }}
                                                        />
                                                    </SoftBox>
                                                )}
                                            </SoftBox>
                                        </Grid>
                                    </Grid>
                                </SoftBox>

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        fullWidth
                                        disabled={loading}
                                    >
                                        {loading ? "Saving Changes..." : "Save Changes"}
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        ) : (
                            <SoftBox p={3} className="text-center">
                                <SoftTypography variant="h6" color="text">
                                    Loading event details...
                                </SoftTypography>
                            </SoftBox>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default EditEvent;