import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData, org } from '../authentication/APIData';
import breakpoints from "assets/theme/base/breakpoints";
import SoftButton from 'components/SoftButton';
import { Link } from 'react-router-dom';

const Name = ({ name, email }) => {
  const [showFullName, setShowFullName] = useState(false);
  const nameWords = name.split(" ");

  const handleToggleFullName = () => {
    setShowFullName(!showFullName);
  };

  const displayedName = showFullName || nameWords.length <= 3
    ? name
    : `${nameWords.slice(0, 3).join(" ")}...`;

  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar
          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
          alt={name}
          size="sm"
          variant="rounded"
        />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {displayedName}
        </SoftTypography>
        {nameWords.length > 3 && (
          <SoftTypography
            variant="caption"
            color="info"
            onClick={handleToggleFullName}
            style={{ cursor: "pointer" }}
          >
            {showFullName ? "Show Less" : "Read More"}
          </SoftTypography>
        )}
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

Name.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

const Organization = ({ salesType, orgName }) => (
  <SoftBox display="flex" flexDirection="column">
    <SoftTypography variant="caption" fontWeight="medium" color="text">
      {salesType}
    </SoftTypography>
    <SoftTypography variant="caption" color="secondary">
      {orgName}
    </SoftTypography>
  </SoftBox>
);

Organization.propTypes = {
  salesType: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};

const CustSuportData = () => {
  const [suptData, setSuptData] = useState([]);
  const [status, setStatus] = useState("All");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(parseInt(accessLevel)); // Convert to number for comparison
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    return setTabValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {
      const baseUrl = `${APIData.api}customer-onboard/?org=${org}`;
      try {
        const response = await fetch(baseUrl, { headers: APIData.headers });
        const data = await response.json();
        setSuptData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [tabValue, isSuperAdmin]);


  const shouldShowActions = () => {
    if (permission === 1100 || permission === 1000) {
      return false;
    }
    return isSuperAdmin || userDepartment === "SALES";
  };

  const columns = [
    { name: "customerId", align: "left" },
    { name: "phone", align: "left" },
    { name: "productName", align: "left" },
    { name: "software", align: "left" },
    { name: "warrantyStartDate", align: "left" },
    { name: "warrantyEndDate", align: "left" },
    // ...(shouldShowActions() ? [{ name: "Action", align: "center" }] : []),
  ];

  const rows = suptData.map((supt) => ({
    customerId: <Name name={supt.customerId} email={supt.companyName} />,
    phone: <Organization orgName={supt.phone} />,
    productName: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {supt.productName}
      </SoftTypography>
    ),
    software: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {supt.software}
      </SoftTypography>
    ),
    warrantyStartDate: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {supt.warrantyStartDate}
      </SoftTypography>
    ),
    warrantyEndDate: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {supt.warrantyEndDate}
      </SoftTypography>
    ),
    // ...(shouldShowActions() ? {
    //   Action: (
    //     <>
    //       <Link to={`/orgmdmupdate/`}>
    //         <SoftTypography
    //           component="a"
    //           href="#"
    //           variant="button"
    //           color="secondary"
    //           fontWeight="medium"
    //         >
    //           Edit
    //         </SoftTypography>
    //       </Link>
    //     </>
    //   )
    // } : {}),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Support to customers</SoftTypography>
            {shouldShowActions() && (
              <Link to='/customer-onboard'>
                <SoftButton>+ New Customer</SoftButton>
              </Link>
            )}
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={columns} rows={rows} />
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CustSuportData;