import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import {
    Card,
    Grid,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    FormHelperText,
    Tooltip,
} from "@mui/material";
import SoftInput from "components/SoftInput";
import { APIData, org } from "authentication/APIData";
import axios from "axios";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import * as AiIcons from "react-icons/ai";

function AddNewPolicy() {
    const [adminId, setAdminId] = useState("");
    const [policyAudience, setPolicyAudience] = useState("");
    const [policyName, setPolicyName] = useState("");
    const [policyType, setPolicyType] = useState("");
    const [policyDescription, setPolicyDescription] = useState("");
    const [policyData, setPolicyData] = useState("");
    const [policyNote, setPolicyNote] = useState("");
    const [policyDate, setPolicyDate] = useState("");
    const [policyDescription2, setPolicyDescription2] = useState("");
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [userdata, setUserData] = useState(null); // Initialize as null

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        if (userData_Local) {
            setUserData(userData_Local);
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const newErrors = {};

        if (!policyAudience) newErrors.policyAudience = "Policy audience is required.";
        if (!policyName) newErrors.policyName = "Policy name is required.";
        if (!policyType) newErrors.policyType = "Policy type is required.";
        if (!policyDescription) newErrors.policyDescription = "Policy description is required.";
        if (!policyDate) newErrors.policyDate = "Policy date is required.";
        if (!org) newErrors.org = "Organization is required.";

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                setLoading(true);

                const formattedPolicyDate = new Date(policyDate).toISOString();
                const policyDataToSubmit = {
                    policy_id: 0,
                    admin_id: userdata ? userdata.userName : '', // Use conditional access
                    policy_audience: policyAudience,
                    policy_name: policyName,
                    policy_type: policyType,
                    policy_description: policyDescription,
                    policy_data: policyData,
                    policy_note: policyNote,
                    policy_date: formattedPolicyDate,
                    policy_description2: policyDescription2,
                    created_by:userdata.name,
                    org: org,
                    created_date_time: new Date().toISOString(),
                    updated_by: "",
                    updated_date_time: null,
                };

                console.log(policyDataToSubmit);

                const url = `${APIData.api}policies/`;
                const response = await axios.post(url, policyDataToSubmit, { headers: APIData.headers });
                console.log(response);

                Swal.fire({
                    icon: "success",
                    title: "Policy Added Successfully",
                    showConfirmButton: false,
                    timer: 1500,
                });

                setTimeout(() => {
                    navigate("/policy-list");
                }, 1500);
            } catch (error) {
                console.error("Error details:", error.response ? error.response.data : error.message);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Failed to add policy",
                    footer: error.message,
                });
                setLoading(false);
            }
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={2} justifyContent="center" alignItems="center" className="min-h-screen p-4">
                <Grid item xs={10} sm={10} md={8} lg={6}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Tooltip title="Close" arrow>
                                <Link to="/policy-list">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Add New Policy
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Admin ID *</SoftTypography>
                                        <FormControl fullWidth error={!!errors.adminId}>
                                            <SoftInput
                                                placeholder="Admin ID"
                                                value={userdata ? userdata.userName : ''}
                                                onChange={(e) => setAdminId(e.target.value)}
                                                disabled
                                            />
                                            {errors.adminId && <FormHelperText>{errors.adminId}</FormHelperText>}
                                        </FormControl>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Policy Audience *</SoftTypography>
                                        <FormControl fullWidth error={!!errors.policyAudience}>
                                            <SoftInput
                                                placeholder="Policy Audience"
                                                value={policyAudience}
                                                onChange={(e) => setPolicyAudience(e.target.value)}
                                            />
                                            {errors.policyAudience && <FormHelperText>{errors.policyAudience}</FormHelperText>}
                                        </FormControl>
                                    </SoftBox>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Policy Date *</SoftTypography>
                                        <FormControl fullWidth error={!!errors.policyDate}>
                                            <SoftInput
                                                type="date"
                                                value={policyDate}
                                                onChange={(e) => setPolicyDate(e.target.value)}
                                            />
                                            {errors.policyDate && <FormHelperText>{errors.policyDate}</FormHelperText>}
                                        </FormControl>
                                    </SoftBox>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Policy Type *</SoftTypography>
                                        <FormControl fullWidth error={!!errors.policyType}>
                                            <SoftInput
                                                placeholder="Policy Type"
                                                value={policyType}
                                                onChange={(e) => setPolicyType(e.target.value)}
                                            />
                                            {errors.policyType && <FormHelperText>{errors.policyType}</FormHelperText>}
                                        </FormControl>
                                    </SoftBox>
                                </Grid>
                            </Grid>

                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Policy Name *</SoftTypography>
                                <FormControl fullWidth error={!!errors.policyName}>
                                    <SoftInput
                                        placeholder="Policy Name"
                                        value={policyName}
                                        onChange={(e) => setPolicyName(e.target.value)}
                                    />
                                    {errors.policyName && <FormHelperText>{errors.policyName}</FormHelperText>}
                                </FormControl>
                            </SoftBox>

                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Policy Description *</SoftTypography>
                                <FormControl fullWidth error={!!errors.policyDescription}>
                                    <SoftInput
                                        multiline
                                        rows={3}
                                        placeholder="Policy Description"
                                        value={policyDescription}
                                        onChange={(e) => setPolicyDescription(e.target.value)}
                                    />
                                    {errors.policyDescription && <FormHelperText>{errors.policyDescription}</FormHelperText>}
                                </FormControl>
                            </SoftBox>

                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Policy Data</SoftTypography>
                                <FormControl fullWidth>
                                    <SoftInput
                                        multiline
                                        rows={6}
                                        placeholder="Policy Data"
                                        value={policyData}
                                        onChange={(e) => setPolicyData(e.target.value)}
                                    />
                                </FormControl>
                            </SoftBox>

                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Policy Note</SoftTypography>
                                <FormControl fullWidth>
                                    <SoftInput
                                        multiline
                                        rows={2}
                                        placeholder="Policy Note"
                                        value={policyNote}
                                        onChange={(e) => setPolicyNote(e.target.value)}
                                    />
                                </FormControl>
                            </SoftBox>

                            <SoftBox mb={2}>
                                <SoftTypography variant="h6">Policy Description 2</SoftTypography>
                                <FormControl fullWidth>
                                    <SoftInput
                                        multiline
                                        rows={3}
                                        placeholder="Policy Description 2"
                                        value={policyDescription2}
                                        onChange={(e) => setPolicyDescription2(e.target.value)}
                                    />
                                </FormControl>
                            </SoftBox>

                            <SoftBox mt={4} mb={2}>
                                <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                    {loading ? "Loading..." : "Add Policy"}
                                </SoftButton>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default AddNewPolicy;
