import { Overlay, Container, Title, Button, Text } from "@mantine/core";
import classes from "./HeroContentLeft.module.css";
import { Appbar } from "../Appbar/Appbar";
import { Link } from "react-router-dom";
import PageLayout from "examples/LayoutContainers/PageLayout";

export default function Home() {
  return (
    <>
    <Appbar/>
    <PageLayout>
      <div className={classes.hero}>
        <Overlay
          gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
          opacity={1}
          zIndex={0}
          
        />
        <Container className={classes.container} size="md"  pt={200}>
          <Title className={classes.title} >
            MCQ Test to get scholarship of 20% in care4edu
          </Title>
          <Text className={classes.description} size="xl" mt="xl">
            Test can be taken by  


            ASAT scholarship test is open to 9<sup>th</sup> and 11<sup>th</sup> grade students, featuring 30 questions to be completed within a 30-minute timeframe. For 9th-grade participants, questions will be given from the subjects Mathematics, Science, and Social Science, while 11th-grade students focus on Physics, Chemistry, and Mathematics for 1st PUC. Prior to taking the test, participants must explicitly accept the terms and conditions. To commence the test, students need to complete the registration process, ensuring compliance with all outlined procedures.
          </Text>
          <Link to={"/register"}>
          <Button
            variant="gradient"
            size="xl"
            radius="xl"
            className={classes.control}
          >
            Take Test
          </Button>
          </Link>
        </Container>
      </div>
      </PageLayout>
    </>
  );
}
