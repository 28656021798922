import React, { useState, useEffect } from "react";
import { useTheme, useMediaQuery, Grid } from "@mui/material";
import { usePDF } from "react-to-pdf";
import axios from "axios";
import { APIData, org } from "../authentication/APIData";
import photo from "./img back.png";
import PropTypes from 'prop-types';
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";

const QuestionPaperTemplate = ({ data }) => {
    const { toPDF, targetRef } = usePDF({ filename: `${data[0]?.grade}th ${data[0]?.subject} QP` });
    const [logoUrl, setLogoUrl] = useState(photo);
    const [titleName, setTitleName] = useState("");

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    const fetchLogo = () => {
        axios.get(APIData.api + `org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
            .then((resp) => {
                setTitleName(resp.data.orgShortName);
                setLogoUrl(resp.data.orgLogo);
            })
            .catch(error => {
                console.error(error);
                setLogoUrl(photo);
            });
    };

    useEffect(() => {
        fetchLogo();
    }, []);

    if (!data || !Array.isArray(data) || data.length === 0) {
        return (
            <div style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                padding: "2rem",
                minHeight: "200px",
                backgroundColor: "white",
                borderRadius: "8px",
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)"
            }}>
                <img src={logoUrl} alt="Logo" style={{ height: "64px", width: "64px", marginBottom: "1rem" }} />
                <h3 style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    color: "#f97316",
                    letterSpacing: "0.05em",
                    textTransform: "uppercase"
                }}>
                    {titleName}
                </h3>
                <p style={{ color: "#6b7280", marginTop: "1rem" }}>No question paper data available</p>
            </div>
        );
    }

    const toRoman = (num) => {
        const romanNumerals = ["I", "II", "III", "IV", "V"];
        return romanNumerals[num - 1] || num;
    };

    const formatDate = (date) => {
        const d = new Date(date);
        return d.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    };

    const calculateDuration = (totalMarks) => {
        if (totalMarks <= 25) return "1 Hour";
        if (totalMarks <= 35) return "1 Hour 30 Minutes";
        if (totalMarks <= 50) return "2 Hours";
        if (totalMarks <= 75) return "2 Hours 30 Minutes";
        return "3 Hours";
    };

    const calculateTotalMarksAndDuration = () => {
        try {
            const questionCounts = {
                ONE_MARK: 0,
                TWO_MARKS: 0,
                THREE_MARKS: 0,
                FIVE_MARKS: 0,
            };

            let totalMarks = 0;

            if (Array.isArray(data)) {
                data.forEach((item) => {
                    if (item && item.question_type) {
                        const marks = {
                            ONE_MARK: 1,
                            TWO_MARKS: 2,
                            THREE_MARKS: 3,
                            FIVE_MARKS: 5,
                        }[item.question_type] || 0;

                        questionCounts[item.question_type] = (questionCounts[item.question_type] || 0) + 1;
                        totalMarks += marks;
                    }
                });
            }

            return {
                totalMarks,
                duration: calculateDuration(totalMarks),
                questionCounts,
            };
        } catch (error) {
            console.error('Error calculating total marks:', error);
            return {
                totalMarks: 0,
                duration: "0 Hours",
                questionCounts: {},
            };
        }
    };

    const { totalMarks, duration, questionCounts } = calculateTotalMarksAndDuration();

    const distinctQuestionTypes = Object.keys(questionCounts).filter(
        (type) => questionCounts[type] > 0
    );

    const paperStyles = {
        width: isSmallScreen ? "100%" : "900px",
        margin: "0 auto",
        padding: isSmallScreen ? "16px" : "40px",
        backgroundColor: "white",
        borderRadius: "12px",
        boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    };

    const headerInfo = {
        fontSize: isSmallScreen ? "14px" : "16px",
        fontFamily: "'Segoe UI', Arial, sans-serif",
    };

    return (
        <>
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "100vh",
                backgroundColor: "#f3f4f6",
            }}>
                <div style={paperStyles} ref={targetRef} id="pdfContent">
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: "2rem" }}>
                        <img src={logoUrl} alt="Logo" style={{ height: "64px", width: "64px", marginBottom: "1rem" }} />
                        <h2 style={{
                            fontSize: "1.5rem",
                            fontWeight: "bold",
                            color: "#f97316",
                            letterSpacing: "0.05em",
                            textTransform: "uppercase",
                            marginBottom: "2rem"
                        }}>
                            {titleName}
                        </h2>
                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: "1rem", marginBottom: "2rem", ...headerInfo }}>
                        <div>
                            <p style={{ fontWeight: "600" }}>Date: {formatDate(new Date())}</p>
                            <p style={{ fontWeight: "600" }}>Class: {data[0]?.grade || "N/A"}</p>
                        </div>
                        <div style={{ textAlign: "center" }}>
                            <p style={{ fontWeight: "600" }}>Subject: {data[0]?.subject || "N/A"}</p>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <p style={{ fontWeight: "600" }}>Total Marks: {totalMarks}</p>
                            <p style={{ fontWeight: "600" }}>Duration: {duration}</p>
                        </div>
                    </div>

                    <div style={{ marginBottom: "2rem" }}>
                        {distinctQuestionTypes.map((questionType, index) => (
                            <div key={index} style={{ borderBottom: "1px solid #e5e7eb", paddingBottom: "1.5rem", marginBottom: "1.5rem" }}>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    fontWeight: "600",
                                    marginBottom: "1rem"
                                }}>
                                    <span>{toRoman(index + 1)}. Each Question Carries {
                                        {
                                            ONE_MARK: "One",
                                            TWO_MARKS: "Two",
                                            THREE_MARKS: "Three",
                                            FIVE_MARKS: "Five"
                                        }[questionType]
                                    } Marks:</span>
                                    <span style={{ color: "#4b5563" }}>
                                        {questionCounts[questionType]} × {
                                            {
                                                ONE_MARK: 1,
                                                TWO_MARKS: 2,
                                                THREE_MARKS: 3,
                                                FIVE_MARKS: 5
                                            }[questionType]
                                        } = {
                                            questionCounts[questionType] * {
                                                ONE_MARK: 1,
                                                TWO_MARKS: 2,
                                                THREE_MARKS: 3,
                                                FIVE_MARKS: 5
                                            }[questionType]
                                        }
                                    </span>
                                </div>
                                <ol style={{ paddingLeft: "2rem", listStyleType: "decimal" }}>
                                    {data
                                        .filter(item => item && item.question_type === questionType)
                                        .map((question, i) => (
                                            <li key={i} style={{ color: "#374151", lineHeight: "1.5" }}>
                                                <div style={{ marginBottom: "0.5rem" }}>{question.question}</div>
                                                {question.image_url && (
                                                    <img
                                                        src={question.image_url}
                                                        alt="Question"
                                                        style={{
                                                            maxWidth: "100%",
                                                            height: "auto",
                                                            borderRadius: "0.5rem",

                                                        }}
                                                    />
                                                )}
                                            </li>
                                        ))}
                                </ol>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Grid item xs={12}>
                <SoftBox mt={4} mb={1}>
                    <SoftButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={toPDF}
                    >
                         Download PDF
                    </SoftButton>
                </SoftBox>
            </Grid>
        </>
    );
};

QuestionPaperTemplate.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        question: PropTypes.string.isRequired,
        question_type: PropTypes.string.isRequired,
        image_url: PropTypes.string,
        grade: PropTypes.string,
        subject: PropTypes.string
    }))
};

export default QuestionPaperTemplate;
