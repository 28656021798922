import React, { useState, useEffect } from "react";
import { Grid, Container } from "@mui/material";
import { Link } from "react-router-dom";
import { APIData, org } from "authentication/APIData";
import axios from "axios";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import Footer from "examples/Footer";
import DefaultProjectCard1 from "examples/Cards/ProjectCards/DefaultProjectCard/index1";

export const InternalJob = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchInternalJobs();
    }, []);

    const fetchInternalJobs = async () => {
        setLoading(true);
        try {
            const url = APIData.api + `jobs/valid-and-opening-type?valid=YES&openingType=INTERNAL&org=${org}`;
            const response = await axios.get(url, { headers: APIData.headers });
            setData(response.data);
            console.log("Internal Jobs data", response.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox mb={3}>
                <Card>
                    <SoftBox pt={2} px={2}>
                        <SoftBox mb={0.5}>
                            <SoftTypography variant="h5" fontWeight="medium">
                                Internal Job Positions
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox mb={1}>
                            <SoftTypography variant="h6"  color="text">
                                Available Internal Opportunities
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>

                    <SoftBox p={2}>
                        {error ? (
                            <SoftTypography color="error" align="center">
                                {error}
                            </SoftTypography>
                        ) : data.length === 0 ? (
                            <SoftBox display="flex" justifyContent="center" p={3}>
                                <SoftTypography variant="h4" color="text">
                                    No openings currently. Please check back later for updates on internal job postings.
                                </SoftTypography>
                            </SoftBox>
                        ) : (
                            <Grid container spacing={3}>
                                {data.map((job, index) => (
                                    <Grid item xs={12} md={6} xl={3} key={job.id || index}>
                                        <Link 
                                            to={`/internal-job-details/${job.job_id}`}
                                            state={{ job }}
                                            style={{ textDecoration: 'none' }}
                                        >
                                            <DefaultProjectCard1
                                                image={job.image_url}
                                                label={job.job_dept}
                                                title={job.job_designation}
                                              
                                                action={{
                                                    type: "internal",
                                                    route: `/internal-job-details/${job.job_id}`,
                                                    color: "info",
                                                    label: "Know More"
                                                }}
                                            />
                                        </Link>
                                    </Grid>
                                ))}
                            </Grid>
                        )}
                    </SoftBox>
                </Card>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default InternalJob;