import { useDisclosure } from '@mantine/hooks';
import { Modal, Button, Text } from '@mantine/core';
import Avinya from "../../Assets/Images/Avinyalogo.png"
import "./AvinyaLoader.css"
import { useState, useEffect } from "react";
import { org, APIData } from "authentication/APIData";
import { CircularProgress } from '@mui/material';

function AvinyaLoader() {
  const [opened, { open, close }] = useDisclosure(false);
  const [orgLogo, setOrgLogo] = useState();

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
        if (!response.ok) {
          throw new Error("Failed to fetch organization data");
        }
        const data = await response.json();
        // console.log(data);
        setOrgLogo(data.orgLogo);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
    fetchOrgData();
  }, []);

  return (
    <>
      <Modal opened={true} centered size={130} c={"cyan.7"} withCloseButton={false}>

        <Text size="1.2rem" fw={"600"}>
          Loading.....
        </Text>
        <img src={orgLogo} alt="Loading" height={80} width={90} className='avilogo' style={{ marginTop: "30px" }} />
      </Modal>
    </>
  );
}

export default AvinyaLoader; 