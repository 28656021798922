import { org } from "authentication/APIData";
import { c4eApi } from "authentication/APIData";

 export const getAllStudentDetails =async ()=>{
    const studentData = await c4eApi.get(`/score?org=${org}`);
    console.log(studentData.data);
    
    return studentData.data;
}
  export const getAllStudentDetailsByEmail =async (id)=>{
    const studentData = await c4eApi.get("/email?email="+id);
    return studentData.data;
}

  export const getAllStudentScore =async ()=>{
    const studentData = await c4eApi.get(`/score?org=${org}`);
    return studentData.data;
}

  export const getAllStudentScoreByEmail =async (id)=>{
    const studentData = await c4eApi.get("/score/email?email="+id);
    return studentData.data;
}