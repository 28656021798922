import React, { useState, useEffect } from "react";
import axios from "axios";
import { APIData, org } from '../authentication/APIData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import PropTypes from 'prop-types';
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import Swal from "sweetalert2";

const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

const TeacherInfo = ({ name }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

TeacherInfo.propTypes = {
    name: PropTypes.string.isRequired,
};

const classLevelsEnum = [
    "FIRST", "SECOND", "THIRD", "FOURTH", "FIFTH",
    "SIXTH", "SEVENTH", "EIGHTH", "NINTH", "TENTH"
];

const AssignTeacher1 = () => {
    const [courses, setCourses] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [searchTerm, setSearchTerm] = useState("");

    const fetchCourses = async () => {
        try {
            const response = await axios.get(`${APIData.api}courses/?org=${org}`, {
                headers: APIData.headers,
            });
            setCourses(response.data.map(course => ({
                courseId: course.course_id,
                courseName: course.course_id,
            })));
        } catch (error) {
            console.error("Error fetching courses:", error.response || error.message);
        }
    };

    const fetchEmployees = async () => {
        try {
            const response = await axios.get(`${APIData.api}employee/details?org=${org}`, {
                headers: APIData.headers,
            });
            setEmployees(response.data.map(employee => ({
                teacherId: employee.employee_id,
                teacherName: employee.name,
            })));
        } catch (error) {
            console.error("Error fetching employees:", error.response || error.message);
        }
    };

    const fetchExistingAssignments = async () => {
        try {
            const response = await axios.get(`${APIData.api}courses/by-org?org=${org}`, {
                headers: APIData.headers,
            });
            const existingAssignments = response.data.map(assignment => ({
                ...assignment,
                id: assignment.id || 0,
            }));
            setAssignments(existingAssignments);
        } catch (error) {
            console.error("Error fetching existing assignments:", error.response || error.message);
        }
    };

    useEffect(() => {
        Promise.all([fetchCourses(), fetchEmployees(), fetchExistingAssignments()]);
    }, []);

    useEffect(() => {
        if (courses.length > 0) {
            const storedAssignments = JSON.parse(localStorage.getItem('submittedAssignments')) || [];
            
            setAssignments(prevAssignments => {
                const newAssignments = courses.map(course => {
                    // Check for existing assignment in previous state
                    const existingAssignment = prevAssignments.find(a => a.courseId === course.courseId);
                    // Check for stored assignment
                    const storedAssignment = storedAssignments.find(a => a.courseId === course.courseId);
                    
                    // Return existing assignment if found, otherwise create new
                    return existingAssignment || storedAssignment || {
                        id: 0,
                        courseId: course.courseId,
                        courseName: course.courseId,
                        teacherId: '',
                        teacherName: '',
                        classLevels: '',
                        org: org,
                        createDateTime: new Date().toISOString(),
                        createdBy: sessiondetails.userName,
                        updateDateTime: new Date().toISOString(),
                        updatedBy: sessiondetails.userName,
                    };
                });
                return newAssignments;
            });
        }
    }, [courses]);

    useEffect(() => {
        const storedAssignments = JSON.parse(localStorage.getItem('submittedAssignments'));
        if (storedAssignments) {
            setAssignments(storedAssignments);
        }
    }, []);

    const handleAssignmentChange = (courseId, field, selectedOption, actionMeta) => {
        setAssignments(prevAssignments => {
            return prevAssignments.map(assignment => {
                if (assignment.courseId === courseId) {
                    const updatedAssignment = { ...assignment };
                    
                    if (field === 'teacher') {
                        updatedAssignment.teacherId = selectedOption?.value || '';
                        updatedAssignment.teacherName = selectedOption?.label || '';
                    } else if (field === 'classLevel') {
                        updatedAssignment.classLevels = selectedOption?.value || '';
                    }

                    return updatedAssignment;
                }
                return assignment;
            });
        });
    };

    const handleSubmit = async () => {
        const validAssignments = assignments
            .filter(assignment => assignment.teacherId && assignment.classLevels)
            .map(assignment => ({
                ...assignment,
                createDateTime: new Date().toISOString(),
                updateDateTime: new Date().toISOString(),
            }));

        if (validAssignments.length === 0) {
            // alert("Please select both teacher and class level for at least one course.");
            Swal.fire({
                icon: "info",
                title: "Please select both teacher and class level for at least one course.",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        try {
            await Promise.all(validAssignments.map(assignment =>
                axios.post(`${APIData.api}courses`, assignment, {
                    headers: APIData.headers,
                })
            ));
            // alert("All assignments submitted successfully!");
            Swal.fire({
                icon: "success",
                title: "All assignments submitted successfully!",
                showConfirmButton: false,
                timer: 1500
            });
            localStorage.setItem('submittedAssignments', JSON.stringify(validAssignments));
            fetchExistingAssignments();
        } catch (error) {
            console.error("Error submitting assignments:", error);
            // alert(`Failed to submit assignments: ${error.response?.data?.message || error.message}`);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to submit assignments",
                footer: error.response.data,
            });
        }
    };

    const filteredCourses = courses.filter(course =>
        course.courseId.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedCourses = filteredCourses.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    const columns = [
        { name: "Course ID", align: "left" },
        { name: "Teacher", align: "center" },
        { name: "Class Level", align: "center" },
    ];

    const rows = paginatedCourses.map(course => {
        const assignment = assignments.find(a => a.courseId === course.courseId) || {};

        return {
            "Course ID": (
                <TeacherInfo name={course.courseId} />
            ),
            Teacher: (
                <CustomSelect
                    name={`teacher-${course.courseId}`}
                    options={employees.map(teacher => ({
                        value: teacher.teacherId,
                        label: teacher.teacherName,
                    }))}
                    value={assignment.teacherId || null}
                    onChange={(selectedOption, actionMeta) =>
                        handleAssignmentChange(course.courseId, 'teacher', selectedOption, actionMeta)
                    }
                    placeholder="Select Teacher"
                    isSearchable={true}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (base) => ({
                            ...base,
                            fontSize: '0.875rem',
                        }),
                        option: (base) => ({
                            ...base,
                            fontSize: '0.875rem',
                            padding: '8px 12px',
                        }),
                        control: (base, state) => ({
                            ...base,
                            borderColor: state.isFocused ? '#17c1e8' : '#d2d6da',
                            boxShadow: state.isFocused ? '0 0 0 2px #17c1e8' : 'none',
                            '&:hover': {
                                borderColor: '#17c1e8'
                            },
                            minHeight: '32px',
                            height: '32px',
                            borderRadius: '8px',
                            fontSize: '0.875rem',
                            width: '180px'
                        }),
                        placeholder: (base) => ({
                            ...base,
                            fontSize: '0.875rem',
                        }),
                    }}

                />
            ),
            "Class Level": (
                <CustomSelect
                    name={`class-level-${course.courseId}`}
                    options={classLevelsEnum.map(level => ({
                        value: level,
                        label: level
                    }))}
                    value={assignment.classLevels || null}
                    onChange={(selectedOption, actionMeta) =>
                        handleAssignmentChange(course.courseId, 'classLevel', selectedOption, actionMeta)
                    }
                    placeholder="Select Grade"
                    isSearchable={true}
                    menuPortalTarget={document.body}
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        menu: (base) => ({
                            ...base,
                            fontSize: '0.875rem',
                        }),
                        option: (base) => ({
                            ...base,
                            fontSize: '0.875rem',
                            padding: '8px 12px',
                        }),
                        control: (base, state) => ({
                            ...base,
                            borderColor: state.isFocused ? '#17c1e8' : '#d2d6da',
                            boxShadow: state.isFocused ? '0 0 0 2px #17c1e8' : 'none',
                            '&:hover': {
                                borderColor: '#17c1e8'
                            },
                            minHeight: '32px',
                            height: '32px',
                            borderRadius: '8px',
                            fontSize: '0.875rem',
                            width: '150px'
                        }),
                        placeholder: (base) => ({
                            ...base,
                            fontSize: '0.875rem',
                        }),
                    }}
                />
            ),
        };
    });

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card sx={{ maxWidth: '900px', margin: '0 auto' }}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Assign Teachers to Courses</SoftTypography>
                            <SoftBox display="flex" gap={2}>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small"
                                />
                            </SoftBox>
                        </SoftBox>

                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>
                        <SoftBox p={3} display="flex" justifyContent="center">
                            <SoftButton
                                variant="gradient"
                                color="info"
                                onClick={handleSubmit}
                            >
                                Submit Assignments
                            </SoftButton>
                        </SoftBox>

                        <SoftBox p={3}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <SoftTypography variant="caption" color="secondary">
                                        {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, filteredCourses.length)} of {filteredCourses.length}
                                    </SoftTypography>
                                </Grid>
                                <Grid item>
                                    <SoftBox display="flex" gap={2}>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            size="large"
                                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                            disabled={page === 0}
                                        >
                                            <KeyboardArrowLeft />
                                        </SoftButton>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            size="large"
                                            onClick={() => setPage(prev => Math.min(Math.ceil(filteredCourses.length / rowsPerPage) - 1, prev + 1))}
                                            disabled={(page + 1) * rowsPerPage >= filteredCourses.length}
                                        >
                                            <KeyboardArrowRight />
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default AssignTeacher1;