import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from 'axios';
import { IoArrowBack } from "react-icons/io5";
import { AiOutlineDownload, AiFillDelete, AiOutlinePlusCircle } from "react-icons/ai";
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { APIData } from 'authentication/APIData';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import SoftAvatar from "components/SoftAvatar";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "examples/Tables/Table";
import Container from "@mui/material/Container";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import CircularProgress from "@mui/material/CircularProgress";
import { org } from 'authentication/APIData';

const OfficeDocumentsData = () => {
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState("");
  const [sortDirection, setSortDirection] = useState("asc");
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            console.log(sessionDetails.user);
            
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${APIData.api}office-file/no-doc?org=${org}`, {
        headers: {
          ...APIData.headers,
          'Content-Type': 'application/json'
        }
      });

      if (response.data) {
        setTasks(response.data);
      } else {
        setError('No data received from server');
      }
    } catch (err) {
      setError(err.message || 'Error fetching data');
      toast.error(err.message || 'Error fetching data');
    } finally {
      setLoading(false);
    }
  };

  const handleDownload = (data) => {
    const url = APIData.api + 'office-file/' + data.id;
    window.location.href = url;
  };

  const handleDelete = async (data) => {
    try {
      const url = APIData.api + 'office-file/' + data.id;
      const response = await axios.delete(url, { headers: APIData.headers });

      if (response.data.status.toLowerCase() === "success") {
        toast.success(response.data.description);
        setTasks(prevTasks => prevTasks.filter(t => t.id !== data.id));
      } else {
        toast.error(response.data.errorDesc);
      }
    } catch (error) {
      toast.error("Error occurred while deleting file.");
    }
  };

  const filteredTasks = tasks.filter(task =>
    task.fileName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    task.uploaded_by.toLowerCase().includes(searchTerm.toLowerCase()) ||
    task.file_size.toString().includes(searchTerm) ||
    new Date(task.uploaded_date).toLocaleDateString().includes(searchTerm)
  );


  const sortedTasks = [...filteredTasks].sort((a, b) => {
    if (!sortField) return 0;
    const aValue = a[sortField];
    const bValue = b[sortField];
    return sortDirection === 'asc' ?
      (aValue > bValue ? 1 : -1) :
      (aValue < bValue ? 1 : -1);
  });

  const paginatedTasks = sortedTasks.slice(
    page * itemsPerPage,
    (page + 1) * itemsPerPage
  );

  const columns = [
    { name: "File Name", align: "left" },
    { name: "File Size", align: "left" },
    { name: "Date of Upload", align: "left" },
    { name: "Uploaded By", align: "left" },
    { name: "Actions", align: "center" }
  ];

  const rows = paginatedTasks.map((task) => ({
    "File Name": (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
          <SoftAvatar
            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(task.fileName)}&background=random`}
            alt={task.fileName}
            size="sm"
            variant="rounded"
          />
        </SoftBox>
        <SoftTypography variant="button" fontWeight="medium">
          {task.fileName}
        </SoftTypography>
      </SoftBox>
    ),
    "File Size": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {(task.file_size / 1024).toFixed(2)} KB
      </SoftTypography>
    ),
    "Date of Upload": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {new Date(task.uploaded_date).toLocaleDateString()}
      </SoftTypography>
    ),
    "Uploaded By": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {task.uploaded_by}
      </SoftTypography>
    ),
    "Actions": (
      <SoftBox display="flex" gap={1} justifyContent="center">
        <SoftButton
          variant="text"
          color="info"
          onClick={() => handleDownload(task)}
          iconOnly
        >
          <AiOutlineDownload />
        </SoftButton>
        { (permission===1111 || isSuperAdmin) &&<SoftButton
          variant="text"
          color="error"
          onClick={() => handleDelete(task)}
          iconOnly
        >
          <AiFillDelete />
        </SoftButton>}
      </SoftBox>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Office Documents</SoftTypography>
            </SoftBox>

            <SoftBox px={3} mb={3}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} md={8}>
                  <SoftInput
                    placeholder="Search..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <SoftBox display="flex" gap={2} justifyContent="flex-end">
                    <Link to="/office-document-upload" className="text-decoration-none">
                    <SoftButton variant="gradient" color="info" startIcon={<AiOutlinePlusCircle />}>
                        Upload
                      </SoftButton>
                    </Link>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>

            <SoftBox sx={{ overflow: 'auto' }}>
              {loading ? (
                <SoftBox display="flex" justifyContent="center" p={3}>
                  <CircularProgress />
                </SoftBox>
              ) : error ? (
                <SoftBox display="flex" justifyContent="center" p={3}>
                  <SoftTypography color="error">{error}</SoftTypography>
                </SoftBox>
              ) : (
                <>
                  <Table columns={columns} rows={rows} />
                  <SoftBox
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    p={3}
                  >
                    <SoftBox display="flex" gap={1} alignItems="center">
                      <SoftTypography variant="caption" color="secondary">
                        {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, sortedTasks.length)} of {sortedTasks.length}
                      </SoftTypography>
                      <SoftButton
                        variant="text"
                        color="info"
                        onClick={() => setPage(prev => Math.max(0, prev - 1))}
                        disabled={page === 0}
                        iconOnly
                      >
                        <KeyboardArrowLeft />
                      </SoftButton>
                      <SoftButton
                        variant="text"
                        color="info"
                        onClick={() => setPage(prev => Math.min(Math.ceil(sortedTasks.length / itemsPerPage) - 1, prev + 1))}
                        disabled={page >= Math.ceil(sortedTasks.length / itemsPerPage) - 1}
                        iconOnly
                      >
                        <KeyboardArrowRight />
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </>
              )}
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default OfficeDocumentsData;