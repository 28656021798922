import React from 'react';
import logo from '../../assets/images/c4e.png';
import { Typography, Paper } from '@mui/material';
import Swal from 'sweetalert2';


const EmpPerfmTemp = () => {
    return (
        <>
            <Typography variant="h5" gutterBottom>
            Defualt Template
            </Typography>
            <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                <div style={styles.container}>
                    <img src={logo} alt="Company Logo" style={styles.logo} />
                    <h3 style={{ fontSize: "16px" }}>Care4Edu Solutions Pvt Ltd</h3>
                    <div style={styles.companyDetails}>
                        <p>+91 7259785184</p>
                        <p>info@care4edu.com</p>
                        <p>www.care4edu.com</p>
                    </div>
                    <h3 style={styles.title}>Performance Review Form</h3>
                    <p style={styles.paragraph}>
                        This appraisal provides a written record of the employee’s 90-day probationary period. Supervisors are expected to be accurate and candid in their evaluation of employees. This appraisal will become part of the employee’s permanent personnel file.
                    </p>
                    <table style={styles.table}>
                        <tr>
                            <td style={styles.tableCell}><srong>EMPLOYEE ID #:</srong></td>
                            <td style={styles.tableCellWide}>c4e2024adhithyan__p_a</td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell}><srong>Employee:</srong></td>
                            <td style={styles.tableCellWide}>Adhithyan P A</td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell}><srong>Job Title:</srong></td>
                            <td style={styles.tableCellWide}>[Developer]</td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell}><srong>Department:</srong></td>
                            <td style={styles.tableCellWide}>[TECHNICAL]</td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell}><srong>Supervisor:</srong></td>
                            <td style={styles.tableCellWide}>[amulya.s@care4edu.com]</td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell}><srong>Review Period:</srong></td>
                            <td style={styles.tableCellWide}> </td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell}><srong>Date of Review:</srong></td>
                            <td style={styles.tableCellWide}> 20/08/2024</td>
                        </tr>
                    </table>
                    <table style={styles.table1}>
                        <tr>
                            <td style={styles.table1head}><srong>Performance Rating Scale:</srong></td>
                            <td style={styles.table1sub}><srong>Rating</srong></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell1}>5 - Outstanding</td>
                            <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell1}>4 - Exceeds expectations</td>
                            <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell1}>3 - Meets expectations</td>
                            <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell1}>2 - Partially meets expectations</td>
                            <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell1}>1 - Unsatisfactory</td>
                            <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell1}>N/A - Not applicable</td>
                            <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                        </tr>
                    </table>
                    <table style={styles.table}>
                        <tr>
                            <td style={styles.table1head}>Performance Factors:</td>
                            <td style={styles.table1sub}>Rating</td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Quality of work: Demonstrates accuracy, thoroughness and effectiveness</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Flexibility: Performs well under pressure; adaptable; welcomes change as an opportunity</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Initiative: Sets own constructive work practices; recommends new procedures; seeks creative solutions to obstacles or problems</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Dependability: Completes work in a timely manner and meets deadlines; follows through on plans and assignments</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Organization: Work is well conceived, analyzed and carried out systematically</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Interpersonal Relations: Is cooperative, considerate and tactful in dealing with supervisors, subordinates, peers, faculty, students and others</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Communication Abilities: Expresses thoughts clearly and concisely, both orally and in writing</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Embraces Diversity: Fosters an inclusive workplace where diversity and individual differences are valued</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Facing Issues: Handles issues and seeks to resolve them by constructive action at his or her own level</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Utilization of Resources: Utilizes funds, staff and/or equipment economically and effectively</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Judgment: Exercises sound judgment; demonstrates awareness of work-related considerations in decision-making</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                        <tr>
                            <td style={styles.tableCell2}>Growth and Development: Attempts to improve competencies in job-related areas; seeks opportunities to develop professionally</td>
                            <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                        </tr>
                    </table>
                    <h2 style={styles.header1}>Goal Setting Worksheet(To be discussed and agreed upon by employee and supervisor.)</h2>
                    <p style={styles.paragraph}>Goal Setting: List goals for the coming year that will support unit objectives and enhance job performance.
                        We recommend using the S.M.A.R.T. criteria for setting effective goals:
                    </p>
                    <ul style={{
                        listStyleType: 'disc',
                        paddingLeft: '20px',
                    }}>
                        <li style={{ marginBottom: '8px' }}>
                            Specific – Helps staff members to understand exactly what is expected.
                        </li>
                        <li style={{ marginBottom: '8px' }}>
                            Measurable – Measurements help staff members and supervisors understand when the expectation has been achieved.<br />
                        </li>
                        <li style={{ marginBottom: '8px' }}>
                            Achievable – Expectations should focus on end results.                        </li>
                        <li style={{ marginBottom: '20px' }}>
                            Results-Oriented – Expectations should be realistic.                        </li>
                        <li style={{ marginBottom: '20px' }}>
                            Time--Specific – Deadlines should be identified where applicable.
                        </li>
                    </ul>

                    <h4 style={styles.header}>Goal 1:</h4>
                    <input type="text" style={styles.input1} />
                    <h3 style={styles.header}>Goal 2:</h3>
                    <input type="text" style={styles.input1} />
                    <h3 style={styles.header}>Goal 3:</h3>
                    <input type="text" style={styles.input1} />
                    <h3 style={styles.header}>Goal 4:</h3>
                    <input type="text" style={styles.input1} />
                    <h3 style={styles.header}>Goal 5:</h3>
                    <input type="text" style={styles.input1} />
                    <h3 style={styles.header}>Achievements:</h3>
                    <textarea style={styles.textarea} />
                    <h3 style={styles.header}>Employee Comments:</h3>
                    <textarea style={styles.textarea} />
                    <h3 style={styles.header}>Supervisor Comments:</h3>
                    <textarea style={styles.textarea} />

                    <h3 style={styles.signatureSection}>Signatures:</h3>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ flex: 1, textAlign: 'center' }}>
                            <p>Supervisor:</p>
                            <p>Date: 20/08/2024</p>
                            <p>(Sign and give form to reviewer.)</p>

                        </div>
                        <div style={{ flex: 1, textAlign: 'center' }}>
                            <p>Employee:</p>
                            <p>Date: 20/08/2024</p>
                            <p>(Sign and return form to your supervisor within 48 hours of its receipt.)</p>

                        </div>

                    </div>
                </div>
            </Paper>
        </>
    );
};

const styles = {
    container: {
        maxWidth: '720px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#fff',
    },
    title: {
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        marginTop: '20px',

    },
    header: {
        fontSize: '14px',
        marginBottom: '10px',
    },
    header1: {
        fontSize: '16px',
        marginBottom: '10px',
    },
    logo: {
        width: '100px',
        height: 'auto',
    },
    paragraph: {
        marginBottom: '10px',
    },
    companyDetails: {
        fontSize: '12px',
        lineHeight: '1.6',
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        marginBottom: '20px',
        tableLayout: 'fixed', 
    },
    tableCell: {
        width: '35%',
        padding: '10px',
        border: '1px solid #ccc',
        fontWeight: 700,
        wordWrap: 'break-word',  

    },
    tableCellWide: {
        width: '65%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',  

    },
    table1: {
        borderCollapse: 'collapse',
        width: '100%',
        marginBottom: '20px',
        marginTop: '20px',
        tableLayout: 'fixed', 

    },
    tableCell1: {
        width: '80%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',  

    },
    tableCellWide1: {
        width: '20%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',  

    },
    table1head: {
        width: '80%',
        padding: '10px',
        border: '1px solid #ccc',
        fontWeight: 700,
        wordWrap: 'break-word',  

    },
    table1sub: {
        width: '20%',
        padding: '10px',
        border: '1px solid #ccc',
        fontWeight: 700,
        wordWrap: 'break-word',  

    },
    tableCell2: {
        width: '90%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',  

    },
    tableCellWide2: {
        width: '10%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',  

    },
    input: {
        width: '100%',
        height: '100%',
        padding: '0',
        margin: '0',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    input1: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        height: '100px',
    },
    signatureSection: {
        marginTop: '40px',
        fontSize: '12px',
        marginBottom: '40px',

    },
};
export default EmpPerfmTemp;