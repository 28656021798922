import {
  AppShell,
  Box,
  Burger,
  Group,
  Text,
  UnstyledButton,
} from "@mantine/core";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import classes from "./Appbar.module.css";
// import AvinyaLogo from "../../Assets/Avinyalogo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { decrypt } from "../../ApiData/Encryption";
import toast from "react-hot-toast";
import { org,APIData } from "authentication/APIData";
import { useState, useEffect } from "react";


export function Appbar() {
  const [opened, { toggle }] = useDisclosure();
  const matches = useMediaQuery("(max-width: 26.25em)");
  const navigate = useNavigate();
  const [orgLogo, setOrgLogo] = useState();
  const [orgname, setOrgname] = useState();

  const logout = () => {
    localStorage.clear();
    toast.success("Logged out successfully");
    navigate("/");
    return;
  };

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
        if (!response.ok) {
          throw new Error("Failed to fetch organization data");
        }
        const data = await response.json();
        // console.log(data);
        setOrgname(data.orgName)
        setOrgLogo(data.orgLogo);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
    fetchOrgData();
  }, []);

  return (
    <div>
      <AppShell
        style={{ height: 60 }}
        header={{ height: 60 }}
        navbar={{
          width: 300,
          breakpoint: "sm",
          collapsed: { desktop: true, mobile: !opened },
        }}
        padding="md"
      >
        <AppShell.Header
          style={{
            background:
              "linear-gradient(90deg, rgba(255,255,255,1) 3%, rgba(7,133,138,0.87718837535014) 25%)",
            color: "aliceblue",
          }}
        >
          <Group h="100%" px="md">
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="md"
              fw={"bold"}
              color="#07858a"
            />
            <Group justify="space-between" style={{ flex: 1 }}>
              <NavLink to={"/"}>
                <img
                  src={orgLogo}
                  height={50}
                  style={{ borderRadius: "6px", paddingTop: "5px" }}
                />
              </NavLink>
              <Text size="md" fw={"600"}>
                {matches ? (
                  <>ASAT</>
                ) : (
                  <span style={{ fontSize: "1.2rem", fontWeight: "bold" }}>
                     {orgname}
                  </span>
                )}
              </Text>
              {/* <Group ml="xs" gap={0} visibleFrom="sm">
                <NavLink to={"https://care4edu.com"} target="_blank">
                  <UnstyledButton className={classes.control}>
                    Care 4 Edu
                  </UnstyledButton>
                </NavLink>
                <NavLink to={"https://avinya.care4edu.com"} target="_blank">
                  <UnstyledButton className={classes.control}>
                    Avinya Academy
                  </UnstyledButton>
                </NavLink>
                <NavLink
                  to={"https://shikshakpro.care4edu.com"}
                  target="_blank"
                >
                  <UnstyledButton className={classes.control}>
                    Shikshak Pro
                  </UnstyledButton>
                </NavLink>
                {decrypt("admin") ? (
                  <>
                    <NavLink to={"/dashboard"}>
                      <UnstyledButton className={classes.control}>
                        Dashboard
                      </UnstyledButton>
                    </NavLink>
                    <UnstyledButton
                      className={classes.control}
                      onClick={logout}
                    >
                      Logout
                    </UnstyledButton>
                  </>
                ) : (
                  <NavLink to="/Login">
                    <UnstyledButton className={classes.control}>
                      Login
                    </UnstyledButton>
                  </NavLink>
                )}
              </Group> */}
            </Group>
          </Group>
        </AppShell.Header>

        <AppShell.Navbar py="md" px={4}>
          
          {decrypt("MEMBER") ? (
            <>
              <NavLink to={"/dashboard"}>
                <UnstyledButton className={[classes.control, classes.a]}>
                  Dashboard
                </UnstyledButton>
              </NavLink>
              <UnstyledButton className={[classes.control, classes.a]} onClick={logout}>
                Logout
              </UnstyledButton>
            </>
          ) : (
            <NavLink to="/Login">
              <UnstyledButton className={[classes.control, classes.a]}>
                Login
              </UnstyledButton>
            </NavLink>
          )}
        </AppShell.Navbar>
      </AppShell>
    </div>
  );
}
