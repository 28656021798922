// import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';


// const ExpandButtonWithTooltip = ({ children }) => {
//   const [showTooltip, setShowTooltip] = useState(false);
//   const tooltipKey = 'hasSeenDetailViewTooltip';

//   ExpandButtonWithTooltip.propTypes = {
//     children: PropTypes.bool.isRequired,
//   };

//   useEffect(() => {
//     const hasSeenTooltip = localStorage.getItem(tooltipKey);
    
//     if (!hasSeenTooltip) {
//       const timer = setTimeout(() => {
//         setShowTooltip(true);
//       }, 3000);
      
//       return () => clearTimeout(timer);
//     }
//   }, []);

//   const handleClose = () => {
//     setShowTooltip(false);
//     localStorage.setItem(tooltipKey, 'true');
//   };

//   if (!showTooltip) return children;

//   return (
//     <div style={{ position: 'relative', display: 'inline-flex' }}>
//       {children}
//       <div style={{
//         position: 'absolute',
//         top: '-64px',
//         left: '50%',
//         transform: 'translateX(-50%)',
//         backgroundColor: '#1f2937',
//         color: 'white',
//         padding: '8px 16px',
//         borderRadius: '8px',
//         boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
//         zIndex: 50,
//         width: '192px',
//       }}>
//         <button 
//           onClick={handleClose}
//           style={{
//             position: 'absolute',
//             top: '-8px',
//             right: '-8px',
//             backgroundColor: '#374151',
//             color: 'white',
//             borderRadius: '50%',
//             width: '20px',
//             height: '20px',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             cursor: 'pointer',
//             outline: 'none',
//           }}
//           onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#4b5563'}
//           onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#374151'}
//         >
//           ×
//         </button>
//         <p style={{ fontSize: '14px', textAlign: 'center' }}>Click here for detailed view</p>
//         <div style={{
//           position: 'absolute',
//           bottom: '0',
//           left: '50%',
//           transform: 'translateX(-50%) translateY(50%)',
//         }}>
//           <div style={{
//             width: 0,
//             height: 0,
//             borderLeft: '8px solid transparent',
//             borderRight: '8px solid transparent',
//             borderTop: '8px solid #1f2937',
//           }}></div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ExpandButtonWithTooltip;


import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

const ExpandButtonWithTooltip = ({ children }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipKey = 'hasSeenDetailViewTooltip';

  ExpandButtonWithTooltip.propTypes = {
    children: PropTypes.bool.isRequired,
  };

  useEffect(() => {
    const hasSeenTooltip = localStorage.getItem(tooltipKey);
    
    if (!hasSeenTooltip) {
      const timer = setTimeout(() => {
        setShowTooltip(true);
      }, 3000);
      
      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setShowTooltip(false);
    localStorage.setItem(tooltipKey, 'true');
  };

  if (!showTooltip) return children;

  return (
    <div style={{ position: 'relative', display: 'inline-flex' }}>
      {children}
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '100%',
          transform: 'translateY(-50%)',
          marginLeft: '8px',
          backgroundColor: '#2D3748',
          color: 'white',
          padding: '8px 16px',
          borderRadius: '8px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          zIndex: 50,
          width: '192px',
          textAlign: 'center',
        }}
      >
        <button
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: '-8px',
            right: '-8px',
            backgroundColor: '#4A5568',
            color: 'white',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            outline: 'none',
          }}
          onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#2D3748')}
          onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#4A5568')}
        >
          ×
        </button>
        <p style={{ fontSize: '14px', margin: 0 }}>Click here for detailed view</p>
        <div
          style={{
            position: 'absolute',
            top: '50%',
            right: '100%',
            transform: 'translateY(-50%)',
            width: '0',
            height: '0',
            borderTop: '8px solid transparent',
            borderBottom: '8px solid transparent',
            borderRight: '8px solid #2D3748',
          }}
        />
      </div>
    </div>
  );
};

export default ExpandButtonWithTooltip;
