import React, { useState, useEffect } from 'react';
import { Grid, Button, Container, CircularProgress, Backdrop, Typography } from '@mui/material';
import { IoAddCircleSharp } from "react-icons/io5";
import { RiDeleteBin6Fill } from "react-icons/ri";
import axios from 'axios';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import SoftInput from 'components/SoftInput';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import SoftTypography from 'components/SoftTypography';
import num2words from "num2words";
import Swal from 'sweetalert2';

const InvoiceNew = () => {
    const [showInvoiceFields, setShowInvoiceFields] = useState(false);
    const [showPartialInvoiceFields, setShowPartialInvoiceFields] = useState(false);
    const [showQuotationFields, setShowQuotationFields] = useState(false);
    const [ParentIdData, setParentIdData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [parentInvoiceIds, setParentInvoiceIds] = useState([]);
    const [emailError, setEmailError] = useState('');
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const [formData, setFormData] = useState({
        idinvoices: "",
        invoiceid: "",
        parentInvoiceid: "",
        custEmailId: "",
        date: "",
        due_date: "",
        toName: "",
        toAddress: "",
        amount: "",
        balance: "",
        amountWords: "",
        total: "",
        subTotal: "",
        cgstPercentage: "",
        cgstAmount: "",
        sgstPercentage: "",
        sgstAmount: "",
        totalTax: "",
        paidAmount: "",
        partialPaid1: "",
        partialPaid2: "",
        partialPaid3: "",
        org: org,
        invType: "",
        items: [{
            item: "",
            quantity: "",
            unitPrice: "",
            itemTotal: "",
            slno: "",
        }],
        paymentstatus: "",
    });
    const [selectedOption, setSelectedOption] = useState(null);

    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email) {
            return 'Email is required';
        }
        if (!emailRegex.test(email)) {
            return 'Please enter a valid email address';
        }
        return '';
    };

    const handleEmailChange = (e) => {
        const email = e.target.value;
        const error = validateEmail(email);
        setEmailError(error);
        setFormData({ ...formData, custEmailId: email });
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...formData.items];
        updatedItems[index][field] = value;

        if (field === 'quantity' || field === 'unitPrice') {
            const quantity = parseFloat(updatedItems[index].quantity) || 0;
            const unitPrice = parseFloat(updatedItems[index].unitPrice) || 0;
            updatedItems[index].itemTotal = (quantity * unitPrice).toFixed(2);
        }
        setFormData({ ...formData, items: updatedItems });
    };
    useEffect(() => {
        const url = `${APIData.api}invoices?org=${org}`;
        axios
            .get(url, { headers: APIData.headers })
            .then((response) => {
                const parentInvoiceIdsArray = [
                    ...new Set(response.data.map((item) => item.parentInvoiceid)),
                ];
                setParentInvoiceIds(parentInvoiceIdsArray);
                console.log(parentInvoiceIdsArray);
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (
            formData.parentInvoiceid &&
            parentInvoiceIds.includes(formData.parentInvoiceid)
        ) {
            fetchParentIdData();
        }
    }, [formData.parentInvoiceid, parentInvoiceIds]);

    useEffect(() => {
        const { partialPaid1, partialPaid2, partialPaid3, paidAmount } = formData;
        const subTotal = formData.items.reduce((acc, item) => acc + parseFloat(item.itemTotal || 0), 0);

        const cgstAmount = formData.cgstPercentage ? parseFloat((subTotal * (formData.cgstPercentage / 100)).toFixed(2)) : 0;

        const sgstAmount = formData.sgstPercentage ? parseFloat((subTotal * (formData.sgstPercentage / 100)).toFixed(2)) : 0;

        const totalTax = parseFloat((cgstAmount + sgstAmount).toFixed(2));

        const total = parseFloat((subTotal + totalTax).toFixed(2));

        setFormData((prevData) => ({
            ...prevData,
            subTotal: subTotal.toFixed(2),
            cgstAmount,
            sgstAmount,
            totalTax,
            total,
        }));

        if (formData.paidAmount) {
            const amountWords = num2words(formData.paidAmount, { currency: true });
            const formattedAmountWords = formatAmountWords(amountWords);

            setFormData((prevState) => ({
                ...prevState,
                amountWords: formattedAmountWords,
            }));
        }

        if (formData.total && partialPaid1 !== undefined && partialPaid2 !== undefined && partialPaid3 !== undefined) {
            const balance = formData.total - partialPaid1 - partialPaid2 - partialPaid3;
            setFormData((prevState) => ({
                ...prevState,
                balance,
                paymentstatus: balance === 0 ? "RECEIVED" : "PENDING",
            }

            ));
        }
        if (formData.total && paidAmount !== undefined) {
            const balance = formData.total - paidAmount;
            setFormData((prevState) => ({
                ...prevState,
                balance,
                paymentstatus: balance === 0 ? "RECEIVED" : "PENDING",
            }

            ));
        }
    }, [formData.items, formData.cgstPercentage, formData.sgstPercentage, formData.paidAmount, formData.total, formData.partialPaid1, formData.partialPaid2, formData.partialPaid3]);

    const formatAmountWords = (amountWords) => {
        const words = amountWords.split(" ");
        return `${words
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(" ")
            .trim()} Rupees`;
    };

    const addItem = () => {
        const newSlno = formData.items.length + 1;
        const newItem = { slno: newSlno, item: "", quantity: "", unitPrice: "", itemTotal: "" };
        setFormData({ ...formData, items: [...formData.items, newItem] });
    };

    const deleteItem = () => {
        if (formData.items.length > 1) {
            const updatedItems = formData.items.slice(0, -1);
            setFormData({ ...formData, items: updatedItems });
        }
    };

    const handleInvTypeChange = (selectedOption, actionMeta) => {
        if (actionMeta.name === 'invType' && selectedOption) {
            const value = selectedOption.value;
            setSelectedOption(selectedOption);
            setFormData(prevData => ({
                ...prevData,
                invType: value
            }));

            setShowQuotationFields(value === 'QUOTATIONS' || value === 'PROFORMA');
            setShowPartialInvoiceFields(value === 'PARTIAL_INVOICE');
            setShowInvoiceFields(value === 'INVOICE');
        }
    };

    const validateForm = () => {
        const requiredFields = [
            formData.custEmailId,
            formData.toName,
            formData.toAddress,
            formData.invType,
            ...formData.items.map(item => item.item),
            ...formData.items.map(item => item.quantity),
            ...formData.items.map(item => item.unitPrice),
            formData.subTotal,
            formData.cgstPercentage,
            formData.sgstPercentage,
            formData.total,
            formData.paymentstatus,
        ];
        if (emailError) {
            return false;
        }
        const isValid = requiredFields.every(field => field !== "" && field !== undefined);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            Swal.fire({
                icon: "error",
                title: "Message",
                text: "Please fill in all required fields.",
            });
            return;
        }
        setLoading(true);
        try {
            const url = APIData.api + `invoices/`;
            const response = await axios.post(url, formData, { headers: APIData.headers });
            console.log(response.data);
            setLoading(false);
            setFormData((prevState) => ({
                ...prevState,
                idinvoices: 0,
                invoiceid: 0,
                parentInvoiceid: "",
                custEmailId: "",
                date: "",
                due_date: "",
                toName: "",
                toAddress: "",
                amount: "",
                balance: "",
                amountWords: "",
                total: "",
                subTotal: "",
                cgstPercentage: "",
                cgstAmount: "",
                sgstPercentage: "",
                sgstAmount: "",
                totalTax: "",
                paidAmount: "",
                partialPaid1: "",
                partialPaid2: "",
                partialPaid3: "",
                org: org,
                invType: "",
                items: [{
                    item: "",
                    quantity: "",
                    unitPrice: "",
                    itemTotal: "",
                    slno: "",
                }],
                paymentstatus: "",
            }))
            console.log(formData);
            Swal.fire({
                icon: "success",
                title: "Form data submitted successfully",
                showConfirmButton: false,
                timer: 1500
            });
        }
        catch (error) {
            console.error(error);
            setLoading(false);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.message || "An error occurred",
            });
        }
    }

    const fetchParentIdData = async () => {
        const url = `${APIData.api}invoices/parent-invoiceId?parentInvoiceId=${formData.parentInvoiceid}&org=${org}`;
        try {
            const response = await axios.get(url, { headers: APIData.headers });
            const data = response.data[response.data.length - 1];
            setParentIdData(response.data);
            setFormData({
                ...formData,
                idinvoices: data.idinvoices,
                invoiceid: data.invoiceid,
                custEmailId: data.custEmailId,
                date: data.date,
                due_date: data.due_date,
                toName: data.toName,
                toAddress: data.toAddress,
                amount: data.amount,
                balance: data.balance,
                amountWords: '',
                total: data.total,
                subTotal: data.subTotal,
                cgstPercentage: data.cgstPercentage,
                cgstAmount: data.cgstAmount,
                sgstPercentage: data.sgstPercentage,
                sgstAmount: data.sgstAmount,
                totalTax: data.totalTax,
                paidAmount: '',
                partialPaid1: data.partialPaid1,
                partialPaid2: data.partialPaid2,
                partialPaid3: data.partialPaid3,
                org: data.org,
                invType: data.invType,
                items: data.items.map((item) => ({
                    slno: item.slno,
                    item: item.item,
                    quantity: item.quantity,
                    unitPrice: item.unitPrice,
                    itemTotal: item.itemTotal,
                })),

                paymentstatus: data.paymentstatus,
            });
            console.log(" parent id based form data:", formData)
        } catch (error) {
            console.error("Error fetching Parent Invoice Data:", error);
        }
    };

    return (
        <DashboardLayout>
            <Container maxWidth="lg">
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Typography variant='h5' style={{ textAlign: "center", alignItems: "center", fontFamily: "Roboto slab" }}> Create Invoice</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} style={{ marginTop: "1rem" }}>
                        <Grid item xs={12} sm={3}>
                            <SoftTypography variant="h6">Select Invoice Type</SoftTypography>
                            <CustomSelect
                                options={[
                                    { value: "QUOTATIONS", label: "QUOTATIONS" },
                                    { value: "PROFORMA", label: "PROFORMA" },
                                    { value: "PARTIAL_INVOICE", label: "PARTIAL INVOICE" },
                                    { value: "INVOICE", label: "INVOICE" }
                                ]}
                                placeholder="Select Invoice Type"
                                name="invType"
                                onChange={handleInvTypeChange}
                                value={selectedOption}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <SoftTypography variant="h6">Customer Email ID</SoftTypography>
                            <SoftInput
                                variant="outlined"
                                fullWidth
                                type='email'
                                size='small'
                                value={formData.custEmailId}
                                onChange={handleEmailChange}
                                error={!!emailError}
                                required
                            />
                            {emailError && (
                                <SoftTypography variant="caption" color="error">
                                    {emailError}
                                </SoftTypography>
                            )}
                            <SoftTypography variant="caption">
                                Enter Customer Email Address
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <SoftTypography variant="h6">Customer Name</SoftTypography>
                            <SoftInput
                                variant="outlined"
                                fullWidth
                                required
                                size='small'
                                value={formData.toName}
                                onChange={(e) => setFormData({ ...formData, toName: e.target.value })}
                            />
                            <SoftTypography variant="caption" >
                                Enter  name of a Customer
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                            <SoftTypography variant="h6">Customer Address</SoftTypography>
                            <SoftInput
                                variant="outlined"
                                fullWidth
                                required
                                size='small'
                                value={formData.toAddress}
                                onChange={(e) => setFormData({ ...formData, toAddress: e.target.value })}
                            />
                            <SoftTypography variant="caption" >
                                Enter Address of a Customer
                            </SoftTypography>
                        </Grid>
                        {formData.items.map((item, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">{`Item ${index + 1}`}</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        value={item.item}
                                        onChange={(e) => handleItemChange(index, 'item', e.target.value)}
                                    />
                                    <SoftTypography variant="caption" >
                                        Enter name of the Product
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">{`Quantity ${index + 1}`}</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={item.quantity}
                                        onChange={(e) => handleItemChange(index, 'quantity', e.target.value)}
                                    />
                                    <SoftTypography variant="caption" >
                                        Enter the Quantity of Product , ie. Number of Product Items
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">{`Unit Price ${index + 1}`}</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={item.unitPrice}
                                        onChange={(e) => handleItemChange(index, 'unitPrice', e.target.value)}
                                    />
                                    <SoftTypography variant="caption" >
                                        Enter the price of the Product
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={2}>
                                    <SoftTypography variant="h6">{`Item Total ${index + 1}`}</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        required
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={item.itemTotal}
                                        onChange={(e) => handleItemChange(index, 'itemTotal', e.target.value)}
                                    />
                                    <SoftTypography variant="caption" >
                                        Total amount of the Product
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={1}>
                                    {index === formData.items.length - 1 && (
                                        <>
                                            <IoAddCircleSharp style={{ color: "green", fontSize: "1.5rem", cursor: "pointer", marginLeft: '0.5rem', marginTop: '1rem' }} onClick={addItem} />
                                            {formData.items.length > 1 && <RiDeleteBin6Fill style={{ color: "red", fontSize: "1.5rem", cursor: "pointer", marginLeft: '0.5rem' }} onClick={deleteItem} />}
                                        </>
                                    )}
                                </Grid>
                            </React.Fragment>
                        ))}
                        {(showQuotationFields || showInvoiceFields || showPartialInvoiceFields) && (
                            <>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Sub Total</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        type="number"
                                        value={formData.subTotal}
                                        onChange={(e) => setFormData({ ...formData, subTotal: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        Sub Total ex: 1st item = 100 Rs(Total amount of an item), 2nd item= 200 Rs(Total amount of an item), Sub Total will be 300Rs
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">CGST Percentage</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.cgstPercentage}
                                        onChange={(e) => setFormData({ ...formData, cgstPercentage: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        CGST : Central Goods and Service Tax
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">CGST Amount</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.cgstAmount}
                                        onChange={(e) => setFormData({ ...formData, cgstAmount: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        CGST Amount= SubTotal of CGST Percentage
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">SGST Percentage</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.sgstPercentage}
                                        onChange={(e) => setFormData({ ...formData, sgstPercentage: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        SGST : State Goods and Service Tax
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">SGST Amount</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.sgstAmount}
                                        onChange={(e) => setFormData({ ...formData, sgstAmount: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        SGST Amount= Subtotal of SGST Percentage
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Total Tax</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.totalTax}
                                        onChange={(e) => setFormData({ ...formData, totalTax: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        Total Tax, ie. Combination of SGST Amount and CGST Amount
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Final Amount</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.total}
                                        onChange={(e) => setFormData({ ...formData, total: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        Final Amount: ie, Amount including tax and product price
                                    </SoftTypography>
                                </Grid>
                            </>
                        )}
                        {showPartialInvoiceFields && (
                            <>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Parent Invoice ID</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        value={formData.parentInvoiceid}
                                        onChange={(e) => setFormData({ ...formData, parentInvoiceid: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        Parent Invoice Id is previous Invoice Id which is generated when we send PROFORMA Invoice
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Partial Paid 1</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.partialPaid1}
                                        onChange={(e) => setFormData({ ...formData, partialPaid1: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        Partial Payment 1, ie. The amount which customer pays first time from the Total Amount
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Partial Paid 2</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.partialPaid2}
                                        onChange={(e) => setFormData({ ...formData, partialPaid2: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        Partial Payment 2, ie. The amount which customer pays Second time from the remaining amount to be paid
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Partial Paid 3</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.partialPaid3}
                                        onChange={(e) => setFormData({ ...formData, partialPaid3: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        Partial Payment 3, ie. The amount which customer pays for the final time from the remaining amount
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Paid Amount</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        required
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.paidAmount}
                                        onChange={(e) => setFormData({ ...formData, paidAmount: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        The amount paid by the customer
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Paid Amount in Words</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        size='small'
                                        required
                                        value={formData.amountWords}
                                        onChange={(e) => setFormData({ ...formData, amountWords: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        The paid amount to display in the Words
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Balance Amount</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.balance}
                                        onChange={(e) => setFormData({ ...formData, balance: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        The balance amount to be paid by the customer
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Date</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        type='date'
                                        fullWidth
                                        required
                                        size='small'
                                        value={formData.date}
                                        onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                                        InputLabelProps={{ shrink: "true" }}
                                    />
                                    <SoftTypography variant="caption" >
                                        Date of generating the invoice
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Due Date</SoftTypography>
                                    <SoftInput
                                        type='date'
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        value={formData.due_date}
                                        InputLabelProps={{ shrink: "true" }}
                                        onChange={(e) => setFormData({ ...formData, due_date: e.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Payment Status</SoftTypography>
                                    <CustomSelect
                                        options={[
                                            { value: "PENDING", label: "PENDING" },
                                            { value: "RECEIVED", label: "RECEIVED" }
                                        ]}
                                        placeholder="Select Payment Status"
                                        name="paymentstatus"
                                        onChange={(selectedOption) => setFormData({ ...formData, paymentstatus: selectedOption.value })}
                                        value={formData.paymentstatus ? { value: formData.paymentstatus, label: formData.paymentstatus } : null}

                                    />
                                    <SoftTypography variant="caption" >
                                        Status of the Payment ie, either the Amount to be paid is Pending or Received
                                    </SoftTypography>
                                </Grid>
                            </>
                        )}

                        {showInvoiceFields && (
                            <>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Paid Amount</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        value={formData.paidAmount}
                                        onChange={(e) => setFormData({ ...formData, paidAmount: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        The amount paid by the customer
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Amount in Words</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        required
                                        size='small'
                                        value={formData.amountWords}
                                        onChange={(e) => setFormData({ ...formData, amountWords: e.target.value })} />
                                    <SoftTypography variant="caption" >
                                        The paid amount to display in the Words
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Balance Amount</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        fullWidth
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        required
                                        value={formData.balance}
                                        onChange={(e) => setFormData({ ...formData, balance: e.target.value })}
                                    />
                                    <SoftTypography variant="caption" >
                                        The balance amount to be paid by the customer
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Date</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        type='date'
                                        fullWidth
                                        required
                                        size='small'
                                        value={formData.date}
                                        InputLabelProps={{ shrink: "true" }}
                                        onChange={(e) => setFormData({ ...formData, date: e.target.value })} />
                                    <SoftTypography variant="caption" >
                                        Date of generating the invoice
                                    </SoftTypography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Payment Status</SoftTypography>
                                    <CustomSelect
                                        options={[
                                            { value: "PENDING", label: "PENDING" },
                                            { value: "RECEIVED", label: "RECEIVED" }
                                        ]}
                                        placeholder="Select Payment Status"
                                        name="paymentstatus"
                                        required
                                        onChange={(selectedOption) => setFormData({ ...formData, paymentstatus: selectedOption.value })}
                                        value={formData.paymentstatus ? { value: formData.paymentstatus, label: formData.paymentstatus } : null}
                                    />
                                    <SoftTypography variant="caption" >
                                        Status of the Payment ie, either the Amount to be paid is Pending or Received
                                    </SoftTypography>
                                </Grid>
                            </>
                        )}
                        {(showQuotationFields) && (
                            <>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Date</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        type='date'
                                        required
                                        fullWidth
                                        size='small'
                                        value={formData.date}
                                        InputLabelProps={{ shrink: "true" }}
                                        onChange={(e) => setFormData({ ...formData, date: e.target.value })} />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Due Date</SoftTypography>
                                    <SoftInput
                                        variant="outlined"
                                        type='date'
                                        required
                                        InputLabelProps={{ shrink: "true" }}
                                        fullWidth
                                        value={formData.due_date}
                                        onChange={(e) => setFormData({ ...formData, due_date: e.target.value })}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <SoftTypography variant="h6">Payment Status</SoftTypography>
                                    <CustomSelect
                                        options={[
                                            { value: "PENDING", label: "PENDING" },
                                            { value: "RECEIVED", label: "RECEIVED" }
                                        ]}
                                        placeholder="Select Payment Status"
                                        required
                                        name="paymentstatus"
                                        onChange={(selectedOption) => setFormData({ ...formData, paymentstatus: selectedOption.value })}
                                        value={formData.paymentstatus ? { value: formData.paymentstatus, label: formData.paymentstatus } : null}

                                    />
                                    <SoftTypography variant="caption" >
                                        Status of the Payment ie, either the Amount to be paid is Pending or Received
                                    </SoftTypography>
                                </Grid>
                            </>
                        )}
                    </Grid>
                    <div style={{ alignContent: "center", alignItems: "center", justifyItems: "center" }}>
                        <Button variant='contained' onClick={handleSubmit} style={{ alignContent: "center", alignItems: "center", justifyContent: "center", justifyItems: "center", textAlign: "center", marginTop: "1rem" }}> Submit </Button>
                    </div>
                </form>
            </Container>
        </DashboardLayout>
    );
};
export default InvoiceNew;
