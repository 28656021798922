// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import { APIData } from 'authentication/APIData';
// import Grid from "@mui/material/Grid";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftButton from "components/SoftButton";
// import Swal from 'sweetalert2';
// import { Tooltip } from "@mui/material";
// import { useNavigate } from "react-router-dom";

// function MyAssets() {
//     const [assets, setAssets] = useState([]);
//     const [userdata, setUserData] = useState();
//     const [loading, setLoading] = useState(false);
//     const navigate = useNavigate();

//     useEffect(() => {
//         const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
//         setUserData(userData_Local);
//     }, []);

//     useEffect(() => {
//         const fetchAssetGroup = async () => {
//             if (!userdata) return; // Ensure userdata is available
//             try {
//                 const response = await axios.get(`${APIData.api}employee-assets/id?empid=${userdata.userName}`, {
//                     headers: APIData.headers
//                 });
//                 setAssets(response.data); // Assuming response.data is an array of assets
//             } catch (error) {
//                 Swal.fire({
//                     title: 'Error!',
//                     text: 'Failed to fetch asset details',
//                     icon: 'error',
//                     confirmButtonText: 'OK'
//                 });
//                 console.error("Failed to fetch assets:", error);
//             }
//         };

//         fetchAssetGroup();
//     }, [userdata]); // Dependency on userdata to re-fetch when it is set

//     const returnAsset = (status, asset_id) => {
//         setLoading(true); 
//         const url = `${APIData.api}employee-assets/return-asset/?asset_id=${asset_id}&allocation_status=${status}`;
//         axios.put(url, {}, { headers: APIData.headers })
//             .then((resp) => {
//                 Swal.fire({
//                     icon: "success",
//                     title: `Successfully ${status} the asset`,
//                     showConfirmButton: false,
//                     timer: 1500
//                 });
//                 setLoading(false);
//                 fetchAssetGroup();
//             })
//             .catch((err) => {
//                 console.error(err);
//                 setLoading(false); // End loading even on error
//             });
//     };

//     const handleBack = () => {
//         navigate("/assets-list");
//     };

//     return (
//         <DashboardLayout>
//             <DashboardNavbar />
//             <SoftBox mb={3}>
//                 <Tooltip title="Back to Asset Group List" arrow>
//                     <SoftButton sx={{ ml: 2 }} onClick={handleBack}> Back</SoftButton>
//                 </Tooltip>
//                 <SoftBox pt={2} px={2}>
//                     <SoftTypography variant="h5" fontWeight="medium">My Assets</SoftTypography>
//                 </SoftBox>
//                 <SoftBox p={2}>
//                     <Grid container spacing={3}>
//                         {assets.map((asset) => (
//                             <Grid item xs={12} sm={6} md={4} lg={3} key={asset.asset_id}>
//                                 <Card variant="outlined" sx={{
//                                     borderRadius: '12px',
//                                     boxShadow: 3,
//                                     display: 'flex',
//                                     flexDirection: 'column',
//                                     justifyContent: 'space-between',
//                                     height: '100%', // Ensures the card takes the full height of the grid item
//                                 }}>
//                                     <CardContent sx={{
//                                         flexGrow: 1,
//                                         display: 'flex',
//                                         flexDirection: 'column',
//                                         justifyContent: 'space-between', // Distributes space between elements
//                                     }}>
//                                         <SoftTypography
//                                             variant="h6"
//                                             fontWeight="bold"
//                                             sx={{
//                                                 textAlign: 'center',
//                                                 mb: 2,
//                                                 mt: 2,
//                                                 backgroundColor: 'silver',
//                                                 borderRadius: '4px',
//                                                 padding: '4px'
//                                             }}
//                                         >
//                                             {userdata?.userName} {/* Use optional chaining to avoid errors */}
//                                         </SoftTypography>
//                                         <SoftTypography variant="body2" color="textSecondary">
//                                             <b>Asset Name:</b> {asset.asset_name}
//                                         </SoftTypography>
//                                         <SoftTypography variant="body2" color="textSecondary">
//                                             <b>Allocation Status:</b> {asset.allocation}
//                                         </SoftTypography>
//                                         <SoftTypography variant="body2" color="textSecondary">
//                                             <b>Description:</b> {asset.description}
//                                         </SoftTypography>
//                                     </CardContent>

//                                     <SoftBox sx={{ textAlign: 'center', pb: 2 }}>
//                                         {asset.allocation === "Allocated" ? (
//                                             <SoftButton
//                                                 variant="contained"
//                                                 color="error"
//                                                 onClick={() => returnAsset("Return Requested", asset.asset_id)} // Call returnAsset on click
//                                                 disabled={loading}
//                                             >
//                                                 Return
//                                             </SoftButton>
//                                         ) : asset.allocation === "Requested" ? (
//                                             <SoftTypography
//                                                 variant="body2"
//                                                 color="error"
//                                                 sx={{ mt: 2 }}
//                                             >
//                                                 Contact Administrator for Approval
//                                             </SoftTypography>
//                                         ) : null}
//                                     </SoftBox>
//                                 </Card>
//                             </Grid>
//                         ))}
//                     </Grid>
//                 </SoftBox>
//             </SoftBox>
//         </DashboardLayout>
//     );
// }

// export default MyAssets;


import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIData } from 'authentication/APIData';
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from 'sweetalert2';
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

function MyAssets() {
    const [assets, setAssets] = useState([]);
    const [userdata, setUserData] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
        setUserData(userData_Local);
    }, []);

    useEffect(() => {
        const fetchAssetGroup = async () => {
            if (!userdata) return;
            try {
                const response = await axios.get(`${APIData.api}employee-assets/id?empid=${userdata.userName}`, {
                    headers: APIData.headers
                });
                setAssets(response.data);
            } catch (error) {
                Swal.fire({
                    title: 'Error!',
                    text: 'No assets available in your name.',
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
                console.error("No assets available in your name.", error);
            }
        };

        fetchAssetGroup();
    }, [userdata]);

    const returnAsset = (status, asset_id) => {
        setLoading(true);
        const url = `${APIData.api}employee-assets/return-asset/?asset_id=${asset_id}&allocation_status=${status}`;
        axios.put(url, {}, { headers: APIData.headers })
            .then((resp) => {
                Swal.fire({
                    icon: "success",
                    title: `Successfully ${status} the asset`,
                    showConfirmButton: false,
                    timer: 1500
                });
                setLoading(false);
                fetchAssetGroup();
            })
            .catch((err) => {
                console.error(err);
                setLoading(false);
            });
    };

    const handleBack = () => {
        navigate("/assets-list");
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox mb={3}>
                <Tooltip title="Back to Asset Group List" arrow>
                    <SoftButton sx={{ ml: 2 }} onClick={handleBack}>Back</SoftButton>
                </Tooltip>
                <SoftBox pt={2} px={2}>
                    <SoftTypography variant="h5" fontWeight="medium">My Assets</SoftTypography>
                </SoftBox>
                <SoftBox p={2}>
                    {assets.length === 0 ? (
                        <SoftBox sx={{ textAlign: "center", mt: 4 }}>
                            <SoftTypography variant="h6" color="textSecondary">
                                No assets available in your name.
                            </SoftTypography>
                        </SoftBox>
                    ) : (
                        <Grid container spacing={3}>
                            {assets.map((asset) => (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={asset.asset_id}>
                                    <Card variant="outlined" sx={{
                                        borderRadius: '12px',
                                        boxShadow: 3,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        height: '100%',
                                    }}>
                                        <CardContent sx={{
                                            flexGrow: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'space-between',
                                        }}>
                                            <SoftTypography
                                                variant="h6"
                                                fontWeight="bold"
                                                sx={{
                                                    textAlign: 'center',
                                                    mb: 2,
                                                    mt: 2,
                                                    backgroundColor: 'silver',
                                                    borderRadius: '4px',
                                                    padding: '4px'
                                                }}
                                            >
                                                {userdata?.userName}
                                            </SoftTypography>
                                            <SoftTypography variant="body2" color="textSecondary">
                                                <b>Asset Name:</b> {asset.asset_name}
                                            </SoftTypography>
                                            <SoftTypography variant="body2" color="textSecondary">
                                                <b>Allocation Status:</b> {asset.allocation}
                                            </SoftTypography>
                                            <SoftTypography variant="body2" color="textSecondary">
                                                <b>Description:</b> {asset.description}
                                            </SoftTypography>
                                        </CardContent>
                                        <SoftBox sx={{ textAlign: 'center', pb: 2 }}>
                                            {asset.allocation === "Allocated" ? (
                                                <SoftButton
                                                    variant="contained"
                                                    color="error"
                                                    onClick={() => returnAsset("Return Requested", asset.asset_id)}
                                                    disabled={loading}
                                                >
                                                    Return
                                                </SoftButton>
                                            ) : asset.allocation === "Requested" ? (
                                                <SoftTypography
                                                    variant="body2"
                                                    color="error"
                                                    sx={{ mt: 2 }}
                                                >
                                                    Contact Administrator for Approval
                                                </SoftTypography>
                                            ) : null}
                                        </SoftBox>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </SoftBox>
            </SoftBox>
        </DashboardLayout>
    );
}

export default MyAssets;
