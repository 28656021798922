import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Card from "@mui/material/Card";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import SoftButton from 'components/SoftButton';
import { Tooltip } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';


const Name = ({ name }) => (
  <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
    <SoftBox mr={2}>
      <SoftAvatar
        src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
        alt={name}
        size="sm"
        variant="rounded"
      />
    </SoftBox>
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="button" fontWeight="medium">
        {name}
      </SoftTypography>
    </SoftBox>
  </SoftBox>
);



Name.propTypes = {
  name: PropTypes.string.isRequired,
};

const Organization = ({ orgName }) => (
  <SoftBox display="flex" flexDirection="column">

    <SoftTypography variant="caption" color="secondary">
      {orgName}
    </SoftTypography>
  </SoftBox>
);

Organization.propTypes = {
  orgName: PropTypes.string.isRequired,
};

const OrgPolicies = () => {
  const [policyDataList, setPolicyData] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const navigate = useNavigate();

  const handleNewClick = () => {
    navigate("/add-new-policy");
  };


  const handleOpenDialog = () => {
    setDialogOpen(true);
  }
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      console.log("PolicyData");
      let url = `${APIData.api}policies/`;
      url += `?org=${org}`;
      const response = await fetch(url, { headers: APIData.headers });
      const data = await response.json();
      setPolicyData(data);
    };
    fetchData();
  }, [status]);

  const columns = [
    { name: "Name", align: "left" },
    { name: "Organization", align: "left" },
    { name: "Policy Type", align: "center" },
    { name: "Audience", align: "center" },
    { name: "Policy Date", align: "center" },
    { name: "Action", align: "center" },
  ];

  const rows = policyDataList.map((policy) => ({
    Name: <Name name={policy.policy_name} />,
    Organization: <Organization orgName={policy.org} />,
    "Policy Type": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {policy.policy_type}
      </SoftTypography>
    ),
    Audience: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {policy.policy_audience}
      </SoftTypography>
    ),
    "Policy Date": (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {policy.policy_date}
      </SoftTypography>
    ),
    Action: (
      <Link to='/policy-data' state={policy}> view </Link>
    ),
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mb={0.5} sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <SoftBox style={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title="Add new organization policy" arrow>
            <SoftButton variant="gradient" color={"info"} sx={{ ml: 2 }} onClick={handleNewClick}>+ Add Policy</SoftButton>
          </Tooltip>
        </SoftBox>
      </SoftBox>
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <SoftTypography variant="h6">Org Policies</SoftTypography>
            </SoftBox>
            <SoftBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default OrgPolicies;