import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { APIData, org } from '../authentication/APIData';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import { IoArrowBack } from "react-icons/io5";
import Swal from 'sweetalert2';
import { Tooltip } from '@mui/material';
import Table from 'examples/Tables/Table';
import PropTypes from 'prop-types';


const GRADES = ["FIRST", "SECOND", "THIRD", "FOURTH", "FIFTH", "SIXTH", "SEVENTH", "EIGHTH", "NINTH", "TENTH"];
const SECTIONS = ["N/A", "A", "B", "C", "D", "E", "F", "G", "H"];
const LUNCH = ["P1", "P2", "P3", "P4", "P5", "P6", "P7"];


const TimeCell = ({ value }) => (
    <div>
        {value.time} - {value.endTime}
    </div>
);

TimeCell.propTypes = {
    value: PropTypes.shape({
        time: PropTypes.string.isRequired,
        endTime: PropTypes.string.isRequired
    }).isRequired
};

function Time({ startTime, endTime }) {
    return (
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="caption" fontWeight="medium" color="text">
                {startTime} - {endTime}
            </SoftTypography>
        </SoftBox>
    );
}

Time.propTypes = {
    startTime: PropTypes.string.isRequired,
    endTime: PropTypes.string.isRequired
};

const TimetableComponent = () => {
    const [startTime, setStartTime] = useState('');
    const [periodsMonToFri, setPeriodsMonToFri] = useState('');
    const [periodsSat, setPeriodsSat] = useState('');
    const [lunchBreakPeriod, setLunchBreakPeriod] = useState('');
    const [periodDuration, setPeriodDuration] = useState('');
    const [className, setClassName] = useState('TENTH');
    const [section, setSection] = useState('A');
    const [timetable, setTimetable] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredTimetable, setFilteredTimetable] = useState(null);
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

    useEffect(() => {
        if (timetable) {
            const filtered = timetable.filter(row =>
                Object.values(row).some(value =>
                    value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
            setFilteredTimetable(filtered);
        }
    }, [searchTerm, timetable]);

    const generateTimetable = async (startTime, periodsMonToFri, periodsSat, lunchBreakPeriod, periodDuration) => {
        // Check if any of the required fields are empty
        if (!startTime || !periodsMonToFri || !periodsSat || !lunchBreakPeriod || !periodDuration) {
            throw new Error('Please enter all required fields before generating the timetable');
        }
    
        try {
            const response = await axios.post(`${APIData.api}timetables/generate`, null, {
                params: {
                    startTime,
                    periodsMonToFri,
                    periodsSat,
                    periodDuration,
                    org: org,
                    lunchBreakPeriod
                },
                headers: APIData.headers
            });
            return response.data;
        } catch (error) {
            throw handleApiError('Error generating timetable');
        }
    };
    

    const deleteTimetable = async () => {
        try {
            setError(null);
            setLoading(true);
            await axios.delete(`${APIData.api}timetables/delete`, {
                params: {
                    org: org,

                },
                headers: APIData.headers
            });
            setTimetable(null);
            Swal.fire({
                icon: "success",
                title: `Timetable deleted successfully!`,
                showConfirmButton: false,
                timer: 1000
            });
        } catch (error) {
            setError(handleApiError('Error deleting timetable', error).message);
        } finally {
            setLoading(false);
        }
    };

    const handleApiError = (message, error) => {
        console.error(message, error);
        let errorMessage = `${message}. `;
        if (error.response) {
            errorMessage += `Server responded with status ${error.response.status}. `;
            errorMessage += `Details: ${JSON.stringify(error.response.data)}`;
        } else if (error.request) {
            errorMessage += "No response received from the server.";
        } else {
            errorMessage += error.message;
        }
        return new Error(errorMessage);
    };

    const handleGenerateTimetable = async () => {
        try {
            setError(null);
            setLoading(true);
            const data = await generateTimetable(startTime, periodsMonToFri, periodsSat, lunchBreakPeriod, periodDuration);
            console.log('Generated Timetable:', data);

            window.location.reload()
            Swal.fire({
                icon: "success",
                title: `Timetable generated successfully`,
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            setError(error.message);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchTimetable(className, section);
    }, [className, section]);

    const fetchTimetable = async (className, section) => {
        try {
            setError(null);
            setLoading(true);
            const response = await axios.get(`${APIData.api}timetables`, {
                params: {
                    org: org,
                    className: className,
                    section: section || '',
                },
                headers: APIData.headers
            });
            const data = response.data;
            if (data && data.length > 0) {
                const processedData = processTimeTableData(data);
                setTimetable(processedData);
            } else {
                setTimetable(null);
                setError("No timetable found for the selected grade and section.");
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setTimetable(null);
                setError("No timetable found for the selected grade and section.");
            } else {
                setError(handleApiError('Error fetching timetable', error).message);
            }
        } finally {
            setLoading(false);
        }
    };

    const processTimeTableData = (data) => {
        const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
        const processedData = [];

        // Group data by time slots
        const groupedByTime = data.reduce((acc, item) => {
            const timeKey = Array.isArray(item.startTime)
                ? item.startTime.join(':')
                : item.startTime;

            const endTimeFormatted = Array.isArray(item.endTime)
                ? item.endTime.join(':')
                : item.endTime;

            if (!acc[timeKey]) {
                acc[timeKey] = {
                    time: timeKey,
                    endTime: endTimeFormatted
                };
            }
            acc[timeKey][item.day] = item.subject;
            return acc;
        }, {});

        // Convert grouped data to array format
        Object.values(groupedByTime).forEach((timeSlot) => {
            const row = {
                time: timeSlot.time,
                endTime: timeSlot.endTime
            };
            days.forEach((day) => {
                row[day] = timeSlot[day] || '-';
            });
            processedData.push(row);
        });

        // Sort by time
        processedData.sort((a, b) => {
            const timeA = a.time.split(':').map(Number);
            const timeB = b.time.split(':').map(Number);
            return timeA[0] * 60 + timeA[1] - (timeB[0] * 60 + timeB[1]);
        });

        return processedData;
    };

    const calculateDuration = (startTime, endTime) => {
        const start = startTime[0] * 60 + startTime[1];
        const end = endTime[0] * 60 + endTime[1];
        return end - start;
    };

    const loadImageFromURL = (url) => {
        return new Promise((resolve, reject) => {
            if (!url) {
                reject(new Error('No image URL provided'));
                return;
            }

            const img = new Image();
            img.crossOrigin = 'Anonymous';

            img.onload = () => {
                try {
                    const canvas = document.createElement('canvas');
                    canvas.width = img.width;
                    canvas.height = img.height;
                    const ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0);
                    const dataURL = canvas.toDataURL('image/png');
                    resolve(dataURL);
                } catch (error) {
                    reject(new Error('Failed to convert image to data URL'));
                }
            };

            img.onerror = () => reject(new Error('Failed to load image'));
            const timestampedUrl = `${url}${url.includes('?') ? '&' : '?'}t=${new Date().getTime()}`;
            img.src = timestampedUrl;
        });
    };

    const downloadPDF = async () => {
        try {
            const doc = new jsPDF('landscape');
            const tableColumn = ["Time", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
            const tableRows = timetable.map(row => [
                `${row.time}`,
                row.MON,
                row.TUE,
                row.WED,
                row.THU,
                row.FRI,
                row.SAT
            ]);


            try {
                const logoUrl = `${APIData.api}org-mdm/org-id?orgId=${org}`;
                const response = await axios.get(logoUrl, { headers: APIData.headers });
                const orgData = response.data;


                if (orgData.orgLogo) {
                    const logoDataUrl = await loadImageFromURL(orgData.orgLogo);
                    doc.addImage(logoDataUrl, 'PNG', 14, 10, 30, 30);
                }

                doc.setFontSize(16);
                doc.text(orgData.orgName || "School Name", 50, 25);
                doc.setFontSize(12);
                doc.text(`Class: ${className} Section: ${section}`, 50, 35);
                doc.autoTable(tableColumn, tableRows, {
                    startY: 45,
                    styles: { fontSize: 10 },
                    headStyles: { fillColor: [34, 62, 107] },
                    margin: { top: 45 }
                });

                doc.save(`timetable_${className}_${section}.pdf`);
            } catch (logoError) {
                doc.setFontSize(16);
                doc.text("School Name", 50, 25);
                doc.setFontSize(12);
                doc.text(`Class: ${className} Section: ${section}`, 50, 35);
                doc.autoTable(tableColumn, tableRows, {
                    startY: 45,
                    styles: { fontSize: 10 },
                    headStyles: { fillColor: [34, 62, 107] },
                    margin: { top: 45 }
                });

                doc.save(`timetable_${className}_${section}.pdf`);
            }
        } catch (error) {
            console.error('Error generating PDF:', error);

            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error generating PDF. Please try again.",
            });
        }
    };

    const downloadExcel = async () => {
        try {
            const logoUrl = `${APIData.api}org-mdm/org-id?orgId=${org}`;
            const response = await axios.get(logoUrl, { headers: APIData.headers });
            const orgData = response.data;

            const workBook = XLSX.utils.book_new();

            const headerRows = [
                ['', orgData.orgName || 'School Name'],
                ['', `Class: ${className} Section: ${section}`],
                [''],
                ['Time', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']
            ];

            const dataRows = timetable.map(row => [
                row.time,
                row.MON,
                row.TUE,
                row.WED,
                row.THU,
                row.FRI,
                row.SAT
            ]);
            const allRows = [...headerRows, ...dataRows];
            const workSheet = XLSX.utils.aoa_to_sheet(allRows);
            const colWidths = [
                { wch: 15 }, // Time column
                { wch: 20 }, // MON column
                { wch: 20 }, // TUE column
                { wch: 20 }, // WED column
                { wch: 20 }, // THU column
                { wch: 20 }, // FRI column
                { wch: 20 }  // SAT column 
            ];
            workSheet['!cols'] = colWidths;

            workSheet['!merges'] = [
                { s: { r: 0, c: 1 }, e: { r: 0, c: 6 } },
                { s: { r: 1, c: 1 }, e: { r: 1, c: 6 } }
            ];
            XLSX.utils.book_append_sheet(workBook, workSheet, "Timetable");
            XLSX.writeFile(workBook, `timetable_${className}_${section}.xlsx`);
            Swal.fire({
                icon: "success",
                title: "Excel file downloaded successfully!",
                showConfirmButton: false,
                timer: 1500
            });
        } catch (error) {
            console.error('Error generating Excel file:', error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Error generating Excel file. Please try again.",
            });
        }
    };


    const renderTimetable = () => {
        if (!filteredTimetable || filteredTimetable.length === 0) return null;
    
        const days = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    
        const columns = [
            {
                name: "TIME",
                align: "left",
                width: "15%"
            },
            ...days.map(day => ({
                name: day,
                align: "center",
                width: `${85 / days.length}%`
            }))
        ];
    
        // Transform the data to match the Table component's expected format
        const rows = filteredTimetable.map(timeSlot => {
            const rowData = {
                TIME: `${timeSlot.time} - ${timeSlot.endTime}`,
                MON: timeSlot.MON || "-",
                TUE: timeSlot.TUE || "-",
                WED: timeSlot.WED || "-",
                THU: timeSlot.THU || "-",
                FRI: timeSlot.FRI || "-",
                SAT: timeSlot.SAT || "-",
                hasBorder: true
            };
            return rowData;
        });
    
        return (
            <SoftBox
                sx={{
                    "& .MuiTableRow-root:not(:last-child)": {
                        "& td": {
                            borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                `${borderWidth[1]} solid ${borderColor}`,
                        },
                    },
                }}
            >
                <Table columns={columns} rows={rows} />
            </SoftBox>
        );
    };

    const gradeOptions = GRADES.map(grade => ({
        value: grade,
        label: grade
    }));

    const sectionOptions = SECTIONS.map(section => ({
        value: section,
        label: section
    }));
    const lunchOptions = LUNCH.map(lunchBreakPeriod => ({
        value: lunchBreakPeriod,
        label: lunchBreakPeriod
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <Card>
                    <SoftBox p={3}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                                    <SoftTypography variant="h6">Timetable Generator</SoftTypography>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} sm={6} md={2}>
                                <SoftBox mb={2}>
                                    <Tooltip title="Enter the time when the first Sessions begins (e.g., 08:00)" arrow placement="top">
                                        <SoftInput
                                            type="time"
                                            label="Start Time"
                                            value={startTime}
                                            onChange={(e) => setStartTime(e.target.value)}
                                            fullWidth
                                            size="small"
                                            placeholder="Start Time"
                                            inputProps={{
                                                step: 300,
                                            }}
                                        />
                                    </Tooltip>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} sm={6} md={2}>
                                <SoftBox mb={2}>
                                    <Tooltip title="Number of class Sessions on weekdays (e.g., 8)" arrow placement="top">
                                        <SoftInput
                                            type="text"
                                            label="Sessions Mon-Fri"
                                            value={periodsMonToFri}
                                            onChange={(e) => setPeriodsMonToFri(e.target.value)}
                                            fullWidth
                                            size="small"
                                            placeholder="Sessions Mon-Fri"
                                        />
                                    </Tooltip>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} sm={6} md={2}>
                                <SoftBox mb={2}>
                                    <Tooltip title="Number of class Sessions on Saturday (e.g., 4, or 0 if no Saturday classes)" arrow placement="top">
                                        <SoftInput
                                            type="text"
                                            label="Sessions Sat"
                                            value={periodsSat}
                                            onChange={(e) => setPeriodsSat(e.target.value)}
                                            fullWidth
                                            size="small"
                                            Required
                                            placeholder="Sessions Sat"
                                        />
                                    </Tooltip>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <SoftBox mb={2}>
                                    <Tooltip title="Which Sessions number is the lunch break (e.g., P5 for 5th period)" arrow placement="top">
                                        {/* <SoftInput
                                            type="text"
                                            label="Lunch Break Session"
                                            value={lunchBreakPeriod}
                                            onChange={(e) => setLunchBreakPeriod(e.target.value)}
                                            fullWidth
                                            Required
                                            placeholder="Lunch Break Session"
                                        /> */}
                                        <CustomSelect
                                            options={lunchOptions}
                                            value={{ value: lunchBreakPeriod, label: lunchBreakPeriod }}
                                            onChange={(selectedOption) => setLunchBreakPeriod(selectedOption.value)}
                                            placeholder="Select Lunch Break Session"
                                            
                                        />
                                    </Tooltip>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12} sm={6} md={3}>
                                <SoftBox mb={2}>
                                    <Tooltip title="Length of each class Sessions in minutes (e.g., 45)" arrow placement="top">
                                        <SoftInput
                                            type="text"
                                            label="Session Duration (minutes)"
                                            value={periodDuration}
                                            onChange={(e) => setPeriodDuration(e.target.value)}
                                            fullWidth
                                            size="small"
                                            Required
                                            placeholder="Session Duration (minutes)"
                                        />
                                    </Tooltip>
                                </SoftBox>
                            </Grid>

                            <Grid item xs={12}>
                                <SoftBox display="flex" justifyContent="center" mb={3}>
                                    {timetable ? (
                                        <SoftButton
                                            variant="gradient"
                                            color="error"
                                            onClick={deleteTimetable}
                                            disabled={loading}
                                        >
                                            Delete Timetable
                                        </SoftButton>
                                    ) : (
                                        <SoftButton
                                            variant="gradient"
                                            color="info"
                                            onClick={handleGenerateTimetable}
                                            disabled={loading}
                                        >
                                            Generate Timetable
                                        </SoftButton>
                                    )}
                                </SoftBox>
                            </Grid>

                            <Grid container justifyContent="center" alignItems="center" spacing={2}>
                                <Grid item xs={12} sm={6} md={2}>
                                    <Tooltip title="Select the grade for the timetable" arrow placement="top">
                                        <CustomSelect
                                            options={gradeOptions}
                                            value={{ value: className, label: className }}
                                            onChange={(selectedOption) => setClassName(selectedOption.value)}
                                            placeholder="Select Grade"
                                            
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid item xs={12} sm={6} md={2}>
                                    <Tooltip title="Select the section for the timetable" arrow placement="top">
                                        <CustomSelect
                                            options={sectionOptions}
                                            value={{ value: section, label: section }}
                                            onChange={(selectedOption) => setSection(selectedOption.value)}
                                            placeholder="Select Section"
                                            
                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>


                            {error && (
                                <Grid item xs={12}>
                                    <SoftBox display="flex" justifyContent="center">
                                        <SoftTypography variant="body2" color="error">
                                            {error}
                                        </SoftTypography>
                                    </SoftBox>
                                </Grid>
                            )}

                            {timetable && (
                                <Grid item xs={12}>
                                    <SoftBox mt={3}>
                                        <SoftBox mb={3}>
                                            <SoftTypography variant="h6" textAlign="center">
                                                Timetable for {className} - {section}
                                            </SoftTypography>
                                        </SoftBox>
                                        <Card>
                                            <SoftBox
                                                sx={{
                                                    "& table": {
                                                        width: "100%",
                                                        borderCollapse: "collapse",
                                                        "& th, & td": {
                                                            border: "1px solid #e9ecef",
                                                            padding: "12px",
                                                            textAlign: "center",
                                                        },
                                                        "& th": {
                                                            backgroundColor: "#f8f9fa",
                                                            fontWeight: "bold",
                                                        }
                                                    }
                                                }}
                                            >
                                                {renderTimetable()}
                                            </SoftBox>
                                        </Card>
                                        <SoftBox display="flex" justifyContent="center" gap={2} mt={3}>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                onClick={downloadPDF}
                                            >
                                                Download PDF
                                            </SoftButton>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                onClick={downloadExcel}
                                            >
                                                Download Excel
                                            </SoftButton>
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                            )}
                        </Grid>
                    </SoftBox>
                </Card>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default TimetableComponent;