import { AppBar, Backdrop, Box, Button, Card, CircularProgress, Grid, Tab, Tabs } from '@mui/material';
import React, { useState, useEffect } from "react";
import SoftBox from "components/SoftBox";
import StationaryInfoCard from 'examples/Cards/InfoCards/StationaryInfoCard';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { APIData, org } from "authentication/APIData";
import axios from "axios";
import breakpoints from "assets/theme/base/breakpoints";
import SoftTypography from 'components/SoftTypography';
import MyStationery from './myStationary';
import Swal from 'sweetalert2';
import VerifyRequests from './verifyRequest';
import EmpStationeryList from './empStationaryList';
import storeimg from '../../assets/images/stationery2.avif'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StationaryItems() {
  const [open, setOpen] = useState(false);
  const [stationeryItems, setStationeryItems] = useState([]);
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [selectedStationaryItem, setSelectedStationaryItem] = useState({
    id: '',
    name: '',
    count: 1,
  });
  const [countError, setCountError] = useState({ error: "" });
  const [procurementErrors, setprocurementErrors] = useState({ stationaryName: "", stationaryCount: "" });
  const [stationaryName, setstationaryName] = useState('');
  const [stationaryCount, setstationaryCount] = useState('');
  const [selectedStationaryImage, setSelectedStationaryImage] = useState(null);
  const [selectedStationaryImagePath, setSelectedStationaryImagePath] = useState(null);
  const [myStationary, setMyStationary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imageError, setImageError] = useState("");
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(parseInt(accessLevel));
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);

  const shouldShowActions = () => {
    if (permission === 1100 || permission === 1000) {
      return false;
    }
    return isSuperAdmin || permission === 1111;
  };

  const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
  const handleClickOpen = (item) => {
    console.log(item);
    setSelectedStationaryItem({
      id: item.id,
      name: item.name,
      count: 1,
      availableCount: item.count
    });
    setCountError({ error: "" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRequestProductSubmit = (e) => {
    setCountError({ error: "" });
    if (selectedStationaryItem.count < 1) {
      setCountError({ error: "Count should be greater than 0" });
    }
    else if (selectedStationaryItem.count > selectedStationaryItem.availableCount) {
      setCountError({ error: "Count should be less then or equal to availability" });
    } else {
      e.preventDefault();
      const employeeStationaryData = {
        count: selectedStationaryItem.count,
        empEmail: sessiondetails.email,
        empId: sessiondetails.user,
        id: 0,
        requestedDate: "",
        stationaryId: selectedStationaryItem.id,
        org: org,
        stationaryName: selectedStationaryItem.name,
        status: "Requested",
        updatedBy: "",
        updatedDate: ""
      };
      const url = APIData.api + "emp/stationary";
      const data = axios
        .post(url, employeeStationaryData, { headers: APIData.headers })
        .then((resp) => {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: "Successfully Requested",
            confirmButtonText: 'Okay'
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: 'success',
            title: 'Success!',
            text: "Failed to request for allocation",
            confirmButtonText: 'Okay'
          });
        });
      setOpen(false);
    }
  };
  const handleStationaryNameChange = (e) => {
    const value = e.target.value;
    setstationaryName(value);

    // Validate name on change
    if (!value) {
      setprocurementErrors((prev) => ({ ...prev, stationaryName: "Stationary name is required." }));
    } else if (value.length > 45) {
      setprocurementErrors((prev) => ({ ...prev, stationaryName: "Stationary name should be less than 45 characters." }));
    } else {
      setprocurementErrors((prev) => ({ ...prev, stationaryName: "" }));
    }
  };

  const handleStationaryCountChange = (e) => {
    const value = e.target.value;
    setstationaryCount(value);

    // Validate count on change
    if (!value) {
      setprocurementErrors((prev) => ({ ...prev, stationaryCount: "Count is required." }));
    } else if (value < 1 || value > 999) {
      setprocurementErrors((prev) => ({ ...prev, stationaryCount: "Count should be in the range of (1, 999)." }));
    } else {
      setprocurementErrors((prev) => ({ ...prev, stationaryCount: "" }));
    }
  };

  const clearForm = () => {
    setstationaryName('');
    setstationaryCount('');
    setSelectedStationaryImage(null);
    setSelectedStationaryImagePath(null);
    setImageError('');
    setprocurementErrors({ stationaryName: "", stationaryCount: "" });
  };

  const handleStationarySubmit = async (event) => {
    event.preventDefault();
    let isValid = true;
    if (!selectedStationaryImage) {
      setImageError("Image upload is required.");
      return;
    } else {
      setImageError("");
    }

    setprocurementErrors(procurementErrors);
    setLoading(true);
    if (isValid) {
      console.log("uploading");
      const formData = {
        count: stationaryCount,
        id: 0,
        name: stationaryName,
        image: "",
        org: org
      };
      console.log(formData);

      try {
        const response = await axios.post(APIData.api + 'stationary', formData, { headers: APIData.headers });
        console.log(response.data.id);
        const id = response.data.id;

        if (selectedStationaryImage) {
          const imageFormData = new FormData();
          imageFormData.append('id', id);
          imageFormData.append('file', selectedStationaryImage);

          const url = `${APIData.api}stationary/${id}/image`;
          await axios.post(url, imageFormData, {
            headers: { ...APIData.headers, 'Content-Type': 'multipart/form-data' },
          }).then((response) => {
            if (response.status == 200 || response.status == 201) {
              Swal.fire({
                icon: 'success',
                title: 'Success!',
                text: "Uploaded stationary",
                confirmButtonText: 'Okay'
              });
            }
            clearForm();
            fetchStationeryItems();
            setTabValue(0);
            setLoading(false);
          });
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: "Failed to add stationary",
          confirmButtonText: 'Okay'
        });
      }
    }
    return isValid;
  };

  const fetchStationeryItems = () => {
    const url = `${APIData.api}stationary?org=${org}`;
    axios.get(url, { headers: APIData.headers })
      .then((resp) => {
        console.log(resp.data);

        setStationeryItems(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const selectStationaryImage = () => {
    document.getElementById('imageInput').click();
  };

  const handleSatationaryImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setSelectedStationaryImagePath(imageUrl);
      setSelectedStationaryImage(file);
      setImageError("");
    } else {
      setImageError("Image upload is required.");
    }
  };

  useEffect(() => {
    fetchStationeryItems();
  }, []);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    return setTabValue(newValue);
  };

  const fetchAssetGroup = () => {
    const url = APIData.api + `emp/stationary/empId?empId=${sessiondetails.userName}`
    const data = axios.get(url, { headers: APIData.headers }).then((resp) => {
      setMyStationary(resp.data);
    }).catch((err) => {
      console.log(err);
    });
  }
  useEffect(() => {
    fetchAssetGroup()
  }, [])


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} md={6} lg={12} sx={{ ml: "0" }}>
            <AppBar position="static">
              <Tabs
                orientation={tabsOrientation}
                value={tabValue}
                onChange={handleSetTabValue}
                sx={{ background: "transparent" }}
              >
                <Tab
                  value={0}
                  label="All Products"
                  sx={{
                    color: tabValue === 0 ? "info.main" : "text.secondary",
                    fontWeight: tabValue === 0 ? "bold" : "normal",
                    borderBottom: tabValue === 0 ? "2px solid" : "none",
                    borderColor: "info.main",
                  }}
                />
                <Tab
                  value={1}
                  label="My Stationary"
                  sx={{
                    color: tabValue === 1 ? "info.main" : "text.secondary",
                    fontWeight: tabValue === 1 ? "bold" : "normal",
                    borderBottom: tabValue === 1 ? "2px solid" : "none",
                    borderColor: "info.main",
                  }}
                />
                {(shouldShowActions() || permission===1110) && <Tab
                  value={2}
                  label="Add Stationary"
                  sx={{
                    color: tabValue === 2 ? "info.main" : "text.secondary",
                    fontWeight: tabValue === 2 ? "bold" : "normal",
                    borderBottom: tabValue === 2 ? "2px solid" : "none",
                    borderColor: "info.main",
                  }}
                />}
                {shouldShowActions() && <Tab
                  value={3}
                  label="Validate Request"
                  sx={{
                    color: tabValue === 3 ? "info.main" : "text.secondary",
                    fontWeight: tabValue === 3 ? "bold" : "normal",
                    borderBottom: tabValue === 3 ? "2px solid" : "none",
                    borderColor: "info.main",
                  }}
                />}
               
                <Tab
                  value={4}
                  label="Allocated Stationary"
                  sx={{
                    color: tabValue === 4 ? "info.main" : "text.secondary",
                    fontWeight: tabValue === 4 ? "bold" : "normal",
                    borderBottom: tabValue === 4 ? "2px solid" : "none",
                    borderColor: "info.main",
                  }}
                />
              </Tabs>
            </AppBar>
          </Grid>
        </Grid>
      </Card>
      <SoftBox mt={4}>
        {tabValue === 0 && (
          <SoftBox mb={1}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={3}>
                {stationeryItems.map((item) => (
                  <Grid item xs={12} md={6} xl={2} key={item.id}>
                    <StationaryInfoCard
                      imageUrl={item.imageUrl ?? storeimg}
                      title={item.name}
                      description={`Available: ${item.count}`}
                    />
                    <SoftBox onClick={() => handleClickOpen(item)} mt={0} display="flex" justifyContent="center">
                      <Button variant="text" size="small">
                        Request
                      </Button>
                    </SoftBox>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </SoftBox>
        )}
        {tabValue === 1 && (
          <SoftBox pt={2} pb={3} px={3}>
            <MyStationery stationary={myStationary} />
          </SoftBox>
        )}
        {tabValue === 2 && (
          <Grid container spacing={0} justifyContent="center" style={{ minHeight: "100vh" }}>
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
              <CircularProgress color="inherit" />
            </Backdrop>
            <Grid item>
              <Card sx={{ width: '500px', height: 'auto' }}>
                <SoftBox p={3} mb={1} textAlign="center">
                  <SoftTypography variant="h5" fontWeight="medium">
                    Add Stationary
                  </SoftTypography>
                </SoftBox>
                <SoftBox pb={3} px={3}>
                  <SoftBox component="form" role="form" onSubmit={handleStationarySubmit}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6">Stationary Name</SoftTypography>
                      <SoftInput
                        placeholder="Stationary Name"
                        value={stationaryName}
                        onChange={handleStationaryNameChange}
                        required
                      />
                      <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                        {procurementErrors.stationaryName}
                      </SoftTypography>
                      <SoftTypography variant="h6">Number of count</SoftTypography>
                      <SoftInput
                        type='number'
                        value={stationaryCount}
                        onChange={handleStationaryCountChange}
                        required
                      />
                      <SoftTypography ml={2} style={{ color: 'red', fontSize: '12px' }}>
                        {procurementErrors.stationaryCount}
                      </SoftTypography>
                    </SoftBox>
                    <SoftBox mt={4} mb={1} >
                      <SoftButton type="button" onClick={selectStationaryImage} variant="gradient" color="secondary">
                        Upload Image
                      </SoftButton>
                      <input
                        type="file"
                        id="imageInput"
                        style={{ display: 'none' }}
                        accept="image/*"
                        onChange={handleSatationaryImageChange}
                      />
                      {selectedStationaryImagePath && (
                        <SoftBox mt={2} >
                          <SoftTypography variant="h6">Selected Image:</SoftTypography>
                          <img src={selectedStationaryImagePath} alt="Selected" style={{ maxWidth: '30%', maxHeight: '30%', marginTop: '5px' }} />
                        </SoftBox>
                      )}
                      {imageError && (
                        <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
                          {imageError}
                        </SoftTypography>
                      )}
                    </SoftBox>
                    <SoftBox mt={4} mb={1}>
                      <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                        Add Stationary
                      </SoftButton>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        )}
        {tabValue === 3 && (
          <VerifyRequests />
        )}
        {tabValue === 4 && (
          <EmpStationeryList />
        )}
      </SoftBox>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="Request Product"
      >
        <DialogTitle>{"Request Product"}</DialogTitle>
        <DialogContent>
          <SoftBox mb={2}>
            <Box
              sx={{
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '4px',
                backgroundColor: '#f9f9f9',
              }}
            >
              <SoftTypography variant="body1">
                Product Name : {selectedStationaryItem.name}
              </SoftTypography>
            </Box>
          </SoftBox>
          <SoftBox mb={2}>
            <SoftInput
              type="number"
              value={selectedStationaryItem.count}
              placeholder="Product counts*"
              name="counts"
              onChange={(e) => setSelectedStationaryItem({ ...selectedStationaryItem, count: e.target.value })}
              helperText={countError.error}
            />
            <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
              {countError.error}
            </SoftTypography>
          </SoftBox>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleRequestProductSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
      <Footer />
    </DashboardLayout>
  );
}

export default StationaryItems;