import React, { useState, useEffect } from 'react';
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { APIData } from 'authentication/APIData';
import PropTypes from 'prop-types';
import { org } from 'authentication/APIData';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Tooltip, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogTitle,
  Grid,
  Divider 
} from '@mui/material';
import SoftButton from 'components/SoftButton';
import Swal from 'sweetalert2';

const EventImage = ({ image, eventName, onClick }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={image}
                alt={eventName}
                size="sm"
                variant="rounded"
                style={{ cursor: 'pointer' }}
                onClick={onClick}
            />
        </SoftBox>
        <SoftTypography variant="button" fontWeight="medium">
            {eventName}
        </SoftTypography>
    </SoftBox>
);

EventImage.propTypes = {
    image: PropTypes.string.isRequired,
    eventName: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

const Event = () => {
    const [eventData, setEventData] = useState([]);
    const [openImagePreview, setOpenImagePreview] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const navigate = useNavigate();
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            console.log(sessionDetails.user);
            
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    useEffect(() => {
        const fetchEventData = async () => {
            const url = `${APIData.api}events/?org=${org}`;
            try {
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                console.log(data);
                setEventData(data);
            } catch (error) {
                console.error('Error fetching event data:', error);
            }
        };

        fetchEventData();
    }, []);

    const handleViewDetails = async (eventId) => {
        try {
            const url = `${APIData.api}events/id?id=${eventId}`;
            const response = await fetch(url, { headers: APIData.headers });
            const eventDetails = await response.json();
            setSelectedEvent(eventDetails);
            setOpenDetailsDialog(true);
        } catch (error) {
            console.error('Error fetching event details:', error);
            Swal.fire('Error', 'Failed to fetch event details', 'error');
        }
    };

    const EventDetailsDialog = () => (
        <Dialog 
            open={openDetailsDialog} 
            onClose={() => setOpenDetailsDialog(false)}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <SoftTypography variant="h6">Event Details</SoftTypography>
            </DialogTitle>
            <DialogContent>
                {selectedEvent && (
                    <Grid container spacing={2} mt={1}>
                        <Grid item xs={12} mb={2}>
                            <SoftBox display="flex" justifyContent="center">
                                <img 
                                    src={selectedEvent.image_url} 
                                    alt={selectedEvent.event_name}
                                    style={{ maxWidth: '100%', maxHeight: '300px', objectFit: 'contain' }}
                                />
                            </SoftBox>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Event Name</SoftTypography>
                            <SoftTypography variant="body2">{selectedEvent.event_name}</SoftTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Date</SoftTypography>
                            <SoftTypography variant="body2">
                                {new Date(selectedEvent.event_date).toLocaleDateString()}
                            </SoftTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Duration</SoftTypography>
                            <SoftTypography variant="body2">{selectedEvent.duration}</SoftTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Location</SoftTypography>
                            <SoftTypography variant="body2">{selectedEvent.location}</SoftTypography>
                        </Grid>
                        <Grid item xs={6}>
                            <SoftTypography variant="subtitle2" color="text">Organizer</SoftTypography>
                            <SoftTypography variant="body2">{selectedEvent.organizer}</SoftTypography>
                        </Grid>
                        <Grid item xs={12}>
                            <SoftTypography variant="subtitle2" color="text">Description</SoftTypography>
                            <SoftTypography variant="body2">{selectedEvent.description}</SoftTypography>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            <DialogActions>
                <SoftButton onClick={() => setOpenDetailsDialog(false)} color="secondary">
                    Close
                </SoftButton>
            </DialogActions>
        </Dialog>
    );

    const canEditDelete = (event) => {
        return true;
    };

    const handleDelete = (eventId) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this event?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                const deleteUrl = `${APIData.api}events/?id=${eventId}`;
                fetch(deleteUrl, { method: 'DELETE', headers: APIData.headers })
                    .then(response => {
                        if (response.ok) {
                            Swal.fire('Deleted!', 'Your event has been deleted.', 'success');
                            setEventData(eventData.filter(event => event.id !== eventId));
                        } else {
                            Swal.fire('Failed!', 'There was an error deleting the event.', 'error');
                        }
                    })
                    .catch((error) => {
                        console.error('Error deleting event:', error);
                        Swal.fire('Error!', 'There was an error deleting the event.', 'error');
                    });
            }
        });
    };

    const handleImageClick = (imageUrl) => {
        setSelectedImage(imageUrl);
        setOpenImagePreview(true);
    };

    const columns = [
        { name: "Event Name", align: "left" },
        { name: "Date", align: "left" },
        { name: "Duration", align: "left" },
        { name: "Location", align: "left" },
        { name: "Organizer", align: "left" },
        { name: "Action", align: "center" },
    ];

    const rows = eventData.map((event) => ({
        "Event Name": <EventImage image={event.image_url} eventName={event.event_name} onClick={() => handleImageClick(event.image_url)} />,
        Date: (
            <SoftTypography variant="caption" color="text" fontWeight="medium">
                {new Date(event.event_date).toLocaleDateString()}
            </SoftTypography>
        ),
        Duration: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {event.duration}
            </SoftTypography>
        ),
        Location: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {event.location}
            </SoftTypography>
        ),
        Organizer: (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {event.organizer}
            </SoftTypography>
        ),
        Action: (
            <SoftBox display="flex" justifyContent="center">
                <SoftTypography
                    component="span"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    mr={2}
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleViewDetails(event.id)}
                >
                    View
                </SoftTypography>
                {canEditDelete(event) && (
                    <>
                        <Link to={`/edit-event/${event.id}`}>
                        {(permission===1100 ||permission===1110) || (permission===1111 || isSuperAdmin) ?"": <SoftTypography
                                component="span"
                                variant="button"
                                color="warning"
                                fontWeight="medium"
                                mr={2}
                            >
                                Edit
                            </SoftTypography>}
                        </Link>
                        {(permission===1100 ||permission===1110) || (permission===1111 || isSuperAdmin) ?"": <SoftTypography
                            component="span"
                            variant="button"
                            color="error"
                            fontWeight="medium"
                            onClick={() => handleDelete(event.id)}
                            style={{ cursor: 'pointer' }}
                        >
                            Delete
                        </SoftTypography>}
                    </>
                )}
            </SoftBox>
        ),
    }));

    const handleNewEventClick = () => {
        navigate("/event-form");
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">Event List</SoftTypography>
                        <Tooltip title="Add New Event" arrow>
                            <SoftButton variant="gradient" color="info" onClick={handleNewEventClick}>
                                + Add
                            </SoftButton>
                        </Tooltip>
                    </SoftBox>
                    <Card>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Footer />

            {/* Image Preview Dialog */}
            <Dialog 
                open={openImagePreview} 
                onClose={() => setOpenImagePreview(false)} 
                maxWidth="sm" 
                fullWidth
            >
                <DialogContent>
                    <img 
                        src={selectedImage} 
                        alt="Preview" 
                        style={{ width: '100%', height: 'auto' }} 
                    />
                </DialogContent>
            </Dialog>

            {/* Event Details Dialog */}
            <EventDetailsDialog />
        </DashboardLayout>
    );
};

export default Event;