import React, { useEffect, useState } from 'react';
import { Button, Grid, Typography, Container, Paper, Dialog, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import EmpProof from './EmplProof';
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import SoftButton from 'components/SoftButton';

const initialState = {

    header1: "",
    header10: "",
    header11: "",
    header12: "",
    header13: "",
    header14: "",
    header15: '',
    header16: "",
    header17: "",
    header18: "",
    header19: "",
    header2: "",
    header20: "",
    header21: "",
    header3: "",
    header4: "",
    header5: "",
    header6: "",
    header7: "",
    header8: "",
    header9: "",
    id: 0,
    org: org,
    para1: "",
    para10: "",
    para11: "",
    para12: "",
    para13: "",
    para14: "",
    para15: "",
    para16: "",
    para17: "",
    para18: "",
    para19: "",
    para2: "",
    para20: "",
    para21: "",
    para22: "",
    para3: "",
    para4: "",
    para5: "",
    para6: "",
    para7: "",
    para8: "",
    para9: "",
    temp_name: "EMPPROOF",
    title: ""

}

function EmplProofEdit() {
    const [formData, setFormData] = useState(initialState);
    const [orgdetails, setOrgdetails] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleBack = () => {
        if (location.state?.returnToStepper) {
            navigate(location.state.returnPath);
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        fetchOrgDetails();
    },);

    const fetchOrgDetails = () => {
        axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
            .then((response) => setOrgdetails(response.data))
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(APIData.api + `db-template/`, formData, { headers: APIData.headers })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Update Error",
                    text: error.response.data
                });

            })
        console.log('Form Data Submitted:', formData);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <Container>
            <PageLayout>
                   <SoftButton onClick={handleBack}>
                Back
            </SoftButton>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h5" gutterBottom>
                        Employee proof Form
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Title</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'title'}
                                 
                                    value={formData.title}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para1'}
                                   
                                    value={formData.para1}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para2'}
                                    
                                    value={formData.para2}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para3'}
                                    
                                    value={formData.para3}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para4'}
                                  
                                    value={formData.para4}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para5'}
                                    
                                    value={formData.para5}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para6'}
                            
                                    value={formData.para6}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para7'}
                                 
                                    value={formData.para7}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para8'}
                                    
                                    value={formData.para8}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para9'}
                                  
                                    value={formData.para9}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para10'}
                                 
                                    value={formData.para10}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para11'}
                                   
                                    value={formData.para11}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para12'}
                                    value={formData.para12}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    style={{ marginBottom: '16px', width: "100%" }}
                                    onClick={handleClickOpen}
                                >
                                    View Template in Full Screen
                                </Button>
                                <Button type="submit" variant="contained" color="primary" style={{ width: '100%' }}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} sm={4}>

                    <Typography variant="h5" gutterBottom>
                    Adaptive Template
                    </Typography>
                    <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                        {orgdetails ? (
                            <div style={styles.container}>
                                <div style={styles.header1}>
                                    <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                                    <div style={styles.companyInfo}>
                                        <h3>{orgdetails.orgName}</h3>
                                        <p>+91 {orgdetails.orgMob}</p>
                                        <p>{orgdetails.orgEmailId}</p>
                                        <p>{orgdetails.orgUrl}</p>
                                    </div>
                                </div>


                                <h2 style={styles.title}><center>{formData.title}</center></h2>
                                <main style={styles.content}>
                                    <strong>
                                        <p>Date: 20/08/2024</p>
                                        <p>To: Adhithyan P A</p>
                                        <p>Address: VidyaranyaPura</p>
                                    </strong>

                                    <p style={styles.name}>Dear <strong>Adhithyan P A,</strong></p>
                                    <p style={styles.letterBody}>
                                        {formData.para1} <strong>Adhithyan P A</strong> {formData.para2} <strong> Care4Edu</strong> {formData.para3} <strong>[Developer]</strong> {formData.para4} <strong>06/08/2024.</strong>
                                    </p>
                                    <p style={styles.letterBody}>
                                        <strong>Adhithyan P A</strong>{formData.para5} <strong>[{formData.para6}]</strong> {formData.para7}
                                    </p>

                                    <p style={styles.letterBody}>
                                        {formData.para8} <strong>Adhithyan P A</strong> {formData.para9} <strong>Care4Edu</strong> {formData.para10}
                                        <strong> EMPLOYEE. </strong>
                                    </p>

                                    <p style={styles.letterBody}>{formData.para11} </p>
                                </main>

                                <div style={styles.bottom}>
                                    <p>{formData.para12}</p>
                                    <strong><p>Rishi</p>
                                        <p>Superadmin</p>
                                        <p>Care4Edu</p>
                                        <p>7259785184</p></strong>
                                </div>
                            </div>
                        ) : (
                            <Typography variant="body1">Loading organization details...</Typography>
                        )}
                    </Paper >
                </Grid>

                <Grid item xs={12} sm={4}>
                    <EmpProof />
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Full Screen View
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: 'absolute', right: 16, top: 16 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {orgdetails ? (
                        <div style={styles.container}>
                            <div style={styles.header1}>
                                <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                                <div style={styles.companyInfo}>
                                    <h3>{orgdetails.orgName}</h3>
                                    <p>+91 {orgdetails.orgMob}</p>
                                    <p>{orgdetails.orgEmailId}</p>
                                    <p>{orgdetails.orgUrl}</p>
                                </div>
                            </div>


                            <h2 style={styles.title}><center>{formData.title}</center></h2>
                            <main style={styles.content}>
                                <strong>
                                    <p>Date: 20/08/2024</p>
                                    <p>To: Adhithyan P A</p>
                                    <p>Address: VidyaranyaPura</p>
                                </strong>

                                <p style={styles.name}>Dear <strong>Adhithyan P A,</strong></p>
                                <p style={styles.letterBody}>
                                    {formData.para1} <strong>Adhithyan P A</strong> {formData.para2} <strong> Care4Edu</strong> {formData.para3} <strong>[Developer]</strong> {formData.para4} <strong>06/08/2024.</strong>
                                </p>
                                <p style={styles.letterBody}>
                                    <strong>Adhithyan P A s</strong>{formData.para5} <strong>[{formData.para6}]</strong> {formData.para7}
                                </p>

                                <p style={styles.letterBody}>
                                    {formData.para8} <strong>Adhithyan P A</strong> {formData.para9} <strong>Care4Edu</strong> {formData.para10}
                                    <strong> EMPLOYEE. </strong>
                                </p>

                                <p style={styles.letterBody}>{formData.para11} </p>
                            </main>

                            <div style={styles.bottom}>
                                <p>{formData.para12}</p>
                                <strong><p>Rishi</p>
                                    <p>Superadmin</p>
                                    <p>Care4Edu</p>
                                    <p>7259785184</p></strong>
                            </div>
                        </div>
                    ) : (
                        <Typography variant="body1">Loading organization details...</Typography>
                    )}
                </DialogContent>
            </Dialog>
            </PageLayout>
        </Container>
    );
};

export default EmplProofEdit;
const styles = {
    container: {
        maxWidth: '700px',
        margin: 'auto',
        padding: '20px',
     
        backgroundColor: '#fff',
    },
    header1: {
        alignItems: 'center',
        paddingBottom: '10px',
        fontSize: '14px',
    },
    logo: {
        width: '100px',
        height: 'auto',
    },
    companyInfo: {
        textAlign: 'left',
        marginTop: '10px',
        fontSize: '14px',


    },
    content: {
        marginTop: '20px',
        fontSize: '14px',
    },
    title: {
        marginTop: '20px',
        fontSize: '20px',
        fontWeight: 'bold',

    },
    name: {
        marginTop: '20px',
        fontSize: '14px',
    },
    letterBody: {
        marginTop: '20px',
        fontSize: '14px',
    },
    bottom: {
        marginTop: '30px',
        textAlign: 'left',
        fontSize: '14px',
    },
};