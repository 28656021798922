import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Grid,
    Card,
    Backdrop,
    CircularProgress,
    FormControl,
} from '@mui/material';
import { Mail, User, Home, Calendar, AtSign, Phone } from 'lucide-react';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import { APIData, org } from '../authentication/APIData';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';

const qualificationOptions = [
    { value: "Masters Degree", label: "Master's Degree" },
    { value: "Bachelors Degree", label: "Bachelor's Degree" },
    { value: "PUC", label: "PUC" },
    { value: "SSLC", label: "SSLC" }
];
const candidateTypeOptions = [
    { value: "FRESHER", label: "Fresher" },
    { value: "EXPERIENCED", label: "Experienced" }
];

const JobApplyPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [jobDetails, setJobDetails] = useState(null);
    const [formData, setFormData] = useState({
        candidateEmail: '',
        firstName: '',
        lastName: '',
        candidatePhNo: '',
        address: '',
        alternateEmail: '',
        dob: '',
        highestQualification: '',
        candidateType: '' 
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        const fetchJobDetails = () => {
            try {
                if (location.state?.jobId) {
                    const stateJobDetails = {
                        jobId: location.state.jobId,
                        createdBy: location.state.createdBy,
                        updatedBy: location.state.updatedBy,
                        department: location.state.department,
                        designation: location.state.designation
                    };
                    setJobDetails(stateJobDetails);
                    localStorage.setItem('sessiondetails', JSON.stringify(stateJobDetails));
                    return;
                }

                const storedJobDetails = localStorage.getItem('sessiondetails');
                if (!storedJobDetails) {
                    throw new Error('No job details found');
                }

                const parsedJobDetails = JSON.parse(storedJobDetails);

                if (!parsedJobDetails || (!parsedJobDetails.jobId && !parsedJobDetails.job_id)) {
                    throw new Error('Invalid job details format');
                }

                const normalizedJobDetails = {
                    jobId: parsedJobDetails.jobId || parsedJobDetails.job_id,
                    createdBy: parsedJobDetails.createdBy || '',
                    updatedBy: parsedJobDetails.updatedBy || '',
                    department: parsedJobDetails.department || parsedJobDetails.job_dept || '',
                    designation: parsedJobDetails.designation || parsedJobDetails.job_designation || ''
                };

                setJobDetails(normalizedJobDetails);

                // Load saved user details
                const sessionDetails = JSON.parse(localStorage.getItem('sessiondetails'));
                if (sessionDetails) {
                    setFormData(prev => ({
                        ...prev,
                        candidateEmail: sessionDetails.candidateEmail || '',
                        firstName: sessionDetails.firstName || '',
                        candidatePhNo: sessionDetails.candidatePhNo || ''
                    }));
                }
            } catch (error) {
                console.error("Error loading job details:", error.message);
                redirectToJobSelection(error.message);
            }
        };

        fetchJobDetails();
    }, [location, navigate]);

    const redirectToJobSelection = (errorMessage) => {
        localStorage.removeItem('sessiondetails');
        Swal.fire({
            icon: "error",
            title: "No Job Selected",
            text: errorMessage || "Please select a job to apply"
        }).then(() => {
            navigate('/userjobs');
        });
    };

    const validateEmail = (email) => {
        return /\S+@\S+\.\S+/.test(email);
    };

    const validatePhone = (phone) => {
        if (!/^\d{10}$/.test(phone)) {
            return "Phone number must be 10 digits";
        }
        if (parseInt(phone[0]) < 6) {
            return "Mobile number must start with 6 or greater";
        }
        return true; // If both conditions pass
    };


    const validateDOB = (dob) => {
        const dobDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - dobDate.getFullYear();
        const monthDiff = today.getMonth() - dobDate.getMonth();

        if (dobDate > today) return "Date of Birth cannot be in the future";
        if (age < 18 || (age === 18 && monthDiff < 0)) return "You must be at least 18 years old";
        return "";
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.candidateEmail) newErrors.candidateEmail = "Email is required";
        else if (!validateEmail(formData.candidateEmail)) newErrors.candidateEmail = "Invalid email format";

        if (formData.alternateEmail && !validateEmail(formData.alternateEmail)) {
            newErrors.alternateEmail = "Invalid email format";
        }

        if (!formData.candidatePhNo) {
            newErrors.candidatePhNo = "Phone number is required";
        } else {
            const phoneError = validatePhone(formData.candidatePhNo);
            if (phoneError !== true) {
                newErrors.candidatePhNo = phoneError; // Set the error message
            }
        }

        if (!formData.dob) newErrors.dob = "Date of birth is required";
        else {
            const dobError = validateDOB(formData.dob);
            if (dobError) newErrors.dob = dobError;
        }

        if (!formData.highestQualification) newErrors.highestQualification = "Qualification is required";
        if (!formData.address) newErrors.address = "Address is required";
        if (!formData.candidateType) newErrors.candidateType = "Candidate type is required"; 
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!jobDetails?.jobId) {
            redirectToJobSelection("Please select a job first");
            return;
        }

        if (!validateForm()) {
            toast.error("Please fill all required fields correctly");
            return;
        }

        setLoading(true);
        const today = new Date();
        const datetime = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

        // Match the exact structure of the successful API payload
        const JobApplicationData = {
            address: formData.address,
            alternateEmail: formData.alternateEmail || "",
            appliedDate: datetime,
            candidateEmail: formData.candidateEmail,
            candidatePhNo: formData.candidatePhNo,
            candidateStatus: "APPLIED",
            candidateType:  formData.candidateType,
            createdBy: jobDetails.createdBy || "",
            created_date_time: datetime,
            dept: jobDetails.department || "",
            designation: jobDetails.designation || "",
            dob: formData.dob,
            firstName: formData.firstName,
            highestQualification: formData.highestQualification,
            lastName: formData.lastName || "",
            org: org,
            enroll: "NO",
            resumeUrl: "",
            role: jobDetails.jobId,
            updatedBy: jobDetails.updatedBy || "",
            updated_date_time: datetime,
            intComments: "",
            interviewer: "",
            hrSpoc: ""
        };
  console.log(JobApplicationData);
  
        try {
            const response = await axios.post(APIData.api + 'candidate/', JobApplicationData, {
                headers: APIData.headers
            });

            if (response.data.status.toString().toLowerCase() === "success") {
                // Store session details
                localStorage.setItem('status', 'success');
                localStorage.setItem('candidateEmail', formData.candidateEmail);
                localStorage.setItem('firstName', formData.firstName);
                localStorage.setItem('candidatePhNo', formData.candidatePhNo);

                Swal.fire({
                    icon: "success",
                    title: "Job Applied Successfully",
                    showConfirmButton: false,
                    timer: 1500
                });

                setTimeout(() => {
                    navigate("/uploadResume");
                }, 1500);
            } else {
                throw new Error(response.data.errorDesc || "Application submission failed");
            }
        } catch (error) {
            console.error("Application submission error:", error);
            Swal.fire({
                icon: "error",
                title: "Application Failed",
                text: error.response?.data?.errorDesc || error.message || "Failed to submit your application. Please try again."
            });
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        setErrors(prev => ({ ...prev, [name]: "" }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        if (selectedOption) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [actionMeta.name]: selectedOption.value,
            }));
            setErrors(prev => ({ ...prev, [actionMeta.name]: "" }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [actionMeta.name]: '',
            }));
        }
    };

    JobApplyPage.propTypes = {
        location: PropTypes.shape({
            state: PropTypes.shape({
                jobId: PropTypes.string,
                createdBy: PropTypes.string,
                updatedBy: PropTypes.string,
                department: PropTypes.string,
                designation: PropTypes.string
            })
        })
    };

    JobApplyPage.defaultProps = {
        location: {
            state: {
                jobId: '',
                createdBy: '',
                updatedBy: '',
                department: '',
                designation: ''
            }
        }
    };

    return (
        <PageLayout>
            <DefaultNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" >
                <Grid item xs={10} sm={10} md={8} lg={6} mt={12}>
                    <Card className="w-full" >
                        <SoftBox p={3} mb={1} textAlign="center">
                            <SoftTypography variant="h4" fontWeight="medium" className="text-gradient">
                                Job Application Form
                            </SoftTypography>
                            <SoftTypography variant="body2" color="text" mt={1}>
                                Please fill in your details below
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={2} pb={3} px={3}>
                            <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6" mb={1}>Email Address</SoftTypography>
                                            <SoftInput
                                                type="email"
                                                name="candidateEmail"
                                                placeholder="Enter your email"
                                                value={formData.candidateEmail}
                                                onChange={handleChange}
                                                error={!!errors.candidateEmail}
                                                icon={<Mail className="text-gray-500" />}
                                            />
                                            {errors.candidateEmail && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.candidateEmail}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6" mb={1}>Alternate Email</SoftTypography>
                                            <SoftInput
                                                type="email"
                                                name="alternateEmail"
                                                placeholder="Alternate email (optional)"
                                                value={formData.alternateEmail}
                                                onChange={handleChange}
                                                error={!!errors.alternateEmail}
                                                icon={<AtSign className="text-gray-500" />}
                                            />
                                            {errors.alternateEmail && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.alternateEmail}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6" mb={1}>First Name</SoftTypography>
                                            <SoftInput
                                                name="firstName"
                                                placeholder="Enter first name"
                                                value={formData.firstName}
                                                onChange={handleChange}
                                                error={!!errors.firstName}
                                                icon={<User className="text-gray-500" />}
                                            />
                                            {errors.firstName && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.firstName}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6" mb={1}>Last Name</SoftTypography>
                                            <SoftInput
                                                name="lastName"
                                                placeholder="Enter last name"
                                                value={formData.lastName}
                                                onChange={handleChange}
                                                icon={<User className="text-gray-500" />}
                                            />
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6" mb={1}>Date of Birth</SoftTypography>
                                            <SoftInput
                                                type="date"
                                                required
                                                name="dob"
                                                value={formData.dob}
                                                onChange={handleChange}
                                                error={!!errors.dob}
                                                icon={<Calendar className="text-gray-500" />}
                                            />
                                            {errors.dob && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.dob}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6" mb={1}>Phone Number</SoftTypography>
                                            <SoftInput
                                                name="candidatePhNo"
                                                placeholder="Enter phone number"
                                                value={formData.candidatePhNo}
                                                onChange={handleChange}
                                                error={!!errors.candidatePhNo}
                                                icon={<Phone className="text-gray-500" />}
                                                onKeyPress={(e) => {
                                                    if (!/[0-9]/.test(e.key)) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                inputProps={{
                                                    maxLength: 10,
                                                    pattern: "[0-9]*",
                                                    inputMode: "numeric"
                                                }}
                                            />
                                            {errors.candidatePhNo && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.candidatePhNo}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6" mb={1}>Highest Qualification</SoftTypography>
                                            <CustomSelect
                                                options={qualificationOptions}
                                                placeholder="Select your qualification"
                                                name="highestQualification"
                                                onChange={handleSelectChange}
                                                value={formData.highestQualification}
                                                error={!!errors.highestQualification}
                                            />
                                            {errors.highestQualification && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.highestQualification}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6" mb={1}>Experience</SoftTypography>
                                            <CustomSelect
                                                options={candidateTypeOptions}
                                                placeholder="Select Experience"
                                                name="candidateType"
                                                onChange={handleSelectChange}
                                                value={formData.candidateType}
                                                error={!!errors.candidateType}
                                            />
                                            {errors.candidateType && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.candidateType}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mb={1}>
                                            <SoftTypography variant="h6" mb={1}>Address</SoftTypography>
                                            <SoftInput
                                                multiline
                                                rows={3}
                                                name="address"
                                                placeholder="Enter your address"
                                                value={formData.address}
                                                onChange={handleChange}
                                                error={!!errors.address}
                                                icon={<Home className="text-gray-500" />}
                                            />
                                            {errors.address && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.address}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mt={2} mb={1}>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                onClick={handleSubmit}
                                                disabled={loading}
                                            >
                                                {loading ? "Submitting..." : "Submit Application"}
                                            </SoftButton>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </PageLayout>
    );
};

export default JobApplyPage;