// ComingSoon.js
import React from 'react';
import Grid from '@mui/material/Grid';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import PageLayout from 'examples/LayoutContainers/PageLayout';

const ComingSoon = () => {
  return (
    <PageLayout>
    <SoftBox component="main" py={6} style={{ backgroundColor: '#f8f9fa', minHeight: '100%' }}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={10} md={8} lg={6}>
          <SoftBox display="flex" flexDirection="column" alignItems="center" textAlign="center">
            <SoftTypography variant="h1" color="#343a40" style={{ fontFamily: 'Poppins, sans-serif' }}>
              Coming Soon
            </SoftTypography>
            <SoftTypography variant="h6" color="secondary" style={{ margin: '20px 0', fontFamily: 'Poppins, sans-serif' }}>
              We&apos;re working hard to launch something amazing!
            </SoftTypography>
            <SoftTypography variant="body1" color="secondary" style={{ fontFamily: 'Poppins, sans-serif' }}>
              ⏳ Stay tuned for updates!
            </SoftTypography>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
    </PageLayout>
  );
};

export default ComingSoon;
