import React, { useEffect, useState } from 'react';
import {
    TextField, Button, Grid, Typography, Container, Paper, Dialog, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import { APIData } from 'authentication/APIData';
import Noc from './Noc';
import { org } from 'authentication/APIData';
import SoftInput from 'components/SoftInput';
import SoftTypography from "components/SoftTypography";
import PageLayout from 'examples/LayoutContainers/PageLayout';
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import SoftButton from 'components/SoftButton';

const initialState = {
    header1: "",
    header10: "",
    header11: "",
    header12: "",
    header13: "",
    header14: "",
    header15: '',
    header16: "",
    header17: "",
    header18: "",
    header19: "",
    header2: "",
    header20: "",
    header21: "",
    header3: "",
    header4: "",
    header5: "",
    header6: "",
    header7: "",
    header8: "",
    header9: "",
    id: 0,
    org: org,
    para1: "",
    para10: "",
    para11: "",
    para12: "",
    para13: "",
    para14: "",
    para15: "",
    para16: "",
    para17: "",
    para18: "",
    para19: "",
    para2: "",
    para20: "",
    para21: "",
    para22: "",
    para3: "",
    para4: "",
    para5: "",
    para6: "",
    para7: "",
    para8: "",
    para9: "",
    temp_name: "NOC",
    title: ""
}

function Nocedit() {
    const [formData, setFormData] = useState(initialState);
    const [orgdetails, setOrgdetails] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleBack = () => {
        if (location.state?.returnToStepper) {
            navigate(location.state.returnPath);
        } else {
            navigate(-1);
        }
    };


    useEffect(() => {
        fetchOrgDetails();
    }, []);

    const fetchOrgDetails = () => {
        axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
            .then((response) => setOrgdetails(response.data))
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(APIData.api + `db-template/`, formData, { headers: APIData.headers })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Update Error",
                    text: error.response.data
                });
            });
        console.log('Form Data Submitted:', formData);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Container>
            <PageLayout>
            
              <SoftButton onClick={handleBack}>
                Back
            </SoftButton>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h5" gutterBottom>
                        Noc Template Form
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Titel</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name="title"
                                 
                                    value={formData.title}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph 1</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name="para1"
                                    multiline={true}
                                    rows={3}
                                    value={formData.para1}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph 2</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name="para2"
                                    multiline={true}
                                    rows={3}
                                    value={formData.para2}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph 3</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name="para3"
                                    multiline={true}
                                    rows={3} 
                                    value={formData.para3}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph 4</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name="para4"
                                    multiline={true}
                                    rows={3}
                                    value={formData.para4}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    style={{ marginBottom: '16px', width: "100%" }}
                                    onClick={handleClickOpen}
                                >
                                    View Template in Full Screen
                                </Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ color: 'primary', width: '100%', marginTop: '5px' }}
                                >
                                    Submit
                                </Button>

                            </Grid>
                        </Grid>
                    </form>

                </Grid>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h5" gutterBottom>
                    Adaptive Template
                    </Typography>

                    <Paper elevation={3} style={{ padding: '16px', margin: '0' }}>
                        {orgdetails ? (
                            <div style={containerStyle}>
                                <header style={headerStyle}>
                                    <img src={orgdetails.orgLogo} style={logoStyle} alt="Organization Logo" />
                                    <div style={headerContentStyle}>
                                        <h2 style={titleTextStyle}>{orgdetails.orgName}</h2>
                                        <p style={paragraphStyle}>+91 {orgdetails.orgMob}</p>
                                        <p style={paragraphStyle}>{orgdetails.orgEmailId}</p>
                                    </div>
                                </header>
                                <div style={contentStyle}>
                                    <center>
                                        <u>
                                            <h2 style={nocTitleStyle}>{formData.title}</h2>
                                        </u>
                                    </center>
                                    <p style={paragraphStyle1}><strong>Date: 20/08/2024</strong></p>
                                    <p style={paragraphStyle1}>To: Whomsoever It May Concern</p>
                                    <div style={paragraphStyle1}>
                                        This is to certify that <strong>Adhithyan P A,</strong> working as <strong>[Developer]</strong> at
                                        <strong> Care4Edu Solutions Pvt Ltd,</strong> {formData.para1} <strong>Adhithyan P A</strong> has tendered their resignation from
                                        <strong> Care4Edu Solutions Pvt Ltd,</strong> effective on <strong>20/08/2024,</strong> {formData.para2}
                                    </div>
                                    <div style={paragraphStyle1}>
                                        Also, <strong>[Developer] Adhithyan P A,</strong> {formData.para3}
                                    </div>
                                    <div style={paragraphStyle1}>
                                        {formData.para4}
                                    </div>
                                    <div style={signatureStyle}>
                                        <div style={signatureBlockStyle}>
                                            <p style={paragraphStyle}>Sincerely,</p>
                                            <p style={paragraphStyle}>Authorized Signatory</p>
                                            <p style={paragraphStyle}>
                                                <strong>Care4Edu Solutions Pvt Ltd</strong>
                                            </p>
                                            <p style={paragraphStyle}>
                                                Vidyaranyapura
                                            </p>
                                        </div>
                                        <div style={signatureBlockStyle}>
                                            <p style={paragraphStyle}>Acknowledged By,</p>
                                            <p style={paragraphStyle}>
                                                <strong>(Adhithyan P A)</strong>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <Typography variant="body1">Loading organization details...</Typography>
                        )}
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Noc />
                </Grid>
            </Grid>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Full Screen View
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: 'absolute', right: 16, top: 16 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {orgdetails ? (
                        <div style={containerStyle}>
                            <header style={headerStyle}>
                                <img src={orgdetails.orgLogo} style={logoStyle} alt="Organization Logo" />
                                <div style={headerContentStyle}>
                                    <h2 style={titleTextStyle}>{orgdetails.orgName}</h2>
                                    <p style={paragraphStyle}>+91 {orgdetails.orgMob}</p>
                                    <p style={paragraphStyle}>{orgdetails.orgEmailId}</p>
                                </div>
                            </header>
                            <div style={contentStyle}>
                                <center>
                                    <u>
                                        <h2 style={nocTitleStyle}>{formData.title}</h2>
                                    </u>
                                </center>
                                <p style={paragraphStyle1}><strong>Date: 20/08/2024</strong></p>
                                <p style={paragraphStyle1}>To: Whomsoever It May Concern</p>
                                <div style={paragraphStyle1}>
                                    This is to certify that <strong>Adhithyan P A,</strong> working as <strong>[Developer]</strong> at
                                    <strong> Care4Edu Solutions Pvt Ltd,</strong> {formData.para1} <strong>Adhithyan P A</strong>
                                    <strong> Care4Edu Solutions Pvt Ltd,</strong> effective on <strong>20/08/2024,</strong> {formData.para2}
                                </div>
                                <div style={paragraphStyle1}>
                                    Also, <strong>[Developer] Adhithyan P A,</strong> {formData.para3}
                                </div>
                                <div style={paragraphStyle1}>
                                    {formData.para4}
                                </div>
                                <div style={signatureStyle}>
                                    <div style={signatureBlockStyle}>
                                        <p style={paragraphStyle}>Sincerely,</p>
                                        <p style={paragraphStyle}>Authorized Signatory</p>
                                        <p style={paragraphStyle}>
                                            <strong>Care4Edu Solutions Pvt Ltd</strong>
                                        </p>
                                        <p style={paragraphStyle}>
                                            Vidyaranyapura
                                        </p>
                                    </div>
                                    <div style={signatureBlockStyle}>
                                        <p style={paragraphStyle}>Acknowledged By,</p>
                                        <p style={paragraphStyle}>
                                            <strong>(Adhithyan P A)</strong>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Typography variant="body1">Loading organization details...</Typography>
                    )}
                </DialogContent>
            </Dialog>
            </PageLayout>
        </Container>
    );
}

const containerStyle = {
    color: '#333',
    maxWidth: '700px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#fff',
};


const headerStyle = {
    textAlign: 'left',
    marginBottom: '20px',
};

const logoStyle = {
    width: '80px',
    height: '80px',
    marginLeft: '20px',

};

const headerContentStyle = {
    marginLeft: '0px',
};

const titleTextStyle = {
    fontSize: '16px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const paragraphStyle = {
    margin: '5px 0',

};
const paragraphStyle1 = {
    margin: '5px 0',
    marginBottom: '20px',

}

const contentStyle = {
    lineHeight: '1.6',
    marginBottom: '20px',
};

const nocTitleStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const signatureStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '40px',
};

const signatureBlockStyle = {
    textAlign: 'center',
};

export default Nocedit;
