import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { APIData, org } from '../authentication/APIData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import AddIcon from '@mui/icons-material/Add';
import { IoArrowBack } from "react-icons/io5";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import { CircularProgress, Backdrop } from "@mui/material";
import Swal from 'sweetalert2';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';


const ClassroomTable = () => {
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const [classrooms, setClassrooms] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newClassroom, setNewClassroom] = useState({
        id: 0,
        roomNo: '',
        classLevel: '',
        section: '',
        org: org,
        createDateTime: new Date().toISOString(),
        createdBy: sessiondetails.userName,
        updateDateTime: new Date().toISOString(),
        updatedBy: sessiondetails.userName,
    });
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(parseInt(accessLevel));
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);
    const classLevels = [
        'FIRST', 'SECOND', 'THIRD', 'FOURTH', 'FIFTH',
        'SIXTH', 'SEVENTH', 'EIGHTH', 'NINTH', 'TENTH'
    ];
    const SECTIONS = ["N/A", "A", "B", "C", "D", "E", "F", "G", "H"];

    const classLevelOptions = classLevels.map(level => ({
        value: level,
        label: level
    }));

    const sectionOptions = SECTIONS.map(section => ({
        value: section,
        label: section
    }));

    useEffect(() => {
        fetchClassrooms();
    }, []);
    // useEffect(() => {
    //     Swal.fire({
    //         icon: "info",
    //         title: "Work is in progress. Please do not report any issues at this time.",
    //         showConfirmButton: false,
    //         timer: 4000
    //     });
    // }, []);

    const fetchClassrooms = async () => {
        try {
            const response = await axios.get(`${APIData.api}classroom/by-org?org=${org}`, {
                headers: APIData.headers,
            });
            setClassrooms(response.data);
        } catch (error) {
            console.error('Error fetching classrooms:', error);
            // toast.error('Failed to fetch classrooms.');
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to fetch classrooms.",error
            });
        }
    };

    const handleInputChange = (name, value) => {
        setNewClassroom(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();
        setIsLoading(true);
        try {
            const response = await axios.post(`${APIData.api}classroom`, newClassroom, {
                headers: APIData.headers,
            });
            // toast.success('Classroom Added successfully!' || response.status);
            Swal.fire({
                icon: "success",
                title: "Classroom Added successfully!",response,
                showConfirmButton: false,
                timer: 1500
            });
            setClassrooms(prev => [...prev, response.data]);
            setNewClassroom({
                id: 0,
                roomNo: '',
                classLevel: '',
                section: '',
                org: org,
                createDateTime: new Date().toISOString(),
                createdBy: sessiondetails.userName,
                updateDateTime: new Date().toISOString(),
                updatedBy: sessiondetails.userName,
            });
            setIsDialogOpen(false);
        } catch (error) {
            console.error('Error creating classroom:', error);
            // toast.error('Failed to create classroom.');
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to create classroom.",
                footer:error.response.data,
            });
        } finally {
            setIsLoading(false);
        }
    };

    const filteredClassrooms = classrooms.filter(classroom =>
        Object.values(classroom).some(value =>
            value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    const paginatedClassrooms = filteredClassrooms.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );
    const shouldShowActions = () => {
        if (permission === 1100 || permission === 1000) {
            return false;
        }
        return isSuperAdmin || userDepartment === "ACADEMY";
    };
    const columns = [
        { name: "Room No", align: "center" },
        { name: "Grade", align: "center" },
        { name: "Section", align: "center" },
    ];

    const rows = paginatedClassrooms.map((classroom) => ({
        "Room No": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {classroom.roomNo}
            </SoftTypography>
        ),
        "Grade": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {classroom.classLevel}
            </SoftTypography>
        ),
        "Section": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {classroom.section || 'N/A'}
            </SoftTypography>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card sx={{ maxWidth: '1000px', margin: '0 auto' }}>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftBox display="flex" alignItems="center">
                                <SoftTypography variant="h6" sx={{ ml: 2 }}>Classrooms</SoftTypography>
                            </SoftBox>
                            <SoftBox display="flex" gap={2}>
                                <SoftInput
                                    placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                    size="small"
                                />
                                {(shouldShowActions())?<SoftButton
                                    variant="gradient"
                                    color="info"
                                    startIcon={<AddIcon />}
                                    onClick={() => setIsDialogOpen(true)}
                                >
                                    Add 
                                </SoftButton>:""}
                                
                            </SoftBox>
                        </SoftBox>

                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>

                        <SoftBox p={3}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <SoftTypography variant="caption" color="secondary">
                                        {page * rowsPerPage + 1} - {Math.min((page + 1) * rowsPerPage, filteredClassrooms.length)} of {filteredClassrooms.length}
                                    </SoftTypography>
                                </Grid>
                                {/* <Grid item>
                                    <SoftBox display="flex" gap={2}>
                                        <SoftButton
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                            disabled={page === 0}
                                        >
                                            Previous
                                        </SoftButton>
                                        <SoftButton
                                            variant="outlined"
                                            color="info"
                                            size="small"
                                            onClick={() => setPage(prev => Math.min(Math.ceil(filteredClassrooms.length / rowsPerPage) - 1, prev + 1))}
                                            disabled={(page + 1) * rowsPerPage >= filteredClassrooms.length}
                                        >
                                            Next
                                        </SoftButton>
                                    </SoftBox>
                                </Grid> */}
                                <Grid item>
                                    <SoftBox display="flex" gap={2}>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            size="large"
                                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                            disabled={page === 0}
                                        >
                                            <KeyboardArrowLeft />
                                        </SoftButton>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            size="large"
                                            onClick={() => setPage(prev => Math.min(Math.ceil(filteredClassrooms.length / rowsPerPage) - 1, prev + 1))}
                                            disabled={(page + 1) * rowsPerPage >= filteredClassrooms.length}
                                        >
                                            <KeyboardArrowRight />
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="sm">
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogTitle>
                    <SoftTypography variant="h6">Add New Classroom</SoftTypography>
                </DialogTitle>
                <DialogContent>
                    <SoftBox display="flex" flexDirection="column" gap={2} mt={1}>
                        <SoftInput
                            placeholder="Classroom No"
                            value={newClassroom.roomNo}
                            onChange={(e) => handleInputChange("roomNo", e.target.value)}
                            required
                        />
                        <CustomSelect
                            options={classLevelOptions}
                            value={{ value: newClassroom.classLevel, label: newClassroom.classLevel }}
                            onChange={(selectedOption) => handleInputChange("classLevel", selectedOption.value)}
                            placeholder="Select Grade"
                            
                        />
                        <CustomSelect
                            options={sectionOptions}
                            value={{ value: newClassroom.section, label: newClassroom.section }}
                            onChange={(selectedOption) => handleInputChange("section", selectedOption.value)}
                            placeholder="Select Section (optional)"
                            
                        />
                    </SoftBox>
                </DialogContent>
                <DialogActions>
                    <SoftButton onClick={() => setIsDialogOpen(false)} variant="text" color="secondary">
                        Cancel
                    </SoftButton>
                    <SoftButton
                        onClick={handleSubmit}
                        variant="gradient"
                        color="info"
                        disabled={isLoading}
                    >
                        {isLoading ? 'Adding...' : 'Add Classroom'}
                    </SoftButton>
                </DialogActions>
            </Dialog>
            <Footer />
        </DashboardLayout>
    );
};

export default ClassroomTable;