import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { APIData, org } from "../authentication/APIData";
import {
  Grid,
  Card,
  Backdrop,
  CircularProgress,
  FormHelperText,
} from '@mui/material';
import 'react-toastify/dist/ReactToastify.css';
import * as AiIcons from 'react-icons/ai';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from 'sweetalert2';

const CandidateInterview = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const initialEmail = queryParams.get('email') || '';

  const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
  const fetchedemail = sessiondetails.email;
  const user = sessiondetails.userType;
  const dept = sessiondetails.dept;

  const [formData, setFormData] = useState({
    title: '',
    provEmailId: fetchedemail,
    custEmailId: initialEmail,
    description: '',
    startTime: '',
    endTime: '',
  });

  const [errors, setErrors] = useState({
    title: '',
    provEmailId: '',
    custEmailId: '',
    description: '',
    startTime: '',
    endTime: '',
    orgName: '',
    orgAddr: '',
  });

  const [loading, setLoading] = useState(false);
  const [orgName, setOrgName] = useState('');
  const [orgAddr, setOrgAddr] = useState('');
  const [touched, setTouched] = useState({});

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        const url = APIData.api + `org-mdm/org-id?orgId=${org}`;
        const response = await axios.get(url, { headers: APIData.headers });
        setOrgName(response.data.orgName);
        setOrgAddr(response.data.orgAddress);
      } catch (error) {
        console.error('Error fetching organization details:', error);
      }
    };

    fetchOrganizationDetails();
  }, []);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    let isValid = true;

    // Title validation
    if (!formData.title.trim()) {
      newErrors.title = 'Subject is required';
      isValid = false;
    }

    // Provider Email validation
    if (!formData.provEmailId.trim()) {
      newErrors.provEmailId = 'Provider Email is required';
      isValid = false;
    } else if (!validateEmail(formData.provEmailId)) {
      newErrors.provEmailId = 'Invalid email format';
      isValid = false;
    }

    // Customer Email validation
    if (!formData.custEmailId.trim()) {
      newErrors.custEmailId = 'Customer Email is required';
      isValid = false;
    } else {
      const emails = formData.custEmailId.split(',').map(email => email.trim());
      const invalidEmails = emails.filter(email => !validateEmail(email));
      if (invalidEmails.length > 0) {
        newErrors.custEmailId = 'One or more email addresses are invalid';
        isValid = false;
      }
    }

    // Description validation
    if (!formData.description.trim()) {
      newErrors.description = 'Description is required';
      isValid = false;
    }

    // Organization validation
    if (!orgName.trim()) {
      newErrors.orgName = 'Organization Name is required';
      isValid = false;
    }
    if (!orgAddr.trim()) {
      newErrors.orgAddr = 'Organization Address is required';
      isValid = false;
    }

    // Time validation
    const currentDate = new Date();
    const startDate = new Date(formData.startTime);
    const endDate = new Date(formData.endTime);

    if (!formData.startTime) {
      newErrors.startTime = 'Start time is required';
      isValid = false;
    } else if (startDate < currentDate) {
      newErrors.startTime = 'Start time cannot be in the past';
      isValid = false;
    }

    if (!formData.endTime) {
      newErrors.endTime = 'End time is required';
      isValid = false;
    } else if (endDate < startDate) {
      newErrors.endTime = 'End time must be after start time';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Clear error when user starts typing
    setErrors(prev => ({
      ...prev,
      [name]: '',
    }));
  };

  const handleBlur = (field) => {
    setTouched(prev => ({
      ...prev,
      [field]: true
    }));
    validateForm();
  };

  const formatDateWithTimezone = (dateString) => {
    const date = new Date(dateString);

    if (isNaN(date.getTime())) {
      console.error("Invalid date value");
      return dateString;
    }

    const formattedDate = new Intl.DateTimeFormat('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hourCycle: 'h23',
      timeZone: 'Asia/Kolkata',
    }).format(date);

    return `${formattedDate.slice(6, 10)}-${formattedDate.slice(3, 5)}-${formattedDate.slice(0, 2)}T${formattedDate.slice(11)}+05:30`.replace("T ", "T");
  };

  const handleOrgNameChange = (e) => {
    setOrgName(e.target.value);
    setErrors(prev => ({
      ...prev,
      orgName: '',
    }));
  };

  const handleOrgAddrChange = (e) => {
    setOrgAddr(e.target.value);
    setErrors(prev => ({
      ...prev,
      orgAddr: '',
    }));
  };

  const getMinDateTime = () => {
    const now = new Date();
    return now.toISOString().slice(0, 16);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      Swal.fire({
        icon: "error",
        title: "Validation Error",
        text: "Please fill in all required fields correctly",
      });
      return;
    }

    setLoading(true);

    const formattedData = {
      ...formData,
      custEmailId: formData.custEmailId.split(',').map((email) => email.trim()),
      startTime: formatDateWithTimezone(formData.startTime),
      endTime: formatDateWithTimezone(formData.endTime),
    };

    try {
      const url = APIData.tpapi + 'web-conf/meeting';
      const headers = {
        'Content-Type': 'application/json',
        'orgName': orgName,
        'orgAddr': orgAddr,
        ...APIData.tpheaders,
      };
      const response = await axios.post(url, formattedData, { headers });
      console.log('Meeting scheduled successfully:', response.data);

      setLoading(false);
      Swal.fire({
        icon: "success",
        title: "Meeting scheduled Successfully",
        showConfirmButton: false,
        timer: 1500
      });
      setTimeout(() => {
        if (dept && dept.includes('HR') || user == "SUPERADMIN") {
          navigate("/candidateData");
        } else {
          navigate("/candidateData");
        }
      }, 1500);
    } catch (error) {
      console.error('Error scheduling meeting:', error);
      Swal.fire({
        icon: "error",
        title: "Oops!",
        text: "Failed to schedule meeting",
        footer: error.message
      });
      setLoading(false);
    }
  };

  const handleBack = () => {
    if (dept && dept.includes('HR') || user == "SUPERADMIN") {
      navigate("/candidateData");
    } else {
      navigate("/candidateData");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Card className="w-full">
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <div onClick={handleBack} style={{ cursor: 'pointer' }}>
                <AiIcons.AiOutlineCloseCircle />
              </div>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Schedule Meeting
              </SoftTypography>
            </SoftBox>

            <SoftBox pt={2} pb={3} px={3}>
              <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Email IDs *</SoftTypography>
                      <SoftInput
                        size="small"
                        placeholder="Email IDs (comma separated)"
                        name="custEmailId"
                        value={formData.custEmailId}
                        onChange={handleChange}
                        onBlur={() => handleBlur('custEmailId')}
                        error={touched.custEmailId && !!errors.custEmailId}
                        fullWidth
                        required
                      />
                      {touched.custEmailId && errors.custEmailId && (
                        <FormHelperText error>{errors.custEmailId}</FormHelperText>
                      )}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Provider Email ID *</SoftTypography>
                      <SoftInput
                        size="small"
                        placeholder="Provider Email ID"
                        name="provEmailId"
                        value={formData.provEmailId}
                        onChange={handleChange}
                        onBlur={() => handleBlur('provEmailId')}
                        error={touched.provEmailId && !!errors.provEmailId}
                        fullWidth
                        required
                      />
                      {touched.provEmailId && errors.provEmailId && (
                        <FormHelperText error>{errors.provEmailId}</FormHelperText>
                      )}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Organization Name *</SoftTypography>
                      <SoftInput
                        size="small"
                        placeholder="Organization Name"
                        value={orgName}
                        onChange={handleOrgNameChange}
                        onBlur={() => handleBlur('orgName')}
                        error={touched.orgName && !!errors.orgName}
                        fullWidth
                        required
                      />
                      {touched.orgName && errors.orgName && (
                        <FormHelperText error>{errors.orgName}</FormHelperText>
                      )}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Organization Address *</SoftTypography>
                      <SoftInput
                        size="small"
                        placeholder="Organization Address"
                        value={orgAddr}
                        onChange={handleOrgAddrChange}
                        onBlur={() => handleBlur('orgAddr')}
                        error={touched.orgAddr && !!errors.orgAddr}
                        fullWidth
                        required
                      />
                      {touched.orgAddr && errors.orgAddr && (
                        <FormHelperText error>{errors.orgAddr}</FormHelperText>
                      )}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Subject *</SoftTypography>
                      <SoftInput
                        size="small"
                        placeholder="Subject"
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        onBlur={() => handleBlur('title')}
                        error={touched.title && !!errors.title}
                        fullWidth
                        required
                      />
                      {touched.title && errors.title && (
                        <FormHelperText error>{errors.title}</FormHelperText>
                      )}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Description *</SoftTypography>
                      <SoftInput
                        size="small"
                        placeholder="Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        onBlur={() => handleBlur('description')}
                        error={touched.description && !!errors.description}
                        multiline
                        rows={3}
                        fullWidth
                        required
                      />
                      {touched.description && errors.description && (
                        <FormHelperText error>{errors.description}</FormHelperText>
                      )}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Start Time *</SoftTypography>
                      <SoftInput
                        size="small"
                        type="datetime-local"
                        name="startTime"
                        value={formData.startTime}
                        onChange={handleChange}
                        onBlur={() => handleBlur('startTime')}
                        error={touched.startTime && !!errors.startTime}
                        fullWidth
                        required
                        inputProps={{
                          min: getMinDateTime()
                        }}
                      />
                      {touched.startTime && errors.startTime && (
                        <FormHelperText error>{errors.startTime}</FormHelperText>
                      )}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>End Time *</SoftTypography>
                      <SoftInput
                        size="small"
                        type="datetime-local"
                        name="endTime"
                        value={formData.endTime}
                        onChange={handleChange}
                        onBlur={() => handleBlur('endTime')}
                        error={touched.endTime && !!errors.endTime}
                        fullWidth
                        required
                        inputProps={{
                          min: formData.startTime || getMinDateTime()
                        }}
                      />
                      {touched.endTime && errors.endTime && (
                        <FormHelperText error>{errors.endTime}</FormHelperText>
                      )}
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12}>
                    <SoftBox mt={4} mb={1}>
                      <SoftButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={loading}
                      >
                        {loading ? "Scheduling..." : "Schedule Meeting"}
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
};

export default CandidateInterview;