import React, { useEffect, useState } from 'react';
import { APIData, org } from '../authentication/APIData';
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import { IoArrowBack } from "react-icons/io5";
import { Link } from "react-router-dom";
import QuestionPaperTemplate from './QuestionPaperTemplate';
import {
  Grid,
  Card,
  Backdrop,
  CircularProgress,
} from "@mui/material";

export const QuestionPaperByAll = () => {
  const [data, setData] = useState(null);
  const [data1, setData1] = useState(null);
  const [grades, setGrades] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [chapters, setChapters] = useState([]);
  const [availableTypes, setAvailableTypes] = useState([]);
  const [selectedGrade, setSelectedGrade] = useState('');
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedChapter, setSelectedChapter] = useState('');
  const [selectedType, setSelectedType] = useState('');
  const [selectedOption, setSelectedOption] = useState('chapter');
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(false);
  const [availableQuestionCounts, setAvailableQuestionCounts] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const url = APIData.api + `questions/?org=${org}`;
      try {
        const response = await fetch(url, { headers: APIData.headers });
        const jsonData = await response.json();
        setData(jsonData);
        const uniqueGrades = [...new Set(jsonData.map(item => item.grade))];
        setGrades(uniqueGrades);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (selectedGrade) {
      const filteredSubjects = [...new Set(data.filter(item => item.grade === selectedGrade).map(item => item.subject))];
      setSubjects(filteredSubjects);
      setSelectedSubject('');
      setSelectedChapter('');
      setSelectedType('');
      setSelectedTypes([]);
      setCounts({});
      setAvailableQuestionCounts({});
    }
  }, [selectedGrade, data]);

  useEffect(() => {
    if (selectedGrade && selectedSubject) {
      const filteredChapters = [...new Set(data.filter(item => 
        item.grade === selectedGrade && 
        item.subject === selectedSubject
      ).map(item => item.chapter))];
      setChapters(filteredChapters);
      setSelectedChapter('');
      setSelectedType('');
      setSelectedTypes([]);
      setCounts({});
      setAvailableQuestionCounts({});
    }
  }, [selectedGrade, selectedSubject, data]);

  useEffect(() => {
    if (data && selectedGrade && selectedSubject) {
      let filteredData = data.filter(item =>
        item.grade === selectedGrade &&
        item.subject === selectedSubject
      );

      if (selectedOption === 'chapter' && selectedChapter) {
        filteredData = filteredData.filter(item => item.chapter === selectedChapter);
      }

      const uniqueTypes = [...new Set(filteredData.map(item => item.question_type))];
      
      // Calculate available questions count for each type
      const questionCounts = uniqueTypes.reduce((acc, type) => {
        acc[type] = filteredData.filter(item => item.question_type === type).length;
        return acc;
      }, {});
      
      setAvailableQuestionCounts(questionCounts);
      
      // Add "All Types" option if there are multiple question types
      if (uniqueTypes.length > 1) {
        setAvailableTypes(['All_types', ...uniqueTypes]);
      } else {
        setAvailableTypes(uniqueTypes);
      }

      if (selectedType && !uniqueTypes.includes(selectedType)) {
        setSelectedType('');
      }
    }
  }, [data, selectedGrade, selectedSubject, selectedChapter, selectedOption]);

  const handleTypeChange = (type) => {
    if (type === 'All_types') {
      // Select all available types except 'All_types'
      const allTypes = availableTypes.filter(t => t !== 'All_types');
      setSelectedTypes(allTypes);
      // Initialize counts for all types
      const initialCounts = allTypes.reduce((acc, t) => {
        acc[t] = '';
        return acc;
      }, {});
      setCounts(initialCounts);
    } else if (!selectedTypes.includes(type)) {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  const handleCountChange = (type, count) => {
    // Validate that count doesn't exceed available questions
    const availableCount = availableQuestionCounts[type] || 0;
    const newCount = Math.min(parseInt(count) || 0, availableCount);
    setCounts({ ...counts, [type]: newCount });
  };

  const handleSubmit = () => {
    setLoading(true);
    let url = `${APIData.api}questions/grade-subject`;
    url += selectedOption === 'chapter'
      ? `-chapter-type?org=${org}&grade=${selectedGrade}&subject=${selectedSubject}&chapter=${selectedChapter}`
      : `-type?org=${org}&grade=${selectedGrade}&subject=${selectedSubject}`;

    for (let i = 0; i < selectedTypes.length; i++) {
      const type = selectedTypes[i];
      const count = counts[type];
      url += `&type${i === 0 ? '' : i + 1}=${type}&count${i === 0 ? '' : i + 1}=${count}`;
    }

    fetch(url, {
      method: 'GET',
      headers: APIData.headers
    })
      .then(response => response.json())
      .then(jsonData => {
        setData1(jsonData);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const generateOptions = (items) => {
    return items.map(item => ({
      value: item,
      label: item
    }));
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      
      <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
        <Grid item xs={12} sm={12} md={10} lg={8}>
          <Card className="w-full">
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/dashboard">
                <IoArrowBack />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Question Paper Generator
              </SoftTypography>
            </SoftBox>

            <SoftBox pt={2} pb={3} px={3}>
              <SoftBox component="form" role="form">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Generate Question Paper By</SoftTypography>
                      <CustomSelect
                        options={[
                          { value: 'chapter', label: 'Chapter' },
                          { value: 'subject', label: 'Whole Subject' }
                        ]}
                        value={selectedOption}
                        onChange={(option) => {
                          setSelectedOption(option.value);
                          setSelectedChapter('');
                          setSelectedType('');
                          setSelectedTypes([]);
                          setCounts({});
                        }}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Grade</SoftTypography>
                      <CustomSelect
                        options={generateOptions(grades)}
                        placeholder="Select Grade"
                        value={selectedGrade}
                        onChange={(option) => setSelectedGrade(option.value)}
                      />
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Subject</SoftTypography>
                      <CustomSelect
                        options={generateOptions(subjects)}
                        placeholder="Select Subject"
                        value={selectedSubject}
                        onChange={(option) => setSelectedSubject(option.value)}
                        isDisabled={!selectedGrade}
                      />
                    </SoftBox>
                  </Grid>

                  {selectedOption === 'chapter' && (
                    <Grid item xs={12} md={6}>
                      <SoftBox mb={2}>
                        <SoftTypography variant="h6" mb={1}>Chapter</SoftTypography>
                        <CustomSelect
                          options={generateOptions(chapters)}
                          placeholder="Select Chapter"
                          value={selectedChapter}
                          onChange={(option) => setSelectedChapter(option.value)}
                          isDisabled={!selectedSubject}
                        />
                      </SoftBox>
                    </Grid>
                  )}

                  <Grid item xs={12} md={6}>
                    <SoftBox mb={2}>
                      <SoftTypography variant="h6" mb={1}>Question Type</SoftTypography>
                      <CustomSelect
                        options={generateOptions(availableTypes)}
                        placeholder="Select Type"
                        value={selectedType}
                        onChange={(option) => {
                          setSelectedType(option.value);
                          handleTypeChange(option.value);
                        }}
                        isDisabled={!selectedSubject || (selectedOption === 'chapter' && !selectedChapter)}
                      />
                    </SoftBox>
                  </Grid>

                  {selectedTypes.map((type, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <SoftBox mb={2}>
                        <SoftTypography variant="h6" mb={1}>{`${type} Count`}</SoftTypography>
                        <SoftInput
                          type="number"
                          size="small"
                          value={counts[type] || ''}
                          onChange={(e) => handleCountChange(type, e.target.value)}
                        />
                        <SoftTypography variant="caption" color="text" mt={1}>
                          Available questions: {availableQuestionCounts[type] || 0}
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  ))}

                  <Grid item xs={12}>
                    <SoftBox mt={4} mb={1}>
                      <SoftButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        onClick={handleSubmit}
                        disabled={!selectedGrade || !selectedSubject || (selectedOption === 'chapter' && !selectedChapter) || selectedTypes.length === 0}
                      >
                        Generate Question Paper
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </Card>

          {data1 && (
            <SoftBox mt={3}>
              <QuestionPaperTemplate data={data1} />
            </SoftBox>
          )}
        </Grid>
      </Grid>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </DashboardLayout>
  );
};

export default QuestionPaperByAll;