import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import Table from "examples/Tables/Table";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { APIData, org } from "authentication/APIData";
import Grid from "@mui/material/Grid";
import { Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Button } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Swal from "sweetalert2"; // Import SweetAlert2

const Name = ({ name }) => (
  <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
    <SoftBox mr={2}>
      <SoftAvatar src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`} alt={name} size="sm" variant="rounded" />
    </SoftBox>
    <SoftBox display="flex" flexDirection="column">
      <SoftTypography variant="button" fontWeight="medium">
        {name}
      </SoftTypography>
    </SoftBox>
  </SoftBox>
);

Name.propTypes = {
  name: PropTypes.string.isRequired,
};

const Description = ({ description = '' }) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const MAX_CHARS = 20;
  const isLongDescription = description?.length > MAX_CHARS;

  const handleToggleDescription = (e) => {
    e.preventDefault();
    setShowFullDescription(!showFullDescription);
  };

  const truncatedText = isLongDescription
    ? `${description.substring(0, MAX_CHARS).trim()}...`
    : description;

  return (
    <div className="flex flex-col">
      <span className="text-sm text-gray-600 font-medium">
        {showFullDescription ? description : truncatedText}
      </span>
      {isLongDescription && (
        <span onClick={handleToggleDescription} style={{ color: "#1E90FF", cursor: "pointer", fontWeight: "500", marginTop: "4px" }}>
          {showFullDescription ? 'Show Less' : 'Read More'}
        </span>
      )}
    </div>
  );
};

Description.propTypes = {
  description: PropTypes.string,
};

Description.defaultProps = {
  description: '',
};

const AssetsRequests = () => {
  const [assetData, setAssetData] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [status, setStatus] = useState("Requested");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(parseInt(accessLevel));
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);

  const shouldShowActions = () => {
    if (permission === 1100 || permission === 1000) {
      return false;
    }
    return isSuperAdmin || permission === 1111;
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    setStatus(newValue === 0 ? "Requested" : "Return Requested");
  };

  const handleOpenDialog = (asset) => {
    setSelectedAsset(asset);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedAsset(null);
  };

  const handleStatusUpdate = async (allocationStatus) => {
    if (selectedAsset) {
      const url = `${APIData.api}employee-assets/return-asset?asset_id=${selectedAsset.asset_id}&allocation_status=${allocationStatus}`;
      try {
        const response = await fetch(url, { method: "PUT", headers: APIData.headers });
        if (response.ok) {
          setAssetData((prevData) =>
            prevData.map((asset) =>
              asset.id === selectedAsset.id ? { ...asset, allocation: allocationStatus } : asset
            )
          );
          handleCloseDialog();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Asset Allocation status updated successfully",
          });
          // window.location.reload();
          fetchData();
        } else {
          Swal.fire({
            icon: "error",
            title: "Update Failed",
            text: "Failed to update Allocation asset status",
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while updating asset Allocation status",
        });
      }
    }
  };

  useEffect(() => {

    fetchData();
  }, [status]);

  const fetchData = async () => {
    const url = `${APIData.api}employee-assets/status?status=${status}&org=${org}`;
    try {
      const response = await fetch(url, { headers: APIData.headers });
      const data = await response.json();
      setAssetData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const approvalColumns = [
    { name: "Asset_Name", align: "left" },
    { name: "Employee_Id", align: "left" },
    { name: "Description", align: "center" },
    { name: "Allocation", align: "center" },
    { name: "Action", align: "left" },
  ];

  const returnColumns = [
    { name: "Asset_Name", align: "left" },
    { name: "Employee_Id", align: "left" },
    { name: "Description", align: "center" },
    { name: "Issued By", align: "center" },
    { name: "Issued Date", align: "center" },
    { name: "Allocation", align: "center" },
    { name: "Action", align: "left" },
  ];

  const rows = assetData.map((asset) => ({
    Asset_Name: <Name name={asset.asset_name} />,
    Employee_Id: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{asset.emp_id}</SoftTypography>,
    Description: <Description description={asset.description} />,
    Allocation: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{asset.allocation}</SoftTypography>,
    Action: (
      <SoftTypography component="a" href="#" variant="button" color="info" fontWeight="medium" onClick={() => handleOpenDialog(asset)}>
        Edit
      </SoftTypography>
    ),
    ...(tabValue !== 0 && {
      IssuedBy: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{asset.issued_by}</SoftTypography>,
      IssuedDate: <SoftTypography variant="caption" color="secondary" fontWeight="medium">{asset.issued_date}</SoftTypography>,
    }),
  }));

  const navigate = useNavigate();

  const handleBack = () => {
    navigate("/assets-list");
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Tooltip title="Back to Asset Group List" arrow>
            <SoftButton sx={{ ml: 1, mb: 1 }} onClick={handleBack}>Back</SoftButton>
          </Tooltip>
          <Card sx={{ mb: 2 }}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md={6} lg={16} sx={{ ml: "0" }}>
                <AppBar position="static">
                  <Tabs value={tabValue} onChange={handleSetTabValue} sx={{ background: "transparent" }}>
                    <Tab label="Approval Request" />
                    <Tab label="Return Request" />
                  </Tabs>
                </AppBar>
              </Grid>
            </Grid>
          </Card>
          <Card>
            <SoftBox sx={{ "& .MuiTableRow-root:not(:last-child)": { "& td": { borderBottom: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`, }, }, }}>
              <Table columns={tabValue === 0 ? approvalColumns : returnColumns} rows={rows} />
            </SoftBox>
          </Card>
        </SoftBox>
      </SoftBox>
      <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ backgroundColor: '#f5f5f5', fontWeight: 'bold', textAlign: 'center' }}>
          Asset Details
        </DialogTitle>
        <DialogContent sx={{ padding: 3 }}>
          <SoftTypography variant="h6" sx={{ marginBottom: 2 }}>
            {selectedAsset?.asset_name}
          </SoftTypography>
          <SoftBox display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
            <SoftTypography variant="body1" sx={{ marginRight: 1, fontWeight: 'medium' }}>
              Requested By:
            </SoftTypography>
            <Description description={selectedAsset?.emp_id} />
          </SoftBox>
          <SoftBox display="flex" alignItems="center" sx={{ marginBottom: 2 }}>
            <SoftTypography variant="body1" sx={{ marginRight: 1, fontWeight: 'medium' }}>
              Description:
            </SoftTypography>
            <Description description={selectedAsset?.description} />
          </SoftBox>
          <SoftTypography variant="body1" sx={{ fontWeight: 'medium', marginTop: 2 }}>
            Current Allocation: <SoftTypography variant="body1" color="info" component="span">{selectedAsset?.allocation}</SoftTypography>
          </SoftTypography>
        </DialogContent>
        {selectedAsset?.emp_id === currentUser ? (
          // If the current user and the selected asset's emp_id are the same, show a message instead of buttons
          <DialogActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
            <SoftTypography variant="body1" color="error">
            You cannot approve or reject your own request.
            </SoftTypography>
          </DialogActions>
        ) : (
         
          <DialogActions sx={{ justifyContent: 'space-around', paddingBottom: 2 }}>
            <SoftButton variant="gradient" color="success" onClick={() => handleStatusUpdate("Allocated")}>
              Approve
            </SoftButton>
            <SoftButton variant="gradient" color="error" onClick={() => handleStatusUpdate("Rejected")}>
              Reject
            </SoftButton>
          </DialogActions>
        )}

      </Dialog>

      <Footer />
    </DashboardLayout>
  );
};

export default AssetsRequests;
