import React, { useEffect, useState } from 'react';
import {
     Button, Grid, Typography, Container, Paper, Dialog, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import Nda from './Nda';
import { APIData, org } from 'authentication/APIData';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Swal from 'sweetalert2';
import { useLocation, useNavigate } from 'react-router-dom';
import SoftButton from 'components/SoftButton';

const containerStyle = {
    fontFamily: 'Arial, sans-serif',
    lineHeight: '1.6',
    color: '#333',
};

const headerStyle = {

    marginBottom: '40px',
};

const titleStyle = {
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '10px 0',
};

const subtitleStyle = {
    fontSize: '16px',
    margin: '3px 0',
};

const sectionTitleStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
    margin: '20px 0 10px 0',
};

const textStyle = {
    margin: '10px 0',
};

const signatureStyle = {
    marginTop: '40px',
    display: 'flex',
    justifyContent: 'space-between',
};

const signOffStyle = {
    fontWeight: 'bold',
};

const initialState = {

    header1: "",
    header10: "",
    header11: "",
    header12: "",
    header13: "",
    header14: "",
    header15: '',
    header16: "",
    header17: "",
    header18: "",
    header19: "",
    header2: "",
    header20: "",
    header21: "",
    header3: "",
    header4: "",
    header5: "",
    header6: "",
    header7: "",
    header8: "",
    header9: "",
    id: 0,
    org: org,
    para1: "",
    para10: "",
    para11: "",
    para12: "",
    para13: "",
    para14: "",
    para15: "",
    para16: "",
    para17: "",
    para18: "",
    para19: "",
    para2: "",
    para20: "",
    para21: "",
    para22: "",
    para3: "",
    para4: "",
    para5: "",
    para6: "",
    para7: "",
    para8: "",
    para9: "",
    temp_name: "NDA",
    title: "NON-DISCLOSURE AGREEMENT"

}

function NdaEdit() {
    const [formData, setFormData] = useState(initialState);
    const [orgdetails, setOrgdetails] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleBack = () => {
        if (location.state?.returnToStepper) {
            navigate(location.state.returnPath);
        } else {
            navigate(-1);
        }
    };


    useEffect(() => {
        fetchOrgDetails();
    },);

    const fetchOrgDetails = () => {
        axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
            .then((response) => setOrgdetails(response.data))
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(APIData.api + `db-template/`, formData, { headers: APIData.headers })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Update Error",
                    text: error.response.data
                });

            })
        // console.log('Form Data Submitted:', formData);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    return (

        <Container>
            <PageLayout> 
              <SoftButton onClick={handleBack}>
                Back
            </SoftButton>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5" gutterBottom>
                            NDA Template Form
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={2}>

                                {/* Section for Header1 and Paragraphs */}
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 1</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header1"
                                        value={formData.header1}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 2</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header2"
                                        value={formData.header2}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 1</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para1"
                                        value={formData.para1}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 2</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para2"
                                        value={formData.para2}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 3</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para3"
                                        value={formData.para3}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 4</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para4"
                                        value={formData.para4}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 5</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para5"
                                        value={formData.para5}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 6</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para6"
                                        value={formData.para6}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 7</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para7"
                                        value={formData.para7}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 8</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para8"
                                        value={formData.para8}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 3</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header3"
                                        value={formData.header3}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 9</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para9"
                                        value={formData.para9}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 10</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para10"
                                        value={formData.para10}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 4</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header4"
                                        value={formData.header4}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 11</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para11"
                                        value={formData.para11}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 12</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para12"
                                        value={formData.para12}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 5</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header5"
                                        value={formData.header5}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 13</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para13"
                                        value={formData.para13}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 14</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para14"
                                        value={formData.para14}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 6</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header6"
                                        value={formData.header6}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 15</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para15"
                                        value={formData.para15}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 7</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header7"
                                        value={formData.header7}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 16</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para16"
                                        value={formData.para16}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 8</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header8"
                                        value={formData.header8}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 17</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para17"
                                        value={formData.para17}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 9</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header9"
                                        value={formData.header9}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 18</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para18"
                                        value={formData.para18}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 10</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header10"
                                        value={formData.header10}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 19</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para19"
                                        value={formData.para19}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Header 11</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="header11"
                                        value={formData.header11}
                                        onChange={handleChange}
                                        multiline={false}
                                        rows={1}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <SoftTypography variant="h6">Paragraph 20</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="para20"
                                        value={formData.para20}
                                        onChange={handleChange}
                                        multiline={true}
                                        rows={3}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        variant="outlined"
                                        style={{ marginBottom: '16px', width: "100%" }}
                                        onClick={handleClickOpen}
                                    >
                                        View Template in Full Screen
                                    </Button>
                                    <Button type="submit" fullWidth variant="contained" color="primary">
                                        Submit
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Typography variant="h5" gutterBottom>
                            Adaptive Template
                        </Typography>
                        <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                            {orgdetails ? (
                                <div style={containerStyle}>
                                    <img src={orgdetails.orgLogo} style={{ width: 40, height: 40 }} />
                                    <div style={headerStyle}>
                                        <div style={titleStyle}>{orgdetails.orgName}</div>
                                        <div style={subtitleStyle}>+91 {orgdetails.orgMob}| {orgdetails.orgEmailId}| {orgdetails.orgURL}</div>
                                    </div>
                                    <div style={{ fontFamily: 'Arial', fontSize: '17px' }}>

                                        <h3 style={{ textAlign: 'center' }}>
                                            {formData.header1}
                                        </h3>
                                        <p>
                                            <strong>I.{formData.header2}</strong>{formData.para1}06/08/2024 {formData.para2} Care4Edu Solutions Pvt Ltd , {formData.para3}Adhithyan P A , {formData.para4} null {formData.para5} Adhithyan P A
                                            {formData.para6} </p>
                                        <p>
                                            {formData.para7} Care4Edu {formData.para8} </p>


                                        <h4>II.{formData.header3}</h4>
                                        <p>{formData.para9}</p>
                                        <p>{formData.para10}</p>
                                        <h4>III. {formData.header4}</h4>
                                        <p>
                                            {formData.para11}
                                        </p>
                                        <p>
                                            {formData.para12}
                                        </p>
                                        <ul>
                                            <li>information generally available to the public;</li>
                                            <li>widely used programming practices or algorithms;</li>
                                            <li>information rightfully in the possession of the Parties prior to signing this Agreement; and</li>
                                            <li>
                                                information independently developed without the use of any of the provided Confidential
                                                Information
                                            </li>
                                        </ul>
                                        <h4>IV. {formData.header5}</h4>
                                        <p>{formData.para13}</p>
                                        <p>{formData.para14}</p>


                                        <h4>V. {formData.header6}.</h4>
                                        <p>
                                            {formData.para15}
                                        </p>
                                        <h4>VI. {formData.header7}</h4>
                                        <p>{formData.para16} </p>

                                        <h4>VII.{formData.header8}</h4>
                                        <p>
                                            {formData.para17} </p>
                                        <h4>VIII. {formData.header9}</h4>
                                        <p>
                                            {formData.para18} </p>
                                        <h4>IX.{formData.header10}</h4>
                                        <p>
                                            {formData.para19}</p>
                                        <p>
                                            Date: 09/08/2024
                                        </p>
                                        <p>
                                            Date: 09/08/2024
                                        </p>
                                        <h4>X.{formData.header11}</h4>
                                        <p>
                                            {formData.para20} </p>
                                        <p>
                                            Shape
                                        </p>
                                        <p>
                                            1st Party’s Signature
                                        </p>
                                        <p>
                                            Rishi
                                        </p>
                                        <p>
                                            2nd Party’s Signature
                                        </p>
                                        <p>
                                            Adhithyan P A
                                        </p>
                                        <p>
                                            Vidyaranyapura
                                        </p>
                                    </div>

                                </div>
                            ) : (
                                <Typography variant="body1">Loading organization details...</Typography>
                            )}
                        </Paper>
                    </Grid>

                    <Grid item xs={12} sm={4}>
                        <Nda />
                    </Grid>
                </Grid>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>
                        Full Screen View
                        <IconButton
                            edge="end"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            style={{ position: 'absolute', right: 16, top: 16 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        {orgdetails ? (
                            <div style={containerStyle}>
                                <img src={orgdetails.orgLogo} style={{ width: 40, height: 40 }} />
                                <div style={headerStyle}>
                                    <div style={titleStyle}>{orgdetails.orgName}</div>
                                    <div style={subtitleStyle}>+91 {orgdetails.orgMob}| {orgdetails.orgEmailId}| {orgdetails.orgURL}</div>
                                </div>
                                <div style={{ fontFamily: 'Arial', fontSize: '12px' }}>

                                    <h3 style={{ textAlign: 'center' }}>
                                        {formData.header1}
                                    </h3>
                                    <p>
                                        <strong>I.{formData.header2}</strong>{formData.para1}06/08/2024 {formData.para2} Care4Edu Solutions Pvt Ltd , {formData.para3}Adhithyan P A , {formData.para4} null {formData.para5} Adhithyan P A
                                        {formData.para6} </p>
                                    <p>
                                        {formData.para7} Care4Edu {formData.para8} </p>


                                    <h4>II.{formData.header3}</h4>
                                    <p>{formData.para9}</p>
                                    <p>{formData.para10}</p>
                                    <h4>III. {formData.header4}</h4>
                                    <p>
                                        {formData.para11}
                                    </p>
                                    <p>
                                        {formData.para12}
                                    </p>
                                    <ul>
                                        <li>information generally available to the public;</li>
                                        <li>widely used programming practices or algorithms;</li>
                                        <li>information rightfully in the possession of the Parties prior to signing this Agreement; and</li>
                                        <li>
                                            information independently developed without the use of any of the provided Confidential
                                            Information
                                        </li>
                                    </ul>
                                    <h4>IV. {formData.header5}</h4>
                                    <p>{formData.para13}</p>
                                    <p>{formData.para14}</p>


                                    <h4>V. {formData.header6}.</h4>
                                    <p>
                                        {formData.para15}
                                    </p>
                                    <h4>VI. {formData.header7}</h4>
                                    <p>{formData.para16} </p>

                                    <h4>VII.{formData.header8}</h4>
                                    <p>
                                        {formData.para17} </p>
                                    <h4>VIII. {formData.header9}</h4>
                                    <p>
                                        {formData.para18} </p>
                                    <h4>IX.{formData.header10}</h4>
                                    <p>
                                        {formData.para19}</p>
                                    <p>
                                        Date: 09/08/2024
                                    </p>
                                    <p>
                                        Date: 09/08/2024
                                    </p>
                                    <h4>X.{formData.header11}</h4>
                                    <p>
                                        {formData.para20} </p>
                                    <p>
                                        Shape
                                    </p>
                                    <p>
                                        1st Party’s Signature
                                    </p>
                                    <p>
                                        Rishi
                                    </p>
                                    <p>
                                        2nd Party’s Signature
                                    </p>
                                    <p>
                                        Adhithyan P A
                                    </p>
                                    <p>
                                        Vidyaranyapura
                                    </p>
                                </div>

                            </div>
                        ) : (
                            <Typography variant="body1">Loading organization details...</Typography>
                        )}
                    </DialogContent>
                </Dialog>
            </PageLayout>
        </Container>
    );
}

export default NdaEdit;

