import React from 'react';
import { AppBar, Box, Container, Grid, Link, Stack, useTheme, useMediaQuery } from '@mui/material';
import { Menu as MenuIcon, ChevronRight, Star, FlashOn, Security, Message } from '@mui/icons-material';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';
import SoftTypography from 'components/SoftTypography';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import SoftButton from 'components/SoftButton';
import Events from './Events';
import Footer from 'layouts/authentication/components/Footer';
import Carousel from './Carousel';

const Landing2 = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const footerLinks = {
    Product: ['Features', 'Pricing', 'Documentation'],
    Company: ['About', 'Careers', 'Blog'],
    Resources: ['Community', 'Contact', 'Support'],
    Legal: ['Privacy', 'Terms', 'Security']
  };

  return (
    <PageLayout>
      <Box>
        <AppBar position="fixed" color="inherit" elevation={1}>
          <DefaultNavbar />
        </AppBar>

        {/* Hero Section */}
        <Box
          sx={{
            pt: 15,
            pb: 8,
            background: 'linear-gradient(180deg, #f3e8ff 0%, #ffffff 100%)'
          }}
        >
          <Container maxWidth="lg">
            <Box textAlign="center" maxWidth="800px" mx="auto">
              <SoftTypography variant="h2" component="h1" gutterBottom fontWeight="bold">
                Make Your Vision a Reality
              </SoftTypography>
              <SoftTypography variant="h5" color="text.secondary" paragraph>
                Transform your Organization into stunning digital experiences with our powerful platform.
              </SoftTypography>
              <Stack
                direction={{ xs: 'column', sm: 'row' }}
                spacing={2}
                justifyContent="center"
                mt={4}
              >
                <SoftButton
                  variant="contained"
                  size="large"
                  endIcon={<ChevronRight />}
                >
                  Get Started
                </SoftButton>
              </Stack>
            </Box>
          </Container>
        </Box>

        {/* Carousel Section */}
        <Box 
          sx={{ 
            py: 10,
            backgroundColor: '#f8fafc',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <Container maxWidth="xl">
            <Box 
              sx={{ 
                height: '600px',
                position: 'relative'
              }}
            >
              <Carousel />
            </Box>
          </Container>
        </Box>

        {/* CTA Section */}
        <Box sx={{ py: 8, px: 7, backgroundColor: 'white', color: 'black' }}>
          <Container maxWidth="lg">
            <Grid container spacing={4} alignItems="center">
              <Grid item xs={12} md={6}>
                <SoftTypography variant="h3" component="h2" gutterBottom fontWeight="bold">
                  Ready to Get Started?
                </SoftTypography>
                <SoftTypography variant="h6" sx={{ opacity: 0.9 }}>
                  Join thousands of satisfied customers who have transformed their business with us.
                </SoftTypography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={2}
                  justifyContent={{ xs: 'center', md: 'flex-end' }}
                >
                  <SoftButton
                    variant="contained"
                    size="large"
                    sx={{ color: 'black', borderColor: 'white' }}
                    startIcon={<Message />}
                  >
                    Contact Sales
                  </SoftButton>
                </Stack>
              </Grid>
            </Grid>
          </Container>
        </Box>

        <Footer />
      </Box>
    </PageLayout>
  );
};

export default Landing2;