import { useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CoverLayout from "layouts/authentication/components/CoverLayout";
import curved9 from "assets/images/curved-images/curved-6.jpg";
import axios from "axios";
import Swal from 'sweetalert2';
import { APIData, org } from "authentication/APIData";

function SignIn() {
  const [rememberMe, setRememberMe] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState("");
  const [showOtpField, setShowOtpField] = useState(false);
  const [errors, setErrors] = useState({ email: "", password: "", otp: "" });
  // const [loading , setLoading] = useState(false);
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate()

  const validateFields = () => {
    const newErrors = { email: "", password: "", otp: "" };
    let isValid = true;

    if (!email) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Email is invalid.";
      isValid = false;
    }

    if (!password) {
      newErrors.password = "Password is required.";
      isValid = false;
    }

    if (showOtpField && !otp) {
      newErrors.otp = "OTP is required.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateFields()) {
      if (!email.includes('@') || !email.includes('.') || !password) {
        Swal.fire({
          icon: 'error',
          title: 'Error!',
          text: "Please Enter Valid Credentials Or Contact Administrator",
          confirmButtonText: 'Okay'
        });
      } else {
        var formdata = {
          "org": org,
          "otp": 0,
          "user_email": email,
          "user_name": "",
          "user_password": password,
          "user_phone_number": ""
        };
        axios
          .post(APIData.api + 'users/user/login', formdata, {
            headers: APIData.headers,
          })
          .then((response) => {
            console.log(response.status);
            if (response.data.status.toString().toLowerCase() === 'success') {
              const sessionData = {
                ...response.data,
                userType: "USER"
              };

              localStorage.setItem("sessiondetails", JSON.stringify(sessionData));
              navigate("/select-dept", { replace: true });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: response.data.errorDesc,
                confirmButtonText: 'Okay'
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: error.response.data,
              confirmButtonText: 'Okay'
            });
            console.log(error);
          });
      }
    }
  };

  return (
    <CoverLayout
      title="User Sign In"
      description="Enter your email and password to sign in"
      image={curved9}
    >
      <SoftBox component="form" role="form" onSubmit={handleSubmit}>
        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Email
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => {
              validateFields();
              return setEmail(e.target.value);
            }}
            // error={!!errors.email}
            helperText={errors.email}
          />
          <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
            {errors.email}
          </SoftTypography>
        </SoftBox>

        <SoftBox mb={2}>
          <SoftBox mb={1} ml={0.5}>
            <SoftTypography component="label" variant="caption" fontWeight="bold">
              Password
            </SoftTypography>
          </SoftBox>
          <SoftInput
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => {
              validateFields();
              return setPassword(e.target.value);
            }}
            // error={!!errors.password}
            helperText={errors.password}
          />
          <SoftTypography style={{ color: 'red', fontSize: '12px' }}>
            {errors.password}
          </SoftTypography>
        </SoftBox>
        {showOtpField && (
          <SoftBox mb={2}>
            <SoftBox mb={1} ml={0.5}>
              <SoftTypography component="label" variant="caption" fontWeight="bold">
                OTP
              </SoftTypography>
            </SoftBox>
            <SoftInput
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              error={!!errors.otp}
              helperText={errors.otp}
            />
          </SoftBox>
        )}
        <SoftBox mt={4} mb={1}>
          <SoftButton type="submit" variant="gradient" color="info" fullWidth>
            {showOtpField ? "Verify OTP" : "Sign in"}
          </SoftButton>
        </SoftBox>
        <SoftBox mt={3} textAlign="center" display="flex" justifyContent="center" alignItems="center">
          <SoftTypography variant="button" color="text" fontWeight="regular" mr={1}>
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
          <SoftTypography variant="button" color="text" fontWeight="regular">
            <SoftTypography
              component={Link}
              to="/forgotPasswordUser"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Click Here!
            </SoftTypography>
            {" "}for Get Your Password
          </SoftTypography>
        </SoftBox>

      </SoftBox>
    </CoverLayout>
  );
}

export default SignIn;
