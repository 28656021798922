import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter } from "react-router-dom";
import '@mantine/core/styles.css';
import App from "App";

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from "context";
import { MantineProvider } from "@mantine/core";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <SoftUIControllerProvider>
      <MantineProvider defaultColorScheme='light'>
        <App />
      </MantineProvider>
    </SoftUIControllerProvider>
  </HashRouter>
);


// import React from "react";
// import ReactDOM from "react-dom/client";
// import { HashRouter } from "react-router-dom";
// import '@mantine/core/styles.css';

// // Import all apps
// import App from "./App";
// import UserApp from './UserApp';
// import SuperAdminApp from './SuperAdminApp';
// import StudentApp from './StudentApp';
// import TechnicalApp from './AdminApp';
// import FinanceApp from './FinanceApp';
// import SalesApp from './SalesApp';
// import HrApp from './FacultyApp';
// import CustomerApp from './CustomerApp';
// import AcademyApp from './AcademyApp';

// // Context providers
// import { SoftUIControllerProvider } from "context";
// import { MantineProvider } from "@mantine/core";

// // App selector component
// const AppSelector = () => {
//   const [currentApp, setCurrentApp] = React.useState(null);

//   React.useEffect(() => {
//     // Check session every 24 hours
//     const checkSessionInfo = () => {
//       const hours = 24;
//       const saved = localStorage.getItem('saved');
//       if (saved && (new Date().getTime() - saved > hours * 60 * 60 * 1000)) {
//         localStorage.clear();
//         window.location.assign("/signin");
//       }
//     };

//     const interval = setInterval(checkSessionInfo, 5000);
//     return () => clearInterval(interval);
//   }, []);

//   React.useEffect(() => {
//     const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
//     const departDetails = localStorage.getItem("Depart Details");
    
//     // Determine which app to render based on session details
//     if (!sessionDetails) {
//       setCurrentApp(<App />);
//       return;
//     }

//     const userType = sessionDetails?.userType?.toUpperCase();
    
//     switch (userType) {
//       case "SUPERADMIN":
//         setCurrentApp(<SuperAdminApp />);
//         break;
//       case "STUDENT":
//         setCurrentApp(<StudentApp />);
//         break;
//       case "CUSTOMER":
//         setCurrentApp(<CustomerApp />);
//         break;
//       case "USER":
//         setCurrentApp(<UserApp />);
//         break;
//       default:
//         // Check department-based routing if no matching user type
//         switch (departDetails) {
//           case "TECHNICAL":
//             setCurrentApp(<TechnicalApp />);
//             break;
//           case "ACADEMY":
//             setCurrentApp(<AcademyApp />);
//             break;
//           case "SALES":
//             setCurrentApp(<SalesApp />);
//             break;
//           case "HR":
//             setCurrentApp(<HrApp />);
//             break;
//           case "FINANCE":
//             setCurrentApp(<FinanceApp />);
//             break;
//           default:
//             setCurrentApp(<App />);
//         }
//     }
//   }, []);

//   if (!currentApp) {
//     return <div>Loading...</div>;
//   }

//   return currentApp;
// };

// // Root component with all providers
// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <HashRouter>
//       <SoftUIControllerProvider>
//         <MantineProvider defaultColorScheme='light'>
//           <AppSelector />
//         </MantineProvider>
//       </SoftUIControllerProvider>
//     </HashRouter>
//   </React.StrictMode>
// );