import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftBadge from "components/SoftBadge";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import breakpoints from "assets/theme/base/breakpoints";
import SoftButton from 'components/SoftButton';
import { Link } from 'react-router-dom';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import Swal from 'sweetalert2';

const Name = ({ name, email }) => {
  const [showFullName, setShowFullName] = useState(false);
  const nameWords = name.split(" ");

  const handleToggleFullName = () => {
    setShowFullName(!showFullName);
  };

  const displayedName = showFullName || nameWords.length <= 3
    ? name
    : `${nameWords.slice(0, 3).join(" ")}...`;

  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox mr={2}>
        <SoftAvatar
          src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
          alt={name}
          size="sm"
          variant="rounded"
        />
      </SoftBox>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {displayedName}
        </SoftTypography>
        {nameWords.length > 3 && (
          <SoftTypography
            variant="caption"
            color="info"
            onClick={handleToggleFullName}
            style={{ cursor: "pointer" }}
          >
            {showFullName ? "Show Less" : "Read More"}
          </SoftTypography>
        )}
        <SoftTypography variant="caption" color="secondary">
          {email}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};

Name.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};

const Organization = ({ salesType, orgName }) => (
  <SoftBox display="flex" flexDirection="column">
    <SoftTypography variant="caption" fontWeight="medium" color="text">
      {salesType}
    </SoftTypography>
    <SoftTypography variant="caption" color="secondary">
      {orgName}
    </SoftTypography>
  </SoftBox>
);

Organization.propTypes = {
  salesType: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
};

const CoursesDetails = () => {
  const [courseData, setCourseData] = useState([]);
  const [status, setStatus] = useState("All");
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [userDepartment, setUserDepartment] = useState("");
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
    if (sessionDetails && sessionDetails.user) {
      setCurrentUser(sessionDetails.user);
      setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
    }
    const accessLevel = localStorage.getItem('Access Level');
    setPermission(accessLevel);
    const Department = localStorage.getItem('Depart Details');
    setUserDepartment(Department);
  }, []);

  const handleDelete = async (courseId) => {
    if (window.confirm('Are you sure you want to delete this course?')) {
      try {
        const deleteUrl = `${APIData.api}courses/${courseId}`;
        const response = await fetch(deleteUrl, {
          method: 'DELETE',
          headers: APIData.headers
        });

        if (response.ok) {
          Swal.fire({
            icon: "success",
            title: "Dleted successfully!",
            showConfirmButton: false,
            timer: 1500
          });
          setCourseData(prevData => prevData.filter(course => course.course_id !== courseId));
        } else {
          console.error('Failed to delete course');
          alert('Failed to delete course. Please try again.');
        }
      } catch (error) {
        console.error('Error deleting course:', error);
        Swal.fire({
          icon: "error",
          title: "Submission Error",
          text: error.response?.data?.message || ('Error deleting course:', error)
        });
      }
    }
  };

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    return setTabValue(newValue);
  };

  useEffect(() => {
    const fetchData = async () => {

      const baseUrl = `${APIData.api}courses/?org=${org}`;

      console.log('Fetching from URL:', baseUrl);

      try {
        const response = await fetch(baseUrl, { headers: APIData.headers });
        const data = await response.json();
        setCourseData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [tabValue]);

  const columns = [
    { name: "Course", align: "left" },
    { name: "Course_Type", align: "left" },
    { name: "sub_Type", align: "left" },
    { name: "Course_Fees", align: "left" },
    { name: "Course_Duratiom", align: "left" },
    { name: "Admin_id", align: "left" },
    ...(permission === "1100" || permission === "1000" ? [] : [{ name: "Action", align: "left" }]),

  ];

  const rows = courseData.map((course) => ({
    Course: <Name name={course.course_id} />,
    Course_Type: <Organization orgName={course.course_type} />,
    sub_Type: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {course.course_sub_type}
      </SoftTypography>
    ),
    Course_Fees: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {course.course_fees}
      </SoftTypography>
    ),
    Course_Duratiom: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {course.course_duration}
      </SoftTypography>
    ),
    Admin_id: (
      <SoftTypography variant="caption" color="secondary" fontWeight="medium">
        {course.admin_id}
      </SoftTypography>
    ),

    Action: !(permission === "1100" || permission === "1000") ? (
      <>
        {!(permission === "1100" || permission === "1000") && (
          <Link to={`/edit-course/${course.course_id}`}>
            <SoftTypography
              component="a"
              href="#"
              variant="button"
              color="info"
              fontWeight="medium"
            >
              Edit
            </SoftTypography>
          </Link>
        )}

        {permission === "1111" && (
          <Link>
            <SoftTypography
              component="a"
              href="#"
              variant="button"
              color="error"
              fontWeight="medium"
              sx={{ marginLeft: "10px" }}
              onClick={() => handleDelete(course.course_id)}
            >
              Delete
            </SoftTypography>
          </Link>
        )}
      </>
    ) : null,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Course Details</SoftTypography>
          {permission === "1000" ? null: <Link to='/add-course'>
              <SoftButton>+ Add New Course</SoftButton>
            </Link>}
           
          </SoftBox>
          <SoftBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={columns} rows={rows} />
          </SoftBox>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default CoursesDetails;