// ResignationForm.jsx
import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { APIData, exitKeyWord, org } from "../authentication/APIData";
import { 
  Alert,
  Card, 
  CardContent, 
  CardHeader, 
  CircularProgress,
  Backdrop
} from "@mui/material";
import SoftTypography from "components/SoftTypography";
import { AiOutlineCloseCircle } from "react-icons/ai";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

const MIN_DESCRIPTION_LENGTH = 30;

export function ResignationForm({ onSubmissionSuccess }) {
  const user = APIData.sessiondetails;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");

  const validateDescription = () => {
    if (description.length < MIN_DESCRIPTION_LENGTH) {
      setError(`Description must be at least ${MIN_DESCRIPTION_LENGTH} characters long`);
      return false;
    }
    setError("");
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateDescription()) {
      return;
    }

    setIsSubmitting(true);
    setError("");

    const formBody = {
      id: 0,
      empEmail: user.email,
      empUserName: user.userName,
      description,
      status: "Resignation letter",
      approvedBy: "",
      type: exitKeyWord,
      createdTime: "",
      updatedTime: "",
      org: org,
    };

    try {
      const url = `${APIData.api}employee/exit`;
      const response = await axios.post(url, formBody, { headers: APIData.headers });
      console.log('Submission response:', response.data);
      
      toast.success("Resignation submitted successfully");
      
      if (onSubmissionSuccess) {
        await onSubmissionSuccess();
      }
    } catch (error) {
      console.error("Submit error:", error);
      toast.error(error.response?.data?.message || "Unable to submit resignation. Please try again.");
      setError("Submission failed. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card>
      <CardHeader
        title={
          <div className="flex justify-between items-center">
            <SoftTypography>Fill this form to initiate your exit process</SoftTypography>
            <Link to="/jobEditButtons" className="text-gray-500 hover:text-gray-700">
              <AiOutlineCloseCircle size={24} />
            </Link>
          </div>
        }
      />
      <CardContent>
        <form onSubmit={handleSubmit} className="space-y-6">
          <SoftInput
            label="User Name"
            value={user.userName}
            readOnly
            fullWidth
          />
          <SoftInput
            label="Email"
            value={user.email}
            readOnly
            fullWidth
          />
          <SoftInput
            label="Enter resignation details"
            multiline
            rows={5}
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
              if (error) validateDescription();
            }}
            error={!!error}
            helperText={error}
            required
            fullWidth
          />
          <SoftButton 
            type="submit" 
            variant="contained"
            fullWidth
            disabled={isSubmitting}
          >
            {isSubmitting ? "Submitting..." : "Submit Resignation"}
          </SoftButton>
        </form>
      </CardContent>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Card>
  );
}

ResignationForm.propTypes = {
  onSubmissionSuccess: PropTypes.func,
};
