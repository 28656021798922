import React, { useEffect, useState } from "react";
import { 
  Card,
  CardContent,
  Typography,
  Alert as MUIAlert
} from "@mui/material";
import PropTypes from 'prop-types';
import { 
  Mail, 
  User, 
  Clock,
  AlertCircle 
} from "lucide-react";
import axios from "axios";
import { toast } from "react-toastify";
import { APIData } from "authentication/APIData";
import { exitKeyWord } from "authentication/APIData";
import { org } from "authentication/APIData";
import PageLayout from "examples/LayoutContainers/PageLayout";

// Custom Loading Component
const Loading = () => (
  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "16rem" }}>
    <div style={{ animation: "spin 1s linear infinite", borderRadius: "50%", height: "2rem", width: "2rem", borderTop: "0.125rem solid", borderBottom: "0.125rem solid", borderColor: "#06b6d4" }}></div>
  </div>
);

// Card Component with Prop Validation
const TaskCard = ({ task }) => {
  const {
    id,
    empEmail,
    empUserName,
    createdTime,
    approvedBy,
    updatedTime
  } = task;

  return (
    <Card style={{ width: "100%", maxWidth: "20rem", backgroundColor: "#ffffff", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", transition: "box-shadow 0.2s" }}>
      <CardContent style={{ padding: "1rem", gap: "0.5rem" }}>
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", fontSize: "0.875rem", color: "#06b6d4" }}>
          <Mail style={{ height: "1rem", width: "1rem", color: "#6B8E23" }} />
          <span>{empEmail}</span>
        </div>
        
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", fontSize: "0.875rem", color: "#06b6d4" }}>
          <User style={{ height: "1rem", width: "1rem", color: "#6B8E23" }} />
          <span>{empUserName}</span>
        </div>
        
        <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", fontSize: "0.875rem", color: "#06b6d4" }}>
          <Clock style={{ height: "1rem", width: "1rem", color: "#6B8E23" }} />
          <span>{new Date(createdTime).toLocaleString()}</span>
        </div>

        {approvedBy && (
          <>
            <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", fontSize: "0.875rem", color: "#16a34a" }}>
              <User style={{ height: "1rem", width: "1rem" }} />
              <span>{approvedBy}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "0.5rem", fontSize: "0.875rem", color: "#16a34a" }}>
              <Clock style={{ height: "1rem", width: "1rem" }} />
              <span>{new Date(updatedTime).toLocaleString()}</span>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

TaskCard.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    empEmail: PropTypes.string.isRequired,
    empUserName: PropTypes.string.isRequired,
    createdTime: PropTypes.string.isRequired,
    approvedBy: PropTypes.string,
    updatedTime: PropTypes.string,
    description: PropTypes.string,
    status: PropTypes.string.isRequired
  }).isRequired
};

// Column Component with Prop Validation
const Column = ({ title, tasks }) => (
  <div style={{ minWidth: "18.75rem", backgroundColor: "#f3f4f6", borderRadius: "0.5rem", padding: "1rem" }}>
    <h3 style={{ fontSize: "1.125rem", fontWeight: "600", marginBottom: "1rem", color: "#06b6d4" }}>{title}</h3>
    <div style={{ gap: "1rem" }}>
      {tasks.map(task => (
        <TaskCard key={task.id} task={task} />
      ))}
    </div>
  </div>
);

Column.propTypes = {
  title: PropTypes.string.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape(TaskCard.propTypes.task)).isRequired
};

// Main Board Component
const ControlledBoard = () => {
  const [columns, setColumns] = useState([]);
  const [lifeCycleStatus, setLifeCycleStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const user = APIData.sessiondetails;

  const createNewStatus = async (task, status) => {
    setLoading(true);
    try {
      const url = `${APIData.api}employee/exit?org=${org}`;
      
      const oldData = {
        id: task.id,
        empEmail: task.empEmail,
        empUserName: task.empUserName,
        description: task.description,
        status: task.status,
        approvedBy: user.email,
        type: exitKeyWord,
        createdTime: task.createdTime,
        updatedTime: "",
        org
      };

      const formBody = {
        id: 0,
        empEmail: task.empEmail,
        empUserName: task.empUserName,
        description: "",
        status,
        approvedBy: "",
        type: exitKeyWord,
        createdTime: "",
        updatedTime: "",
        org
      };

      await axios.put(url, oldData, { headers: APIData.headers });
      await axios.post(url, formBody, { headers: APIData.headers });
      
      toast.success("Status updated successfully");
      await fetchExitLifeCycleStatuses();
    } catch (err) {
      toast.error("Failed to update status. Please try again.");
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchExitLifeCycleStatusesPending = async (status) => {
    try {
      const url = `${APIData.api}employee/exit/pending/status-type?status=${status}&type=${exitKeyWord}&org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      return response.data;
    } catch (err) {
      toast.error("Failed to fetch status data");
      throw err;
    }
  };

  const fetchExitLifeCycleStatuses = async () => {
    setLoading(true);
    try {
      const url = `${APIData.api}employee/life-cycle/type?type=${exitKeyWord}&org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      const statuses = response.data || [];
      
      setLifeCycleStatus(statuses);
      
      const columnsData = await Promise.all(
        statuses.map(async (status) => {
          const tasks = await fetchExitLifeCycleStatusesPending(status.statusName);
          return {
            id: status.id,
            title: status.statusName,
            tasks
          };
        })
      );

      setColumns(columnsData.sort((a, b) => a.id - b.id));
    } catch (err) {
      setError(err.message);
      toast.error("Failed to fetch lifecycle statuses");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchExitLifeCycleStatuses();
  }, []);

  if (error) {
    return (
      <MUIAlert severity="error">
        <AlertCircle style={{ height: "1rem", width: "1rem" }} />
        <span>Error loading board: {error}</span>
      </MUIAlert>
    );
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div style={{ backgroundColor: "#fef3c7", padding: "1.5rem", borderRadius: "0.5rem", border: "2px solid #06b6d4", boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)" }}>
      <div style={{ display: "flex", gap: "1.5rem", overflowX: "auto", paddingBottom: "1rem" }}>
        {columns.map(column => (
          <Column 
            key={column.id}
            title={column.title}
            tasks={column.tasks}
          />
        ))}
      </div>
    </div>
  );
};

// Main Component
const DetailsForExit = () => (
  <PageLayout>
  <div style={{ maxWidth: "100%", margin: "0 auto", padding: "2rem" }}>
    <h1 style={{ fontSize: "2rem", color: "#06b6d4", textAlign: "center", fontFamily: "monospace", marginBottom: "2rem", textDecoration: "underline" }}>
      Employee Exit Board
    </h1>
    <ControlledBoard />
  </div>
  </PageLayout>
);

export default DetailsForExit;
