import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIData } from "./APIData";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import { Backdrop, CircularProgress, Grid, Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import image from "../assets/images/output-onlinepngtools.png";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";

const ForgotPasswordUser = () => {
  const [email, setEmail] = useState(null);
  const [hidden, setHidden] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const changeHandler = (e) => {
    setEmail(e.target.value);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!email) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please enter an email!',
      });
    } else {
      setLoading(true);
      axios
        .get(APIData.api + "users/forgot-password?emailId=" + email, {
          headers: APIData.headers,
        })
        .then((response) => {
          if (response.data.status.toString().toLowerCase() === "success") {
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: response.data.description,
            });
            setTimeout(() => {
              navigate("/authentication/sign-in");
            }, 1500);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Oops...',
              text: response.data.errorDesc,
            });
          }
        })
        .catch((error) => {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Something went wrong. Please try again later.',
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <PageLayout>
      <DefaultNavbar />
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Backdrop open={loading} style={{ zIndex: 9999, color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card sx={{ height: "auto" }}>
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/authentication/sign-in">
                <AiIcons.AiFillCloseCircle />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: "center" }}>
                Get Your Password
              </SoftTypography>
            </SoftBox>

            <SoftBox display="flex" justifyContent="center" p={3}>
              <img src={image} alt="profile" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
            </SoftBox>

            <SoftBox pt={2} pb={3} px={3}>
              <SoftBox component="form" onSubmit={submitHandler}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" fontWeight="medium">
                    Enter email
                  </SoftTypography>
                  <SoftInput
                    placeholder="Enter email"
                    name="email"
                    value={email || ""}
                    onChange={changeHandler}
                  />
                </SoftBox>

                <SoftBox mt={4} mb={1}>
                  <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                    Get Your Password
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default ForgotPasswordUser;