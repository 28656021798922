import { useState, useEffect, useMemo } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import Sidenav from "examples/Sidenav";
import Configurator from "examples/Configurator";
import theme from "assets/theme";
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";
import routes from "routes";
import { useSoftUIController, setMiniSidenav, setOpenConfigurator } from "context";
import brand from "assets/images/c4e.png";
import Landing2 from "Home/Landing2";
import AddAssetgroup from "layouts/stores/addAssectgroup";
import ListSingleAssetGroup from "layouts/stores/SinglegroupAssetList";
import AddAssetByGroup from "layouts/stores/addAssetByGroupId";
import UpdateSales from "sales/UpdateSales";
import { Salesonboard } from "sales/SalesOnboard";
import OrgSignIn from "layouts/authentication/org-sign-in";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import OrgMember from "authentication/OrgMember";
import ComingSoon from "Home/ComingSoon";
import UserJobs from "user/UserJobs";
import JobApplyPage from "user/JobApplyPage";
import JobView from "user/JobView";
import AssetList from "layouts/stores/assets-list";
import UploadResume from "user/UploadResume";
import OrgMdmform from "sales/OrgMdmform";
import Editmdm from "sales/Editmdm";
import OrgHierarchy from "layouts/orgHierarchy/org-hierarchy";
import StationaryItems from "layouts/stationary/stationaryItems";
import JobEditButtons from "sales/JobEditButtons";
import AssetsRequests from "layouts/stores/assets-requests";
import OrgMdmdata from "sales/OrgMdmdata";
import CustomerSalesOnboard from "sales/CustomerSalesOnboard";
import ProcurementRequest from "layouts/procurement/procurement-request";
import CandidateEnrolled from "candidate/CandidateEnrolled";
import CandidateInterview from "candidate/CandidateInterview";
import Nocedit from "LetterHeadTemp/Noc/NocEdit";
import OfferEdit from "LetterHeadTemp/OfferLetter/OfferEdit";
import NdaEdit from "LetterHeadTemp/Nda/NdaEdit";
import CandidateData from "candidate/CandidateData";
import MyAssets from "layouts/stores/my-assets";
import RelievingEdit from "LetterHeadTemp/Reliveing/ReliveingEdit";
import EmplProofEdit from "LetterHeadTemp/EmployeeProof/EmplProofedit";
import HardWareAcptEdit from "LetterHeadTemp/Hardware/HardWareAcptEdit";
import TabTempEdit from "LetterHeadTemp/TabMember/TabTempEdit";
import EditEmpPerfm from "LetterHeadTemp/EmplPerfmence/EditEmpPerfm";
import EmpExitedit from "LetterHeadTemp/EmployeeExit/EmpExitedit";
import PolicyData from "layouts/org-policies/policydata";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";
import AddCourses from "Courses/AddCourses";
import { EditCourses } from "Courses/EditCourses";
import UserCourses from "Courses/UserCourses";
import ApplyCourse from "Courses/ApplyCourse";
import FestivalList from "layouts/festival/festival-list";
import AddNewFestival from "layouts/festival/add-new-festival";
import AddNewPolicy from "layouts/org-policies/add-new-policy";
import OrgPolicies from "layouts/org-policies/orgpolicies";
import CreateMarks from "SqlTables/CreateMarks";
import ExpandButtonWithTooltip from "SqlTables/ExpandButtonWithTooltip";
import ExpenseUpdate from "Expenses/ExpensUpdate";
import ProcurementList from "layouts/procurement/procurement-list";
import EditTicketForm from "layouts/ticket/edit-ticket-status";
import TicketTable from "layouts/ticket/ticket-list";
import CustomerSignIn from "layouts/authentication/customer-sign-in";
import CustomerContact from "layouts/authentication/customer-sign-in/ticket-form";
import QuestionPaperTemplate from "question_paper/QuestionPaperTemplate";
import OrgPasswordCreate from "Password/OrgCreatepass";
import EditPassword from "Password/EditPassword";
import RequestPassword from "Password/RequestPassword";
import AccessRole from "authentication/AccessRole";
import Layout from "ASAT/Components/Layout";
import AdminLayout from "ASAT/Components/Adminlayout";
import Instruction from "ASAT/Components/Instructions/Instruction";
import Questions from "ASAT/Components/TestQuestions/Questions";
import Results from "ASAT/Components/Results/Results";
import AdminDashboard from "ASAT/Components/Admin/AdminDashboard";
import QuestionAdd from "ASAT/Components/Questions/Addquestion";
import DeletQuestion from "ASAT/Components/Questions/Deletequestion";
import RegistrationForm from "ASAT/Components/Registration/RegistrationForm";
import Login from "ASAT/Components/AdminSignin/Login";
import Home from "ASAT/Components/Home/Home";
import AddExpenses from "Expenses/AddExpenses";
import ViewTickets from "layouts/authentication/customer-sign-in/viewTickets";
import ViewDetails from "layouts/authentication/customer-sign-in/viewDetailedTicket";
import EditProfile from "layouts/profile/editProfile";
import Overview from "layouts/profile";
import EditEmployee from "layouts/profile/editProfile";
import StudentRegistration from "SqlTables/StudentRegisteration";
import AssignTeacher1 from "SqlTables/AssignTeacher";
import CustomerOnboard from "sales/CustomerOnboard";
import EditSpoc from "layouts/DepartmentSpoc/EditSpoc";
import AddSpoc from "layouts/DepartmentSpoc/AddSpoc";
import UserLayout from "authentication/UserLayout";
import Dashboard from "layouts/dashboard";
import filteredRoutes from "routes";
import InternalJobDetails from "Internal Job/InternalJobDetail";
import InternalJobApply from "Internal Job/InternalJobApply";
import ApproveRequest from "Password/ApproveRequest";
import EventForm from "layouts/event/eventForm";
import Event from "layouts/event/eventList";
import EditEvent from "layouts/event/editEvent";
import HolidayForm from "layouts/holidays/holidayForm";
import HolidayList from "layouts/holidays/holidayList";
import { ResignationForm } from "EmployeeExit/ResignationForm";
import ChangePassword from "authentication/ChangePassword";
import GetPassword from "authentication/GetPassword";
import ForgotPasswordUser from "authentication/ForgotPasswordUser";
import Theme from "stepperform/Theme";
import UploadDocument from "document/uploadDocument";
import DocumentsDataTable from "document/document";
import OfficeDocumentsData from "document/officeDocument";
import UploadOfficeDocument from "document/uploadoOfficeDocument";



export default function App() {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [orgLogo, setOrgLogo] = useState();
  const [orgname, setOrgname] = useState();
  const [loading, setLoading] = useState(true);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };
  useEffect(() => {
    const fetchOrgData = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
        if (!response.ok) {
          throw new Error("Failed to fetch organization data");
        }
        const data = await response.json();
        setOrgname(data.orgShortName);
        setOrgLogo(data.orgLogo);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      } finally {
        setLoading(false); // Set loading to false after fetching
      }
    };
    fetchOrgData();
  }, []);

  const checkAndHandleNewLogin = () => {
    const isNewlyLoggedIn = sessionStorage.getItem('newLogin');
    if (isNewlyLoggedIn === 'true') {
      sessionStorage.removeItem('newLogin');
      window.location.reload();
    }
  };

  useEffect(() => {
    checkAndHandleNewLogin();
  }, [pathname]);

  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };


  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);


  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);


  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  const configsButton = (
    <SoftBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.5rem"
      height="3.5rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: "pointer" }}
      onClick={handleConfiguratorOpen}
    >
      <Icon fontSize="default" color="inherit">
        settings
      </Icon>
    </SoftBox>
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {layout === "dashboard" && !loading && (
        <>
          <Sidenav
            color={sidenavColor}
            brand={orgLogo}
            brandName={orgname}
            routes={filteredRoutes}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
          />
          <Configurator />
          {configsButton}
        </>
      )}
      {layout === "vr" && <Configurator />}
      <Routes>


        <Route element={<OrgMember />}>
          {getRoutes(filteredRoutes)}
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/orgmdmonboarding" element={<OrgMdmform />} />
          <Route path="/update-customer-sales" element={<OrgMdmform />} />
          <Route path="/orgmdmupdate/:id" element={<Editmdm />} />
          <Route path="/add-asset-group" element={<AddAssetgroup />} />
          <Route path="/salesonboard" element={<Salesonboard />} />
          <Route path="/single-group-asset-list/:id" element={<ListSingleAssetGroup />} />
          <Route path="/add-asset-by-group/:id" element={<AddAssetByGroup />} />
          <Route path="/edit-customer-sales/:email" element={<UpdateSales />} />
          <Route path="/assets-list" element={<AssetList />} />
          <Route path="/orgHierarchy" element={<OrgHierarchy />} />
          <Route path="/stationaryItems" element={<StationaryItems />} />
          <Route path="/jobEditButtons" element={<JobEditButtons />} />
          <Route path="/assets-requests" element={<AssetsRequests />} />
          <Route path="/orgmdmonboarding" element={<OrgMdmform />} />
          <Route path="/update-customer-sales" element={<OrgMdmform />} />
          <Route path="/orgmdmupdate/:id" element={<Editmdm />} />
          <Route path="/orgmdmdata" element={<OrgMdmdata />} />
          <Route path="/customerSalesOnboard" element={<CustomerSalesOnboard />} />
          <Route path="/procurement-request" element={<ProcurementRequest />} />
          <Route path="/candidateEnrolled" element={<CandidateEnrolled />} />
          <Route path="/candidateInterview" element={<CandidateInterview />} />
          <Route path="/nocedit" element={<Nocedit />} />
          <Route path="/enrolledStatusTemp" element={<OfferEdit />} />
          <Route path="/ndaEditTemplate" element={<NdaEdit />} />
          <Route path="/candidateData" element={<CandidateData />} />
          <Route path="/my-assets" element={<MyAssets />} />
          <Route path="/relievingEdit" element={<RelievingEdit />} />
          <Route path="/empProofEdit" element={<EmplProofEdit />} />
          <Route path="/HardWareAcptEdit" element={<HardWareAcptEdit />} />
          <Route path="/tabTemplateEdit" element={<TabTempEdit />} />
          <Route path="/empPerformanceEdit" element={<EditEmpPerfm />} />
          <Route path="/empexittemplateedit" element={<EmpExitedit />} />
          <Route path="/policy-data" element={<PolicyData />} />
          <Route path="/add-course" element={<AddCourses />} />
          <Route path="/edit-course/:courseId" element={<EditCourses />} />
          <Route path="/course-details/:courseid" element={<ApplyCourse />} />
          <Route path="/festival-list" element={<FestivalList />} />
          <Route path="/add-new-festival" element={<AddNewFestival />} />
          <Route path="/add-new-policy" element={<AddNewPolicy />} />
          <Route path="/policy-list" element={<OrgPolicies />} />
          <Route path="/createMarks" element={<CreateMarks />} />
          <Route path="/expandButtonWithTooltip" element={<ExpandButtonWithTooltip />} />
          <Route path="/questionPaperTemplate" element={<QuestionPaperTemplate />} />
          <Route path="/edit-expense/:id" element={<ExpenseUpdate />} />
          <Route path="/procurement-list" element={<ProcurementList />} />
          <Route path="/edit-ticket-status/:id" element={<EditTicketForm />} />
          <Route path="/ticket-list" element={<TicketTable />} />
          <Route path="/createMarks" element={<CreateMarks />} />
          <Route path="/expandButtonWithTooltip" element={<ExpandButtonWithTooltip />} />
          <Route path="/edit-expense/:id" element={<ExpenseUpdate />} />
          <Route path="/add-org-password" element={<OrgPasswordCreate />} />
          <Route path="/edit-password/:titel" element={<EditPassword />} />
          <Route path="/req-password/" element={<RequestPassword />} />
          <Route path="/select-dept" element={<AccessRole />} />
          <Route path='/admin-dashboard' element={<AdminDashboard />} />
          <Route path='/addquestion' element={<QuestionAdd />} />
          <Route path='/deletQuestion' element={<DeletQuestion />} />
          <Route path='/add-expenses' element={<AddExpenses/>} />
          <Route path='/edit-profile/:id' element={<EditProfile/>} />
          <Route path='/profile' element={<Overview/>} />
          <Route path='/studentRegistration' element={<StudentRegistration/>} />
          <Route path='/add-expenses' element={<AddExpenses />} />
          <Route path='/edit-profile/:id' element={<EditProfile />} />
          <Route path='/profile' element={<Overview />} />
          <Route path='/assignteacher' element={<AssignTeacher1 />} />
          <Route path='/customer-onboard' element={<CustomerOnboard />} />
          <Route path='/edit-role' element={<EditSpoc />} />
          <Route path='/allocate-role' element={<AddSpoc />} />
          <Route path='/internal-job-details/:id' element={<InternalJobDetails />} /> 
          <Route path='/internal-job-apply' element={<InternalJobApply />} />
          <Route path='/update-password' element={<ApproveRequest/>} />
          <Route path='/event-form' element={<EventForm/>} />
          <Route path='/event-list' element={<Event/>} />
          <Route path='/edit-event/:id' element={<EditEvent/>} />
          <Route path='/holiday-form' element={<HolidayForm/>} />
          <Route path='/holiday-list' element={<HolidayList/>} />
          <Route path='/resignationForm' element={<ResignationForm/>} />
          <Route path='/changePassword' element={<ChangePassword/>} />
          <Route path='/stepper' element={<Theme/>} />
          <Route path='/upload-document' element={<UploadDocument/>} />
          <Route path='/document-list' element={<DocumentsDataTable/>} />
          <Route path='/office-document-list' element={<OfficeDocumentsData/>} />
          <Route path='/office-document-upload' element={<UploadOfficeDocument/>} />

        </Route>
        <Route element={<Layout />}>
          <Route path='/instructions' element={<Instruction />} />
          <Route path='/test' element={<Questions />} />
          <Route path='/results' element={<Results />} />
        </Route>
        <Route element={<UserLayout />}>
          {getRoutes(routes)}
        </Route>
        <Route path="/" element={<Landing2 />} />
        <Route path="/company" element={<ComingSoon />} />
        <Route path="/about-us" element={<ComingSoon />} />
        <Route path="/team" element={<ComingSoon />} />
        <Route path="/product" element={<ComingSoon />} />
        <Route path="/blog" element={<ComingSoon />} />
        <Route path="/pricing" element={<ComingSoon />} />
        <Route path="/authentication/org-sign-in/" element={<OrgSignIn />} />
        <Route path="/authentication/sign-in/" element={<SignIn />} />
        <Route path="/authentication/sign-up/" element={<SignUp />} />
        <Route path="/userJobs" element={<UserJobs />} />
        <Route path="/jobView/:id" element={<JobView />} />
        <Route path="/jobApplyPage" element={<JobApplyPage />} />
        <Route path="/uploadResume" element={<UploadResume />} />
        <Route path="/Courses" element={<UserCourses />} />
        <Route path="/customer-sign-in" element={<CustomerSignIn />} />
        <Route path='/register' element={<RegistrationForm />} />
        <Route path='/Login' element={<Login />} />
        <Route path='/asat' element={<Home />} />
        <Route path='/view-tickets' element={<ViewTickets />} />
        <Route path='/view-ticket-status/:id' element={<ViewDetails />} />
        <Route path="/ticket-form" element={<CustomerContact />} />
        <Route path='/getPassword' element={<GetPassword />} />
        <Route path='/forgotPasswordUser' element={<ForgotPasswordUser />} />

      </Routes>
    </ThemeProvider>
  );
}

