// EmployeeExit.jsx
import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Box, Card, CardContent, Typography, CircularProgress } from '@mui/material';
import { ClipboardCheck, CheckCircle } from 'lucide-react';
import { APIData, exitKeyWord, org } from '../authentication/APIData';
import { ResignationForm } from './ResignationForm';

const TimelineEvent = ({ date, title, approvedBy, description, isCompleted, isLast }) => {
  return (
    <Box display="flex" alignItems="start" mb={2}>
      <Box width="25%" pr={2} textAlign="right" color="primary.main">
        {date && new Date(date).toString().substring(0, 25)}
      </Box>

      <Box display="flex" flexDirection="column" alignItems="center" position="relative">
        <Box
          width={32}
          height={32}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="50%"
          bgcolor={isCompleted ? 'primary.light' : 'grey.200'}
          color={isCompleted ? 'primary.main' : 'grey.400'}
        >
          {isCompleted ? <CheckCircle size={20} /> : <ClipboardCheck size={20} />}
        </Box>
        {!isLast && <Box width={1} height="100%" bgcolor="grey.200" position="absolute" top={40} />}
      </Box>

      <Box width="75%" pl={2}>
        <Card variant="outlined">
          <CardContent style={{ paddingTop: '24px' }}>
            <Typography variant="h6" fontFamily="monospace" color="primary" gutterBottom>
              {title}
            </Typography>
            {approvedBy && (
              <Typography variant="body2" color="textSecondary">
                Approved By: {approvedBy}
              </Typography>
            )}
            {description && (
              <Typography variant="body2" color="textSecondary" mt={1}>
                Details: {description}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

TimelineEvent.propTypes = {
  date: PropTypes.string,
  title: PropTypes.string.isRequired,
  approvedBy: PropTypes.string,
  description: PropTypes.string,
  isCompleted: PropTypes.bool,
  isLast: PropTypes.bool
};

const LoadingSpinner = () => (
  <Box display="flex" justifyContent="center" alignItems="center" height="200px">
    <CircularProgress color="primary" />
  </Box>
);

export function EmployeeExit() {
  const [lifecycleStatus, setLifecycleStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [empStatus, setEmpStatus] = useState([]);
  const [hasAppliedResignation, setHasAppliedResignation] = useState(false);
  const user = APIData.sessiondetails;

  const fetchExitLifeCycleStatuses = useCallback(async () => {
    try {
      const url = `${APIData.api}employee/life-cycle/type?type=${exitKeyWord}&org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      console.log('Lifecycle statuses response:', response.data);
      
      if (Array.isArray(response.data) && response.data.length > 0) {
        return response.data;
      }
      console.warn('Lifecycle statuses response is empty or not an array');
      return [];
    } catch (error) {
      console.error('Error fetching lifecycle statuses:', error);
      toast.error('Unable to fetch lifecycle statuses');
      return [];
    }
  }, []);

  const fetchEmployeeExitStatus = useCallback(async () => {
    try {                        
      const url = `${APIData.api}employee/exit/emp-username?username=${user.userName}&type=${exitKeyWord}&org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      console.log('Employee status response:', response.data);
      
      if (Array.isArray(response.data)) {
        return response.data;
      } else if (response.data) {
        return [response.data];
      }
      return [];
    } catch (error) {
      console.error('Error fetching employee status:', error);
      toast.error('Unable to fetch user status');
      return [];
    }
  }, [user.userName]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const employeeStatus = await fetchEmployeeExitStatus();
      console.log('Processed employee status:', employeeStatus);
      
      const hasApplied = Array.isArray(employeeStatus) && employeeStatus.length > 0;
      console.log('Has applied resignation:', hasApplied);
      
      setEmpStatus(employeeStatus);
      setHasAppliedResignation(hasApplied);

      if (hasApplied) {
        const lifecycleStatuses = await fetchExitLifeCycleStatuses();
        console.log('Processed lifecycle statuses:', lifecycleStatuses);
        setLifecycleStatus(lifecycleStatuses);
      }
    } catch (error) {
      console.error('Error loading data:', error);
      toast.error('Error loading exit process data');
    } finally {
      setLoading(false);
    }
  }, [fetchEmployeeExitStatus, fetchExitLifeCycleStatuses]);

  // Single useEffect for initial load
  useEffect(() => {
    loadData();
  }, [loadData]);

  // Single useEffect for debugging
  useEffect(() => {
    console.log('State updated:', {
      hasAppliedResignation,
      lifecycleStatusLength: lifecycleStatus.length,
      empStatusLength: empStatus.length
    });
  }, [hasAppliedResignation, lifecycleStatus, empStatus]);

  const handleFormSubmission = async () => {
    console.log('Form submission callback triggered');
    await loadData();
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <Box mx="auto" px={4} py={8} maxWidth={800}>
      <Typography variant="h4" align="center" fontFamily="monospace" color="primary" gutterBottom>
        {user.userName} Exit Process
      </Typography>

      <Box mt={4}>
        {hasAppliedResignation && lifecycleStatus.length > 0 ? (
          <Box position="relative">
            {lifecycleStatus.map((lifeCycle, index) => {
              const empStat = index < empStatus.length ? empStatus[index] : null;
              console.log(`Rendering timeline event ${index}:`, { lifeCycle, empStat });
              return (
                <TimelineEvent
                  key={index}
                  date={empStat?.updatedTime}
                  title={lifeCycle.statusName}
                  approvedBy={empStat?.approvedBy}
                  description={empStat?.description}
                  isCompleted={empStat?.status === 'Completed'}
                  isLast={index === lifecycleStatus.length - 1}
                />
              );
            })}
          </Box>
        ) : (
          <ResignationForm onSubmissionSuccess={handleFormSubmission} />
        )}
      </Box>
    </Box>
  );
}

export default EmployeeExit;