
import { Mcq } from "authentication/APIData";
import axios from "axios";


export const postMcqRequest = async(formData)=>{
    // console.log('addsrequest');
    const response = await Mcq.post("asat/mcq",formData);
    // console.log(response.data);
    return response.data;
} 