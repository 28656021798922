import React, { useState, useEffect } from "react";
import { APIData, org } from '../authentication/APIData';
import axios from "axios";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    DialogActions,
    Grid,
    Tooltip
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from "sweetalert2";
import DefaultProjectCard1 from "examples/Cards/ProjectCards/DefaultProjectCard/index1";
import CustomSelect from "assets/theme/components/Select/CustomSelect";

export const JobEditButtons = () => {
    const [selectedJobType, setSelectedJobType] = useState("INTERNAL");
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [formData, setFormData] = useState({});
    const [selectedValid, setSelectedValid] = useState("YES");

    const validOptions = [
        { value: "YES", label: "Posted" },
        { value: "NO", label: "Not Posted" }
    ];

    const fetchJobs = async (jobType) => {
        setLoading(true);
        try {
            const url = APIData.api + `jobs/opening-type?openingType=${jobType}&org=${org}`;
            const response = await axios.get(url, { headers: APIData.headers });
            setJobs(response.data);
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobs(selectedJobType);
    }, [selectedJobType]);

    const handleJobTypeChange = (type) => {
        setSelectedJobType(type);
    };

    const handleEditClick = (e, jobData) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("Opening dialog for job:", jobData);
        setFormData(jobData);
        setSelectedValid(jobData.valid || "YES");
        setShowDialog(true);
    };

    const closeDialog = () => {
        setShowDialog(false);
        setFormData({});
        setSelectedValid("YES");
    };

    const handleValidChange = (selectedOption) => {
        setSelectedValid(selectedOption.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = `${APIData.api}jobs/?id=${formData.id}`;
        const updatedFormData = new FormData();

        const fields = [
            'job_type', 'job_id', 'job_designation', 'job_dept', 'description',
            'location', 'branch', 'job_tenure', 'salary', 'role', 'roles_and_resp'
        ];

        fields.forEach(field => {
            if (formData[field]) {
                updatedFormData.append(field, formData[field]);
            }
        });

        updatedFormData.append('valid', selectedValid);
        updatedFormData.append('org', org);

        try {
            const response = await axios.put(url, updatedFormData, {
                headers: { ...APIData.headers, 'Content-Type': 'multipart/form-data' }
            });

            if (response.status === 201 || response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Posted Successfully",
                    showConfirmButton: false,
                    timer: 1500
                });
                closeDialog();
                fetchJobs(selectedJobType); 
            }
        } catch (error) {
            console.error("Error updating job:", error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to Post job",
                error
            });
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox mb={3}>
                <Card>
                    <SoftBox pt={2} px={2}>
                        <SoftBox mb={0.5} display="flex" justifyContent="space-between" alignItems="center">
                            <SoftTypography variant="h2" fontWeight="medium">
                            </SoftTypography>
                            <SoftBox display="flex" gap={2}>
                                <Tooltip title="View Internal Jobs" arrow>
                                    <SoftButton
                                        variant={selectedJobType === "INTERNAL" ? "contained" : "outlined"}
                                        onClick={() => handleJobTypeChange("INTERNAL")}
                                        color="info"
                                    >
                                        Internal
                                    </SoftButton>
                                </Tooltip>
                                <Tooltip title="View External Jobs" arrow>
                                    <SoftButton
                                        variant={selectedJobType === "EXTERNAL" ? "contained" : "outlined"}
                                        onClick={() => handleJobTypeChange("EXTERNAL")}
                                        color="info"
                                    >
                                        External
                                    </SoftButton>
                                </Tooltip>
                            </SoftBox>
                        </SoftBox>
                        <SoftBox mb={1}>
                            <SoftTypography variant="h4" fontWeight="regular" color="text">
                                {selectedJobType === "INTERNAL" ? "Internal Job Positions" : "External Job Positions"}
                            </SoftTypography>
                        </SoftBox>
                    </SoftBox>

                    <SoftBox p={2}>
                        {error ? (
                            <SoftTypography color="error" align="center">
                                {error}
                            </SoftTypography>
                        ) : jobs && jobs.length > 0 ? (
                            <Grid container spacing={3}>
                                {jobs.map((job, index) => (
                                    <Grid item xs={12} md={6} xl={3} key={job.id || index}>
                                        <DefaultProjectCard1
                                            image={job.image_url}
                                            label={job.job_dept}
                                            title={job.job_designation}
                                            description={job.location}
                                            action={{
                                                type: "external",
                                                route: "javascript:void(0)",
                                                color: "info",
                                                label: "Posting Status",
                                                onClick: (e) => handleEditClick(e, job)
                                            }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <SoftBox display="flex" justifyContent="center" p={3}>
                                <SoftTypography variant="h4" color="text">
                                    No job openings available at the moment.
                                </SoftTypography>
                            </SoftBox>
                        )}
                    </SoftBox>
                </Card>
            </SoftBox>

            <Dialog
                open={showDialog}
                onClose={closeDialog}
                maxWidth="sm"
                fullWidth
                sx={{
                    borderRadius: '8px',
                    boxShadow: 3, 
                }}
            >
                <DialogTitle>
                    <SoftTypography variant="h4" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
                        Posting {formData.job_designation} Status
                    </SoftTypography>
                </DialogTitle>
                <DialogContent>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <SoftTypography id="valid-select-label" mb={2}>Job Status :</SoftTypography>
                        <CustomSelect
                            options={validOptions}
                            name="valid"
                            value={selectedValid}
                            onChange={handleValidChange}
                            placeholder="Select status"
                            isSearchable={false}
                        />
                    </FormControl>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <SoftButton onClick={closeDialog} variant="text" color="secondary">
                        Cancel
                    </SoftButton>
                    <SoftButton onClick={handleSubmit} variant="gradient" color="info">
                        Update
                    </SoftButton>
                </DialogActions>
            </Dialog>

            <Footer />
        </DashboardLayout>
    );
};

export default JobEditButtons;