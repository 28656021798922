import React, { useState, useEffect } from "react";
import axios from 'axios';
import { APIData, org } from '../authentication/APIData';
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";
import Table from "examples/Tables/Table";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    Backdrop, CircularProgress,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SoftAvatar from "components/SoftAvatar";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import SoftInput from "components/SoftInput";
import Swal from "sweetalert2";

const currentDate = new Date().toISOString().split('T')[0];

const Name = ({ name, email }) => (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox mr={2}>
            <SoftAvatar
                src={`https://ui-avatars.com/api/?name=${encodeURIComponent(name)}&background=random`}
                alt={name}
                size="sm"
                variant="rounded"
            />
        </SoftBox>
        <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
                {name}
            </SoftTypography>
            <SoftTypography variant="caption" color="secondary">
                {email}
            </SoftTypography>
        </SoftBox>
    </SoftBox>
);

Name.propTypes = {
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

const EnrollmentsData = () => {
    // State Management
    const [loading, setLoading] = useState(false);
    const [sortField, setSortField] = useState("");
    const [sortDirection, setSortDirection] = useState("asc");
    const [reloading, setReloading] = useState(false);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState("");
    const [enrolldata, setEnrolldata] = useState(null);
    const [tabValue, setTabValue] = useState(0);
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [userDepartment, setUserDepartment] = useState("");
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const [formData, setFormData] = useState({
        status: ''
    });
    const [formErrors, setFormErrors] = useState({
        deptSupervisor: '',
        status: ''
    });

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(parseInt(accessLevel));
        const Department = localStorage.getItem('Depart Details');
        setUserDepartment(Department);
    }, []);

    // Data fetching effect
    useEffect(() => {
        // Only fetch data once we have the user type information
        if (typeof isSuperAdmin !== 'undefined' && userDepartment !== '') {
            fetchData();
        }
    }, [isSuperAdmin, userDepartment]);

    const handleSelectChange = (selectedOption, actionMeta) => {
        if (selectedOption) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [actionMeta.name]: selectedOption.value,
            }));
            if (actionMeta.name === 'status') {
                setSelectedStatus(selectedOption.value);
                setFormErrors(prev => ({ ...prev, status: '' }));
            }
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [actionMeta.name]: '',
            }));
        }
    };

    const statusOptions = [
        { value: 'APPLIED', label: 'APPLIED' },
        { value: 'ACCEPTED', label: 'ACCEPTED' },
        { value: 'COMPLETED', label: 'COMPLETED' }
    ];

    const fetchData = async () => {
        try {
            setLoading(true);
            let url;
            if (isSuperAdmin) {
                url = APIData.api + `enrollments/status/dept?org=${org}`;
            } else {
                url = APIData.api + `enrollments/status/dept?org=${org}&department=${userDepartment}`;
            }

            const response = await axios.get(url, { headers: APIData.headers });
            setEnrolldata(response.data);
        } catch (error) {
            console.error("Error fetching enrollment data:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to fetch enrollment data"
            });
        } finally {
            setLoading(false);
        }
    };

    const filterEnrollments = async (value) => {
        try {
            setLoading(true);
            let url;
            if (value === 'all') {
                url = isSuperAdmin
                    ? APIData.api + `enrollments/status/dept?org=${org}`
                    : APIData.api + `enrollments/status/dept?org=${org}&department=${userDepartment}`;
            } else {
                const status = value.toUpperCase();
                url = isSuperAdmin
                    ? APIData.api + `enrollments/status/dept?org=${org}&status=${status}`
                    : APIData.api + `enrollments/status/dept?org=${org}&status=${status}&department=${userDepartment}`;
            }

            const response = await axios.get(url, { headers: APIData.headers });
            setEnrolldata(response.data);
        } catch (error) {
            console.error("Error filtering enrollments:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to filter enrollments"
            });
        } finally {
            setLoading(false);
        }
    };

    const handleUpdate = (task) => {
        setSelectedTask(task);
        setIsDialogOpen(true);
        setSelectedStatus(task.enrollment_status);
        // Reset form errors when opening dialog
        setFormErrors({
            deptSupervisor: '',
            status: ''
        });
    };

    const validateForm = () => {
        let isValid = true;
        const errors = {
            deptSupervisor: '',
            status: ''
        };

        if (!selectedTask?.deptSupervisor) {
            errors.deptSupervisor = 'Department Supervisor is required';
            isValid = false;
        }

        if (!selectedStatus) {
            errors.status = 'Status is required';
            isValid = false;
        }

        setFormErrors(errors);
        return isValid;
    };

    const handleUpdate2 = async () => {
        if (!validateForm()) {
            return;
        }

        setReloading(true);

        try {
            const form = {
                admin_id: selectedTask.admin_id || "",
                branch: selectedTask.branch || "",
                designation: selectedTask.designation || "",
                job_id: selectedTask.job_id || "",
                enrolled_date: selectedTask.enrolled_date || currentDate,
                enrollment_status: selectedStatus,
                enrollment_type: selectedTask.enrollment_type || "",
                followup_datetime: selectedTask.followup_datetime || "",
                office_email: selectedTask.office_email || "",
                user_email: selectedTask.user_email,
                user_name: selectedTask.user_name,
                role: selectedTask.role || "",
                dept: selectedTask.dept || "",
                user_phone_number: selectedTask.user_phone_number || "",
                deptSupervisor: selectedTask.deptSupervisor || "",
                resume_url: selectedTask.resume_url || "",
                prevDeptSupervisor: selectedTask.prevDeptSupervisor || "",
                release_status: selectedTask.release_status || "",
                org: org,
                new_enrolled_date: currentDate,
            };

            if (selectedTask.enrollment_type === "STUDENT") {
                form.course_id = selectedTask.course_id || "";
                form.enrollmentSupervisor = "";
            } else {
                form.course_id = "";
                form.emp_acceptance_status = "PENDING";
                form.enrollmentSupervisor = "";
            }

            const url = selectedTask.enrollment_type === "STUDENT"
                ? `${APIData.api}enrollments/student?orgId=${org}`
                : `${APIData.api}enrollments/?orgId=${org}`;

            const response = await axios.post(url, form, {
                headers: APIData.headers
            });

            if (response.status === 201 || response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: 'Updated successfully',
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    window.location.reload();
                }, 1500);
            }
        } catch (error) {
            console.error("API Error:", error);
            Swal.fire({
                icon: "error",
                title: "Update Failed",
                text: error.response?.data?.errorDesc || "An error occurred while updating"
            });
        } finally {
            setReloading(false);
            setIsDialogOpen(false);
        }
    };

    const columns = [
        { name: "User Details", align: "left" },
        { name: "Type", align: "center" },
        { name: "Status", align: "center" },
        { name: "Role", align: "center" },
        { name: "Enrolled Date", align: "center" },
        { name: "Department", align: "center" },
       ...((permission == 1110 || permission == 1100 || permission == 1000  ) ?[] : [{ name: "Action", align: "center" }])
    ];

    const rows = (enrolldata || []).map((task) => ({
        "User Details": <Name name={task.user_name} email={task.user_email} />,
        "Type": (
            <SoftBadge
                variant="gradient"
                badgeContent={task.enrollment_type.toLowerCase()}
                color="secondary"
                size="xs"
                container
            />
        ),
        "Status": (
            <SoftBadge
                variant="gradient"
                badgeContent={task.enrollment_status.toLowerCase()}
                color={task.enrollment_status === "ACCEPTED" ? "success" :
                    task.enrollment_status === "COMPLETED" ? "success" : "info"}
                size="xs"
                container
            />
        ),
        "Role": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {task.job_id}
            </SoftTypography>
        ),
        "Enrolled Date": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {task.enrolled_date}
            </SoftTypography>
        ),
        "Department": (
            <SoftTypography variant="caption" color="secondary" fontWeight="medium">
                {task.dept}
            </SoftTypography>
        ),
        "Action": (
            <SoftBox display="flex" alignItems="center" mt={-1}>
                <SoftTypography
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    onClick={() => handleUpdate(task)}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer'
                    }}
                >
                    <EditIcon sx={{ mr: 1 }} fontSize="small" />
                    Edit
                </SoftTypography>
            </SoftBox>
        )
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <Grid container spacing={3} alignItems="center">
                            <Grid item xs={12} md={6} lg={16} sx={{ ml: "0" }}>
                                <AppBar position="static">
                                    <Tabs
                                        value={tabValue}
                                        onChange={(e, newValue) => {
                                            setTabValue(newValue);
                                            const values = ['all', 'applied', 'accepted', 'completed'];
                                            filterEnrollments(values[newValue]);
                                        }}
                                        sx={{ background: "transparent" }}
                                    >
                                        {['All', 'Applied', 'Accepted', 'Completed'].map((label, index) => (
                                            <Tab
                                                key={label}
                                                label={label}
                                                sx={{
                                                    color: tabValue === index ? "info.main" : "text.secondary",
                                                    fontWeight: tabValue === index ? "bold" : "normal",
                                                    borderBottom: tabValue === index ? "2px solid" : "none",
                                                    borderColor: "info.main",
                                                }}
                                            />
                                        ))}
                                    </Tabs>
                                </AppBar>
                            </Grid>
                        </Grid>
                    </Card>

                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h6">Enrollments</SoftTypography>
                    </SoftBox>

                    {loading ? (
                        <SoftBox display="flex" justifyContent="center" alignItems="center" p={3}>
                            <CircularProgress />
                        </SoftBox>
                    ) : (
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>
                    )}
                </SoftBox>

                {/* Update Dialog */}
                <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="md">
                    <Backdrop open={reloading} style={{ color: '#fff', zIndex: 1300 }}>
                    <CircularProgress color="inherit" />
                    </Backdrop>
                    <DialogTitle>
                        <SoftTypography variant="body1" textAlign="center">
                            Update Status Of {selectedTask?.user_name}
                        </SoftTypography>
                    </DialogTitle>
                    <DialogContent style={{ padding: '20px' }}>
                        <Grid container spacing={3}>
                            {/* Content in two columns */}
                            <Grid item xs={12} sm={6}>
                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Email Id: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.user_email}</SoftTypography>
                                    </li>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Enrollment Type: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.enrollment_type}</SoftTypography>
                                    </li>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Job ID: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.job_id}</SoftTypography>
                                    </li>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Department: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.dept}</SoftTypography>
                                    </li>
                                </ul>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Office Email: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.office_email}</SoftTypography>
                                    </li>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Enrollment Status: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.enrollment_status}</SoftTypography>
                                    </li>
                                    <li>
                                        <SoftTypography component="span" fontWeight="bold" variant="h6">Enrolled Date: </SoftTypography>
                                        <SoftTypography component="span" variant="h6">{selectedTask?.enrolled_date}</SoftTypography>
                                    </li>
                                </ul>
                            </Grid>

                            {/* Input fields in a single row */}
                            <Grid item xs={12} sm={6}>
                                <SoftTypography variant="h6" mb={1}>Dept Supervisor<span style={{ color: 'red' }}>*</span></SoftTypography>
                                <SoftBox mb={2}>
                                    <SoftInput
                                        label="Dept Supervisor"
                                        value={selectedTask?.deptSupervisor || ""}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        error={!!formErrors.deptSupervisor}
                                        onChange={(e) => {
                                            setSelectedTask({
                                                ...selectedTask,
                                                deptSupervisor: e.target.value
                                            });
                                            if (e.target.value) {
                                                setFormErrors(prev => ({ ...prev, deptSupervisor: '' }));
                                            }
                                        }}
                                    />
                                    {formErrors.deptSupervisor && (
                                        <SoftTypography variant="caption" color="error">
                                            {formErrors.deptSupervisor}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6" mb={1}>Status<span style={{ color: 'red' }}>*</span></SoftTypography>
                                    <CustomSelect
                                        options={statusOptions}
                                        placeholder="Choose Status"
                                        name="status"
                                        onChange={handleSelectChange}
                                        value={statusOptions.find(option => option.value === selectedStatus)}
                                    />
                                    {formErrors.status && (
                                        <SoftTypography variant="caption" color="error">
                                            {formErrors.status}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <SoftBox display="flex" justifyContent="center" gap={2} width="100%" p={2}>
                            <SoftButton
                                variant="gradient"
                                fullWidth
                                color="secondary"
                                onClick={() => setIsDialogOpen(false)}
                            >
                                Cancel
                            </SoftButton>
                            <SoftButton
                                variant="gradient"
                                fullWidth
                                color="info"
                                onClick={handleUpdate2}
                            >
                                Save
                            </SoftButton>
                        </SoftBox>
                    </DialogActions>
                </Dialog>
            </SoftBox>
            <Footer />
        </DashboardLayout>
    );
};

export default EnrollmentsData;