import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
    Grid,
    Card,
    Backdrop,
    CircularProgress,
} from "@mui/material";

import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { APIData, org } from '../authentication/APIData';
import * as AiIcons from "react-icons/ai";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import CustomSelect from "assets/theme/components/Select/CustomSelect";

const MAX_CHAR_LENGTH = 45;
const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails")) || "";
const job_created_by = sessiondetails.email;

const departmentOptions = [
    { value: 'HR', label: 'HR' },
    { value: 'SALES', label: 'SALES' },
    { value: 'FINANCE', label: 'FINANCE' },
    { value: 'TECHNICAL', label: 'TECHNICAL' },
    { value: 'ACADEMY', label: 'ACADEMY' }
];

const privilegeOptions = [
    { value: '1000', label: 'Read Only' },
    { value: '1100', label: 'Read, Write' },
    { value: '1110', label: 'Read, Write, Update' },
    { value: '1111', label: 'Read, Write, Update, Delete' }
];

const Add_designation = () => {
    const [formData, setFormData] = useState({
        dept: '',
        id: 0,
        designation: '',
        privilege: '',
        role: '',
        grade: '',
        org: org,
        access_level: [],
        job_created_by: job_created_by
    });

    const [touchedFields, setTouchedFields] = useState({
        dept: false,
        designation: false,
        privilege: false,
        role: false
    });

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [isFormValid, setIsFormValid] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        validateForm();
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        if ((name === 'designation' || name === 'role') && value.length > MAX_CHAR_LENGTH) {
            setErrors(prev => ({
                ...prev,
                [name]: `Cannot exceed ${MAX_CHAR_LENGTH} characters`
            }));
            return;
        }

        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value,
        }));

        // Mark the current field as touched
        setTouchedFields(prev => ({
            ...prev,
            [name]: true
        }));

        // If this field is filled, mark previous fields as touched
        if (value) {
            markPreviousFieldsAsTouched(name);
        }

        setErrors(prev => ({ ...prev, [name]: "" }));
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        if (selectedOption) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [actionMeta.name]: selectedOption.value,
            }));

            // Mark the current field as touched
            setTouchedFields(prev => ({
                ...prev,
                [actionMeta.name]: true
            }));

            // If this field is filled, mark previous fields as touched
            markPreviousFieldsAsTouched(actionMeta.name);

            setErrors(prev => ({ ...prev, [actionMeta.name]: "" }));
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [actionMeta.name]: '',
            }));
        }
    };

    const markPreviousFieldsAsTouched = (currentField) => {
        const fieldOrder = ['dept', 'designation', 'privilege', 'role'];
        const currentIndex = fieldOrder.indexOf(currentField);
        
        const newTouchedFields = { ...touchedFields };
        for (let i = 0; i <= currentIndex; i++) {
            newTouchedFields[fieldOrder[i]] = true;
        }
        setTouchedFields(newTouchedFields);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.dept && touchedFields.dept) {
            newErrors.dept = "Department is required";
        }

        if (!formData.designation && touchedFields.designation) {
            newErrors.designation = "Designation is required";
        } else if (formData.designation.length > MAX_CHAR_LENGTH && touchedFields.designation) {
            newErrors.designation = `Cannot exceed ${MAX_CHAR_LENGTH} characters`;
        }

        if (!formData.privilege && touchedFields.privilege) {
            newErrors.privilege = "Privilege is required";
        }

        if (!formData.role && touchedFields.role) {
            newErrors.role = "Role is required";
        } else if (formData.role.length > MAX_CHAR_LENGTH && touchedFields.role) {
            newErrors.role = `Cannot exceed ${MAX_CHAR_LENGTH} characters`;
        }

        setErrors(newErrors);

        // For submit button validation, check if all fields are filled regardless of touch status
        const isValid = formData.dept && 
                       formData.designation && 
                       formData.privilege && 
                       formData.role && 
                       formData.designation.length <= MAX_CHAR_LENGTH && 
                       formData.role.length <= MAX_CHAR_LENGTH;
        
        setIsFormValid(isValid);
        return isValid;
    };

    const handleSubmit = async (e) => {
        if (e) e.preventDefault();

        // Mark all fields as touched when submitting
        setTouchedFields({
            dept: true,
            designation: true,
            privilege: true,
            role: true
        });

        if (!validateForm()) {
            toast.error("Please fix all errors before submitting");
            return;
        }

        setLoading(true);

        try {
            const url = APIData.api + 'org-designation/';
            const headers = APIData.headers;
            const response = await axios.post(url, formData, { headers });

            if (response.status === 200 || response.status === 201) {
                Swal.fire({
                    icon: "success",
                    title: "Designation created successfully",
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    navigate("/addjob");
                }, 1500);

                setFormData({
                    dept: '',
                    id: 0,
                    designation: '',
                    privilege: '',
                    role: '',
                    grade: '',
                    org: org,
                    access_level: ["HR"],
                    job_created_by: job_created_by
                });
            }
        } catch (error) {
            console.error('Error details:', error);

            let errorMessage = "Error creating designation";

            if (error.response) {
                switch (error.response.status) {
                    case 400:
                        errorMessage = error.response.data?.message || "Bad request - Please check your input";
                        break;
                    case 409:
                        errorMessage = "Designation is already exists, try different one or create a new one";
                        break;
                    case 404:
                        errorMessage = "Not found - The resource you are looking for was not found";
                        break;
                    case 500:
                        errorMessage = "Server error - Please try again later";
                        break;
                    default:
                        errorMessage = error.response.data?.message || "An unexpected error occurred";
                }
            }

            Swal.fire({
                icon: "error",
                title: "Oops!",
                text: errorMessage,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />

            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/jobEditButtons">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Create New Designation
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox pt={2} pb={3} px={3}>
                            <SoftBox component="form" role="form" onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Choose Department</SoftTypography>
                                            <CustomSelect
                                                options={departmentOptions}
                                                placeholder="Choose Department"
                                                name="dept"
                                                onChange={handleSelectChange}
                                                value={formData.dept}
                                                error={!!errors.dept}
                                            />
                                            {errors.dept && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.dept}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Designation</SoftTypography>
                                            <SoftInput
                                                size="small"
                                                placeholder="Designation"
                                                name="designation"
                                                value={formData.designation}
                                                onChange={handleChange}
                                                error={!!errors.designation}
                                            />
                                            <SoftTypography variant="caption" color={errors.designation ? "error" : "dark"}>
                                                {errors.designation || `${formData.designation.length}/${MAX_CHAR_LENGTH} characters`}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Choose Privilege</SoftTypography>
                                            <CustomSelect
                                                options={privilegeOptions}
                                                placeholder="Choose Privilege"
                                                name="privilege"
                                                onChange={handleSelectChange}
                                                value={formData.privilege}
                                                error={!!errors.privilege}
                                            />
                                            {errors.privilege && (
                                                <SoftTypography variant="caption" color="error">
                                                    {errors.privilege}
                                                </SoftTypography>
                                            )}
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="h6" mb={1}>Role</SoftTypography>
                                            <SoftInput
                                                size="small"
                                                placeholder="Role"
                                                name="role"
                                                value={formData.role}
                                                onChange={handleChange}
                                                error={!!errors.role}
                                            />
                                            <SoftTypography variant="caption" color={errors.role ? "error" : "dark"}>
                                                {errors.role || `${formData.role.length}/${MAX_CHAR_LENGTH} characters`}
                                            </SoftTypography>
                                        </SoftBox>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <SoftBox mt={4} mb={1}>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                fullWidth
                                                onClick={handleSubmit}
                                                disabled={loading || !isFormValid}
                                            >
                                                {loading ? "Creating..." : "Create Designation"}
                                            </SoftButton>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </DashboardLayout>
    );
};

export default Add_designation;