import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Swal from 'sweetalert2';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import Table from "examples/Tables/Table";
import { TextField } from "@mui/material";
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';

const SpocData = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    fetchRoles();
  }, []);

  const fetchRoles = async () => {
    setLoading(true);
    try {
      const response = await axios.get(APIData.api + `org-roles-head?org=${org}`, { 
        headers: APIData.headers 
      });
      setRoles(response.data);
    } catch (error) {
      showErrorAlert("Failed to fetch roles data");
      console.error(error);
    }
    setLoading(false);
  };

  const showErrorAlert = (message) => {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: message,
      confirmButtonColor: '#3085d6'
    });
  };

  const showSuccessAlert = (message) => {
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: message,
      confirmButtonColor: '#3085d6'
    });
  };

  const handleDelete = async (id, supervisor) => {
    // Show confirmation dialog
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `Do you want to delete the role for "${supervisor}"?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel'
    });

    if (result.isConfirmed) {
      try {
        // Show loading state
        Swal.fire({
          title: 'Deleting...',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });

        const response = await axios.delete(
          `${APIData.api}org-roles-head/?id=${id}`,
          { headers: APIData.headers }
        );

        if (response.data.status.toLowerCase() === "success") {
          showSuccessAlert(response.data.description);
          fetchRoles();
        } else {
          showErrorAlert(response.data.errorDesc);
        }
      } catch (error) {
        showErrorAlert("Operation failed. Please try again.");
        console.error(error);
      }
    }
  };

  const handleEdit = (role) => {
    navigate(`/edit-role`, { state: { roleData: role } });
  };

  const filteredRoles = roles.filter(role =>
    Object.values(role).some(value =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const columns = [
    { name: "User", align: "left" },
    { name: "Department", align: "left" },
    { name: "Email", align: "left" },
    { name: "Actions", align: "center" }
  ];

  const rows = filteredRoles
    .slice(page * itemsPerPage, (page + 1) * itemsPerPage)
    .map((role) => ({
      User: (
        <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography variant="button" fontWeight="medium">
              {role.supervisor}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
      Department: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {role.roles}
        </SoftTypography>
      ),
      Email: (
        <SoftTypography variant="caption" color="secondary" fontWeight="medium">
          {role.email_id}
        </SoftTypography>
      ),
      Actions: (
        <SoftBox display="flex" justifyContent="center" gap={2}>
          <SoftTypography
            component="a"
            onClick={() => handleEdit(role)}
            variant="caption"
            color="info"
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
          >
            Edit
          </SoftTypography>
          <SoftTypography
            component="a"
            onClick={() => handleDelete(role.id, role.supervisor)}
            variant="caption"
            color="error"
            fontWeight="medium"
            sx={{ cursor: 'pointer' }}
          >
            Delete
          </SoftTypography>
        </SoftBox>
      )
    }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <SoftTypography variant="h6">Department SPOC</SoftTypography>
            <SoftBox display="flex" gap={2}>
              <TextField
                size="small"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <Link to="/allocate-role">
                <SoftButton>
                  + New Role
                </SoftButton>
              </Link>
            </SoftBox>
          </SoftBox>
          <Table columns={columns} rows={rows} />
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
};

export default SpocData;