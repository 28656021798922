import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { Grid, CircularProgress, Backdrop } from '@mui/material';
import { Card } from "@mui/material";
import * as AiIcons from "react-icons/ai";
import { APIData } from "authentication/APIData";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftInput from "components/SoftInput";

const ExpenseUpdate = () => {
    const fetchedDept = localStorage.getItem("Depart Details");
    const [data, setData] = useState();
    const [status, setStatus] = useState("");
    const [comment, setComment] = useState("");
    const [approval, setApproval] = useState("");
    const [loading, setLoading] = useState(true);
    const navigate=useNavigate()
    const { id } = useParams();

    useEffect(() => {
        fetchExpense(id);
    }, [id]);

    const fetchExpense = (id) => {
        axios
            .get(`${APIData.api}expenses/${id}`, { headers: APIData.headers })
            .then((response) => {
                setData(response.data);
                setStatus(response.data.status);
                setApproval(response.data.approval);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Error fetching expense data:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: "Failed to fetch expense data"
                });
            });
    };

    const handleUpdate = () => {
        const updatedExpense = {
            ...data,
            status: status,
            approval: approval,
            comment: comment,
        };

        axios
            .put(`${APIData.api}expenses/`, updatedExpense, { headers: APIData.headers })
            .then((response) => {
                if (response.status === 201 || response.data.status === "success") {
                    Swal.fire({
                        icon: "success",
                        title: "Updated successfully",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    navigate(`/expenses`)
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Update failed, please check with the admin.",
                    });
                }
            })
            .catch((error) => {
                console.error("Error updating expense:", error);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error,
                });
            });
    };

    const handleDownload = () => {
        setLoading(true);
        axios
            .get(APIData.api + "expenses/download/" + id, {
                headers: APIData.headers,
            })
            .then((response) => {
                setLoading(false);
                window.location.href = response.request.responseURL;
            })
            .catch((error) => {
                setLoading(false);
                Swal.fire({
                    icon: "error",
                    title: "Download Failed",
                    text: "Please try again later."
                });
            });
    }

    const statusOptions = [
        { value: 'VERIFYING', label: 'VERIFYING' },
        { value: 'REJECTED', label: 'REJECTED' },
        { value: 'APPROVED', label: 'APPROVED' }
    ];

    const approvalOptions = [
        { value: 'PAID', label: 'PAID' },
        { value: 'UNPAID', label: 'UNPAID' }
    ];

    // Create the details array
    const expenseDetails = [
        { label: "Cost", value: "₹" + data?.cost },
        { label: "Expense Type", value: data?.exp_type },
        { label: "Expense Date", value: data?.exp_date },
        { label: "Person", value: data?.created_by },
        ...(data?.comment && data?.comment.trim() !== "" ? [{ label: "Comment", value: data?.comment }] : []),
        {
            label: "Image/file",
            value: (
                <SoftButton
                    variant="text"
                    color="info"
                    size="small"
                    onClick={handleDownload}
                    startIcon={<AiIcons.AiOutlineDownload />}
                >
                    Download
                </SoftButton>
            )
        },
        { label: "Reason", value: data?.reason },
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100%" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} sm={10}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/expenses">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Update Expense
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <Grid container spacing={2}>
                                {/* Expense Details Section */}
                                <Grid item xs={12} md={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" fontWeight="medium" mb={2}>
                                            Expense Details
                                        </SoftTypography>
                                        {expenseDetails.map((item, index) => (
                                            <SoftBox key={index} mb={2}>
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={4}>
                                                        <SoftTypography variant="button" fontWeight="medium">
                                                            {item.label}:
                                                        </SoftTypography>
                                                    </Grid>
                                                    <Grid item xs={8}>
                                                        <SoftTypography variant="button" color="text">
                                                            {item.value}
                                                        </SoftTypography>
                                                    </Grid>
                                                </Grid>
                                            </SoftBox>
                                        ))}
                                    </SoftBox>
                                </Grid>

                                {/* Update Form Section */}
                                <Grid item xs={12} md={6}>
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" fontWeight="medium" mb={2}>
                                            Update Status
                                        </SoftTypography>
                                        
                                        <SoftBox mb={2}>
                                            <SoftTypography variant="button" fontWeight="medium">
                                                Status
                                            </SoftTypography>
                                            <CustomSelect
                                                options={statusOptions}
                                                value={status}
                                                name="status"
                                                onChange={(selectedOption) => setStatus(selectedOption.value)}
                                                placeholder="Select Status"
                                            />
                                        </SoftBox>

                                        {fetchedDept === "FINANCE" && (
                                            <SoftBox mb={2}>
                                                <SoftTypography variant="button" fontWeight="medium">
                                                    Approval
                                                </SoftTypography>
                                                <CustomSelect
                                                    options={approvalOptions}
                                                    value={approval}
                                                    name="approval"
                                                    onChange={(selectedOption) => setApproval(selectedOption.value)}
                                                    placeholder="Select Approval"
                                                />
                                            </SoftBox>
                                        )}

                                        <SoftBox mb={2}>
                                            <SoftTypography variant="button" fontWeight="medium">
                                                Comment
                                            </SoftTypography>
                                            <SoftInput
                                                multiline
                                                rows={3}
                                                value={comment}
                                                onChange={(e) => setComment(e.target.value)}
                                                placeholder="Enter your comment"
                                            />
                                        </SoftBox>

                                        <SoftBox mt={4} display="flex" justifyContent="space-between">
                                           
                                            <Link to="/expenses">
                                                <SoftButton variant="outlined" color="info">
                                                    Back
                                                </SoftButton>
                                            </Link>
                                            <SoftButton
                                                variant="gradient"
                                                color="info"
                                                onClick={handleUpdate}
                                            >
                                                Update
                                            </SoftButton>
                                        </SoftBox>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default ExpenseUpdate;