import React, { useEffect, useState } from 'react';
import {
 Button, Grid, Typography, Container, Paper, Dialog, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import EmpPerfmTemp from './EmplPerfmTemp';
import Swal from 'sweetalert2';
import SoftButton from 'components/SoftButton';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import { useLocation, useNavigate } from 'react-router-dom';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';

const initialState = {

    header1: "",
    header10: "",
    header11: "",
    header12: "",
    header13: "",
    header14: "",
    header15: '',
    header16: "",
    header17: "",
    header18: "",
    header19: "",
    header2: "",
    header20: "",
    header21: "",
    header3: "",
    header4: "",
    header5: "",
    header6: "",
    header7: "",
    header8: "",
    header9: "",
    id: 0,
    org: org,
    para1: "",
    para10: "",
    para11: "",
    para12: "",
    para13: "",
    para14: "",
    para15: "",
    para16: "",
    para17: "",
    para18: "",
    para19: "",
    para2: "",
    para20: "",
    para21: "",
    para22: "",
    para3: "",
    para4: "",
    para5: "",
    para6: "",
    para7: "",
    para8: "",
    para9: "",
    temp_name: "EMPPERF",
    title: "Employee Performance Review"

}

function EditEmpPerfm() {
    const [formData, setFormData] = useState(initialState);
    const [orgdetails, setOrgdetails] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleBack = () => {
        if (location.state?.returnToStepper) {
            navigate(location.state.returnPath);
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        fetchOrgDetails();
    },);

    const fetchOrgDetails = () => {
        axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
            .then((response) => setOrgdetails(response.data))
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(APIData.api + `db-template/`, formData, { headers: APIData.headers })
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Update Error",
                    text: error.response.data
                });

            })
        console.log('Form Data Submitted:', formData);
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    return (
        <Container>
            <PageLayout>
              <SoftButton onClick={handleBack}>
                Back
            </SoftButton>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <Typography variant="h5" gutterBottom>
                        Emp Performance Form
                    </Typography>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header1</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header1'}
                                  
                                    value={formData.header1}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">para1</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para1'}
                                    value={formData.para1}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header2</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header2'}
                                   
                                    value={formData.header2}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item xs={12}>
                            <SoftTypography variant="h6">Header3</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header3'}
                                
                                    value={formData.header3}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header4</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header4'}
                                 
                                    value={formData.header4}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">header5</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header5'}
                                    value={formData.header5}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">header6</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header6'}
                                    value={formData.header6}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">header7</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header7'}
                                    value={formData.header7}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">header8</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header8'}
                                    value={formData.header8}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">header9</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header9'}
                                    value={formData.header9}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">header10</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header10'}
                               
                                    value={formData.header10}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item xs={12}>
                            <SoftTypography variant="h6">header11</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header11'}
                             
                                    value={formData.header11}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">header12</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header12'}
                                
                                    value={formData.header12}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">header13</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header13'}
                                 
                                    value={formData.header13}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header14</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header14'}
                                    value={formData.header14}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header15</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header15'}
                              
                                    value={formData.header15}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header16</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header16'}
                                   
                                    value={formData.header16}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid><Grid item xs={12}>
                            <SoftTypography variant="h6">Header17</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header17'}
                                    value={formData.header17}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Header18</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'header18'}
                                    value={formData.header18}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Para2</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para2'}
                                    value={formData.para2}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> <Grid item xs={12}>
                            <SoftTypography variant="h6">Para3</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para3'}
                                    value={formData.para3}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  <Grid item xs={12}>
                            <SoftTypography variant="h6">Para4</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para4'}
                                    value={formData.para4}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Para5</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para5'}
                                    value={formData.para5}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid> <Grid item xs={12}>
                            <SoftTypography variant="h6">Para6</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para6'}
                                    value={formData.para6}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  <Grid item xs={12}>
                            <SoftTypography variant="h6">Para7</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para7'}
                                    value={formData.para7}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Para8</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para8'}
                                    value={formData.para8}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Para9</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para9'}
                                    value={formData.para9}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>  <Grid item xs={12}>
                            <SoftTypography variant="h6">Para10</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para10'}
                                    value={formData.para10}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Para11</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para11'}
                                    value={formData.para11}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Para12</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para12'}
                                
                                    value={formData.para12}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Para13</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para13'}
                              
                                    value={formData.para13}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Para14</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para14'}
                               
                                    value={formData.para14}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">para15</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para15'}
                                 
                                    value={formData.para15}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">para16</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para16'}
                             
                                    value={formData.para16}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                            <SoftTypography variant="h6">para17</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para17'}
                                 
                                    value={formData.para17}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">para18</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para18'}
                             
                                    value={formData.para18}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item xs={12}>
                            <SoftTypography variant="h6">Paragraph 19</SoftTypography>
                                <SoftInput
                                    fullWidth
                                    name={'para19'}
                                  
                                    value={formData.para19}
                                    onChange={handleChange}
                                    variant="outlined"
                                    margin="normal"
                                    type="text"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    style={{ marginBottom: '16px', width: "100%" }}
                                    onClick={handleClickOpen}
                                >
                                    View Template in Full Screen
                                </Button>
                                <Button type="submit" variant="contained" color="primary" style={{ width: '100%' }}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Grid>
                <Grid item xs={12} sm={4}>

                    <Typography variant="h5" gutterBottom>
                    Adaptive Template
                    </Typography>
                    <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
                        {orgdetails ? (
                            <div style={styles.container}>
                                <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                                <h3 style={{ fontSize: "16px" }}>{orgdetails.orgName}</h3>
                                <div style={styles.companyDetails}>
                                    <p>+91 {orgdetails.orgMob}</p>
                                    <p>{orgdetails.orgEmailId}</p>
                                    <p>{orgdetails.orgUrl}</p>
                                </div>
                                <h3 style={styles.title}>{formData.header1}</h3>
                                <p style={styles.paragraph}>
                                    {formData.para1}
                                </p>
                                <table style={styles.table}>
                                    <tr>
                                        <td style={styles.tableCell}><srong>{formData.header2}</srong></td>
                                        <td style={styles.tableCellWide}>c4e2024adhithyan__p_a</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell}><srong>{formData.header3}</srong></td>
                                        <td style={styles.tableCellWide}>Adhithyan P A</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell}><srong>{formData.header4}</srong></td>
                                        <td style={styles.tableCellWide}>[Developer]</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell}><srong>{formData.header5}</srong></td>
                                        <td style={styles.tableCellWide}>[TECHNICAL]</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell}><srong>{formData.header6}</srong></td>
                                        <td style={styles.tableCellWide}>[amulya.s@care4edu.com]</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell}><srong>{formData.header7}</srong></td>
                                        <td style={styles.tableCellWide}> </td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell}><srong>{formData.header8}</srong></td>
                                        <td style={styles.tableCellWide}> 20/08/2024</td>
                                    </tr>
                                </table>
                                <table style={styles.table1}>
                                    <tr>
                                        <td style={styles.table1head}><srong>{formData.header9}</srong></td>
                                        <td style={styles.table1sub}><srong>{formData.header10}</srong></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell1}>{formData.header11}</td>
                                        <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell1}>{formData.header12}</td>
                                        <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell1}>{formData.header13}</td>
                                        <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell1}>{formData.header14}</td>
                                        <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell1}>{formData.header15}</td>
                                        <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell1}>{formData.header16}</td>
                                        <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                    </tr>
                                </table>
                                <table style={styles.table}>
                                    <tr>
                                        <td style={styles.table1head}>{formData.header17}</td>
                                        <td style={styles.table1sub}>{formData.header10}</td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para2}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para3}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para4}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para5}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para6}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para7}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para8}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para9}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para10}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para11}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para12}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                    <tr>
                                        <td style={styles.tableCell2}>{formData.para13}</td>
                                        <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                    </tr>
                                </table>
                                <h2 style={styles.header1}>{formData.header18}</h2>
                                <p style={styles.paragraph}>{formData.para14}</p>
                                <ul style={{
                                    listStyleType: 'disc',
                                    paddingLeft: '20px',
                                }}>
                                    <li style={{ marginBottom: '8px' }}>
                                        {formData.para15}
                                    </li>
                                    <li style={{ marginBottom: '8px' }}>
                                        {formData.para16}
                                    </li>
                                    <li style={{ marginBottom: '20px' }}>
                                        {formData.para17}
                                    </li>
                                    <li style={{ marginBottom: '8px' }}>
                                        {formData.para18}
                                    </li>
                                    <li style={{ marginBottom: '20px' }}>
                                        {formData.para19}
                                    </li>
                                </ul>

                                <h4 style={styles.header}>Goal 1:</h4>
                                <input type="text" style={styles.input1} />
                                <h3 style={styles.header}>Goal 2:</h3>
                                <input type="text" style={styles.input1} />
                                <h3 style={styles.header}>Goal 3:</h3>
                                <input type="text" style={styles.input1} />
                                <h3 style={styles.header}>Goal 4:</h3>
                                <input type="text" style={styles.input1} />
                                <h3 style={styles.header}>Goal 5:</h3>
                                <input type="text" style={styles.input1} />
                                <h3 style={styles.header}>Achievements:</h3>
                                <textarea style={styles.textarea} />
                                <h3 style={styles.header}>Employee Comments:</h3>
                                <textarea style={styles.textarea} />
                                <h3 style={styles.header}>Supervisor Comments:</h3>
                                <textarea style={styles.textarea} />

                                <h3 style={styles.signatureSection}>Signatures:</h3>
                                <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                    <div style={{ flex: 1, textAlign: 'center' }}>
                                        <p>Supervisor:</p>
                                        <p>Date: 20/08/2024</p>
                                        <p>(Sign and give form to reviewer.)</p>

                                    </div>
                                    <div style={{ flex: 1, textAlign: 'center' }}>
                                        <p>Employee:</p>
                                        <p>Date: 20/08/2024</p>
                                        <p>(Sign and return form to your supervisor within 48 hours of its receipt.)</p>

                                    </div>

                                </div>
                            </div>
                        ) : (
                            <Typography variant="body1">Loading organization details...</Typography>
                        )}
                    </Paper >
                </Grid>

                <Grid item xs={12} sm={4}>
                    <EmpPerfmTemp/>
                </Grid>
            </Grid>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Full Screen View
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        style={{ position: 'absolute', right: 16, top: 16 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {orgdetails ? (
                        <div style={styles.container}>
                            <img src={orgdetails.orgLogo} alt="Company Logo" style={styles.logo} />
                            <h3 style={{ fontSize: "16px" }}>{orgdetails.orgName}</h3>
                            <div style={styles.companyDetails}>
                                <p>+91 {orgdetails.orgMob}</p>
                                <p>{orgdetails.orgEmailId}</p>
                                <p>{orgdetails.orgUrl}</p>
                            </div>
                            <h3 style={styles.title}>{formData.header1}</h3>
                            <p style={styles.paragraph}>
                                {formData.para1}
                            </p>
                            <table style={styles.table}>
                                <tr>
                                    <td style={styles.tableCell}><srong>{formData.header2}</srong></td>
                                    <td style={styles.tableCellWide}>c4e2024adhithyan__p_a</td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell}><srong>{formData.header3}</srong></td>
                                    <td style={styles.tableCellWide}>Adhithyan P A</td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell}><srong>{formData.header4}</srong></td>
                                    <td style={styles.tableCellWide}>[Developer]</td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell}><srong>{formData.header5}</srong></td>
                                    <td style={styles.tableCellWide}>[TECHNICAL]</td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell}><srong>{formData.header6}</srong></td>
                                    <td style={styles.tableCellWide}>[amulya.s@care4edu.com]</td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell}><srong>{formData.header7}</srong></td>
                                    <td style={styles.tableCellWide}> </td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell}><srong>{formData.header8}</srong></td>
                                    <td style={styles.tableCellWide}> 20/08/2024</td>
                                </tr>
                            </table>
                            <table style={styles.table1}>
                                <tr>
                                    <td style={styles.table1head}><srong>{formData.header9}</srong></td>
                                    <td style={styles.table1sub}><srong>{formData.header10}</srong></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell1}>{formData.header11}</td>
                                    <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell1}>{formData.header12}</td>
                                    <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell1}>{formData.header13}</td>
                                    <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell1}>{formData.header14}</td>
                                    <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell1}>{formData.header15}</td>
                                    <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell1}>{formData.header16}</td>
                                    <td style={styles.tableCellWide1}><input type="text" style={styles.input} /></td>
                                </tr>
                            </table>
                            <table style={styles.table}>
                                <tr>
                                    <td style={styles.table1head}>{formData.header17}</td>
                                    <td style={styles.table1sub}>{formData.header10}</td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para2}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para3}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para4}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para5}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para6}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para7}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para8}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para9}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para10}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para11}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para12}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                                <tr>
                                    <td style={styles.tableCell2}>{formData.para13}</td>
                                    <td style={styles.tableCellWide2}><input type="text" style={styles.input} /></td>
                                </tr>
                            </table>
                            <h2 style={styles.header1}>{formData.header18}</h2>
                            <p style={styles.paragraph}>{formData.para14}</p>
                            <ul style={{
                                listStyleType: 'disc',
                                paddingLeft: '20px',
                            }}>
                                <li style={{ marginBottom: '8px' }}>
                                    {formData.para15}
                                </li>
                                <li style={{ marginBottom: '8px' }}>
                                    {formData.para16}
                                </li>
                                <li style={{ marginBottom: '20px' }}>
                                    {formData.para17}
                                </li>
                                <li style={{ marginBottom: '8px' }}>
                                    {formData.para18}
                                </li>
                                <li style={{ marginBottom: '20px' }}>
                                    {formData.para19}
                                </li>
                            </ul>

                            <h4 style={styles.header}>Goal 1:</h4>
                            <input type="text" style={styles.input1} />
                            <h3 style={styles.header}>Goal 2:</h3>
                            <input type="text" style={styles.input1} />
                            <h3 style={styles.header}>Goal 3:</h3>
                            <input type="text" style={styles.input1} />
                            <h3 style={styles.header}>Goal 4:</h3>
                            <input type="text" style={styles.input1} />
                            <h3 style={styles.header}>Goal 5:</h3>
                            <input type="text" style={styles.input1} />
                            <h3 style={styles.header}>Achievements:</h3>
                            <textarea style={styles.textarea} />
                            <h3 style={styles.header}>Employee Comments:</h3>
                            <textarea style={styles.textarea} />
                            <h3 style={styles.header}>Supervisor Comments:</h3>
                            <textarea style={styles.textarea} />

                            <h3 style={styles.signatureSection}>Signatures:</h3>
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                <div style={{ flex: 1, textAlign: 'center' }}>
                                    <p>Supervisor:</p>
                                    <p>Date: 20/08/2024</p>
                                    <p>(Sign and give form to reviewer.)</p>

                                </div>
                                <div style={{ flex: 1, textAlign: 'center' }}>
                                    <p>Employee:</p>
                                    <p>Date: 20/08/2024</p>
                                    <p>(Sign and return form to your supervisor within 48 hours of its receipt.)</p>

                                </div>

                            </div>
                        </div>
                    ) : (
                        <Typography variant="body1">Loading organization details...</Typography>
                    )}
                </DialogContent>
            </Dialog>
            </PageLayout>
        </Container>
    );
};

const styles = {
    container: {
        maxWidth: '700px',
        margin: 'auto',
        padding: '20px',
        backgroundColor: '#fff',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        marginBottom: '20px',
        textAlign: 'center',
        marginTop: '20px',

    },
    header: {
        fontSize: '14px',
        marginBottom: '10px',
    },
    header1: {
        fontSize: '18px',
        marginBottom: '10px',
    },
    logo: {
        width: '100px',
        height: 'auto',
    },
    paragraph: {
        marginBottom: '10px',
    },
    companyDetails: {
        fontSize: '14px',
        lineHeight: '1.6',
    },
    table: {
        borderCollapse: 'collapse',
        width: '100%',
        marginBottom: '20px',
        tableLayout: 'fixed',
    },
    tableCell: {
        width: '35%',
        padding: '10px',
        border: '1px solid #ccc',
        fontWeight: 700,
        wordWrap: 'break-word',

    },
    tableCellWide: {
        width: '65%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',

    },
    table1: {
        borderCollapse: 'collapse',
        width: '100%',
        marginBottom: '20px',
        marginTop: '20px',
        tableLayout: 'fixed',

    },
    tableCell1: {
        width: '80%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',

    },
    tableCellWide1: {
        width: '20%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',

    },
    table1head: {
        width: '80%',
        padding: '10px',
        border: '1px solid #ccc',
        fontWeight: 700,
        wordWrap: 'break-word',

    },
    table1sub: {
        width: '20%',
        padding: '10px',
        border: '1px solid #ccc',
        fontWeight: 700,
        wordWrap: 'break-word',

    },
    tableCell2: {
        width: '90%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',

    },
    tableCellWide2: {
        width: '10%',
        padding: '10px',
        border: '1px solid #ccc',
        wordWrap: 'break-word',

    },
    input: {
        width: '100%',
        height: '100%',
        padding: '0',
        margin: '0',
        border: '1px solid #ccc',
        boxSizing: 'border-box',
    },
    input1: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
    },
    textarea: {
        width: '100%',
        padding: '10px',
        border: '1px solid #ccc',
        height: '100px',
    },
    signatureSection: {
        marginTop: '40px',
        fontSize: '14px',
        marginBottom: '40px',

    },
};
export default EditEmpPerfm;
