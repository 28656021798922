// // prop-types is library for typechecking of props
// import PropTypes from "prop-types";

// // @mui material components
// import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// // Soft UI Dashboard React components
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";

// function StationaryInfoCard({ color, imageUrl, title, description, value }) {
//   return (
//     <Card >
//     <SoftBox
//       p={2}
//       mx={3}
//       display="flex"
//       justifyContent="center"
//       alignItems="center"
//       minHeight={100}
//     >
//         <img
//           src={imageUrl}
//           alt={title}
//           style={{
//             width: '4rem',
//             height: '4rem',
//             borderRadius: '8px',
//             backgroundColor: color,
//             boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
//           }}
//         />
//       </SoftBox>
//       <SoftBox pb={2} px={2} textAlign="center" lineHeight={1}>
//         <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize" >
//           {title}
//         </SoftTypography>
//         {description && (
//           <SoftTypography variant="caption" color="text" fontWeight="regular" mt={1}>
//             {description}
//           </SoftTypography>
//         )}
//         {description && !value ? null : <Divider />}
//         {value && (
//           <SoftTypography variant="h5" fontWeight="medium">
//             {value}
//           </SoftTypography>
//         )}
//       </SoftBox>
//     </Card>
//   );
// }

// // Setting default values for the props of StationaryInfoCard
// StationaryInfoCard.defaultProps = {
//   color: "info",
//   value: "",
//   description: "",
// };

// // Typechecking props for the StationaryInfoCard
// StationaryInfoCard.propTypes = {
//   color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
//   imageUrl: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string,
//   value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// };

// export default StationaryInfoCard;

import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

function StationaryInfoCard({ color, imageUrl, title, description, value }) {
  return (
    <Card
      style={{
        height: '200px', 
        overflow: 'hidden'
      }}
    >
      <SoftBox
        p={2}
        mx={3}
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight={100}
      >
        <img
          src={imageUrl}
          alt={title}
          style={{
            width: '4rem',
            height: '4rem',
            borderRadius: '8px',
            backgroundColor: color,
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', 
          }}
        />
      </SoftBox>
      <SoftBox pb={2} px={2} textAlign="center" lineHeight={1}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
        {description && (
          <SoftTypography variant="caption" color="text" fontWeight="regular" mt={1}>
            {description}
          </SoftTypography>
        )}
        {description && !value ? null : <Divider />}
        {value && (
          <SoftTypography variant="h5" fontWeight="medium">
            {value}
          </SoftTypography>
        )}
      </SoftBox>
    </Card>
  );
}

// Setting default values for the props of StationaryInfoCard
StationaryInfoCard.defaultProps = {
  color: "info",
  value: "",
  description: "",
};

// Typechecking props for the StationaryInfoCard
StationaryInfoCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  imageUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default StationaryInfoCard;