
import { Signin } from "authentication/APIData";
import axios from "axios";


export const postSigninRequest = async(newjson)=>{
    // console.log('addsrequest');
    const response = await Signin.post("login-type/login",newjson);
    // console.log(response.data);
    return response.data;
} 