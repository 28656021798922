import React, { useState, useEffect, useRef, useCallback } from 'react';
import html2canvas from 'html2canvas';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import axios from "axios";
import SoftBox from 'components/SoftBox';
import { Grid } from '@mui/material';
import IdCard1 from './idCard1';
import IdCard2 from './idCard2';
import { APIData, org } from 'authentication/APIData';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import IdCard5 from './idCard5';
import IdCard4 from './idCard4';
import IdCard3 from './idCard3';

function EmployeeIdCard() {
    const [selectedID, setSelectedID] = useState("");
    const [showBadge, setShowBadge] = useState(false);
    const [dob, setDob] = useState("");
    const [name, setName] = useState("");
    const [id, setId] = useState("");
    const [photo, setPhoto] = useState("");
    const [role, setRole] = useState("");
    const [orgName, setOrgName] = useState("");
    const [orgAddress, setOrgAddress] = useState("");
    const [logoUrl, setLogoUrl] = useState("");
    const [empdata, setEmpdata] = useState([]);
    const badgeRef1 = useRef();
    const badgeRef2 = useRef();
    const badgeRef3 = useRef();
    const badgeRef4 = useRef();
    const badgeRef5 = useRef();


    useEffect(() => {
        const fetchData = async () => {
            try {
                const url = `${APIData.api}employee/details?org=${org}`;
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                setEmpdata(data);
            } catch (err) {
                console.error(err);
            }
        };

        const fetchIcon = async () => {
            try {
                const response = await axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers });
                if (response.status === 200) {
                    const { orgLogo, orgName, orgAddress } = response.data;
                    setLogoUrl(orgLogo || "");
                    setOrgName(orgName || "");
                    setOrgAddress(orgAddress || "");
                } else {
                    console.error("No data found.");
                }
            } catch (error) {
                console.error("Error fetching icon:", error);
            }
        };

        fetchData();
        fetchIcon();
    }, []);

    const handleIDChange = (e) => {
        const id = e.value;
        setSelectedID(id);
        setShowBadge(false);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = `${APIData.api}employee/${selectedID}`;
        axios.get(url, { headers: APIData.headers })
            .then((response) => {
                setName(response.data.name);
                setDob(response.data.dob);
                setId(response.data.employee_id);
                setPhoto(response.data.photoUrl);
                setRole(response.data.access_profiles);
                setShowBadge(true);
            })
            .catch((err) => console.error(err));
    };

    const uniqueEmployeeIds = [...new Set(empdata.map(item => item.employee_id))];

    const downloadBadge = useCallback(async (badgeRef) => {
        if (badgeRef.current) {
            try {
                const canvas = await html2canvas(badgeRef.current, { scale: 2, useCORS: true });
                const dataUrl = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.download = `badge_${id}.png`;
                link.href = dataUrl;
                link.click();
            } catch (error) {
                console.error('Error generating badge:', error);
            }
        }
    }, [id]);

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <div className="carrybox" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <label className='Filter'><b></b></label>
                <SoftBox mb={2}>
                    <SoftTypography variant="h6">Select Employee Id</SoftTypography>
                    <CustomSelect
                        options={uniqueEmployeeIds.map(id => ({ value: id, label: id }))}
                        placeholder="Select Employee"
                        name="salesType"
                        onChange={handleIDChange}
                        value={uniqueEmployeeIds.find(id => id === selectedID) ? { value: selectedID, label: selectedID } : null}
                        required
                    />
                </SoftBox>
                <SoftBox mt={2} mb={1}>
                    <SoftButton onClick={handleSubmit} variant="gradient" color="info" fullWidth>
                        Generate id Card
                    </SoftButton>
                </SoftBox>
            </div>

            {showBadge && (
                <SoftBox mb={1.5}>
                    <Grid item xs={12} lg={8}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} xl={4}>
                                <div ref={badgeRef1} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
                                    <IdCard1
                                        name={name}
                                        dob={dob}
                                        id={id}
                                        photo={photo}
                                        role={role}
                                        orgName={orgName}
                                        orgAddress={orgAddress}
                                        logoUrl={logoUrl}
                                    />
                                </div>
                                <SoftBox mt={2} mb={1}>
                                    <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef1)}>Download ID Card 1</SoftButton>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <div ref={badgeRef2} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
                                    <IdCard2
                                        name={name}
                                        dob={dob}
                                        id={id}
                                        photo={photo}
                                        role={role}
                                        orgName={orgName}
                                        orgAddress={orgAddress}
                                        logoUrl={logoUrl}
                                    />
                                </div>
                                <SoftBox mt={2} mb={1}>
                                    <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef2)}>Download ID Card 2</SoftButton>
                                </SoftBox>
                            </Grid>
                            <Grid item xs={12} md={6} xl={4}>
                                <div ref={badgeRef3} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
                                    <IdCard3
                                        name={name}
                                        dob={dob}
                                        id={id}
                                        photo={photo}
                                        role={role}
                                        orgName={orgName}
                                        orgAddress={orgAddress}
                                        logoUrl={logoUrl}
                                    />
                                </div>
                                <SoftBox mt={2} mb={1}>
                                    <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef3)}>Download ID Card 3</SoftButton>
                                </SoftBox>
                            </Grid><Grid item xs={12} md={6} xl={4}>
                                <div ref={badgeRef4} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
                                    <IdCard4
                                        name={name}
                                        dob={dob}
                                        id={id}
                                        photo={photo}
                                        role={role}
                                        orgName={orgName}
                                        orgAddress={orgAddress}
                                        logoUrl={logoUrl}
                                    />
                                </div>
                                <SoftBox mt={2} mb={1}>
                                    <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef4)}>Download ID Card 4</SoftButton>
                                </SoftBox>
                            </Grid><Grid item xs={12} md={6} xl={4}>
                                <div ref={badgeRef5} style={{ display: 'inline-block', padding: '0', margin: '0' }}>
                                    <IdCard5
                                        name={name}
                                        dob={dob}
                                        id={id}
                                        photo={photo}
                                        role={role}
                                        orgName={orgName}
                                        orgAddress={orgAddress}
                                        logoUrl={logoUrl}
                                    />
                                </div>
                                <SoftBox mt={2} mb={1}>
                                    <SoftButton variant="gradient" color="secondary" onClick={() => downloadBadge(badgeRef5)}>Download ID Card 5</SoftButton>
                                </SoftBox>
                            </Grid>
                        </Grid>
                    </Grid>
                </SoftBox>
            )}
        </DashboardLayout>
    );
}

export default EmployeeIdCard;
