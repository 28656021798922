import React from 'react';
import logo from '../../assets/images/c4e.png';
import { Typography, Paper } from '@mui/material';


const EmpProof = () => {
    const styles = {
        container: {
            maxWidth: '700px',
            margin: 'auto',
            padding: '20px',
            backgroundColor: '#fff',
        },
        header1: {
            alignItems: 'center',
            paddingBottom: '10px',
            fontSize: '14px',
        },
        logo: {
            width: '100px',
            height: 'auto',
        },
        companyInfo: {
            textAlign: 'left',
            marginTop: '10px',
            fontSize: '14px',


        },
        content: {
            marginTop: '20px',
            fontSize: '14px',
        },
        title: {
            marginTop: '20px',
            fontSize: '20px',
            fontWeight: 'bold',

        },
        name: {
            marginTop: '20px',
            fontSize: '14px',
        },
        letterBody: {
            marginTop: '20px',
            fontSize: '14px',
        },
        bottom: {
            marginTop: '30px',
            textAlign: 'left',
            fontSize: '14px',
        },
    };

    return (
        <>
            <Typography variant="h5" gutterBottom>
            Defualt Template
            </Typography>
            <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>

                <div style={styles.container}>
                    <div style={styles.header1}>
                        <img src={logo} alt="Company Logo" style={styles.logo} />
                        <div style={styles.companyInfo}>
                            <h3>Care4Edu Solutions Pvt Ltd</h3>
                            <p>+91 7259785184</p>
                            <p>info@care4edu.com</p>
                            <p>www.care4edu.com</p>
                        </div>
                    </div>


                    <h2 style={styles.title}><center>Proof of Employment</center></h2>
                    <main style={styles.content}>
                        <strong>
                            <p>Date: 20/08/2024</p>
                            <p>To: Adhithyan P A</p>
                            <p>Address: VidyaranyaPura</p>
                        </strong>

                        <p style={styles.name}>Dear <strong>Adhithyan P A,</strong></p>
                        <p style={styles.letterBody}>
                            This letter is to confirm that <strong>Adhithyan P A</strong> has been employed with<strong> Care4Edu</strong> as a <strong>[Developer]</strong> since <strong>06/08/2024.</strong>
                        </p>
                        <p style={styles.letterBody}>
                            <strong>Adhithyan P A</strong>s current role involves <strong>[Brief Description of Responsibilities].</strong> They have been a valuable
                            member of our team and have consistently demonstrated professionalism, dedication, and strong work ethic.
                        </p>

                        <p style={styles.letterBody}>
                            As of the date of this letter, <strong>Adhithyan P A</strong> remains employed with <strong>Care4Edu</strong> and their employment status is
                            <strong> EMPLOYEE.</strong>
                        </p>

                        <p style={styles.letterBody}>Should you require any further information or clarification, please do not hesitate to contact us.</p>
                    </main>

                    <div style={styles.bottom}>
                        <p>Sincerely,</p>
                        <strong><p>Rishi</p>
                            <p>Superadmin</p>
                            <p>Care4Edu</p>
                            <p>7259785184</p></strong>
                    </div>
                </div>
            </Paper >

        </>
    );
};

export default EmpProof;
