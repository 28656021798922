import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Grid, CircularProgress, Backdrop, Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { APIData, org } from '../authentication/APIData';
import Swal from 'sweetalert2';
import axios from 'axios';
import * as AiIcons from "react-icons/ai";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';

const UpdateSales = () => {
    const { email } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [errors, setErrors] = useState({});
    const [commentInput, setCommentInput] = useState("");

    const months = [
        'JAN', 'FEB', 'MARCH', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];

    const salesTypes = [
        { value: 'LEAD', label: 'LEAD' },
        { value: 'PROSPECT', label: 'PROSPECT' },
        { value: 'OPPURTUNITY', label: 'OPPURTUNITY' }
    ];

    const [customerData, setCustomerData] = useState({
        customerName: '',
        customerEmail: '',
        org_name: '',
        customerMobileNum: '',
        customerAddress: '',
        customerGST: '',
        org: org,
        month: '',
        salesType: '',
        salesYear: '',
        comment: [],
        updated_by: '',
        updated_date_time: ''
    });

    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const response = await fetch(
                    `${APIData.api}customer-sales/emailId?emailId=${email}`,
                    { headers: APIData.headers }
                );
                const data = await response.json();
                setCustomerData({
                    ...data,
                    org: org,
                    updated_by: JSON.parse(localStorage.getItem("sessiondetails"))?.userName || '',
                    updated_date_time: getCurrentDateTimeString()
                });
                setLoading(false);
            } catch (error) {
                console.error('Error fetching customer data:', error);
                setLoading(false);
            }
        };

        fetchCustomerData();
    }, [email]);

    const getCurrentDateTimeString = () => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const date = String(currentDate.getDate()).padStart(2, '0');
        return `${year}-${month}-${date}`;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCustomerData(prev => ({
            ...prev,
            [name]: value
        }));
        setErrors(prev => ({ ...prev, [name]: '' }));
    };

    const handleSelectChange = (selectedOption) => {
        setCustomerData(prev => ({
            ...prev,
            salesType: selectedOption.value
        }));
        setErrors(prev => ({ ...prev, salesType: '' }));
    };

    const validateFields = () => {
        const newErrors = {};
        const requiredFields = [
            'customerName',
            'customerEmail',
            'org_name',
            'customerMobileNum',
            'customerAddress',
            'month',
            'salesType',
            'salesYear',
            'comment',
        ];

        requiredFields.forEach(field => {
            if (!customerData[field] && field !== 'comment') {
                newErrors[field] = 'This field is required';
            }
        });

        if (commentInput.trim() === "") {
            newErrors.comment = 'Comment is required';
        }

        if (customerData.customerEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(customerData.customerEmail)) {
            newErrors.customerEmail = 'Invalid email format';
        }

        if (customerData.customerMobileNum && !/^\d{10}$/.test(customerData.customerMobileNum)) {
            newErrors.customerMobileNum = 'Phone number must be 10 digits';
        }

        if (customerData.salesYear && !/^\d{4}$/.test(customerData.salesYear)) {
            newErrors.salesYear = 'Sales year must be a valid 4-digit year';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (!validateFields()) {
            Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: "Please fill in all required fields correctly.",
            });
            setLoading(false);
            return;
        }

        const updatedData = {
            ...customerData,
            comment: Array.isArray(customerData.comment)
                ? [...customerData.comment, commentInput.trim()]
                : [commentInput.trim()],
        };

        try {
            const response = await axios.put(APIData.api + `customer-sales/emailid`, updatedData, {
                headers: APIData.headers
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Updated successfully",
                    showConfirmButton: false,
                    timer: 1500
                });
                setTimeout(() => {
                    navigate('/customerSalesOnboard');
                }, 1500);
            } else {
                throw new Error('Update failed');
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to update customer data",
            });
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Grid item xs={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/customerSalesOnboard">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Update Customer Sales Details
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>
                                {/* Customer Name Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Customer Name *</SoftTypography>
                                    <SoftInput
                                        placeholder="Customer Name"
                                        name="customerName"
                                        value={customerData.customerName}
                                        onChange={handleInputChange}
                                        error={!!errors.customerName}
                                        required
                                    />
                                    {errors.customerName && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.customerName}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                {/* Customer Email Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Customer Email *</SoftTypography>
                                    <SoftInput
                                        type="email"
                                        placeholder="Customer Email"
                                        name="customerEmail"
                                        value={customerData.customerEmail}
                                        onChange={handleInputChange}
                                        error={!!errors.customerEmail}
                                        disabled
                                        required
                                    />
                                </SoftBox>

                                {/* Organization Name Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Organization Name *</SoftTypography>
                                    <SoftInput
                                        placeholder="Organization Name"
                                        name="org_name"
                                        value={customerData.org_name}
                                        onChange={handleInputChange}
                                        error={!!errors.org_name}
                                        required
                                    />
                                    {errors.org_name && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.org_name}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                {/* Phone Number Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Phone Number *</SoftTypography>
                                    <SoftInput
                                        placeholder="Phone Number"
                                        name="customerMobileNum"
                                        value={customerData.customerMobileNum}
                                        onChange={handleInputChange}
                                        error={!!errors.customerMobileNum}
                                        onKeyPress={(e) => {
                                            if (!/[0-9]/.test(e.key)) {
                                                e.preventDefault();
                                            }
                                        }}
                                        inputProps={{
                                            maxLength: 10,
                                            pattern: "[0-9]*",
                                            inputMode: "numeric"
                                        }}
                                        required
                                    />
                                    {errors.customerMobileNum && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.customerMobileNum}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                {/* Customer Address Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Customer Address *</SoftTypography>
                                    <SoftInput
                                        placeholder="Customer Address"
                                        name="customerAddress"
                                        value={customerData.customerAddress}
                                        onChange={handleInputChange}
                                        error={!!errors.customerAddress}
                                        required
                                    />
                                    {errors.customerAddress && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.customerAddress}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                {/* Customer GST Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Customer GST</SoftTypography>
                                    <SoftInput
                                        placeholder="Customer GST (Optional)"
                                        name="customerGST"
                                        value={customerData.customerGST}
                                        onChange={handleInputChange}
                                    />
                                </SoftBox>

                                {/* Month Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Month *</SoftTypography>
                                    <SoftInput
                                        select
                                        name="month"
                                        value={customerData.month}
                                        onChange={handleInputChange}
                                        error={!!errors.month}
                                        required
                                        disabled
                                    >
                                        {months.map((month, index) => (
                                            <option key={index} value={month}>{month}</option>
                                        ))}
                                    </SoftInput>
                                    {errors.month && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.month}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                {/* Sales Type Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Sales Type *</SoftTypography>
                                    <CustomSelect
                                        options={salesTypes}
                                        placeholder="Select Sales Type"
                                        name="salesType"
                                        onChange={handleSelectChange} 
                                        value={customerData.salesType}
                                        error={!!errors.salesType}
                                        required
                                    />
                                    {errors.salesType && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.salesType}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                {/* Sales Year Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Sales Year *</SoftTypography>
                                    <SoftInput
                                        placeholder="Sales Year (YYYY)"
                                        name="salesYear"
                                        value={customerData.salesYear}
                                        onChange={handleInputChange}
                                        error={!!errors.salesYear}
                                        required
                                    />
                                    {errors.salesYear && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.salesYear}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                {/* Comments Field */}
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Add New Comment *</SoftTypography>
                                    <SoftInput
                                        placeholder="Add a comment"
                                        value={commentInput}
                                        onChange={(e) => setCommentInput(e.target.value)}
                                        multiline
                                        rows={3}
                                    />
                                    {errors.comment && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.comment}
                                        </SoftTypography>
                                    )}
                                </SoftBox>
                                {Array.isArray(customerData.comment) && customerData.comment.length > 0 && (
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6" fontWeight="small">Previous Comments</SoftTypography>
                                        {customerData.comment.map((comment, index) => (
                                            <SoftBox
                                                key={index}
                                                p={1}
                                                mb={1}
                                                style={{
                                                    backgroundColor: '#f8f9fa',
                                                    borderRadius: '8px'
                                                }}
                                            >
                                                <SoftTypography variant="h6">{comment}</SoftTypography>
                                            </SoftBox>
                                        ))}
                                    </SoftBox>
                                )}


                                <SoftBox mt={2}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        Update Sales
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Footer />
        </DashboardLayout>
    );
};

export default UpdateSales;

