import React, { useEffect, useRef, useState } from 'react';
import { Stepper, Step, StepLabel, Container, Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useNavigate } from 'react-router-dom';
import DetailUpdate from './DetailUpdate';
import AddEmails from './AddEmails';
import DepartmentSpoc from './DeptSpoc';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import EmailIcon from '@mui/icons-material/Email';
import PropTypes from 'prop-types';
import SoftButton from 'components/SoftButton';
import EmailTempselect from './EmailTemplate';
import IdCard from './Idcard';


const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 14,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 2,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme }) => ({
    backgroundColor: '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 28,
    height: 28,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
}));

function ColorlibStepIcon(props) {
    const { active, completed, icon } = props;
    const icons = {
        1: <SettingsIcon />,
        2: <VideoLabelIcon />,
        3: <GroupAddIcon />,
        4: <EmailIcon />,
        5: <AssignmentTurnedInIcon />,
    };

    return (
        <ColorlibStepIconRoot>
            {icons[String(icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    active: PropTypes.bool,
    completed: PropTypes.bool,
    icon: PropTypes.node.isRequired,
};

const FixedStepperContainer = styled(Box)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    padding: '10px',
    height: "150px",
    backgroundColor: "white"
}));

const ContentContainer = styled(Box)(({ theme }) => ({
    marginTop: '150px',
    padding: '10px',
    width: '100%'
}));

const FinalReview = ({ }) => (
    <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2 }}>
        <Typography variant="h5" gutterBottom>Final Review</Typography>
        <Typography variant="body1" paragraph>
            Please review all the information you have provided before final submission.
        </Typography>
    </Box>
);

const steps = [ 'Organization Details',
    'Emails Notification settings',
    'Spoc for specific Department',
    'Letter templates',
     'ID card templates'];

const StepperForm = () => {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(() => {
        const savedStep = localStorage.getItem('stepperActiveStep');
        // Parse hash instead of search params
        const hash = location.hash;
        const stepMatch = hash.match(/step=(\d+)/);
        const stepParam = stepMatch ? stepMatch[1] : null;
        return stepParam ? parseInt(stepParam) : savedStep ? parseInt(savedStep) : 0;
    });
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [stepValidation, setStepValidation] = useState(() => {
        const savedValidation = localStorage.getItem('stepValidation');
        return savedValidation ? JSON.parse(savedValidation) : {
            0: false,
            1: false,
            2: false,
            3: false,
            4: false
        };
    });
    const [formData, setFormData] = useState(() => {
       
        const savedData = localStorage.getItem('stepperFormData');
        return savedData ? JSON.parse(savedData) : {
            details: null,
            emails: null,
            spoc: null,
            emailTemplate: null
        };
    });

    useEffect(() => {
        localStorage.setItem('stepperActiveStep', activeStep);
        localStorage.setItem('stepValidation', JSON.stringify(stepValidation));
        localStorage.setItem('stepperFormData', JSON.stringify(formData));
        
        // Update URL with hash instead of search params
        const currentHash = location.hash.split('?')[0] || '#';
        const newHash = `${currentHash}${currentHash.includes('?') ? '&' : '?'}step=${activeStep}`;
        navigate(newHash, { replace: true });
    }, [activeStep, stepValidation, formData]);

    const handleExternalNavigation = (path) => {
        localStorage.setItem('stepperActiveStep', '3');
        // Add hash to the path if it doesn't have one
        const hashPath = path.startsWith('#') ? path : `#${path}`;
        navigate(hashPath);
    };


    const detailUpdateRef = useRef(null);
    const addEmailsRef = useRef(null);
    const departmentSpocRef = useRef(null);
    const emailTemplateRef = useRef(null);

    const handleNext = async () => {
        setIsSubmitting(true);
        if (stepValidation[activeStep]) {
            setActiveStep((prev) => prev + 1);
            setIsSubmitting(false);
            return;
        }

        if (activeStep === steps.length - 1) {
            // Handle final submission
            setShowSuccessMessage(true);
            setIsSubmitting(false);
            return;
        }

        switch (activeStep) {
            case 0:
                if (detailUpdateRef.current) {
                    detailUpdateRef.current.click();
                }
                break;
            case 1:

                if (addEmailsRef.current) {
                    addEmailsRef.current.click();
                }

            case 2:
                if (departmentSpocRef.current) {
                    departmentSpocRef.current.click();
                }
                break;
            case 3:


                if (emailTemplateRef.current) {
                    emailTemplateRef.current.click();
                }
                break;

        }

        setIsSubmitting(false);
    };

    const handleBack = () => {
        setActiveStep((prev) => prev - 1);
    };

    const handleDetailUpdateComplete = (success, data) => {
        setIsSubmitting(false);
        if (success) {
            setFormData(prev => ({ ...prev, details: data }));
            setStepValidation(prev => ({ ...prev, 0: true }));
            setActiveStep((prev) => prev + 1);
        }
    };

    const handleEmailSubmissionComplete = (success, data) => {
        setIsSubmitting(false);
        if (success) {
            setFormData(prev => ({ ...prev, emails: data }));
            setStepValidation(prev => ({ ...prev, 1: true }));
            setActiveStep((prev) => prev + 1);
        }
    };

    const handleDepartmentSpocComplete = (success, data) => {
        setIsSubmitting(false);
        if (success) {
            setFormData(prev => ({ ...prev, spoc: data }));
            setStepValidation(prev => ({ ...prev, 2: true }));
            setActiveStep((prev) => prev + 1);
        }
    };

    const handleEmailTemplateComplete = (success, data) => {
        setIsSubmitting(false);
        if (success) {
            setFormData(prev => ({ ...prev, emailTemplate: data }));
            setStepValidation(prev => ({ ...prev, 3: true }));
            setActiveStep((prev) => prev + 1);
        }
    };

    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return (
                    <DetailUpdate
                        onSubmit={handleDetailUpdateComplete}
                        submitRef={detailUpdateRef}
                    />
                );
            case 1:
                return (

                    <AddEmails
                        onSubmit={handleEmailSubmissionComplete}
                        submitRef={addEmailsRef}
                    />

                );

            case 2:
                return (
                    <DepartmentSpoc
                        onSubmit={handleDepartmentSpocComplete}
                        submitRef={departmentSpocRef}
                    />
                );
            case 3:
                return (

                    <EmailTempselect
                        onSubmit={handleEmailTemplateComplete}
                        submitRef={emailTemplateRef}
                        onExternalNavigation={handleExternalNavigation}
                    />
                );
                case 4:
                    return (
    
                        <IdCard/>
                    );
            default:
                return 'Unknown step';
        }
    };

    return (
        <Box>

            <FixedStepperContainer>

                <Stepper activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </FixedStepperContainer>

            <ContentContainer>
                <Container>
                    {showSuccessMessage ? (
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '60vh',
                                textAlign: 'center',
                                backgroundColor: '#f0f0f0',
                                padding: '20px',
                                borderRadius: '10px',
                                boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.2)',
                            }}
                        >
                            <Typography variant="h4" color="success.main" sx={{ marginBottom: '20px', fontWeight: 'bold' }}>
                                Initial Setup Successfully Completed!
                            </Typography>
                            <SoftButton
                                onClick={() => { navigate('/dashboard'); }}
                                variant="gradient"
                                color="info"
                                sx={{ padding: '10px 20px', borderRadius: '8px' }}
                            >
                                Done
                            </SoftButton>
                        </Box>
                    ) : (
                        <>
                            {getStepContent(activeStep)}
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                                <SoftButton
                                    disabled={activeStep === 0}
                                    onClick={handleBack}
                                    variant="gradient"
                                    color="info"
                                >
                                    Back
                                </SoftButton>
                                <SoftButton
                                    onClick={handleNext}
                                    variant="gradient"
                                    color="info"
                                    disabled={isSubmitting}
                                >
                                    {activeStep === steps.length - 1 ? 'Submit' : 'Next'}
                                </SoftButton>
                            </Box>
                        </>
                    )}
                </Container>
            </ContentContainer>
        </Box>
    );
};

export default StepperForm;