// ChatBot.js
import React, { useState, useEffect } from 'react';
import { MessageCircle, X } from 'lucide-react';
import './ChatBot.css';
import Chat from './Chat';

const ChatBot = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  // Show tooltip after 3 seconds
  useEffect(() => {
    const timer = setTimeout(() => setShowTooltip(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  // Close tooltip manually
  const handleCloseTooltip = () => setShowTooltip(false);

  return (
    <div className="app-container">
      <button
        className={`chat-trigger-button ${showTooltip ? 'highlighted' : ''}`}
        onClick={() => {
          setIsModalOpen(true);
          setShowTooltip(false); // hide tooltip when button is clicked
        }}
      >
        <MessageCircle size={24} />
      </button>

      <Chat isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
      
      {showTooltip && (
        <div className="tooltip">
          <p>Hey, I am Drona. Need any help?</p>
          <button className="tooltip-close-button" onClick={handleCloseTooltip}>
            <X size={16} />
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatBot;