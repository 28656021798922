import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const customSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#17c1e8' : '#d2d6da',
    boxShadow: state.isFocused ? '0 0 0 2px #17c1e8' : 'none',
    '&:hover': {
      borderColor: '#17c1e8'
    },
    minHeight: '32px',
    height: '32px',
    borderRadius: '8px',
    fontSize: '0.875rem'
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: '32px',
    padding: '0 8px'
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0'
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#7b809a',
    fontSize: '0.875rem'
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '0.875rem'
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: '0.875rem',
    padding: '6px 12px',
    backgroundColor: state.isSelected ? '#1a73e8' : state.isFocused ? '#abc9eb' : 'white',
    color: state.isSelected ? 'white' : '#344767',
    '&:hover': {
      backgroundColor: state.isSelected ? '#1a73e8' : '#abc9eb'
    }
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '4px',
    fontSize: '0.875rem'
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 9999
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '4px'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    margin: '4px 0'
  })
};

const CustomSelect = ({
  options,
  placeholder,
  name,
  onChange,
  isSearchable = true,
  isClearable = false,
  isMulti = false,
  isDisabled = false,
  value = null,
  error = false,
  customStyles = {},
  ...props
}) => {
  const selectedOption = value ? options.find(option => option.value === value) : null;

  return (
    <Select
      options={options}
      styles={{ ...customSelectStyles, ...customStyles }}
      placeholder={placeholder}
      name={name}
      onChange={(selectedOption, actionMeta) => {
        onChange(selectedOption, { ...actionMeta, name });
      }}
      isSearchable={isSearchable}
      isClearable={isClearable}
      isMulti={isMulti}
      isDisabled={isDisabled}
      value={selectedOption}
      className={error ? 'select-error' : ''}
      menuPortalTarget={document.body}
      {...props}
    />
  );
};

CustomSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]).isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.null
  ]),
  error: PropTypes.bool,
  customStyles: PropTypes.object,
};

CustomSelect.defaultProps = {
  placeholder: '',
  isSearchable: true,
  isClearable: false,
  isMulti: false,
  isDisabled: false,
  value: null,
  error: false,
  customStyles: {},
};

export default CustomSelect;