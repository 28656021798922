import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Card, Typography, ToggleButton, ToggleButtonGroup } from '@mui/material';
import StationaryCard from 'examples/Cards/StationaryCards/stationaryCard';

const MyStationery = ({ stationary, className }) => {
  const [statusFilter, setStatusFilter] = useState("approved"); // State to track the selected filter

  const handleStatusChange = (event, newStatus) => {
    if (newStatus) {
      setStatusFilter(newStatus);
    }
  };

  // Filter stationary items based on the selected status
  const filteredStationary = stationary.filter(item => {
    return (statusFilter === "approved" && item.status === "Approved") ||
      (statusFilter === "rejected" && item.status === "Rejected") ||
      (statusFilter === "returned" && item.status === "Returned") ||
      (statusFilter === "requested" && item.status === "Requested");
  });

  return (
    <Container className={className}>
      <Container sx={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
        <ToggleButtonGroup value={statusFilter} exclusive onChange={handleStatusChange}>
          <ToggleButton value="approved" sx={{ border: '1px solid #007a99' }}>
            Approved
          </ToggleButton>
          <ToggleButton value="rejected" sx={{ border: '1px solid #007a99' }}>
            Rejected
          </ToggleButton>
          <ToggleButton value="requested" sx={{ border: '1px solid #007a99' }}>
            Requested
          </ToggleButton>
        </ToggleButtonGroup>
      </Container>

      <Container
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '1rem',
          padding: '2rem',
        }}
      >
        {filteredStationary.length === 0 ? (
          <Typography
            sx={{
              marginTop: '1rem',
              color: '#00a3cc',
              textAlign: 'center',
              gridColumn: '1 / -1',
            }}
          >
            No Items
          </Typography>
        ) : (
          filteredStationary.map((item) => (
            <StationaryCard
              key={item.id}
              stationaryId={item.stationaryId}
              stationaryName={item.stationaryName}
              status={item.status}
              statusColor={
                item.status === "Approved" ? "success" :
                  item.status === "Rejected" ? "error" :
                    item.status === "Requested" ? "warning" : "warning"
              }
              count={item.count}
              empId={item.empId}
              empEmail={item.empEmail}
              showApprovalButtons={false}
            />
            // <Card
            //   key={item.id}
            //   sx={{
            //     backgroundColor: '#f7f9fc',
            //     borderRadius: '10px',
            //     boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            //     padding: '1.5rem',
            //     display: 'flex',
            //     flexDirection: 'column',
            //     wordWrap: 'break-word',
            //     overflowWrap: 'break-word',
            //     wordBreak: 'break-word',
            //   }}
            // >
            //   <Typography
            //     sx={{
            //       margin: 0,
            //       padding: '0.5rem',
            //       borderRadius: '5px',
            //       backgroundColor: '#e0f7fa',
            //       fontSize: '1.4rem',
            //       textAlign: 'center',
            //       color: '#007a99',
            //       fontWeight: 'bold',
            //     }}
            //   >
            //     Item ID: {item.stationaryId}
            //   </Typography>

            //   <div style={{ display: 'flex', flexDirection: 'column', padding: '0.5rem 0', gap: '0.5rem' }}>
            //     {[
            //       { label: 'Name', value: item.stationaryName },
            //       { label: 'Status', value: item.status },
            //       { label: 'Count', value: item.count },
            //       { label: 'EmpId', value: item.empId },
            //       { label: 'EmpMail', value: item.empEmail },
            //     ].map(({ label, value }, index) => (
            //       <div
            //         key={index}
            //         style={{
            //           display: 'flex',
            //           justifyContent: 'space-between',
            //           fontSize: '1.1rem',
            //           padding: '0.3rem 0',
            //           flexWrap: 'wrap',
            //           wordBreak: 'break-word',
            //           overflowWrap: 'break-word',
            //         }}
            //       >
            //         <span style={{ color: '#003380', fontWeight: '600', flex: '1' }}>{label}:</span>
            //         <span
            //           style={{
            //             color: '#001433',
            //             fontWeight: '500',
            //             textAlign: 'center',
            //             flex: '2',
            //             wordBreak: 'break-word',
            //             overflowWrap: 'break-word',
            //           }}
            //         >
            //           {value}
            //         </span>
            //       </div>
            //     ))}
            //   </div>
            // </Card>
          ))
        )}
      </Container>
    </Container>
  );
};

MyStationery.propTypes = {
  stationary: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      stationaryId: PropTypes.string.isRequired,
      stationaryName: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
      empId: PropTypes.string.isRequired,
      empEmail: PropTypes.string.isRequired,
    })
  ).isRequired,
  className: PropTypes.string,
};

export default MyStationery;
