import Landing2 from 'Home/Landing2';
import React from 'react'
import { Outlet } from 'react-router-dom'


export const authenticateUser = ()=>{
    const access = ["SUPERADMIN", "EMPLOYEE", "MEMBER", "ORGADMIN","USER"]
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
  return sessionDetails && access.includes(sessionDetails.userType) ? true : false;
}

const OrgMember = () => {
  return (
    <>
    {
      authenticateUser()? 
      <>
        <Outlet/>
      </>
      :
      <>
        <Landing2/>
      </>
    }
    </>
  )
}

export default OrgMember;