import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
    Grid,
    Card,
    Backdrop,
    CircularProgress,
} from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import { APIData, org } from '../authentication/APIData';
import { MdCloudUpload } from 'react-icons/md';
import Swal from "sweetalert2";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Resume from "user/Resume.jpg"
import PageLayout from "examples/LayoutContainers/PageLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

const UploadResume = () => {
    const [resumeFile, setResumeFile] = useState(null);
    const [resumePreview, setResumePreview] = useState(null); 
    const candemail = localStorage.getItem('candidateEmail');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleUpload = async (e) => {
        setLoading(true);
        e.preventDefault();
        try {
            const formData = new FormData();
            formData.append('resume_url', resumeFile);
            formData.append('email', candemail);
            formData.append('org', org);

            const response = await axios.post(APIData.api + 'resumes/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...APIData.headers
                }
            });

            console.log('Response:', response);
            Swal.fire({
                icon: "success",
                title: "Resume uploaded successfully",
                showConfirmButton: false,
                timer: 1500
            });
            setTimeout(() => {
                navigate('/');
            }, 1500);
        } catch (error) {
            console.error('Error:', error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to upload resume",
                footer: error.message
            });
        } finally {
            setLoading(false);
        }
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const maxSizeInBytes = 1 * 1024 * 1024; // 1 MB in bytes
    
        if (file && file.size > maxSizeInBytes) {
            Swal.fire({
                icon: "error",
                title: "File too large",
                text: "The file size exceeds 1MB. Please upload a smaller file.",
            });
            setResumeFile(null); // Reset the file state
            setResumePreview(null); // Reset the preview
            return;
        }
    
        setResumeFile(file);
    
        if (file && file.type === 'application/pdf') {
            setResumePreview(URL.createObjectURL(file));
        } else {
            setResumePreview(null);
        }
    };
    

    return (
        <PageLayout>
            <DefaultNavbar/>
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4">
                <Grid item xs={10} sm={10} md={8} lg={6} mt={12}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1}>
                            <SoftTypography variant="h5" fontWeight="medium" textAlign="center">
                                Upload Your Resume
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={2} pb={3} px={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                                        <SoftTypography variant="h6" align="center" mb={4} >
                                            Upload Your Resume
                                        </SoftTypography>
                                        <input
                                            type="file"
                                            onChange={handleFileChange}
                                            accept=".pdf,.doc,.docx,.txt"
                                            style={{ display: 'none' }}
                                            id="upload-file"
                                        />
                                        <label htmlFor="upload-file">
                                            <SoftButton
                                                component="span"
                                                variant="gradient"
                                                color="info"
                                                style={{ marginBottom: '1rem' }}
                                                iconOnly
                                            >
                                                <MdCloudUpload size={24} />
                                            </SoftButton>
                                        </label>
                                        {resumeFile && (
                                            <SoftTypography variant="body2" color="text" mt={1} textAlign="center">
                                                Selected file: {resumeFile.name}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <SoftBox mb={2} display="flex" justifyContent="center">
                                        {resumePreview ? (
                                            <iframe
                                                src={resumePreview}
                                                title="PDF Preview"
                                                width="100%"
                                                height="200px"
                                                style={{ borderRadius: '8px' }}
                                            ></iframe>
                                        ) : (
                                            <img
                                                src={Resume}
                                                alt="resume upload"
                                                style={{ maxWidth: '80%', height: 'auto', borderRadius: '8px' }}
                                            />
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <Card
                                        sx={{
                                            bgcolor: 'info.main',
                                            color: 'white',
                                            p: 3,
                                            mt: 2
                                        }}
                                    >
                                        <SoftBox>
                                            <SoftTypography variant="h6" color="white" mb={2}>
                                                Important Guidelines
                                            </SoftTypography>
                                            <SoftTypography variant="body2" color="white" mb={1}>
                                                • Ensure your resume is up-to-date and clearly highlights your skills and experiences.
                                            </SoftTypography>
                                            <SoftTypography variant="body2" color="white" mb={1}>
                                                • Upload your resume and we will make it easier to complete your profile.
                                            </SoftTypography>
                                            <SoftTypography variant="body2" color="white" mb={1}>
                                                • Accepted formats: .pdf, .doc, .docx, .txt
                                            </SoftTypography>
                                            <SoftTypography variant="body2" color="white">
                                                • Max Size: 1 MB
                                            </SoftTypography>
                                        </SoftBox>
                                    </Card>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox mt={4} mb={1}>
                                        <SoftButton
                                            variant="gradient"
                                            color="info"
                                            fullWidth
                                            onClick={handleUpload}
                                            disabled={!resumeFile || loading}
                                        >
                                            {loading ? "Uploading..." : "Upload Resume"}
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            </PageLayout>
    );
};

export default UploadResume;
