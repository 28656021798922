import React, { useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Swal from "sweetalert2";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { APIData, org } from 'authentication/APIData';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Grid, Tooltip } from "@mui/material";
import * as AiIcons from "react-icons/ai";

function ProcurementRequest() {
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const [assetName, setAssetName] = useState("");
    const [description, setDescription] = useState("");
    const [assetListType, setAssetListType] = useState("");
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);

    const postEmployeeAssetRequest = () => {
        const employeeAssetData = {
            approved_by: "",
            asset_name: assetName,
            asset_description: description,
            emp_id: sessiondetails.userName,
            type: assetListType,
            status: "Initiated",
            procured_date: "",
            renewed_date: "",
            id: 0,
            org: org
        };
        console.log(employeeAssetData);
        

        const url = `${APIData.api}procurement/`;
        axios.post(url, employeeAssetData, { headers: APIData.headers })
            .then((resp) => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Requested",
                    showConfirmButton: false,
                    timer: 1500
                });
                navigate("/procurement-list");
            })
            
            .catch((error) => {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Failed to request for allocation",
                    footer: error.message || error.response.data
                });

            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        postEmployeeAssetRequest();
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={10} sm={10} md={8} lg={6}>
            <SoftBox p={3}>
                <Card className="w-full">
                    <CardContent>
                        <Tooltip  title="Close" arrow>
                            <Link to="/procurement-list">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                        </Tooltip>
                        <SoftTypography variant="h5" fontWeight="medium" mb={3} style={{ flex: 1, textAlign: 'center' }}>
                            New Procurement Request
                        </SoftTypography>
                        <form onSubmit={handleSubmit}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="body2" fontWeight="medium">Asset Name</SoftTypography>
                                <SoftInput
                                    type="text"
                                    value={assetName}
                                    onChange={(e) => setAssetName(e.target.value)}
                                    placeholder="Enter asset name"
                                    required
                                />
                            </SoftBox>
                            <SoftBox mb={2}>
                                <SoftTypography variant="body2" fontWeight="medium">Description</SoftTypography>
                                <SoftInput
                                    type="text"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Enter description"
                                    required
                                />
                            </SoftBox>
                            <SoftBox mb={2}>
                                <SoftTypography variant="body2" fontWeight="medium">Asset Type</SoftTypography>
                                <select 
                                    value={assetListType} 
                                    onChange={(e) => setAssetListType(e.target.value)} 
                                    required
                                    style={{
                                        width: "100%",
                                        padding: "10px",
                                        borderRadius: "4px",
                                        border: "1px solid #ccc"
                                    }}
                                >
                                    <option value="" disabled>Select asset type</option>
                                    <option value="Hardware">Hardware</option>
                                    <option value="Software">Software</option>
                                    <option value="Stationary">Stationary</option>
                                </select>
                            </SoftBox>
                            <SoftButton type="submit" variant="gradient" color="info" fullWidth disabled={loading}>
                                {loading ? "Submitting..." : "Submit Request"}
                            </SoftButton>
                        </form>
                    </CardContent>
                </Card>
            </SoftBox>
            </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default ProcurementRequest;
