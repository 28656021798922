import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftButton from 'components/SoftButton';
import { APIData, org } from '../authentication/APIData';
import Swal from 'sweetalert2';
import SoftInput from 'components/SoftInput';
import { Grid, Card, Backdrop, CircularProgress } from '@mui/material';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import axios from 'axios';

const ApproveRequest = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [passwordRequest, setPasswordRequest] = useState(null);
    const [status, setStatus] = useState('');
    const [rejectReason, setRejectReason] = useState('');
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const options = [
        { value: 'approved', label: 'Approve' },
        { value: 'rejected', label: 'Reject' }
    ];

    useEffect(() => {
        setPasswordRequest(location.state);
    }, [location.state]);

    const handleStatusChange = (newStatus) => {
        setStatus(newStatus.value);
        // Clear reject reason when status changes to approved
        if (newStatus.value === 'approved') {
            setRejectReason('');
            setErrors({ ...errors, rejectReason: '' });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        
        if (!status) {
            newErrors.status = "Status is required";
        }
        
        if (status === 'rejected' && !rejectReason.trim()) {
            newErrors.rejectReason = "Rejection reason is required";
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }
    
        const sendstate = {
            id: passwordRequest.id,
            title: passwordRequest.title,
            reason: passwordRequest.reason,
            requested_by: passwordRequest.requested_by,
            authorised_by: passwordRequest.authorised_by,
            status: status,
            requested_at: passwordRequest.requested_at,
            authorised_at: new Date().toISOString().slice(0, 10), 
            supervisor_id: passwordRequest.supervisor_id,
            supervisor_email: passwordRequest.supervisor_email,
            reject_reason: status === 'rejected' ? rejectReason : "",
            requested_email: passwordRequest.requested_email,
            org: passwordRequest.org
        };
        
        setLoading(true);
    
        try {
            const url = `${APIData.api}passwordaccess/update?id=${passwordRequest.id}`;
            const response = await axios.put(url, sendstate, {
                headers: APIData.headers
            });
    
            if (response.status === 200) {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Password request updated successfully',
                    confirmButtonColor: '#3085d6'
                });
                navigate('/requested-password');
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: response.data.message || 'Failed to update password request',
                    confirmButtonColor: '#3085d6'
                });
            }
        } catch (error) {
            console.error('Error updating password request:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to update password request. Please try again.',
                confirmButtonColor: '#3085d6'
            });
        } finally {
            setLoading(false);
        }
    };

    if (!passwordRequest) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SoftBox py={3}>
                    <SoftTypography variant="h6">Loading...</SoftTypography>
                </SoftBox>
                <Footer />
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "80vh" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} sm={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/requested-password">
                                <AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Update Password Request Status
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Requested By</SoftTypography>
                                    <SoftInput
                                        placeholder="Requested By"
                                        name="requested_by"
                                        value={passwordRequest.requested_by}
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Reason</SoftTypography>
                                    <SoftInput
                                        placeholder="Reason"
                                        name="reason"
                                        value={passwordRequest.reason}
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Current Status</SoftTypography>
                                    <SoftInput
                                        placeholder="Current Status"
                                        name="status"
                                        value={passwordRequest.status}
                                        disabled
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Update Status</SoftTypography>
                                    <CustomSelect
                                        options={options}
                                        placeholder="Select Status"
                                        name="status"
                                        value={options.find(option => option.value === status)}
                                        onChange={handleStatusChange}
                                        error={!!errors.status}
                                    />
                                    {errors.status && (
                                        <SoftTypography variant="caption" color="error">
                                            {errors.status}
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                {status === 'rejected' && (
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">Rejection Reason</SoftTypography>
                                        <SoftInput
                                            placeholder="Enter reason for rejection"
                                            name="rejectReason"
                                            value={rejectReason}
                                            onChange={(e) => setRejectReason(e.target.value)}
                                            error={!!errors.rejectReason}
                                        />
                                        {errors.rejectReason && (
                                            <SoftTypography variant="caption" color="error">
                                                {errors.rejectReason}
                                            </SoftTypography>
                                        )}
                                    </SoftBox>
                                )}

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        {loading ? 'Updating...' : 'Update Password Request'}
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Footer />
        </DashboardLayout>
    );
};

export default ApproveRequest;