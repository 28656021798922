import React from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import { FaBoxOpen } from "react-icons/fa";
import SoftButton from "components/SoftButton";

function ProcurementCard({ 
  assetName, 
  employeeId, 
  description, 
  type, 
  status, 
  onApprove, 
  onReject,
  tabValue
}) {
    const showApprovalButtons = tabValue === 2;
  return (
    <Card 
      sx={{ 
        padding: "20px", 
        borderRadius: "12px", 
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", 
        backgroundColor: "#fff",
        height: "100%",
        display: "flex",
        flexDirection: "column"
      }}
    >
      <div style={{ 
        display: "flex", 
        justifyContent: "space-between", 
        alignItems: "center" 
      }}>
        <h2 style={{ 
          margin: "0", 
          color: "#333",
          fontSize: "1.25rem",
          fontWeight: "600" 
        }}>
          {assetName}
        </h2>
        <div style={{ color: "#007bff" }}>
          <FaBoxOpen size={36} />
        </div>
      </div>

      <div style={{ flex: 1 }}>
        <InfoRow label="Employee ID" value={employeeId} />
        <InfoRow label="Description" value={description} />
        <InfoRow label="Type" value={type} />
        <InfoRow 
          label="Status" 
          value={status} 
          valueStyle={{ 
            color: status === "Initiated" ? "#28a745" : "#dc3545" 
          }} 
        />
      </div>

      {showApprovalButtons && (
        <div style={{ 
          display: "flex", 
          justifyContent: "space-between", 
          marginTop: "10px", 
          gap: "12px" 
        }}>
          <SoftButton 
            onClick={onApprove} 
            sx={{ 
              flex: 1,
              backgroundColor: "#28a745", 
              color: "#fff",
              "&:hover": {
                backgroundColor: "#218838"
              }
            }}
          >
            Approve
          </SoftButton>
          <SoftButton 
            onClick={onReject} 
            sx={{ 
              flex: 1,
              backgroundColor: "#dc3545", 
              color: "#fff",
              "&:hover": {
                backgroundColor: "#c82333"
              }
            }}
          >
            Reject
          </SoftButton>
        </div>
      )}
    </Card>
  );
}

// Updated InfoRow component without space between label and value
const InfoRow = ({ label, value, valueStyle = {} }) => (
  <p style={{ 
    margin: "8px 0", 
    fontSize: "14px", 
    color: "#555"
  }}>
    <strong>{label}</strong>: {value}
  </p>
);

ProcurementCard.propTypes = {
  assetName: PropTypes.string.isRequired,
  employeeId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  tabValue: PropTypes.number.isRequired,
};

InfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valueStyle: PropTypes.object,
};

export default ProcurementCard;
