import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { Grid, Card } from '@mui/material';
import { APIData } from 'authentication/APIData';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import * as AiIcons from "react-icons/ai";
import Swal from "sweetalert2";
import PageLayout from 'examples/LayoutContainers/PageLayout';

const ViewDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [ticket, setTicket] = useState(null);
    const [status, setStatus] = useState('');

    useEffect(() => {
        const fetchTicketDetails = async () => {
            try {
                const response = await fetch(`${APIData.api}Ticket/${id}`, {
                    headers: APIData.headers,
                });
                const data = await response.json();
                console.log("Image URL:", data.image_url);  // Log image URL
                setTicket(data);
                setStatus(data.status);
            } catch (error) {
                console.error('Error fetching ticket details:', error);
            }
        };

        fetchTicketDetails();
    }, [id]);

    if (!ticket) return <SoftTypography>Loading ticket details...</SoftTypography>;

    return (
        <PageLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Grid item xs={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/view-tickets">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Edit Ticket #{ticket.ticket_id}
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox pt={1} pb={3} px={3}>
                            <form>
                                <SoftBox mb={2} display="flex" justifyContent="center">
                                    <img
                                        src={ticket.image_url || "https://via.placeholder.com/150"}  // Placeholder image
                                        alt="Ticket"
                                        style={{ width: '150px', height: '150px', borderRadius: '8px' }}
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Name</SoftTypography>
                                    <SoftInput value={ticket.name} disabled />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Email</SoftTypography>
                                    <SoftInput value={ticket.emailid} disabled />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Product</SoftTypography>
                                    <SoftInput value={ticket.product} disabled />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Phone</SoftTypography>
                                    <SoftInput value={ticket.phone_number} disabled />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Priority</SoftTypography>
                                    <SoftInput value={ticket.priority} disabled />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Status</SoftTypography>
                                    <SoftInput value={ticket.status} disabled />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Description</SoftTypography>
                                    <SoftInput
                                        multiline
                                        rows={4}
                                        value={ticket.description}
                                        disabled
                                    />
                                </SoftBox>
                            </form>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
            <Footer />
        </PageLayout>
    );
};

export default ViewDetails;
