import React, { useEffect } from 'react';
import { Card } from "@mui/material";
import { Grid, Paper } from '@mui/material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

const LetterHead = ({  }) => {
    const navigate = useNavigate();
    const [state, setState] = React.useState({
        Emp_Exit: true,
        EnrolledStatus: true,
        Emp_performance: true,
        Tab: true,
        HardWareAcpt: true,
        Emp_Proof: true,
        Relieving: true,
        NDA: true,
        Noc: true,
        BirthdayWishes: false,
        Events: false,
    });
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };

    const handleConform = (e) => {
        e.preventDefault();
        onSubmit(true);
    };

    const templates = [
        { name: 'Noc', label: 'No Objection Certificate', route: '/nocedit' },
        { name: 'EnrolledStatus', label: 'Offer Letter', route: '/enrolledStatusTemp' },
        { name: 'NDA', label: 'Non Disclosure Agreement', route: '/ndaEditTemplate' },
        { name: 'Relieving', label: 'Relieving Letter', route: '/relievingEdit' },
        { name: 'Emp_Proof', label: 'Employee Proof', route: '/empProofEdit' },
        { name: 'HardWareAcpt', label: 'Hardware Acceptance', route: '/HardWareAcptEdit' },
        { name: 'Tab', label: 'Technical Advisory Board Agreement', route: '/tabTemplateEdit' },
        { name: 'Emp_performance', label: 'Employee Performance', route: '/empPerformanceEdit' },
        { name: 'Emp_Exit', label: 'Employee Exit', route: '/empexittemplateedit' }
    ];

    return (
      <DashboardLayout>
        <DashboardNavbar/>
        <Grid container spacing={0} justifyContent="center" alignItems="center">
            <Card sx={{ height: 'auto', width: '50%', }}>
                <SoftBox pt={1} pb={3} px={3}>
                    <SoftBox mb={2}>
                        <SoftTypography variant="h4" textAlign="center">
                            Letter Templates
                        </SoftTypography>
                    </SoftBox>
                    
                    <SoftBox component="form" onSubmit={handleConform}>
                        
                            {templates.map((template) => (
                                <SoftBox 
                                    key={template.name}
                                    display="flex" 
                                    alignItems="center" 
                                    mb={2}
                                    sx={{
                                        borderBottom: '1px solid #eee',
                                        paddingBottom: 1
                                    }}
                                >
                                   
                                    <SoftTypography variant="button" fontWeight="regular" flex={1}>
                                        {template.label}
                                    </SoftTypography>
                                    <Button 
                                        variant="text" 
                                        color="info"
                                        onClick={() => navigate(template.route)}
                                        sx={{ minWidth: '100px' }}
                                    >
                                        Check
                                    </Button>
                                </SoftBox>
                            ))}
                        
                    </SoftBox>
                </SoftBox>
            </Card>
        </Grid>
        </DashboardLayout>
    );
};


export default LetterHead;