import React, { useState } from "react";
import { Box, Button, Center, Stack, Text } from "@mantine/core";
import { useFullscreen } from "@mantine/hooks";
import { NavLink } from "react-router-dom";
import { Appbar } from "../Appbar/Appbar";
 
const Instruction = () => {
  const { toggle, fullscreen } = useFullscreen();
  const [checkboxChecked, setCheckboxChecked] = useState(false);
 
  const handleCheckboxChange = () => {
    setCheckboxChecked(!checkboxChecked);
  };
 
  const handleStartTest = () => {
    if (checkboxChecked) {
      toggle();
    } else {
      alert("Please acknowledge the terms and conditions by checking the box.");
    }
  };
  return (
    <>    <Appbar/>

    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: "10px",
      }}
    >
      <Stack
        bg=""
        style={{
          borderRadius: "6px",
          boxShadow: "rgba(17, 12, 46, 0.15) 0px 48px 100px 0px",
        }}
        p={10}
        w={"85%"}
        maw={{ xs: "95%", sm: "90%", md: "70%", xl: "50%" }}
      >
        <Center
          style={{ display: "flex", flexDirection: "column" }}
          c={"cyan.8"}
        >
          <Text size="1.4rem" fw={"500"}>
            <> TERMS AND CONDITIONS FOR ASAT (Avinya Scholarship Aptitude Test)</>
          </Text>
          <p>ACADEMIC YEAR 2024-2025</p>
          <Box w={{ base: "90%", sm: "80%", md: "80%" }} p={13} c={"#343A40"}>
            <div size="lg" style={{ marginTop: "1rem" }}>
              <h4>1.Eligibility:</h4>
              <li style={{ padding: 10 }}>The scholarship test is open to students from 5th grade to 11th grade who wish to enroll in
                tuition programs for the academic year 2024-2025.</li>
              <li style={{ padding: 10 }}>Each student is eligible to participate only once in the scholarship test.</li>
              <h4>2.Registration and Basic Details:</h4>
              <li style={{ padding: 10 }}>Students must provide accurate and complete information during the registration process.
                This includes Name, Email, Phone Number, Address, Grade selection for the academic year
                2024-2025, School Name, and a valid ID Proof (Students ID should be either Aadhar card
                or School ID)</li>
              <li style={{ padding: 10 }}>Any discrepancy in the provided information may lead to disqualification.
              </li>
              <h4>3.Syllabus:</h4>
              <li style={{ padding: 10 }}> The syllabus for the scholarship test will be based on the students previous academic year.
                For example, students entering 5th grade for the academic year 2024-25 will be tested on
                the 4th-grade syllabus, and so forth. The subjects for test will be on Mathematics, Scinece
                and Social Science.</li>
              <li style={{ padding: 10 }}> Students applying for 2nd PUC will be tested on Physics, Chemistry, and Mathematics from
                the 1st year.</li>
              <h4>4.Scholarship Criteria:
              </h4><li style={{ padding: 10 }}>To be eligible for a scholarship, a student must achieve a minimum score of 80% in the test.</li>
              <li style={{ padding: 10 }}> The selection and disqualification for the scholarship are at the sole discretion of the
                academy, based on predefined criteria.
              </li>
              <h4>5.Test Roles:</h4><li style={{ padding: 10 }}>Once the student starts the test, a timer will be set, and it will begin counting down. The
                timer cannot be paused or reset.</li>
              <li style={{ padding: 10 }}> Students must answer all questions within the allocated time</li>
              <li style={{ padding: 10 }}> Moving to the next question implies finalizing the response for the current question; there
                is no option to revisit previous questions</li>
              <li style={{ padding: 10 }}> Exiting the test screen before completing all questions will result in disqualification.</li>
              <h4>6.Academic Integrity:
              </h4>
              <li style={{ padding: 10 }}> Students are expected to maintain academic integrity during the test. Any form of cheating
                or malpractice will result in immediate disqualification.</li>
              <li style={{ padding: 10 }}> The academy reserves the right to employ anti-cheating measures, including but not
                limited to, proctoring tools and plagiarism detection.</li>
              <h4>7.Selection and Disqualification:</h4>
              <li style={{ padding: 10 }}> The academy reserves the right to disqualify any student who violates the terms and
                conditions or engages in any misconduct during the test.</li>
              <li style={{ padding: 10 }}> Selection for the scholarship is solely based on the performance in the test and other
                criteria set by the academy.</li>
              <h4>8.Rights and Reservations:
              </h4>
              <li style={{ padding: 10 }}> The academy retains all rights regarding the scholarship test, including but not limited to
                the right to modify or cancel the test, change eligibility criteria, and award scholarships as
                deemed fit.
              </li>
              <h4>9.Communication:</h4>
              <li style={{ padding: 10 }}>All communications regarding the scholarship test, including results and further
                procedures, will be conveyed to the registered email address provided by the student.</li>
              <h4>10.Acceptance of Terms:
              </h4><li style={{ padding: 10 }}> By participating in the scholarship test, students agree to abide by these terms and
                conditions.</li><br />
              <b><p>To participate in the scholarship test for the academic year 2024-2025, students must explicitly acknowledge their understanding and agreement with the stated terms and conditions. Failure to provide this acknowledgment will result in the student being denied access to the test. This ensures that participants are fully aware of the rules and agree to comply before taking the examination.</p></b>
              <label  style={{ display: "flex", alignItems: "center", justifyContent:"center" , paddingTop:"1rem 0rem"}}>
                <input
                  type="checkbox"
                  required
                  checked={checkboxChecked}
                  onChange={handleCheckboxChange}
                  style={{ marginRight: "10px" }}
                />
                I Acknowledge terms and conditions
              </label>
            </div>
            <NavLink to={"/test"}>
              <Button
                type="submit"
                mt="sm"
                w={{ base: "100%" }}
                variant="gradient"
                onClick={handleStartTest}
                disabled={!checkboxChecked}
              >
                Start Test
              </Button>
            </NavLink>
          </Box>
        </Center>
      </Stack>
    </div>
    </>
  );
};
export default Instruction;