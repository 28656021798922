
// import { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import Container from "@mui/material/Container";
// import SettingsIcon from '@mui/icons-material/Settings';
// import Tooltip from "@mui/material/Tooltip";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
// import { APIData } from "authentication/APIData";
// import { org } from "authentication/APIData";

// function DefaultNavbar({ transparent, light, action }) {
//   const [mobileNavbar, setMobileNavbar] = useState(false);
//   const [mobileView, setMobileView] = useState(true);
//   const [orgLogo, setOrgLogo] = useState();
//   const [orgname, setOrgname] = useState();
//   const [isRotated, setIsRotated] = useState(false);
//   const [showTooltip, setShowTooltip] = useState(false); 

//   const openMobileNavbar = ({ currentTarget }) => {
//     setMobileNavbar(currentTarget.parentNode);
//     setIsRotated(!isRotated); 
//     setShowTooltip(false); 
//   };

//   const closeMobileNavbar = () => setMobileNavbar(false);

// useEffect(() => {
//   const fetchOrgData = async () => {
//     try {
//       const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
//       if (!response.ok) {
//         throw new Error("Failed to fetch organization data");
//       }
//       const data = await response.json();
//       console.log(data);
//       setOrgname(data.orgName)
//       setOrgLogo(data.orgLogo);
//     } catch (error) {
//       console.error("Error fetching organization data:", error);
//     }
//   };

//   fetchOrgData();


//     const timer = setTimeout(() => {
//       setShowTooltip(true); 
//     }, 2000); 


//     const hideTooltipTimer = setTimeout(() => {
//       setShowTooltip(false); 
//     },); 


//     return () => {
//       clearTimeout(timer);
//       clearTimeout(hideTooltipTimer);
//     };
//   }, []);

//   return (
//     <Container>
//       <SoftBox
//         py={1.5}
//         px={{ xs: transparent ? 4 : 5, sm: transparent ? 2 : 5, lg: transparent ? 0 : 5 }}
//         my={2}
//         mx={3}
//         width="calc(100% - 48px)"
//         borderRadius="section"
//         shadow={transparent ? "none" : "md"}
//         color={light ? "white" : "dark"}
//         display="flex"
//         justifyContent="space-between"
//         alignItems="center"
//         position="absolute"
//         left={0}
//         zIndex={3}
//         sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
//           backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
//           backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
//         })}
//       >
//         <SoftBox component={Link} to="/" py={transparent ? 1.5 : 0.75} lineHeight={1} display="flex" >
//           {orgLogo ? (
//             <img
//               src={orgLogo}
//               alt="Organization Logo"
//               style={{ height: "30px", width: "30px", objectFit: "contain" }}
//               onError={() => setOrgLogo("")}
//             />
//           ) : (
//             <SoftTypography variant="body2"></SoftTypography>
//           )}

//           <SoftTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"} display="flex"
//             alignItems="center"
//             style={{ height: "30px" }}>
//          {orgname}
//           </SoftTypography>
//         </SoftBox>

//         <Tooltip
//           title="Click here to know more"
//           arrow
//           open={showTooltip} 
//           disableHoverListener 
//           enterDelay={500}
//           leaveDelay={200}
//         >
//           <SoftBox
//             display="inline-block"
//             lineHeight={0}
//             color="inherit"
//             sx={{
//               cursor: "pointer",

//               transition: "transform 0.3s ease-in-out", 
//               transform: isRotated ? "rotate(180deg)" : "rotate(0deg)", 
//             }}
//             onClick={openMobileNavbar}
//           >
//             <SettingsIcon fontSize="large" />
//           </SoftBox>
//         </Tooltip>
//       </SoftBox>

//       {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
//     </Container>
//   );
// }

// DefaultNavbar.defaultProps = {
//   transparent: false,
//   light: false,
//   action: false,
// };


// DefaultNavbar.propTypes = {
//   transparent: PropTypes.bool,
//   light: PropTypes.bool,
//   action: PropTypes.oneOfType([
//     PropTypes.bool,
//     PropTypes.shape({
//       type: PropTypes.oneOf(["external", "internal"]).isRequired,
//       route: PropTypes.string.isRequired,
//       color: PropTypes.oneOf([
//         "primary",
//         "secondary",
//         "info",
//         "success",
//         "warning",
//         "error",
//         "dark",
//         "light",
//       ]),
//       label: PropTypes.string.isRequired,
//     }),
//   ]),
// };

// export default DefaultNavbar;


import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
import breakpoints from "assets/theme/base/breakpoints";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";

function DefaultNavbar({ transparent, light, action }) {
  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);
  const [orgLogo, setOrgLogo] = useState();
  const [orgname, setOrgname] = useState();
  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);



  useEffect(() => {
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    const fetchOrgData = async () => {
      try {
        const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
        if (!response.ok) {
          throw new Error("Failed to fetch organization data");
        }
        const data = await response.json();
        // console.log(data);
        setOrgname(data.orgShortName)
        setOrgLogo(data.orgLogo);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };

    window.addEventListener("resize", displayMobileNavbar);


    displayMobileNavbar();
    fetchOrgData();
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container>
      <SoftBox
        py={1.5}
        px={{ xs: transparent ? 4 : 5, sm: transparent ? 2 : 5, lg: transparent ? 0 : 5 }}
        my={2}
        mx={3}
        width="calc(100% - 48px)"
        borderRadius="section"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        position="absolute"
        left={0}
        zIndex={3}
        sx={({ palette: { transparent: transparentColor, white }, functions: { rgba } }) => ({
          backgroundColor: transparent ? transparentColor.main : rgba(white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
      >
        <SoftBox component={Link} to="/" py={transparent ? 1.5 : 0.75} lineHeight={1} display="flex" >
          {orgLogo ? (
            <img
              src={orgLogo}
              alt="Organization Logo"
              style={{ height: "30px", width: "30px", objectFit: "contain" }}
              onError={() => setOrgLogo("")}
            />
          ) : (
            <SoftTypography variant="body2"></SoftTypography>
          )}

          <SoftTypography variant="button" fontWeight="bold" color={light ? "white" : "dark"} display="flex"
            alignItems="center"
            style={{ height: "30px" }}>
            {orgname}
          </SoftTypography>
        </SoftBox>
        <SoftBox
          color="inherit"
          display={{ xs: "none", lg: "flex" }}
          m={0}
          p={0}
        >
          <DefaultNavbarLink
            icon="account_circle"
            name="User SignIn"
            route="/authentication/sign-in"
            light={light}
          />
          <DefaultNavbarLink
            icon="person"
            name="Org SignIn"
            route="/authentication/org-sign-in"
            light={light}
          />
          <DefaultNavbarLink
            icon="account_circle"
            name="Sign Up"
            route="/authentication/sign-up"
            light={light}
          />
          <DefaultNavbarLink
            icon="donut_large"
            name="Courses"
            route="/courses"
            light={light}
          />
          <DefaultNavbarLink
            icon="person"
            name="Careers"
            route="/userJobs"
            light={light}
          />

          <DefaultNavbarLink
            icon="person"
            name="Support"
            route="/customer-sign-in"
            light={light}
          />

          <DefaultNavbarLink
            icon="person"
            name="ASAT"
            route="/asat"
            light={light}
          />

        </SoftBox>

        <SoftBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </SoftBox>
      </SoftBox>
      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
  action: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(["external", "internal"]).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "dark",
        "light",
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
};

export default DefaultNavbar;