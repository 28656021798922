import React, { useState, useEffect } from "react";
import { Grid, CircularProgress, Backdrop, Card } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import * as AiIcons from "react-icons/ai";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { APIData, org } from "./APIData";
import image from "../assets/images/output-onlinepngtools.png";
import Swal from "sweetalert2";
var passwordValidator = require("password-validator");

const schema = new passwordValidator();
schema
  .is().min(8)
  .is().max(32)
  .has().uppercase(1)
  .has().lowercase(1)
  .has().digits(1)
  .has().symbols(1)
  .has().not().spaces()
  .is().not().oneOf(["Passw0rd", "Password123"]);

const ChangePassword = () => {
  const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
  const [profileInfo, setProfileData] = useState({});
  const [password, setPassword] = useState("");
  const [hidden, setHidden] = useState(true);
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    const userdata = sessiondetails?.user;
    if (userdata) {
      const fetchData = async () => {
        try {
          const url = `${APIData.api}employee/${userdata}`;
          const response = await fetch(url, { headers: APIData.headers });
          const data = await response.json();
          console.log(data);
          setProfileData(data.photoUrl);
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: `Failed to fetch "${org}" details`,
            icon: "error",
            confirmButtonText: "OK",
          });
        }
      };
      fetchData();
    }
  }, [sessiondetails]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const toggleShow = () => {
    setHidden(!hidden);
  };

  const submitHandler = (e) => {
    e.preventDefault();

    if (!password) {
      setPasswordError("Please enter a password");
      return;
    }

    if (!schema.validate(password)) {
      setPasswordError("Password must contain at least 1 lowercase, 1 uppercase, 1 number & 1 special character and be at least 8 characters long");
      return;
    }

    setLoading(true);
    axios
      .post(`${APIData.api}login-type/update`, { org, username: sessiondetails.user, password }, {
        headers: APIData.headers,
      })
      .then((response) => {
        if (response.status === 200) {
          // alert("Password Changed Successfully");
          Swal.fire({
            icon: "success",
            title: "Password Changed Successfully!",
            showConfirmButton: false,
            timer: 1500
        });
          const url = new URL(APIData.url);
          window.location.href = url;
          localStorage.clear();
        } else {
          // alert("Password Update Failed");
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Password Update Failed",
        });
        }
      })
      .catch((error) => {
        // alert("Something went wrong. Please try again later.");
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong. Please try again later.",
      });
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100%" }}>
        <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card sx={{ height: 'auto' }}>
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/profile">
                <AiIcons.AiOutlineCloseCircle size={24} />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Change Password
              </SoftTypography>
            </SoftBox>

            <SoftBox display="flex" justifyContent="center" p={3}>
              <img
                src={profileInfo}
                alt="profile"
                style={{ width: '100px', height: '100px', borderRadius: '50%' }}
              />
            </SoftBox>

            <SoftBox display="flex" justifyContent="center" mb={2}>
              <SoftTypography variant="body2">
                ID: <strong>{sessiondetails.user}</strong>
              </SoftTypography>
            </SoftBox>

            <SoftBox pt={2} pb={3} px={3}>
              <SoftBox component="form" onSubmit={submitHandler}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" fontWeight="medium">
                    Enter New Password
                  </SoftTypography>
                  <SoftBox position="relative">
                    <SoftInput
                      type={hidden ? "password" : "text"}
                      placeholder="Enter New Password"
                      name="password"
                      value={password}
                      onChange={handlePasswordChange}
                      error={!!passwordError}
                      icon={{
                        component: hidden ? AiIcons.AiFillEyeInvisible : AiIcons.AiFillEye,
                        direction: "right",
                        onClick: toggleShow
                      }}
                    />
                    {passwordError && (
                      <SoftTypography variant="caption" color="error">
                        {passwordError}
                      </SoftTypography>
                    )}
                  </SoftBox>
                </SoftBox>

                <SoftBox mt={4} mb={1}>
                  <SoftButton 
                    type="submit" 
                    variant="gradient" 
                    color="info" 
                    fullWidth
                  >
                    Update Password
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default ChangePassword;
