import React from 'react';
import logo from '../../assets/images/c4e.png';
import { Typography, Paper } from '@mui/material';


const Noc = () => {
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Defualt Template
      </Typography>
      <Paper elevation={3} style={{ padding: '16px', margin: "0" }}>
        <div style={containerStyle}>
          <header style={headerStyle}>
            <img src={logo} alt="Care4Edu Logo" style={logoStyle} />
            <div style={headerContentStyle}>
              <h2 style={titleTextStyle}>Care4Edu</h2>
              <p style={paragraphStyle}>+91 7259785184</p>
              <p style={paragraphStyle}>care4edu.com</p>
            </div>
          </header>

          <div style={contentStyle}>
            <center>
              <u>
                <h2 style={nocTitleStyle}>No Objection Certificate</h2>
              </u>
            </center>
            <p style={paragraphStyle1}><strong>Date: 20/08/2024</strong></p>
            <p style={paragraphStyle1}>To: Whomsoever It May Concern</p>

            <div style={paragraphStyle1}>
              This is to certify that <strong>Adhithyan P A,</strong> working as <strong>[Developer]</strong> at <strong>Care4Edu Solutions Pvt Ltd,</strong> has requested a No Objection Certificate for pursuing new employment opportunities.<strong>Adhithyan P A</strong> has tendered their resignation from <strong>Care4Edu Solutions Pvt Ltd,</strong> effective on <strong>20/08/2024,</strong> and we
              acknowledge their decision to leave our organization. We hereby confirm that we have no objection to the aforementioned employee.
            </div>

            <div style={paragraphStyle1}>
              Also, <strong>[Developer] Adhithyan P A,</strong> hereby obliges that with or without his knowledge, he/she will not replicate the
              codes, Confidential Information, Technologies, Software, etc., used while working in the company. If the employee
              replicates or uses any confidential information, Technologies, Software, etc., of the company, he/she will be
              facing legal issues as per the state laws of the Government of Karnataka.
            </div>

            <div style={paragraphStyle1}>
              This No Objection Certificate is issued at the request of the employee for the purpose stated above. Going forward,
              the company does not have any dependencies regarding hardware or software.
            </div>

            <div style={signatureStyle}>
              <div style={signatureBlockStyle}>
                <p style={paragraphStyle}>Sincerely,</p>
                <p style={paragraphStyle}>Authorized Signatory</p>
                <p style={paragraphStyle}><strong>Care4Edu Solutions Pvt Ltd</strong></p>
                <p style={paragraphStyle}>Vidyaranyapura</p>
              </div>
              <div style={signatureBlockStyle}>
                <p style={paragraphStyle}>Acknowledged By,</p>
                <p style={paragraphStyle}><strong>(Adhithyan P A)</strong></p>
              </div>
            </div>
          </div>
        </div>
      </Paper >
    </>
  );
};

const containerStyle = {
  color: '#333',
  maxWidth: '700px',
  margin: 'auto',
  padding: '20px',
  backgroundColor: '#fff',
};


const headerStyle = {
  textAlign: 'left',
  marginBottom: '20px',

};

const logoStyle = {
  width: '80px',
  height: '80px',
  marginLeft: '20px',

};

const headerContentStyle = {
  marginLeft: '0px',
};

const titleTextStyle = {
  fontSize: '16px',
  fontWeight: 'bold',
  margin: '10px 0',
};

const paragraphStyle = {
  margin: '5px 0',

};
const paragraphStyle1 = {
  margin: '5px 0',
  marginBottom: '20px',

}

const contentStyle = {
  lineHeight: '1.6',
  marginBottom: '20px',
};

const nocTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  margin: '10px 0',
};

const signatureStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '40px',
};

const signatureBlockStyle = {
  textAlign: 'center',
};

export default Noc;
