import React, { useState, useEffect } from 'react';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import { Card, Grid, FormControl, Tooltip } from "@mui/material";
import { APIData } from 'authentication/APIData';
import axios from 'axios';
import { toast } from "react-toastify";
import { CircularProgress, Backdrop } from '@mui/material';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import { Link } from 'react-router-dom';
import * as AiIcons from "react-icons/ai";
import { FormHelperText } from "@mui/material";
import Swal from 'sweetalert2';
import CustomSelect from "assets/theme/components/Select/CustomSelect";

const productOptions = [
    { value: 'BNB', label: 'BNB' },
    { value: 'SMARTERPORTAL', label: 'SMARTERPORTAL' },
    { value: 'SHIKSHAKPRO', label: 'SHIKSHAKPRO' }
];

const priorityOptions = [
    { value: 'HIGH', label: 'HIGH' },
    { value: 'MODERATE', label: 'MODERATE' },
    { value: 'LOW', label: 'LOW' },
    { value: 'CRITICAL', label: 'CRITICAL' }
];

const typeOptions = [
    { value: 'TECHNICAL', label: 'TECHNICAL' },
    { value: 'NONTECHNICAL', label: 'NONTECHNICAL' },
    { value: 'FUNCTIONAL', label: 'FUNCTIONAL' },
    { value: 'OTHERS', label: 'OTHERS' }
];

function CustomerContact() {
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const lastdate = sessiondetails.warrantyEndDate;
    const currentDate = new Date().toISOString().split('T')[0];

    const [product, setProduct] = useState('');
    const [customProduct, setCustomProduct] = useState('');
    const [type, setType] = useState('');
    const [customType, setCustomType] = useState('');
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [priority, setPriority] = useState('');
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [priorityNote, setPriorityNote] = useState("");
    const [image, setImage] = useState(null);
    const [imagePreview, setImagePreview] = useState("");
    const [error, setError] = useState("");

    const priorityMessages = {
        HIGH: "Issue will be resolved within 24 to 48 hours(If it is a valid Issue and valid priority).",
        MODERATE: "Issue will be resolved within 48 to 72 hours(If it is a valid Issue and valid priority).",
        LOW: "Issue will be resolved within 72 to 96 hours(If it is a valid Issue and valid priority).",
        CRITICAL: "Issue will be resolved within 24 hours(If it is a valid Issue and valid priority)."
    };

    const typeMessages = {
        TECHNICAL: "Technical issues are related to software or hardware malfunctions.",
        NONTECHNICAL: "Non-technical issues include general queries or informational requests.",
        FUNCTIONAL: "Functional issues are related to the application's core functionality.",
        OTHERS: "Other issues may require additional details to specify the type."
    };

    const [typeNote, setTypeNote] = useState("");

    const handleSelectChange = (selectedOption, actionMeta) => {
        if (selectedOption) {
            switch (actionMeta.name) {
                case 'product':
                    setProduct(selectedOption.value);
                    break;
                case 'priority':
                    setPriority(selectedOption.value);
                    setPriorityNote(priorityMessages[selectedOption.value] || "");
                    break;
                case 'type':
                    setType(selectedOption.value);
                    setTypeNote(typeMessages[selectedOption.value] || "");
                    if (selectedOption.value === 'OTHERS') {
                        setCustomType('');
                    }
                    break;
                default:
                    break;
            }
        }
    };


    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                setError("File too large. Max file size is 1MB.");
                setImage(null);
                setImagePreview("");
                return;
            }
            setImage(file);
            setImagePreview(URL.createObjectURL(file));
            setError("");
        }
    };

    useEffect(() => {
        axios.get(APIData.api + `customer-onboard/id?id=${sessiondetails.customerId}`, { headers: APIData.headers })
            .then(res => setData(res.data))
            .catch(err => console.log(err));
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newErrors = {};
        if (lastdate === currentDate || lastdate < currentDate) {
            toast.error("Your service date is over");
            return;
        }
        if (!image) {
            toast.error('Please upload an image.');
            return;
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                setLoading(true);

                const url = `${APIData.api}Ticket/`;
                const formData = {
                    consumerId: e.target.customerid.value,
                    description: e.target.ProductDesc.value,
                    emailid: sessiondetails.email,
                    custom_product: customProduct,
                    name: e.target.requestedBy.value,
                    org: "c4e",
                    phone_number: sessiondetails.phone,
                    priority: priority,
                    product: product,
                    status: "REQUESTED",
                    comments: [],
                    type: type === 'Other' ? customType : type,
                };

                const ticketResponse = await axios.post(url, formData, { headers: APIData.headers });
                const ticketId = ticketResponse.data.id;

                if (image) {
                    const formDataImage = new FormData();
                    formDataImage.append('TicketId', ticketId);
                    formDataImage.append('image', image);

                    await axios.post(`${APIData.api}Ticket/uploadImage?TicketId=${ticketId}`, formDataImage, {
                        headers: {
                            ...APIData.headers,
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                }

                Swal.fire({
                    icon: "success",
                    title: "Query Raised Successfully",
                    showConfirmButton: false,
                    timer: 1500
                });

            } catch (error) {
                console.error(error);
                toast.error('Error submitting form');
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <PageLayout>
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Grid container spacing={0} justifyContent="center" alignItems="center" className="min-h-screen p-4" mt={3}>
                <Grid item xs={10} sm={10} md={8} lg={6}>
                    <Card className="w-full">
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Tooltip title="Close" arrow>
                                <Link to="/view-tickets">
                                    <AiIcons.AiOutlineCloseCircle />
                                </Link>
                            </Tooltip>
                            <SoftTypography mb={2} variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Raise a Query
                            </SoftTypography>
                        </SoftBox>
                        <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">User ID</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="customerid"
                                                name="customerid"
                                                value={sessiondetails.customerId}
                                                required
                                                disabled
                                                size="small"
                                            />
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6" mb={1}>Product</SoftTypography>
                                        <CustomSelect
                                            options={productOptions}
                                            placeholder="Select Product"
                                            name="product"
                                            onChange={handleSelectChange}
                                            value={product}
                                        />
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Requested By</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                id="requestedBy"
                                                name="requestedBy"
                                                required
                                                size="small"
                                            />
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6" mb={1}>Priority</SoftTypography>
                                        <CustomSelect
                                            options={priorityOptions}
                                            placeholder="Select Priority"
                                            name="priority"
                                            onChange={handleSelectChange}
                                            value={priority}
                                            size="small"
                                        />
                                        {priorityNote && (
                                            <FormHelperText>{priorityNote}</FormHelperText>
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6" mb={1}>Type</SoftTypography>
                                        <CustomSelect
                                            options={typeOptions}
                                            placeholder="Select Type"
                                            name="type"
                                            onChange={handleSelectChange}
                                            value={type}
                                            size="small"
                                        />
                                        {typeNote && (
                                            <FormHelperText>{typeNote}</FormHelperText>
                                        )}
                                        {type === 'OTHERS' && (
                                            <SoftInput
                                                id="customType"
                                                name="customType"
                                                value={customType}
                                                onChange={(e) => setCustomType(e.target.value)}
                                                placeholder="Enter custom type"
                                                required
                                            />
                                        )}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Description</SoftTypography>
                                        <FormControl fullWidth error={!!errors.description}>
                                            <SoftInput
                                                id="ProductDesc"
                                                name="ProductDesc"
                                                multiline
                                                rows={4}
                                                required
                                                size="small"
                                            />
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox display="flex" flexDirection="column" alignItems="center">
                                        <input
                                            accept="image/*"
                                            style={{ display: 'none' }}
                                            id="upload-image"
                                            type="file"
                                            onChange={handleImageChange}
                                        />
                                        <label htmlFor="upload-image">
                                            <SoftButton variant="contained" component="span">
                                                Upload Image
                                            </SoftButton>
                                        </label>
                                        {imagePreview && (
                                            <SoftBox mt={2} display="flex" justifyContent="center" alignItems="center">
                                                <img src={imagePreview} alt="Preview" style={{ width: '200px', height: 'auto' }} />
                                            </SoftBox>
                                        )}
                                        {error && <FormHelperText error>{error}</FormHelperText>}
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12}>
                                    <SoftBox display="flex" justifyContent="center" mt={4} mb={2}>
                                        <SoftButton type="submit" variant="gradient" color="info" fullWidth disabled={loading}>
                                            {loading ? "Submitting..." : "Submit"}
                                        </SoftButton>
                                    </SoftBox>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </PageLayout>
    );
}

export default CustomerContact;