import React from 'react';
import { Grid, Card } from '@mui/material';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import logo from '../../assets/images/c4e.png';

const RelievingTemp = () => {
  const styles = {
    container: {
      maxWidth: '700px',
      margin: 'auto',
      backgroundColor: '#fff',
    },
    logo: {
      width: '80px',
      height: '80px',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '20px',
    },
    list: {
      paddingLeft: '20px',
      marginBottom: "40px",
      listStyleType: 'disc',
    },
  };

  return (
    <Grid container spacing={0} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <SoftTypography variant="h5" gutterBottom>
          Default Template
        </SoftTypography>
        <Card sx={{ height: 'auto' }}>
          <SoftBox pt={1} pb={3} px={3}>
            <div style={styles.container}>
              <div style={styles.header}>
                <img src={logo} alt="Company Logo" style={styles.logo} />
                <SoftBox ml={2}>
                  <SoftTypography variant="h4">Care4Edu Solutions Pvt Ltd</SoftTypography>
                  <SoftTypography variant="body2">+91 7259785184 | info@care4edu.com | www.care4edu.com</SoftTypography>
                </SoftBox>
              </div>

              <SoftBox textAlign="center" mb={3}>
                <SoftTypography 
                  variant="h4" 
                  component="h2"
                  sx={{ 
                    textDecoration: 'underline',
                    my: 2
                  }}
                >
                  Relieving Letter
                </SoftTypography>
              </SoftBox>

              <SoftBox textAlign="right" mb={3}>
                <SoftTypography variant="body1">
                  Date: 23/08/2024
                </SoftTypography>
              </SoftBox>

              <SoftBox mb={3}>
                <SoftTypography variant="body1">To,</SoftTypography>
                <SoftTypography variant="body1">Adhithyan P A</SoftTypography>
                <SoftTypography variant="body1">Emp ID: c4e2024adhithyan__p_a</SoftTypography>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="body1" fontWeight="bold">
                  Subject: Relieving Letter
                </SoftTypography>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="body1">
                  Dear Adhithyan P A,
                </SoftTypography>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="body1">
                  I am writing to formally acknowledge and accept your resignation from your position at <strong>Care4Edu Solutions Pvt Ltd</strong>, effective <strong>23/08/2024</strong> in Accordance with your resignation notice.
                </SoftTypography>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="body1">
                  We understand and respect your decision to move on to new opportunities, and we would like to express our gratitude for your valuable contributions during your tenure with our company. Your dedication and hard work have been appreciated and have contributed significantly to our success.
                </SoftTypography>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="body1">
                  This letter serves as an official confirmation of relieving of your employment from Care4Edu Solutions Pvt Ltd. Below are the details related to your relieving:
                </SoftTypography>
              </SoftBox>

              <SoftBox component="ul" style={styles.list}>
                {[
                  'Your last working day with Care4Edu Solutions Pvt Ltd, was on 23/08/2024.',
                  'You have returned all company property, including but not limited to keys, access cards, laptop, and any other equipment or materials, as per our company policy.',
                  'Your final settlement, including any pending dues, benefits, and the return of your security deposit (if applicable), will be processed in accordance with our HR procedures. You will receive the relevant documentation separately.',
                  'We would like to remind you of your obligation to maintain the confidentiality of any company-related information, and we kindly request your cooperation in this matter.'
                ].map((item, index) => (
                  <SoftTypography component="li" variant="body1" mb={2} key={index}>
                    {item}
                  </SoftTypography>
                ))}
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="body1">
                  We would like to take this opportunity to wish you the very best in your future endeavors. Please keep in touch, and we hope that our paths may cross again in the future.
                </SoftTypography>
              </SoftBox>

              <SoftBox mb={2}>
                <SoftTypography variant="body1">
                  Once again, thank you for your time and dedication to Care4Edu Solutions Pvt Ltd. We sincerely appreciate your efforts during your time with us.
                </SoftTypography>
              </SoftBox>

              <SoftBox mb={4}>
                <SoftTypography variant="body1">
                  Please acknowledge your receipt of this letter by sighing and returning the duplicate copy attached to this document.
                </SoftTypography>
              </SoftBox>

              <SoftBox mt={4}>
                <SoftTypography variant="body1">Sincerely,</SoftTypography>
                <SoftTypography variant="body1">Authorized Signatory</SoftTypography>
                <SoftTypography variant="body1">Care4Edu</SoftTypography>
              </SoftBox>
            </div>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default RelievingTemp;