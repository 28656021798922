import { createTheme, ThemeProvider } from '@mui/material/styles';
import stepper from 'assets/theme/components/stepper';
import step from 'assets/theme/components/stepper/step';
import stepConnector from 'assets/theme/components/stepper/stepConnector';
import stepIcon from 'assets/theme/components/stepper/stepIcon';
import stepLabel from 'assets/theme/components/stepper/stepLabel';
import StepperForm from './StepperForm';
import PageLayout from 'examples/LayoutContainers/PageLayout';



const theme = createTheme({
  components: {
    MuiStepper: stepper,
    MuiStep: step,
    MuiStepConnector: stepConnector,
    MuiStepIcon: stepIcon,
    MuiStepLabel: stepLabel,
  },
});

function Theme() {
  return (
   
      <PageLayout>
       
        <StepperForm />
      </PageLayout>
  
  );
}

export default Theme;