import React, { useState } from "react";
import { Grid, CircularProgress, Backdrop } from "@mui/material";
import { Card } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import * as AiIcons from "react-icons/ai";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import image from "../assets/images/output-onlinepngtools.png";
import { APIData } from "./APIData";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const GetPassword = () => {
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const changeHandler = (e) => {
    setId(e.target.value);
    setError("");
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (!id) {
      setError("Please enter an email");
      return;
    }

    setLoading(true);

    axios
      .get(`${APIData.api}login-type/forgot-password/${id}`, {
        headers: APIData.headers,
      })
      .then((response) => {
        if (response.data.status.toString().toLowerCase() === "success") {
          // alert(response.data.description);
          Swal.fire({
            icon: "success",
            title: response.data.description,
            showConfirmButton: false,
            timer: 1500
          });
          setTimeout(() => {
            navigate("/authentication/org-sign-in");
          }, 1500);
        } else {
          // alert(response.data.errorDesc);
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: response.data.errorDesc,
          });
        }
      })
      .catch(() => {
        alert("Something went wrong. Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <PageLayout>
      <DefaultNavbar />
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Backdrop open={loading} style={{ zIndex: 9999, color: "#fff" }}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Grid item xs={12} sm={8} md={6} lg={4}>
          <Card sx={{ height: "auto" }}>
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/authentication/org-sign-in">
                <AiIcons.AiOutlineCloseCircle />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: "center" }}>
                Get Your Password
              </SoftTypography>
            </SoftBox>

            <SoftBox display="flex" justifyContent="center" p={3}>
              <img src={image} alt="profile" style={{ width: "100px", height: "100px", borderRadius: "50%" }} />
            </SoftBox>

            <SoftBox pt={2} pb={3} px={3}>
              <SoftBox component="form" onSubmit={submitHandler}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6" fontWeight="medium">
                    Enter UserID
                  </SoftTypography>
                  <SoftInput
                    placeholder="Enter ID"
                    name="id"
                    value={id || ""}
                    onChange={changeHandler}
                    error={!!error}
                  />
                  {error && (
                    <SoftTypography variant="caption" color="error">
                      {error}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mt={4} mb={1}>
                  <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                    Get Your Password
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default GetPassword;
