import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Backdrop, Card } from '@mui/material';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import axios from 'axios';
import Swal from 'sweetalert2';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { APIData, org } from 'authentication/APIData';

const RequestPassword = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
  const [data, setData] = useState("");
  const [formData, setFormData] = useState({
    authorised_at: "",
    authorised_by: state.supervisor_id,
    id: 0,
    reject_reason: "",
    status: "requested",
    supervisor_id: state.supervisor_id,
    reason: '',
    title: state.title,
  });

  const [loading, setLoading] = useState(false);
  const [reasonError, setReasonError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = APIData.api + `orgpasswords/orgpassword?title=${state.title}`;
        const response = await axios.get(url, { headers: APIData.headers });
        setData(response.data);
      } catch (error) {
        toast.error(error.response?.data || 'An error occurred while fetching data');
      }
    };

    fetchData();
  }, [state.title]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'reason') {
      if (value.trim().length < 10) {
        setReasonError(true);
      } else {
        setReasonError(false);
      }
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (reasonError) {
      toast.error('Reason must be at least 10 characters long');
      return;
    }
    setLoading(true);
    const today = new Date().toISOString().slice(0, 10);

    const newData = {
      ...formData,
      supervisor_email: data.supervisor_email,
      requested_email: sessiondetails.email,
      requested_at: today,
      requested_by: sessiondetails.user,
      org: org
    };

    try {
      const url = APIData.api + `passwordaccess/`;
      await axios.post(url, newData, { headers: APIData.headers });
      Swal.fire({
        icon: "success",
        title: "Requested successfully",
        showConfirmButton: false,
        timer: 1500
      });
      navigate('/password-managing');
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data || "An error occurred while updating data",
      });
    }
    setLoading(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "60vh" }}>
        <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12} sm={8}>
          <Card sx={{ height: 'auto' }}>
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/password-managing">
                <AiOutlineCloseCircle />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Org Password Manage
              </SoftTypography>
            </SoftBox>

            <SoftBox pt={1} pb={3} px={3}>
              <SoftBox component="form" onSubmit={handleSubmit}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Title</SoftTypography>
                  <SoftInput
                    placeholder="Enter Title"
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    disabled
                  />
                </SoftBox>
                <SoftTypography variant="h6">Reason *</SoftTypography>
                <SoftInput
                  placeholder="Enter Reason (at least 10 characters)"
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  error={reasonError}
                  required
                />
                {reasonError && (
                  <SoftTypography variant="caption" color="error">
                    Reason must be at least 10 characters long
                  </SoftTypography>
                )}

                <SoftBox mt={4} mb={1}>
                  <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                    Submit
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </DashboardLayout>
  );
};

export default RequestPassword;