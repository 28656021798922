import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid, CircularProgress, Backdrop, Tooltip, IconButton, Card } from '@mui/material';
import { HelpCenterRounded } from '@mui/icons-material';
import Swal from 'sweetalert2';
import * as AiIcons from "react-icons/ai";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import { APIData, org } from 'authentication/APIData';

const AddExpenses = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const Department = localStorage.getItem("Depart Details");

    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        exp_name: "",
        exp_type: "",
        cost: "",
        exp_date: "",
        item_count: "",
        month: "",
        reason: "",
        selectedFile: null,
    });
    const getCurrentMonth = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        return `${year}-${month}`;
    };

    const expenseTypeOptions = [
        { value: 'miscellaneous', label: 'Miscellaneous' },
        { value: 'office', label: 'Office' },
        { value: 'travel', label: 'Travel' },
        { value: 'personal', label: 'Personal' }
    ];
    const validationRules = {
        exp_name: (value) => {
            if (!value.trim()) return "Expense name is required";
            if (value.length < 3) return "Expense name must be at least 3 characters";
            return '';
        },
        exp_type: (value) => {
            if (!value) return "Expense type is required";
            return '';
        },
        cost: (value) => {
            if (!value) return "Cost is required";
            if (isNaN(value) || Number(value) <= 0) return "Cost must be a positive number";
            if (value.length > 10) return "Cost amount is too large";
            return '';
        },
        exp_date: (value) => {
            if (!value) return "Date is required";
            const selectedDate = new Date(value);
            const today = new Date();
            if (selectedDate > today) return "Date cannot be in the future";
            return '';
        },
        item_count: (value) => {
            if (!value) return "Item count is required";
            if (!/^\d+$/.test(value)) return "Item count must be a positive number";
            if (Number(value) <= 0) return "Item count must be greater than 0";
            if (value.length > 5) return "Item count is too large";
            return '';
        },
        month: (value) => {
            if (!value) return "Month is required";
            const [selectedYear, selectedMonth] = value.split('-').map(Number);
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth() + 1; // Adding 1 since getMonth() returns 0-11

            if (selectedYear > currentYear || 
                (selectedYear === currentYear && selectedMonth > currentMonth)) {
                return "Month cannot be in the future";
            }
            return '';
        },
        reason: (value) => {
            if (!value.trim()) return "Description is required";
            if (value.length < 10) return "Description must be at least 10 characters";
            if (value.length > 500) return "Description cannot exceed 500 characters";
            return '';
        },
        selectedFile: (value) => {
            if (!value) return "Receipt is required";
            if (value.size > 1000000) return "File size should be less than 1MB";
            return '';
        }
    };

    const getErrorHelperText = (fieldName) => {
        return errors[fieldName] ? (
            <SoftTypography variant="caption" color="error" style={{ marginTop: '4px', display: 'block' }}>
                {errors[fieldName]}
            </SoftTypography>
        ) : null;
    };

    const validateField = (name, value) => {
        const validationRule = validationRules[name];
        if (validationRule) {
            const error = validationRule(value);
            setErrors(prev => ({
                ...prev,
                [name]: error
            }));
            return !error;
        }
        return true;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        validateField(name, value);
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const name = actionMeta.name;
        const value = selectedOption ? selectedOption.value : '';

        setFormData(prev => ({
            ...prev,
            [name]: value,
        }));
        validateField(name, value);
    };

    const handleCostChange = (e) => {
        const value = e.target.value.replace(/[^\d]/g, "");
        setFormData(prev => ({ ...prev, cost: value }));
        validateField('cost', value);
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setFormData(prev => ({ ...prev, selectedFile: file }));
            validateField('selectedFile', file);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;

        // Validate all fields
        Object.keys(validationRules).forEach(fieldName => {
            const value = formData[fieldName];
            const error = validationRules[fieldName](value);
            if (error) {
                newErrors[fieldName] = error;
                isValid = false;
            }
        });

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: "Please fill in all required fields correctly",
            });
            return;
        }

        setLoading(true);

        const sendstate = {
            cost: formData.cost,
            comment: "",
            exp_date: formData.exp_date,
            exp_name: formData.exp_name,
            exp_type: formData.exp_type,
            item_count: formData.item_count,
            month: formData.month,
            org: org,
            status: "VERIFYING",
            reason: formData.reason,
            created_by: sessiondetails.user,
            approval: "UNPAID",
            department: Department,
            file_name: "",
            id: 0,
            supervisor: sessiondetails.privileges?.[0]?.supervisor || null,
            reciept: "",
        };
      
        console.log(sendstate);
        
        const submitData = new FormData();
        submitData.append("file", formData.selectedFile);
        submitData.append("expense", JSON.stringify(sendstate));
        console.log(submitData);
        try {
            const response = await axios.post(APIData.api + "expenses/", submitData, {
                headers: APIData.headers
            });

            if (response.data.status.toLowerCase() === "success" || 
                response.data.status === 200 || 
                response.data.status === 201) {

                Swal.fire({
                    icon: "success",
                    title: "Added Successfully",
                    showConfirmButton: false,
                    timer: 1500
                });

                setTimeout(() => {
                    navigate("/expenses");
                }, 1500);
            } else {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: response.data.errorDesc,
                });
            }
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response?.data?.message || "An error occurred while submitting",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>

                <Grid item xs={12} sm={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/expenses">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Add Expense
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Expense Name</SoftTypography>
                                    <SoftInput
                                        placeholder="Enter Expense Name"
                                        name="exp_name"
                                        value={formData.exp_name}
                                        onChange={handleChange}
                                        error={!!errors.exp_name}
                                        required
                                    />
                                    {getErrorHelperText('exp_name')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Expense Type</SoftTypography>
                                    <CustomSelect
                                        options={expenseTypeOptions}
                                        placeholder="Select Expense Type"
                                        name="exp_type"
                                        onChange={handleSelectChange}
                                        value={expenseTypeOptions.find(option => option.value === formData.exp_type)}
                                        error={!!errors.exp_type}
                                        required
                                    />
                                    {getErrorHelperText('exp_type')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Cost</SoftTypography>
                                    <SoftInput
                                        placeholder="Enter Cost"
                                        name="cost"
                                        value={formData.cost ? `₹ ${formData.cost}` : ""}
                                        onChange={handleCostChange}
                                        error={!!errors.cost}
                                        required
                                    />
                                    {getErrorHelperText('cost')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Date</SoftTypography>
                                    <SoftInput
                                        type="date"
                                        name="exp_date"
                                        value={formData.exp_date}
                                        onChange={handleChange}
                                        error={!!errors.exp_date}
                                        required
                                    />
                                    {getErrorHelperText('exp_date')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Item Count</SoftTypography>
                                    <SoftInput
                                        placeholder="Enter Item Count"
                                        name="item_count"
                                        value={formData.item_count}
                                        onChange={handleChange}
                                        error={!!errors.item_count}
                                        required
                                    />
                                    {getErrorHelperText('item_count')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Month</SoftTypography>
                                    <SoftInput
                                        type="month"
                                        name="month"
                                        value={formData.month}
                                        onChange={handleChange}
                                        error={!!errors.month}
                                        required
                                    />
                                    {getErrorHelperText('month')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Description</SoftTypography>
                                    <SoftInput
                                        multiline
                                        rows={4}
                                        placeholder="Enter Description"
                                        name="reason"
                                        value={formData.reason}
                                        onChange={handleChange}
                                        error={!!errors.reason}
                                        required
                                    />
                                    {getErrorHelperText('reason')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">
                                        Upload Receipt
                                        <Tooltip title="Receipt should be less than 1MB">
                                            <IconButton edge="end" size="small">
                                                <HelpCenterRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </SoftTypography>
                                    <input
                                        accept=".doc,.docx,image/*"
                                        style={{ display: 'none' }}
                                        id="upload-file"
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="upload-file">
                                        <SoftButton variant="contained" component="span">
                                            Upload Receipt
                                        </SoftButton>
                                    </label>
                                    {formData.selectedFile && (
                                        <SoftTypography variant="body2" mt={1}>
                                            Selected file: {formData.selectedFile.name}
                                        </SoftTypography>
                                    )}
                                    {getErrorHelperText('selectedFile')}
                                </SoftBox>

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        Add Expense
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default AddExpenses;