import React, { useEffect, useState } from "react";
import axios from "axios";
import { Typography, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import CustomSelect from "assets/theme/components/Select/CustomSelect";
import StationaryCard from "examples/Cards/StationaryCards/stationaryCard";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";

const EmpStationeryList = () => {
  const [stationary, setStationary] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [selectedID, setSelectedID] = useState("");
  
  const fetchStationaryGroup = async () => {
    try {
      const url = `${APIData.api}emp/stationary/empId?empId=${selectedID}`;
      const response = await axios.get(url, { headers: APIData.headers });
      setStationary(response.data);
    } catch (error) {
      setStationary([]);
    }
  };

  const fetchEmployeeUserNames = async () => {
    try {
      const url = `${APIData.api}employee/username?org=${org}`;
      const response = await axios.get(url, { headers: APIData.headers });
      setUserNames(response.data);
    } catch (error) {
    }
  };

  useEffect(() => {
    fetchEmployeeUserNames();
    if (selectedID) {
      fetchStationaryGroup();
    }
  }, [selectedID]);

  const handleIDChange = (selectedOption) => {
    setSelectedID(selectedOption.value);
  };

  return (
    <>
      <SoftBox mb={2} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <SoftTypography variant="h6">Select Employee Id</SoftTypography>
        <CustomSelect
          options={userNames.map(id => ({ value: id, label: id }))}
          placeholder="Select Employee"
          onChange={handleIDChange}
          value={userNames.find(id => id === selectedID) ? { value: selectedID, label: selectedID } : null}
          required
          styles={{ 
            container: (provided) => ({
              ...provided,
              width: 'auto',
              minWidth: '200px',
              maxWidth: '400px',
            }),
            control: (provided) => ({
              ...provided,
              fontSize: '0.875rem', // Smaller font size for the control
            }),
            option: (provided) => ({
              ...provided,
              fontSize: '0.875rem', // Smaller font size for the options
            }),
          }}
        />
      </SoftBox>
      <Grid container spacing={2} style={{ padding: '0 2rem 2rem', justifyContent: 'center' }}>
        {stationary.length === 0 ? (
          <Typography variant="h6" style={{ marginTop: '2rem', color: '#00a3cc', textAlign: 'center' }}>No Items</Typography>
        ) : (
          stationary.map((item) => (
            <Grid item xs={12} sm={6} md={4} key={item.asset_id}>
              <StationaryCard
              key={item.id}
              stationaryId={item.stationaryId}
              stationaryName={item.stationaryName}
              status={item.status}
              statusColor={
                item.status === "Approved" ? "success" :
                  item.status === "Rejected" ? "error" :
                    item.status === "Requested" ? "warning" : "warning"
              }
              requestedDate={item.requestedDate}
              approvedDate={item.updatedDate}
              showApprovalButtons={false}
            />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};

export default EmpStationeryList;
