import Dashboard from "layouts/dashboard";
import Profile from "layouts/profile";
import Shop from "examples/Icons/Shop";
import CustomerSupport from "examples/Icons/CustomerSupport";
import CreditCard from "examples/Icons/CreditCard";
import CustomerSalesOnboard from "sales/CustomerSalesOnboard";
import AssetList from "layouts/stores/assets-list";
import AddJob from "Super_Admin/Addjob";
import Add_designation from "Super_Admin/Add_designation";
import JobEditButtons from "sales/JobEditButtons";
import EmailServices from "layouts/emailServices";
import OrgMdmdata from "sales/OrgMdmdata";
import OrgHierarchy from "layouts/orgHierarchy/org-hierarchy";
import StationaryItems from "layouts/stationary/stationaryItems";
import CandidateData from "candidate/CandidateData";
import ProcurementList from "layouts/procurement/procurement-list";
import EmployeeIdCard from "layouts/idCards/employeeIdCard";
import LetterHead from "LetterHeadTemp/LetterHead";
import EnrollmentsData from "SqlTables/EnrollmentsData";
import CoursesTable from "SqlTables/CoursesTable";
import ClassroomTable from "SqlTables/ClassroomTable";
import TimetableComponent from "SqlTables/TimetableComponent";
import OrgPolicies from "layouts/org-policies/orgpolicies";
import { CreateSuperadmin } from "sales/CraeteSuperadmin";
import CoursesDetails from "Courses/CoursesDetails";
import Courses from "Courses/Courses";
import CoursesEnrolled from "Courses/CoursesEnrolled";
import FestivalList from "layouts/festival/festival-list";
import MarksData from "SqlTables/MarksData";
import ExpensesDetails from "Expenses/ExpensesDeatils";
import TicketTable from "layouts/ticket/ticket-list";
import AddQuestions from "question_paper/Addquestions";
import ViewQuestions from "question_paper/ViewQuestions";
import QuestionPaperByAll from "question_paper/QuestionPaperByAll";
import Schedule from "ScheduleMeeting";
import OrgPassword from "Password/OrgPassword";
import PasswordReq from "Password/PasswordReq";
import AdminDashboard from "ASAT/Components/Admin/AdminDashboard";
import CustSuportData from "sales/CustSuportData";
import SpocData from "layouts/DepartmentSpoc/SpocData";
import { InternalJob } from "Internal Job/InternalJob";
import Event from "layouts/event/eventList";
import HolidayList from "layouts/holidays/holidayList";
import RegisterationDetails from "SqlTables/RegisterationDetails";
import AdminConsoleForExit from "EmployeeExit/AdminConsoleForExit/AdminConsoleForExit";
import ResignationForm from "EmployeeExit/ResignationForm";
import DetailsForExit from "EmployeeExit/AdminConsoleForExit/DetailsForExit";
import EmployeeExit from "EmployeeExit/EmployeeExit";
import OfficeDocumentsData from "document/officeDocument";
import DocumentsData from "document/document";
import InvoiceNew from "Expenses/Invoice";



const getUserRole = () => {
  const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
  const type = sessionDetails?.userType;
  const dept = localStorage.getItem("Depart Details");
  return { type, dept };
};

const userRole = getUserRole();

const routes = [
  {
    type: "title", title: "Account Pages", key: "account-pages",
  },
  {
    type: "collapse",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <CustomerSupport size="12px" />,
    component: <Profile />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "title", title: "Finance", key: "Finance", departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Invoice",
    key: "new-invoice",
    route: "/new-invoice",
    icon: <Shop size="12px" />,
    component: <InvoiceNew/>,
    noCollapse: true,
    departments: [ "FINANCE"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Expenses",
    key: "expenses",
    route: "/expenses",
    icon: <Shop size="12px" />,
    component: <ExpensesDetails />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "title", title: "Courses", key: "Courses", departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Courses",
    key: "Courses",
    route: "/courses-details",
    icon: <Shop size="12px" />,
    component: <CoursesDetails />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Apply for Courses",
    key: "Courses",
    route: "/Apply-courses",
    icon: <Shop size="12px" />,
    component: <Courses />,
    noCollapse: true,
    userTypes: ["USER"],
    hideForSuperadmin: true,
  },
  {
    type: "collapse",
    name: "Enrolled Courses",
    key: "enrolled-Courses",
    route: "/enrolled-Courses",
    icon: <Shop size="12px" />,
    component: <CoursesEnrolled />,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
    noCollapse: true,
  },
  {
    type: "title", title: "Sales", key: "Sales", departments: ["SALES"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Customer Sales Onboarding",
    key: "customerSalesOnboarding",
    route: "/customerSalesOnboard",
    icon: <Shop size="12px" />,
    component: <CustomerSalesOnboard />,
    noCollapse: true,
    departments: ["SALES",],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Customer Mdm Onboarding",
    key: "orgmdmdata",
    route: "/orgmdmdata",
    icon: <Shop size="12px" />,
    component: <OrgMdmdata />,
    noCollapse: true,
    departments: ["SALES"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Create-superadmin",
    key: "create-superadmin",
    route: "/create-superadmin",
    icon: <Shop size="12px" />,
    component: <CreateSuperadmin />,
    noCollapse: true,
    departments: ["SALES"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Customer Onboard",
    key: "customer-support",
    route: "/customer-support",
    icon: <Shop size="12px" />,
    component: <CustSuportData />,
    noCollapse: true,
    departments: ["SALES"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "title", title: "Password", key: "Password", departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Passwords",
    key: "passwor",
    route: "/password-managing",
    icon: <Shop size="12px" />,
    component: <OrgPassword />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Requested",
    key: "requested",
    route: "/requested-password",
    icon: <Shop size="12px" />,
    component: <PasswordReq />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "title", title: "Question", key: "Question", departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Add Questions",
    key: "addquestions",
    route: "/addquestions",
    icon: <CreditCard size="12px" />,
    component: <AddQuestions />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "View Questions",
    key: "viewQuestions",
    route: "/viewQuestions",
    icon: <CreditCard size="12px" />,
    component: <ViewQuestions />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Generate Question Paper",
    key: "all-questions",
    route: "/all-questions",
    icon: <CreditCard size="12px" />,
    component: <QuestionPaperByAll />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "title", title: "TimeTable", key: "TimeTable", departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "CoursesTable",
    key: "coursesTable",
    route: "/coursesTable",
    icon: <Shop size="12px" />,
    component: <CoursesTable />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "ClassroomTable",
    key: "classroomTable",
    route: "/classroomTable",
    icon: <Shop size="12px" />,
    component: <ClassroomTable />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "TimetableComponent",
    key: "timetableComponent",
    route: "/timetableComponent",
    icon: <Shop size="12px" />,
    component: <TimetableComponent />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  { type: "title", title: "Job", key: "Job", departments: ["HR"],
    userTypes: ["EMPLOYEE"], },
  {
    type: "collapse",
    name: "Add job",
    key: "addjob",
    route: "/addjob",
    icon: <Shop size="12px" />,
    component: <AddJob />,
    noCollapse: true,
    departments: ["HR"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Add Designation",
    key: "add_designation",
    route: "/add_designation",
    icon: <Shop size="12px" />,
    component: <Add_designation />,
    noCollapse: true,
    departments: ["HR"],
    userTypes: ["EMPLOYEE"],
  },

  {
    type: "collapse",
    name: "View Jobs",
    key: "jobEditButtons",
    route: "/jobEditButtons",
    icon: <Shop size="12px" />,
    component: <JobEditButtons />,
    noCollapse: true,
    departments: ["HR"],
    userTypes: ["EMPLOYEE"],
  },

  {
    type: "title", title: "Quick Links", key: "Quick-Links", departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE",],
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <Shop size="12px" />,
    component: <Dashboard />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "ASAT Dashboard",
    key: "adminDashboard",
    route: "/adminDashboard",
    icon: <CreditCard size="12px" />,
    component: <AdminDashboard />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Candidate Onboarding",
    key: "candidateData",
    route: "/candidateData",
    icon: <Shop size="12px" />,
    component: <CandidateData />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Department Spoc",
    key: "depart-spoc",
    route: "/depart-spoc",
    icon: <Shop size="12px" />,
    component: <SpocData />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Internal Job",
    key: "internal-job",
    route: "/internal-job",
    icon: <CreditCard size="12px" />,
    component: <InternalJob/>,
    noCollapse: true,
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Scehdule Meeting",
    key: "Schedule meeting",
    route: "/schedule-meeting",
    icon: <Shop size="12px" />,
    component: <Schedule />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },

  {
    type: "collapse",
    name: "Email Service",
    key: "Email Service",
    route: "/emailServices",
    icon: <Shop size="12px" />,
    component: <EmailServices />,
    noCollapse: true,
    userTypes: ["EMPLOYEE",],
  },
  // {
  //   type: "collapse",
  //   name: " Stepper",
  //   key: "stepper",
  //   route: "/stepper",
  //   icon: <Shop size="12px" />,
  //   component: <Theme />,
  //   noCollapse: true,
  //   departments: ["TECHNICAL","HR","SALES","FINANCE","ACADEMY"],
  //   userTypes: ["EMPLOYEE"],
  // },

  {
    type: "collapse",
    name: "Enrollments",
    key: "enrollmentsData",
    route: "/enrollmentsData",
    icon: <Shop size="12px" />,
    component: <EnrollmentsData />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Cours Document",
    key: "document",
    route: "/document",
    icon: <Shop size="12px" />,
    component: <DocumentsData />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Office Document",
    key: "office-document",
    route: "/office-document",
    icon: <Shop size="12px" />,
    component: <OfficeDocumentsData />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    route: "/events",
    icon: <Shop size="12px" />,
    component: <Event />,
    noCollapse: true,
    userTypes: ["EMPLOYEE", "USER"],
  },
  {
    type: "collapse",
    name: "OrgHierarchy",
    key: "OrgHierarchy",
    route: "/orghierarchy",
    icon: <Shop size="12px" />,
    component: <OrgHierarchy />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },


  {
    type: "collapse",
    name: "LetterHeads",
    key: "letter-heads",
    route: "/letter-heads",
    icon: <Shop size="12px" />,
    component: <LetterHead />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Employee IdCard",
    key: "Employee IdCard",
    route: "/employeeIdCard",
    icon: <Shop size="12px" />,
    component: <EmployeeIdCard />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Policy",
    key: "Policy",
    route: "/orgPolicies",
    icon: <Shop size="12px" />,
    component: <OrgPolicies />,
    noCollapse: true,
    userTypes: ["EMPLOYEE", "USER"],
  },
  {
    type: "collapse",
    name: "Stationary",
    key: "stationaryItems",
    route: "/stationaryItems",
    icon: <Shop size="12px" />,
    component: <StationaryItems />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Stores",
    key: "assets-list",
    route: "/assets-list",
    icon: <Shop size="12px" />,
    component: <AssetList />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Procurement",
    key: "procurement-list",
    route: "/procurement-list",
    icon: <Shop size="12px" />,
    component: <ProcurementList />,
    noCollapse: true,
    departments: ["TECHNICAL", "HR", "SALES", "FINANCE", "ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "Festival",
    key: "festival-list",
    route: "/festival-list",
    icon: <Shop size="12px" />,
    component: <FestivalList />,
    noCollapse: true,
    userTypes: ["EMPLOYEE", "USER"],
  },
  {
    type: "collapse",
    name: "HolidaysList",
    key: "holidaysList",
    route: "/holidaysList",
    icon: <CreditCard size="12px" />,
    component: <HolidayList/>,
    noCollapse: true,
  },

  {
    type: "collapse",
    name: "Query",
    key: "ticket-list",
    route: "/ticket-list",
    icon: <CreditCard size="12px" />,
    component: <TicketTable />,
    noCollapse: true,
    departments: ["TECHNICAL",],
    userTypes: ["EMPLOYEE"],
  },

  {
    type: "collapse",
    name: "MarksData",
    key: "marksData",
    route: "/marksData",
    icon: <CreditCard size="12px" />,
    component: <MarksData />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  {
    type: "collapse",
    name: "ASAT Dashboard",
    key: "adminDashboard",
    route: "/adminDashboard",
    icon: <CreditCard size="12px" />,
    component: <AdminDashboard />,
    noCollapse: true,
  },
  { type: "title", title: "Student", key: "Student",departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"], },
  {
    type: "collapse",
    name: "Student Details",
    key: "registerationDetails",
    route: "/registerationDetails",
    icon: <Shop size="12px" />,
    component: <RegisterationDetails />,
    noCollapse: true,
    departments: ["ACADEMY"],
    userTypes: ["EMPLOYEE"],
  },
  { type: "title", title: "Employee Exit", key: "Employee Exit" },
  {
    type: "collapse",
    name: "Employee Exit Dashboard",
    key: "adminConsoleForExit",
    route: "/adminConsoleForExit",
    icon: <Shop size="12px" />,
    component: <AdminConsoleForExit />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Empoyee Exit Overview",
    key: "detailsForExit",
    route: "/detailsForExit",
    icon: <Shop size="12px" />,
    component: <DetailsForExit />,
    noCollapse: true,
  },
  {
    type: "collapse",
    name: "Empoyee Exit",
    key: "employeeExit",
    route: "/employeeExit",
    icon: <Shop size="12px" />,
    component: <EmployeeExit />,
    noCollapse: true,
  },

];

const filteredRoutes = routes.filter(route => {
  // If the route is explicitly hidden, exclude it
  if (route.visible === false) {
    return false;
  }
  if (route.hideForSuperadmin && userRole.type === "SUPERADMIN") {
    return false; // Hide this route for superadmin
  }
  // If userType is superadmin, show all routes 
  if (userRole.type === "SUPERADMIN") {
    return true;
  }

  // If userType is listed for this route, include this route
  if (route.userTypes) {
    // Check if the current userType is allowed for this route
    if (route.userTypes.includes(userRole.type)) {
      // Additionally, check if the department is allowed (if specified)
      if (route.departments && route.departments.includes(userRole.dept)) {
        return true;
      }
      // Show route if no department restrictions or user is in the allowed department
      if (!route.departments) {
        return true;
      }
    }
  }

  return false; 
});

export default filteredRoutes;

