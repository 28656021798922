import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { TableRow, TableCell, Button, Backdrop, CircularProgress, Card, DialogActions, TextField, DialogContent, DialogTitle, Dialog, Typography, Tooltip } from '@mui/material';
import Swal from 'sweetalert2';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Table from "examples/Tables/Table";
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import { APIData, org } from 'authentication/APIData';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.grey,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
}));

const HolidayList = () => {
    const [tasks, setTasks] = useState([]);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [selectedTask, setSelectedTask] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const [fullDescription, setFullDescription] = useState('');
    const [loading, setLoading] = useState(true);
    const [editForm, setEditForm] = useState({
        holiday_name: '',
        holiday_date: '',
        description: ''
    });
    const [errors, setErrors] = useState({
        holiday_name: '',
        holiday_date: '',
        description: '',
    });
    const navigate = useNavigate();

    useEffect(() => {
        fetchData();
    }, [org]);

    const fetchData = async () => {
        try {
            const url = `${APIData.api}holidays/?org=${org}`;
            const response = await fetch(url, { headers: APIData.headers });
            if (response.ok) {
                const data = await response.json();
                setTasks(data);
                setLoading(false);
            } else {
                Swal.fire('Error', 'Failed to fetch holidays', 'error');
                setLoading(false);
            }
        } catch (error) {
            Swal.fire('Error', 'Error fetching holidays', 'error');
            setLoading(false);
        }
    };

    const handleSearch = (event) => setSearchTerm(event.target.value.toLowerCase());

    const handleSort = (field) => {
        let direction = "asc";
        if (sortField === field && sortDirection === "asc") {
            direction = "desc";
        }
        setSortField(field);
        setSortDirection(direction);
    };

    const handlePageChange = (event, page) => setCurrentPage(page + 1);

    const handleUpdate = (task) => {
        setIsDialogOpen(true);
        setSelectedTask(task);
        setEditForm({
            holiday_name: task.holiday_name,
            holiday_date: task.holiday_date,
            description: task.description
        });

    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;
        if (!editForm.holiday_name.trim()) {
            newErrors.holiday_name = 'Holiday name is required';
            isValid = false;
        }
        if (editForm.holiday_name.length > 50) {
            newErrors.holiday_name = 'Holiday name must be less than 50 characters';
            isValid = false;
        }
        if (!editForm.holiday_date) {
            newErrors.holiday_date = 'Holiday date is required';
            isValid = false;
        }
        if (!editForm.description.trim()) {
            newErrors.description = 'Description is required';
            isValid = false;
        }
        if (editForm.description.length > 250) {
            newErrors.description = 'Description must be less than 250 characters';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };
    const handleEditFormChange = (e) => {
        const { name, value } = e.target;
        setEditForm((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSave = () => {
        if (validateForm()) {
            handleUpdateHoliday(editForm);
            setIsDialogOpen(false);
        }
    };

    const handleUpdateHoliday = async () => {
        setIsDialogOpen(false);
        const form = {
            id: selectedTask.id,
            holiday_name: editForm.holiday_name,
            holiday_date: editForm.holiday_date,
            description: editForm.description,
            org: selectedTask.org
        };
        const url = `${APIData.api}holidays/${selectedTask.id}`;
        try {
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    ...APIData.headers
                },
                body: JSON.stringify(form),
            });

            const data = await response.json();
            console.log(response.status);
            console.log(JSON.stringify(form));

            if (response.status == 200 || response.status == 201) {
                Swal.fire('Success', 'Updated successfully', 'success');
                fetchData();
            } else {
                Swal.fire('Error', 'Failed to update', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An error occurred during the update', 'error');
            console.error('Error during the PUT request:', error);
        }
    };


    const handleDelete = (task) => {
        const id = task.id;
        const url = `${APIData.api}holidays/?id=${id}`;
        Swal.fire({
            title: 'Are you sure?',
            text: 'This action cannot be undone once deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                fetch(url, {
                    method: 'DELETE',
                    headers: APIData.headers,
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.status === 'success') {
                            Swal.fire('Success', 'Deleted successfully', 'success');
                            fetchData();
                        } else {
                            Swal.fire('Error', 'Failed to delete', 'error');
                        }
                    })
                    .catch(error => {
                        Swal.fire('Error', "An error occurred", 'error');
                    });
            }
        });
    };


    const renderDescription = (description) => {
        const words = description.split(' ');
        if (words.length > 7) {
            return (
                <>
                    {words.slice(0, 7).join(' ')}...
                    <Button onClick={() => { setFullDescription(description); setIsDescriptionModalOpen(true); }} variant="text" color="primary">
                        Know More
                    </Button>
                </>
            );
        }
        return description;
    };

    const filteredTasks = tasks.filter(task =>
        Object.values(task).some(value =>
            value.toString().toLowerCase().includes(searchTerm)
        )
    );

    const sortedTasks = [...filteredTasks].sort((a, b) => {
        const aValue = typeof a[sortField] === 'string' ? a[sortField].toLowerCase() : a[sortField];
        const bValue = typeof b[sortField] === 'string' ? b[sortField].toLowerCase() : b[sortField];

        return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
    });

    const lastIndex = currentPage * itemsPerPage;
    const firstIndex = lastIndex - itemsPerPage;
    const currentItems = sortedTasks.slice(firstIndex, lastIndex);

    if (loading) {
        return (
            <Backdrop open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }

    const columns = [
        { name: "Holiday Name", align: "left" },
        { name: "Holiday Date", align: "left" },
        { name: "Description", align: "left" },
        { name: "Action", align: "center" },
    ];

    const rows = currentItems.map((task) => ({
        "Holiday Name": <SoftTypography variant="button" fontWeight="medium">
            {task.holiday_name}
        </SoftTypography>,
        "Holiday Date": task.holiday_date,
        Description: renderDescription(task.description),
        Action: (
            <div>
                <SoftTypography
                    component="span"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setErrors({});
                        return handleUpdate(task);
                    }}
                >
                    Edit
                </SoftTypography>
                <SoftTypography
                    component="span"
                    variant="button"
                    color="error"
                    fontWeight="medium"
                    style={{ cursor: 'pointer' }}
                    sx={{ marginLeft: "10px" }}
                    onClick={() => {
                        return handleDelete(task);
                    }}
                >
                    Delete
                </SoftTypography>
            </div>
        ),
    }));

    const handleNewHolidayClick = () => {
        navigate("/holiday-form");
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                        <SoftTypography variant="h4"
                            fontWeight="medium"
                            textAlign="left"
                            textTransform="capitalize"
                            style={{ margin: 0 }}>Holiday List</SoftTypography>
                            <Tooltip title="Add New Holiday" arrow>
                            <SoftButton variant="gradient" color="info" onClick={handleNewHolidayClick}>
                                + Add
                            </SoftButton>
                        </Tooltip>

                    </SoftBox>
                    <Card>
                        <SoftBox
                            sx={{
                                "& .MuiTableRow-root:not(:last-child)": {
                                    "& td": {
                                        borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                                            `${borderWidth[1]} solid ${borderColor}`,
                                    },
                                },
                            }}
                        >
                            <Table columns={columns} rows={rows} />
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>
            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="md">
                <DialogTitle>Edit Holiday</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">Holiday Name</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="holiday_name"
                        value={editForm.holiday_name}
                        onChange={handleEditFormChange}
                        margin="normal"
                        error={Boolean(errors.holiday_name)}
                        helperText={errors.holiday_name}
                    />
                    <Typography variant="h6">Holiday Date</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="holiday_date"
                        type="date"
                        value={editForm.holiday_date}
                        onChange={handleEditFormChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={Boolean(errors.holiday_date)}
                        helperText={errors.holiday_date}
                    />
                    <Typography variant="h6">Description</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="description"
                        value={editForm.description}
                        onChange={handleEditFormChange}
                        multiline
                        rows={4}
                        error={Boolean(errors.description)}
                        helperText={errors.description}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

HolidayList.propTypes = {
    org: PropTypes.string.isRequired,
};

export default HolidayList;
