import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Card from "@mui/material/Card";
import { Grid, CircularProgress } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import Footer from "examples/Footer";

const JobView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { job_id } = useParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const sessiondetails = localStorage.getItem("sessiondetails");

  useEffect(() => {
    const job = location.state?.job || JSON.parse(localStorage.getItem('selectedItem'));

    if (job) {
      setSelectedItem(job);
      localStorage.setItem('selectedItem', JSON.stringify(job));
    } else {
      navigate('/userjobs');
    }
  }, [location.state, job_id, navigate]);

  if (!selectedItem) {
    return (
      <PageLayout>
        <DefaultNavbar />
        <SoftBox display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
          <CircularProgress />
        </SoftBox>
        <Footer />
      </PageLayout>
    );
  }

  const renderDetailItem = (label, value) => (
    <SoftBox mb={1} display="flex" alignItems="center">
      <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
        {label}:
      </SoftTypography>
      <SoftTypography variant="h6" fontWeight="regular" ml={1} color="text">
        {value}
      </SoftTypography>
    </SoftBox>
  );

  const renderDescriptionSection = (title, content) => (
    <SoftBox mt={2}>
      <SoftTypography variant="h4" fontWeight="medium" mb={2} color="secondary">
        {title}
      </SoftTypography>
      <SoftBox component="ul" sx={{ pl: 2 }}>
        {content && content.split('.').map((sentence, index) => (
          sentence.trim() && (
            <SoftTypography
              key={index}
              component="li"
              variant="body2"
              color="text"
              mb={1}
            >
              {sentence.trim()}
            </SoftTypography>
          )
        ))}
      </SoftBox>
    </SoftBox>
  );

  const handleApply = () => {
    navigate("/jobApplyPage", {
      state: {
        jobId: selectedItem.job_id,
        Role: selectedItem.role,
        designation: selectedItem.job_designation,
        branch: selectedItem.branch,
        department: selectedItem.job_dept,
        createdBy: selectedItem.createdBy,
        updatedBy: selectedItem.updatedBy
      }
    });
  };

  return (
    <PageLayout>
      {!sessiondetails && <DefaultNavbar />}
      <SoftBox mb={3} mt={6} display="flex" justifyContent="center">
        <Card
          sx={{
            p: 4,
            mt: 10,
            borderRadius: 3,
            boxShadow: "0 8px 20px rgba(0, 0, 0, 0.12)",
            maxWidth: { xs: "80%", md: "60%", lg: "50%" },
            width: "100%"
          }}
        >
          <SoftBox display="flex" flexDirection="column" alignItems="center" mb={4}>
            <SoftTypography variant="h4" fontWeight="bold" mb={1}>
              {selectedItem.job_designation}
            </SoftTypography>
            <SoftTypography variant="h6" color="text" mb={1}>
              Job Role: {selectedItem.job_id}
            </SoftTypography>
          </SoftBox>

          <Grid container spacing={3}>
            {/* Job Details and Image Section */}
            <Grid item xs={12} md={6}>
              <SoftBox>
                <SoftTypography variant="h4" fontWeight="medium" mb={3}>
                  JOB DETAILS
                </SoftTypography>
                {renderDetailItem("Department", selectedItem.job_dept)}
                {renderDetailItem("Job Type", selectedItem.job_type)}
                {renderDetailItem("Salary", selectedItem.salary)}
                {renderDetailItem("Job Tenure", selectedItem.job_tenure)}
                {renderDetailItem("Location", selectedItem.location)}
                {/* {renderDetailItem("Branch", selectedItem.branch)} */}
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={4} display="flex" justifyContent="center" alignItems="center">
              <img
                src={selectedItem.image_url}
                alt={selectedItem.job_designation}
                style={{
                  maxWidth: "100%",
                  maxHeight: "250px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)"
                }}
              />
            </Grid>

            {/* Description and Roles Section */}
            <Grid item xs={12}>
              {renderDescriptionSection("Job Description", selectedItem.description)}
              {selectedItem.roles_and_resp &&
                renderDescriptionSection("Roles and Responsibilities", selectedItem.roles_and_resp)
              }
            </Grid>

            {/* Apply Button Section */}
            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
              <SoftBox textAlign="center" mt={2}>
                <SoftTypography variant="h6" fontWeight="medium" mb={2}>
                  Ready to Apply?
                </SoftTypography>
                <SoftButton
                  variant="gradient"
                  color="info"
                  onClick={handleApply}
                  sx={{ transition: "all 0.3s", "&:hover": { boxShadow: "0px 5px 15px rgba(0, 123, 255, 0.5)" } }}
                >
                  Apply Now
                </SoftButton>
              </SoftBox>
            </Grid>
          </Grid>
        </Card>
      </SoftBox>
      <Footer />
    </PageLayout>
  );
};

export default JobView;