import React from "react";
import PropTypes from "prop-types";
import { Card, Grid, Typography } from "@mui/material";
import { FaBoxOpen } from "react-icons/fa";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftBadge from "components/SoftBadge";

function StationaryCard({
  stationaryId,
  stationaryName,
  status,
  count,
  empId,
  empEmail,
  approvedDate,
  requestedDate,
  statusColor,
  onApprove,
  onReject,
  showApprovalButtons
}) {
  return (
    <Card
      sx={{
        padding: "1.5rem",
        borderRadius: "10px",
        boxShadow: 2,
        backgroundColor: "#f7f9fc",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between"
      }}
    >
      <div style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
      }}>
        <Typography
          variant="h6"
          sx={{
            margin: 0,
            color: "#003380",
            fontWeight: "600"
          }}
        >
          {stationaryName}
        </Typography>
      </div>

      <div style={{ flex: 1 }}>
        <InfoRow label="Stationary ID" value={stationaryId} />
        {empId && <InfoRow label="Employee ID" value={empId} />}
        {empEmail && <InfoRow label="Email" value={empEmail} />}
        {count !== undefined && <InfoRow label="Count" value={count} />}
        
        <InfoRow
          label="Status"
          value={<SoftBadge variant="gradient" badgeContent={status} color={statusColor} size="xs" container />}
        />
        {approvedDate && <InfoRow label="Approved Date" value={approvedDate} />}
        {requestedDate && <InfoRow label="Requested Date" value={requestedDate} />}
      </div>

      {showApprovalButtons && (
        <Grid item xs={12}>
          <SoftBox mt={4} mb={1}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <SoftButton fullWidth color="info" variant="gradient" onClick={onApprove}>
                  Approve
                </SoftButton>
              </Grid>
              <Grid item xs={6}>
                <SoftButton fullWidth color="secondary" variant="gradient" onClick={onReject}>
                  Reject
                </SoftButton>
              </Grid>
            </Grid>
          </SoftBox>
        </Grid>
      )}
    </Card>
  );
}

// Updated InfoRow component
const InfoRow = ({ label, value, valueStyle = {} }) => (
  <Typography
    sx={{
      margin: "0.5rem 0",
      fontSize: "1rem",
      color: "#555"
    }}
  >
    <strong>{label}:</strong> <span style={valueStyle}>{value}</span>
  </Typography>
);

StationaryCard.propTypes = {
  stationaryId: PropTypes.string.isRequired,
  stationaryName: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  count: PropTypes.number,
  empId: PropTypes.string, 
  empEmail: PropTypes.string, 
  approvedDate: PropTypes.string,
  requestedDate: PropTypes.string,
  statusColor: PropTypes.string.isRequired,
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  showApprovalButtons: PropTypes.bool.isRequired,
};

InfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  valueStyle: PropTypes.object,
};

export default StationaryCard;
