
// import React from 'react';
// import PropTypes from 'prop-types';
// import SoftBox from 'components/SoftBox';
// import SoftTypography from 'components/SoftTypography';
// import Card from "@mui/material/Card";
// import SoftButton from 'components/SoftButton';

// function DefaultProjectCard2({
//   image,
//   title,
//   description,
//   label,
//   onViewAssetClick,
//   onDeleteClick,
//   viewButtonLabel = "View",
//   deleteButtonLabel = "Delete",
//   buttonSize = "medium",
//   showViewButton = true,
//   showDeleteButton = true
// }) {
//   return (
//     <Card sx={{ height: '400px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
//       <SoftBox p={2} sx={{ flexGrow: 1 }}>
//         {image && (
//           <SoftBox component="img" src={image} alt={title} width="100%" sx={{ height: '150px', objectFit: 'cover', mb: 2 }} />
//         )}

//         {label && (
//           <SoftTypography variant="caption" fontWeight="medium" color="textSecondary" mt={1}>
//             {label}
//           </SoftTypography>
//         )}

//         <SoftTypography variant="h6" fontWeight="medium" mt={2}>
//           {title}
//         </SoftTypography>

//         <SoftTypography variant="body2" color="text" mb={1}>
//           {description}
//         </SoftTypography>
//       </SoftBox>

//       <SoftBox sx={{ display: 'flex', justifyContent: 'space-between', mt: 1, p: 2, gap: 2 }}>
//         {showViewButton && !showDeleteButton && (
//           <SoftButton
//             variant="outlined"
//             size={buttonSize}
//             color="info"
//             onClick={onViewAssetClick}
//             sx={{ width: '100%' }}
//           >
//             {viewButtonLabel}
//           </SoftButton>
//         )}

//         {showDeleteButton && !showViewButton && (
//           <SoftButton
//             variant="outlined"
//             size={buttonSize}
//             color="error"
//             onClick={onDeleteClick}
//             sx={{ width: '100%' }}
//           >
//             {deleteButtonLabel}
//           </SoftButton>
//         )}

//         {showViewButton && showDeleteButton && (
//           <>
//             <SoftButton
//               variant="outlined"
//               size={buttonSize}
//               color="info"
//               onClick={onViewAssetClick}
//               sx={{ width: '50%', mr: 1 }}
//             >
//               {viewButtonLabel}
//             </SoftButton>

//             <SoftButton
//               variant="outlined"
//               size={buttonSize}
//               color="error"
//               onClick={onDeleteClick}
//               sx={{ width: '50%' }}
//             >
//               {deleteButtonLabel}
//             </SoftButton>
//           </>
//         )}
//       </SoftBox>
//     </Card>
//   );
// }

// DefaultProjectCard2.propTypes = {
//   image: PropTypes.string,
//   title: PropTypes.string.isRequired,
//   description: PropTypes.string,
//   label: PropTypes.string,
//   onViewAssetClick: PropTypes.func.isRequired,
//   onDeleteClick: PropTypes.func.isRequired,
//   viewButtonLabel: PropTypes.string,
//   deleteButtonLabel: PropTypes.string,
//   buttonSize: PropTypes.oneOf(["small", "medium", "large"]),
//   showViewButton: PropTypes.bool,
//   showDeleteButton: PropTypes.bool
// };

// DefaultProjectCard2.defaultProps = {
//   image: null,
//   description: '',
//   label: '',
//   viewButtonLabel: 'View',
//   deleteButtonLabel: 'Delete',
//   buttonSize: 'medium',
//   showViewButton: true,
//   showDeleteButton: true
// };

// export default DefaultProjectCard2;


import React from 'react';
import PropTypes from 'prop-types';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import Card from '@mui/material/Card';
import SoftButton from 'components/SoftButton';
import EditIcon from '@mui/icons-material/Edit'; // Importing the Edit icon
import { Tooltip } from '@mui/material';

function DefaultProjectCard2({
  image,
  title,
  description,
  label,
  onViewAssetClick,
  onDeleteClick,
  onEditClick,
  viewButtonLabel = 'View',
  deleteButtonLabel = 'Delete',
  buttonSize = 'medium',
  showViewButton = true,
  showDeleteButton = true,
  showEditButton = true, // Control visibility of the Edit button
}) {
  return (
    <Card sx={{ height: '370px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <SoftBox pl={2} pr={2} pt={2} pb={0} sx={{ flexGrow: 1, position: 'relative' }}>
        {image && (
          <SoftBox
            component="img"
            src={image}
            alt={title}
            width="100%"
            sx={{ height: '150px', objectFit: 'cover', mb: 2, position: 'relative' }}
          />
        )}

        {/* Edit button over the image */}
        <Tooltip title="Edit" arrow>
        {showEditButton && (
          <SoftButton
            variant="outlined"
            size={buttonSize}
            color="warning"
            onClick={onEditClick}
            sx={{
              position: 'absolute',
              top: 10,
              right: 10,
              zIndex: 2,
              backgroundColor: 'rgba(255, 165, 0, 0.8)', // Semi-transparent background
              color: '#fff',
              minWidth: 'auto',
              padding: '4px 8px',
            }}
          >
            <EditIcon /> {/* Adding the pencil icon */}
          </SoftButton>
        )}
        </Tooltip>

        {label && (
          <SoftTypography variant="caption" fontWeight="medium" color="textSecondary" mt={1}>
            {label}
          </SoftTypography>
        )}

        <SoftTypography variant="h6" fontWeight="medium" mt={2}>
          {title}
        </SoftTypography>

        <SoftTypography variant="body2" color="text" mb={0}>
          {description}
        </SoftTypography>
      </SoftBox>

      <SoftBox sx={{ display: 'flex', flexDirection: 'column', gap: 1, px: 2, pb: 2, mt: 0}}>
        <SoftBox sx={{ display: 'flex', justifyContent: 'space-between', mt: 0, gap: 2 }}>
          {showViewButton && !showDeleteButton && (
            <SoftButton
              variant="gradient"
              size={buttonSize}
              color="info"
              onClick={onViewAssetClick}
              sx={{ width: '100%' }}
            >
              {viewButtonLabel}
            </SoftButton>
          )}

          {showDeleteButton && !showViewButton && (
            <SoftButton
              variant="outlined"
              size={buttonSize}
              color="error"
              onClick={onDeleteClick}
              sx={{ width: '100%' }}
            >
              {deleteButtonLabel}
            </SoftButton>
          )}

          {showViewButton && showDeleteButton && (
            <>
              <SoftButton
                variant="outlined"
                size={buttonSize}
                color="info"
                onClick={onViewAssetClick}
                sx={{ width: '50%', mr: 1 }}
              >
                {viewButtonLabel}
              </SoftButton>

              <SoftButton
                variant="outlined"
                size={buttonSize}
                color="error"
                onClick={onDeleteClick}
                sx={{ width: '50%' }}
              >
                {deleteButtonLabel}
              </SoftButton>
            </>
          )}
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

DefaultProjectCard2.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  label: PropTypes.string,
  onViewAssetClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired, // Prop type for Edit button click
  viewButtonLabel: PropTypes.string,
  deleteButtonLabel: PropTypes.string,
  buttonSize: PropTypes.oneOf(['small', 'medium', 'large']),
  showViewButton: PropTypes.bool,
  showDeleteButton: PropTypes.bool,
  showEditButton: PropTypes.bool, // Prop type for Edit button visibility
};

DefaultProjectCard2.defaultProps = {
  image: null,
  description: '',
  label: '',
  viewButtonLabel: 'View',
  deleteButtonLabel: 'Delete',
  buttonSize: 'medium',
  showViewButton: true,
  showDeleteButton: true,
  showEditButton: true, // Edit button visible by default
};

export default DefaultProjectCard2;