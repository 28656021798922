import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Grid, CircularProgress, Backdrop, Paper } from '@mui/material';
import { Card } from "@mui/material";
import PropTypes from 'prop-types';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import Swal from 'sweetalert2';



const initialState = {
    designated_person: '',
    designated_person_error: '',
    email_id: '',
    email_id_error: ''
};

const DepartmentSpoc = ({ onSubmit, submitRef }) => {
    const [loading, setLoading] = useState(true);
    const [dataExists, setDataExists] = useState(false);
    const [formStates, setFormStates] = useState({
        HR: { ...initialState, department: 'HR', roles: 'HR ' },
        TECHNICAL: { ...initialState, department: 'TECHNICAL', roles: 'TECHNICAL ' },
        FINANCE: { ...initialState, department: 'FINANCE', roles: 'FINANCE ' },
        SALES: { ...initialState, department: 'SALES', roles: 'SALES ' },
        ACADEMY: { ...initialState, department: 'ACADEMY', roles: 'ACADEMY ' },
        EVENTS: { ...initialState, department: 'EVENTS', roles: 'EVENTS ' },
        ENROLLMENTS: { ...initialState, department: 'ENROLLMENTS', roles: 'ENROLLMENTS ' },
        ORGCOMM: { ...initialState, department: 'ORGCOMM', roles: 'COMMUNICATION ' },
        SUPPORT: { ...initialState, department: 'SUPPORT', roles: 'SUPPORT ' },
    });
    const [Empdetails, setEmpdetails] = useState([]);

    useEffect(() => {
        checkIfDataExists();
        fetchEmpDetails();
        window.scrollTo(0, 0);
    }, []);

    const checkIfDataExists = () => {
        axios.get(`${APIData.api}org-roles-head/?org=${org}`, { headers: APIData.headers })
            .then((resp) => {
                if (resp.data.length > 0) {
                    setDataExists(true);
                //    alert("Details have already been entered.");
                } else {
                    setDataExists(false);
                }
            })
            .catch((err) => {
                console.log(err);
                // alert("Error checking existing details.");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const fetchEmpDetails = () => {
        axios.get(`${APIData.api}login-type/Details?org=${org}`, { headers: APIData.headers })
            .then((resp) => {
                setEmpdetails(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const validation = (formState) => {
        let email_id_error = '';
        let designated_person_error = '';

        if (!formState.email_id) {
            email_id_error = "Email is required.";
        } else if (!/\S+@\S+\.\S+/.test(formState.email_id)) {
            email_id_error = "Invalid Email Format.";
        }

        if (!formState.designated_person) {
            designated_person_error = "Designated Person is required.";
        }

        return { designated_person_error, email_id_error };
    };

    const changeHandler = (e, formKey) => {
        const { name, value } = e.target;

        setFormStates(prevStates => ({
            ...prevStates,
            [formKey]: {
                ...prevStates[formKey],
                [name]: value,
                [`${name}_error`]: ''
            }
        }));

        const { designated_person_error, email_id_error } = validation({
            ...formStates[formKey],
            [name]: value,
        });

        setFormStates(prevStates => ({
            ...prevStates,
            [formKey]: {
                ...prevStates[formKey],
                designated_person_error,
                email_id_error
            }
        }));
    };

    const submitAllHandler = (e) => {
        e.preventDefault();
        let hasErrors = false;

        const allData = Object.keys(formStates).map(formKey => {
            const formState = formStates[formKey];
            const { designated_person_error, email_id_error } = validation(formState);

            if (designated_person_error || email_id_error) {
                setFormStates(prevStates => ({
                    ...prevStates,
                    [formKey]: {
                        ...prevStates[formKey],
                        designated_person_error,
                        email_id_error
                    }
                }));
                hasErrors = true;
                return null;
            }

            return {
                supervisor: formState.designated_person,
                email_id: formState.email_id,
                id: 0,
                opt: "YES",
                org: org,
                roles: formState.roles,
            };
        }).filter(Boolean);

        if (!hasErrors && allData.length > 0) {
            setLoading(true);
            axios.post(`${APIData.api}org-roles-head/`, allData, { headers: APIData.headers })
                .then(response => {
                    Swal.fire({
                        icon: "success",
                        title: "Successfully Updated",
                        showConfirmButton: false,
                        timer: 1500
                    });
                    // console.log(response);
                    onSubmit(true);
                })
                .catch(error => {
                    // alert(`Please contact administrator ${error}`);
                    Swal.fire({
                        icon: "error",
                        title: "Update Error",
                        showConfirmButton: false,
                        timer: 1500,
                        text: error.response.data.message 
                        
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (hasErrors) {
            alert("Please fill out all required fields correctly.");
        }
    };

    return (
        <Grid container spacing={0} justifyContent="center" alignItems="center">
            <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* {dataExists ? (
                <SoftTypography variant="h6" color="success">
                    Details have already been entered for this organization.
                </SoftTypography>
            ) : ( */}
                <Card sx={{ height: 'auto' }}>
                    <SoftBox pt={1} pb={3} px={3}>
                        {/* <SoftBox mb={2}>
                            <SoftTypography variant="h4" textAlign="center">
                                Set Department Specific Spoc
                            </SoftTypography>
                        </SoftBox> */}
                        <SoftBox component="form" onSubmit={submitAllHandler}>
                            <Grid container spacing={2}>
                                {Object.keys(formStates).map((formKey) => (
                                    <Grid item xs={12} sm={6} md={4} key={formKey}>
                                        <SoftBox mb={2}>
                                        <Paper elevation={3} sx={{ padding: 2 }}>
                                            <SoftTypography variant="h6">
                                                {formStates[formKey].roles}
                                            </SoftTypography>
                                            
                                            <SoftBox mb={2}>
                                                <SoftTypography variant="h6">Designated Person</SoftTypography>
                                                <SoftInput
                                                    name="designated_person"
                                                    placeholder="Designated Person"
                                                    value={formStates[formKey].designated_person}
                                                    onChange={(e) => changeHandler(e, formKey)}
                                                    error={!!formStates[formKey].designated_person_error}
                                                    required
                                                />
                                                {formStates[formKey].designated_person_error && (
                                                    <SoftTypography variant="caption" color="error">
                                                        {formStates[formKey].designated_person_error}
                                                    </SoftTypography>
                                                )}
                                            </SoftBox>

                                            <SoftBox mb={2}>
                                                <SoftTypography variant="h6">Email ID</SoftTypography>
                                                <SoftInput
                                                    name="email_id"
                                                    placeholder="Email ID"
                                                    required
                                                    value={formStates[formKey].email_id}
                                                    onChange={(e) => changeHandler(e, formKey)}
                                                    error={!!formStates[formKey].email_id_error}
                                                />
                                                {formStates[formKey].email_id_error && (
                                                    <SoftTypography variant="caption" color="error">
                                                        {formStates[formKey].email_id_error}
                                                    </SoftTypography>
                                                )}
                                            </SoftBox>
                                            </Paper>
                                        </SoftBox>
                                    </Grid>
                                ))}
                            </Grid>
                            <SoftBox mt={4} mb={1}>
                                <Grid container justifyContent="center">
                                    <Grid item>
                                        <button
                                            type="submit"
                                            ref={submitRef}
                                            style={{ display: 'none' }}
                                        />
                                    </Grid>
                                </Grid>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </Card>
           
        </Grid>
    );
};

DepartmentSpoc.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    submitRef: PropTypes.object,
};

export default DepartmentSpoc;