import React, { useEffect, useState } from 'react';
import { Button, Grid, Container, Dialog, DialogContent, IconButton, Card } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import axios from 'axios';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import Swal from 'sweetalert2';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import RelievingTemp from './Reliveing';
import { useLocation, useNavigate } from 'react-router-dom';
import SoftButton from 'components/SoftButton';

const initialState = {
    header1: "", header2: "", header3: "", header4: "", header5: "",
    header6: "", header7: "", header8: "", header9: "", header10: "",
    header11: "", header12: "", header13: "", header14: "", header15: "",
    header16: "", header17: "", header18: "", header19: "", header20: "",
    header21: "", para1: "", para2: "", para3: "", para4: "", para5: "",
    para6: "", para7: "", para8: "", para9: "", para10: "", para11: "",
    para12: "", para13: "", para14: "", para15: "", para16: "", para17: "",
    para18: "", para19: "", para20: "", para21: "", para22: "",
    id: 0, org: org, temp_name: "EMPRELIEVING", title: ""
};

const containerStyle = {
    color: '#333',
    margin: 'auto',
    backgroundColor: '#fff',
};

const headerStyle = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
};

const logoStyle = {
    width: '80px',
    height: '80px',
};

const contentStyle = {
    lineHeight: '1.6',
};

function RelievingEdit() {
    const [formData, setFormData] = useState(initialState);
    const [orgdetails, setOrgdetails] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    
    const handleBack = () => {
        if (location.state?.returnToStepper) {
            navigate(location.state.returnPath);
        } else {
            navigate(-1);
        }
    };

    useEffect(() => {
        fetchOrgDetails();
    }, []);

    const fetchOrgDetails = () => {
        axios.get(`${APIData.api}org-mdm/org-id?orgId=${org}`, { headers: APIData.headers })
            .then((response) => setOrgdetails(response.data))
            .catch((error) => console.error(error));
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post(APIData.api + `db-template/`, formData, { headers: APIData.headers })
            .then(() => {
                Swal.fire({
                    icon: "success",
                    title: "Successfully Updated",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch((error) => {
                console.log(error);
                Swal.fire({
                    icon: "error",
                    title: "Update Error",
                    text: error.response.data
                });
            });
    };

    const handleClickOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const renderTemplateContent = () => (
        <div style={containerStyle}>
            <SoftBox component="header" style={headerStyle}>
                <img src={orgdetails?.orgLogo} style={logoStyle} alt="Organization Logo" />
                <SoftBox ml={2}>
                    <SoftTypography variant="h4">{orgdetails?.orgName}</SoftTypography>
                    <SoftTypography variant="body2">+91 {orgdetails?.orgMob}</SoftTypography>
                    <SoftTypography variant="body2">{orgdetails?.orgEmailId}</SoftTypography>
                    <SoftTypography variant="body2">{orgdetails?.orgUrl}</SoftTypography>
                </SoftBox>
            </SoftBox>

            <SoftBox component="div" style={contentStyle}>
                <SoftBox textAlign="center">
                    <SoftTypography variant="h4" component="h2" sx={{ textDecoration: 'underline', my: 2 }}>
                        {formData.title}
                    </SoftTypography>
                </SoftBox>

                <SoftTypography variant="body1" mb={2}>
                    Date: 23/08/2024
                </SoftTypography>

                <SoftBox mb={3}>
                    <SoftTypography variant="body1">To,</SoftTypography>
                    <SoftTypography variant="body1">Adhithyan P A</SoftTypography>
                    <SoftTypography variant="body1">Emp ID: c4e2024adhithyan__p_a</SoftTypography>
                </SoftBox>

                <SoftTypography variant="body1" fontWeight="bold" mb={2}>
                    {formData.para1}
                </SoftTypography>

                <SoftTypography variant="body1" mb={2}>
                    Dear Adhithyan P A,
                </SoftTypography>

                <SoftBox mb={3}>
                    <SoftTypography variant="body1">
                        {formData.para2} <strong>Care4Edu Solutions Pvt Ltd</strong>, {formData.para3} <strong>23/08/2024</strong> {formData.para4}
                    </SoftTypography>
                </SoftBox>

                <SoftBox mb={3}>
                    <SoftTypography variant="body1">
                        {formData.para5}
                    </SoftTypography>
                </SoftBox>

                <SoftBox mb={3}>
                    <SoftTypography variant="body1">
                        {formData.para6} Care4Edu Solutions Pvt Ltd. {formData.para7}
                    </SoftTypography>
                </SoftBox>

                <SoftBox component="ul" sx={{ pl: 3, mb: 4 }}>
                    <li>Your last working day with Care4Edu Solutions Pvt Ltd, was on 23/08/2024.</li>
                    <li>{formData.para8}</li>
                    <li>{formData.para9}</li>
                    <li>{formData.para10}</li>
                </SoftBox>

                <SoftBox mb={3}>
                    <SoftTypography variant="body1">
                        {formData.para11}
                    </SoftTypography>
                </SoftBox>

                <SoftBox mb={3}>
                    <SoftTypography variant="body1">
                        {formData.para12}Care4Edu Solutions Pvt Ltd. {formData.para13}
                    </SoftTypography>
                </SoftBox>

                <SoftBox mb={3}>
                    <SoftTypography variant="body1">
                        {formData.para14}
                    </SoftTypography>
                </SoftBox>

                <SoftBox mt={4}>
                    <SoftTypography variant="body1">Sincerely,</SoftTypography>
                    <SoftTypography variant="body1">Authorized Signatory</SoftTypography>
                    <SoftTypography variant="body1">Care4Edu</SoftTypography>
                </SoftBox>
            </SoftBox>
        </div>
    );

    return (
        <PageLayout>
          
              <SoftButton onClick={handleBack}>
                Back
            </SoftButton>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    <SoftTypography variant="h5" gutterBottom>
                        Relieving Template Form
                    </SoftTypography>
                    <Card>
                        <SoftBox pt={2} pb={3} px={3}>
                            <form onSubmit={handleSubmit}>
                                <Grid container spacing={2}>
                                    {[
                                        { label: "Title", name: "title" },
                                        { label: "Subject Line", name: "para1" },
                                        { label: "Introduction", name: "para2" },
                                        { label: "Date Reference", name: "para3" },
                                        { label: "Additional Context", name: "para4" },
                                        { label: "Main Content", name: "para5" },
                                        { label: "Company Reference", name: "para6" },
                                        { label: "Additional Details", name: "para7" },
                                        { label: "Terms Point 1", name: "para8" },
                                        { label: "Terms Point 2", name: "para9" },
                                        { label: "Terms Point 3", name: "para10" },
                                        { label: "Conclusion Part 1", name: "para11" },
                                        { label: "Conclusion Part 2", name: "para12" },
                                        { label: "Final Note", name: "para14" },
                                    ].map((field) => (
                                        <Grid item xs={12} key={field.name}>
                                            <SoftTypography variant="h6">{field.label}</SoftTypography>
                                            <SoftInput
                                                fullWidth
                                                name={field.name}
                                                value={formData[field.name]}
                                                onChange={handleChange}
                                                multiline={field.name.startsWith('para')}
                                                rows={field.name.startsWith('para') ? 3 : 1}
                                            />
                                        </Grid>
                                    ))}
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            fullWidth
                                            onClick={handleClickOpen}
                                            sx={{ mb: 2 }}
                                        >
                                            View Template in Full Screen
                                        </Button>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            fullWidth
                                        >
                                            Submit
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        </SoftBox>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <SoftTypography variant="h5" gutterBottom>
                        Adaptive Template
                    </SoftTypography>
                    <Card>
                        <SoftBox pt={1} pb={3} px={3}>
                            {orgdetails ? renderTemplateContent() : (
                                <SoftTypography variant="body1">Loading organization details...</SoftTypography>
                            )}
                        </SoftBox>
                    </Card>
                </Grid>

                <Grid item xs={12} sm={4}>
                    <RelievingTemp/>
                </Grid>
            </Grid>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
            >
                <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <SoftTypography variant="h6">Full Screen View</SoftTypography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </SoftBox>
                <DialogContent>
                    {orgdetails ? renderTemplateContent() : (
                        <SoftTypography variant="body1">Loading organization details...</SoftTypography>
                    )}
                </DialogContent>
            </Dialog>
        </PageLayout>
    );
}

export default RelievingEdit;