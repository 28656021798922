import React, { useState, useEffect } from 'react';
import { Grid, CircularProgress, Backdrop, Card } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import axios from 'axios';
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';
import Swal from 'sweetalert2';
import Icon from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye';


const OrgPasswordCreate = () => {
    const navigate = useNavigate();
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));

    const [formData, setFormData] = useState({
        title: '',
        username: '',
        password: '',
        url: '',
        description: '',
    });

    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [empdata, setEmpdata] = useState([]);
    const [urlError, setUrlError] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };

    useEffect(() => {
        const fetchEmployeeData = async () => {
            setLoading(true);
            try {
                const url = APIData.api + `employee/details?org=${org}`;
                const response = await fetch(url, { headers: APIData.headers });
                const data = await response.json();
                setEmpdata(data);
            } catch (err) {
                toast(err);
            }
            setLoading(false);
        };

        fetchEmployeeData();
    }, []);

    const updateSupervisor = (supervisor_id) => {

        const data = empdata.filter(item => item.employee_id === supervisor_id);
        setFormData(prev => ({
            ...prev,
            supervisor_id,
            supervisor_email: data[0].email
        }));

    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'url') {
            if (/^(ftp|http|https):\/\/[^ "]+$/.test(value) || value === "") {
                setUrlError(false);
            } else {
                setUrlError(true);
            }
        }

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (urlError) {
            toast("Enter valid credentials and try again");
            return;
        }

        setLoading(true);
        const today = new Date().toISOString().slice(0, 10);

        const orgPasswordManage = {
            ...formData,
            created_by: sessiondetails.user,
            created_date: today,
            updated_by: '',
            updated_date: '',
            org: org
        };
        console.log(orgPasswordManage);

        try {
            const url = APIData.api + "orgpasswords/";
            await axios.post(url, orgPasswordManage, { headers: APIData.headers });
            Swal.fire({
                icon: "success",
                title: "Form data submitted successfully",
                showConfirmButton: false,
                timer: 1500
            });
            navigate("/password-managing");
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: error.response.data || "An error occurred",
            });
        }
        setLoading(false);
    };

    const supervisorOptions = [

        ...empdata.map(item => ({
            value: item.employee_id,
            label: item.employee_id
        }))
    ];

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} sm={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/password-managing">
                                <AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Org Password Manage
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>
                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Title *</SoftTypography>
                                    <SoftInput
                                        placeholder="Enter Title"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleChange}
                                        required
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Username *</SoftTypography>
                                    <SoftInput
                                        placeholder="Enter Username"
                                        name="username"
                                        value={formData.username}
                                        onChange={handleChange}
                                        required
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Password *</SoftTypography>
                                    <SoftBox position="relative">
                                        <SoftInput
                                            placeholder="Enter Password"
                                            name="password"
                                            type={showPassword ? 'text' : 'password'}
                                            value={formData.password}
                                            onChange={handleChange}
                                            required
                                            icon={{
                                                component:  <Icon
                                                        icon={showPassword ? eyeOff : eye}
                                                        onClick={togglePasswordVisibility}
                                                        style={{ cursor: 'pointer' }}
                                                      />,
                                                direction: "right",
                                              }}
                                        />
                                    </SoftBox>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">URL</SoftTypography>
                                    <SoftInput
                                        placeholder="Enter URL"
                                        name="url"
                                        value={formData.url}
                                        onChange={handleChange}
                                        error={urlError}
                                    />
                                    {urlError && formData.url.length !== 0 && (
                                        <SoftTypography variant="caption" color="error">
                                            Enter a valid url
                                        </SoftTypography>
                                    )}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Description *</SoftTypography>
                                    <SoftInput
                                        placeholder="Enter Description"
                                        name="description"
                                        value={formData.description}
                                        onChange={handleChange}
                                        required
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Supervisor ID *</SoftTypography>
                                    <CustomSelect
                                        options={supervisorOptions}
                                        placeholder="Select Supervisor ID"
                                        name="supervisor_id"
                                        value={formData.supervisor_id}
                                        onChange={(selectedOption) => updateSupervisor(selectedOption.value)}
                                        required
                                    />
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Supervisor Email</SoftTypography>
                                    <SoftInput
                                        value={formData.supervisor_email}
                                        readOnly
                                    />
                                </SoftBox>

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        Submit
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default OrgPasswordCreate;