// import React from 'react';
// import { useLocation, Link } from 'react-router-dom';
// import { Button, Typography } from '@mui/material';
// import { useEffect } from 'react';

// export const InternalJobDetails = () => {
//     const location = useLocation();
//     // const selectedJob = location.state?.job || JSON.parse(localStorage.getItem('selectedJob'));
//     const selectedJob = location.state?.job || JSON.parse(localStorage.getItem('selectedJob'));
//     // console.log("Selected Job:",selectedJob)

//     useEffect(() => {
//         if (location.state?.job) {
//           localStorage.setItem('selectedJob', JSON.stringify(location.state.job));
//         }
//       }, [location.state]);
//   return (

//         <div className="courseDetailsContainer">
//           {/* <Typography variant='h5' style={{ fontFamily: "Roboto Slab" }} className="courseTitle">{selectedItem.course_id}</Typography> */}
//           <div className='course_details_upper'>


//           <h4>{selectedJob.job_designation}</h4>
//             <p> Role:{selectedJob.job_id}</p>
//             <p>Department:{selectedJob.job_dept}</p>
//             <p>Job Type: {selectedJob.job_type}</p>
//             <p>Salary: {selectedJob.salary}</p>
//             <p>Job Tenure:{selectedJob.job_tenure}</p>
//             {/* <p>Role:{selectedItem.role}</p> */}



//             <hr className='line' style={{
//               color: '#CDC9CA',
//               backgroundColor: '#CDC9CA',
//               width: "100%",
//               height: .5,
//               borderColor: '#CDC9CA'
//             }} />
//             <Link to={{ pathname: "/internal-job-apply", state: { jobId: selectedJob.job_id, Role:selectedJob.role,designation:selectedJob.job_designation,branch:selectedJob.branch,department:selectedJob.job_dept} }}>

//             <Button variant='contained' color="primary" >Apply</Button>
//             </Link>


//           </div>
//           <br></br>

//           <div className='course_details_page'>
//             <center>  <Typography variant='h6'> Job Description</Typography></center>
//             <ul style={{ fontWeight: '400', fontSize: '15px' }}>
//               {selectedJob.description && selectedJob.description.split('.').map((sentence, index) => (
//                 <li key={index}>{sentence.trim()}</li>
//               ))}
//             </ul>
//           </div>

//           <br></br>

//           {selectedJob.roles_and_resp && (
//             <div className='course_details_page'>
//               <center><Typography variant='h6'>Roles and Responsibilities</Typography></center>
//               <ul style={{ fontWeight: '400', fontSize: '15px' }}>
//                 {selectedJob.roles_and_resp.split('.').map((sentence, index) => (
//                   <li key={index}>{sentence.trim()}</li>
//                 ))}
//               </ul>
//             </div>
//           )}


//         </div>
//       );
//     };
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import {
    Grid,
    Button,
    Typography,
    Box,
    Divider
} from '@mui/material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';

const InternalJobDetails = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const selectedJob = location.state?.job || JSON.parse(localStorage.getItem('selectedJob'));

    useEffect(() => {
        if (location.state?.job) {
            localStorage.setItem('selectedJob', JSON.stringify(location.state.job));
        }
    }, [location.state]);

    if (!selectedJob) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="60vh">
                <Typography>Loading job details...</Typography>
            </Box>
        );
    }

    const renderDetailItem = (label, value) => (
        <Box mb={1} display="flex" alignItems="center">
            <Typography variant="subtitle1" fontWeight="600" sx={{ mr: 1 }}>
                {label}:
            </Typography>
            <Typography variant="body1" color="text.secondary">
                {value}
            </Typography>
        </Box>
    );

    const renderDescriptionSection = (title, content) => (
        <Box mt={3}>
            <Typography variant="h6" fontWeight="600" mb={2} color="primary">
                {title}
            </Typography>
            <Box component="ul" sx={{ pl: 2 }}>
                {content && content.split('.').map((sentence, index) => (
                    sentence.trim() && (
                        <Typography
                            key={index}
                            component="li"
                            variant="body2"
                            color="text.secondary"
                            mb={1}
                        >
                            {sentence.trim()}
                        </Typography>
                    )
                ))}
            </Box>
        </Box>
    );

    const handleApply = () => {
        navigate("/internal-job-apply", {
            state: {
                jobId: selectedJob.job_id,
                Role: selectedJob.role,
                designation: selectedJob.job_designation,
                branch: selectedJob.branch,
                department: selectedJob.job_dept
            }
        });
    };

    return (
        <DashboardLayout>
            <DashboardNavbar/>
        <Box
            sx={{
                p: 3,
                maxWidth: "900px",
                margin: "0 auto",
                mt: 4
            }}
        >
            <Card
                sx={{
                    p: 4,
                    borderRadius: 2,
                    boxShadow: "0 8px 24px rgba(0, 0, 0, 0.12)",
                }}
            >
                <Box display="flex" flexDirection="column" alignItems="center" mb={3}>
                    <Typography variant="h5" fontWeight="700" mb={1}>
                        {selectedJob.job_designation}
                    </Typography>
                    <Typography variant="h6" color="text.secondary" mb={2}>
                        Role: {selectedJob.job_id}
                    </Typography>
                </Box>

                <Grid container spacing={3}>
                    {/* Job Details Section */}
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5" fontWeight="600" mb={2}>
                            Job Details
                        </Typography>
                        {renderDetailItem("Department", selectedJob.job_dept)}
                        {renderDetailItem("Job Type", selectedJob.job_type)}
                        {renderDetailItem("Salary", selectedJob.salary)}
                        {renderDetailItem("Job Tenure", selectedJob.job_tenure)}
                    </Grid>

                    {/* Image Section - If you have job images */}
                    <Grid item xs={12} md={6} display="flex" justifyContent="center" alignItems="center">
                        {selectedJob.image_url && (
                            <img
                                src={selectedJob.image_url}
                                alt={selectedJob.job_designation}
                                style={{
                                    maxWidth: "100%",
                                    maxHeight: "200px",
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)"
                                }}
                            />
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Divider sx={{ my: 2}} />
                    </Grid>

                    {/* Description Section */}
                    <Grid item xs={12}>
                        {renderDescriptionSection("Job Description", selectedJob.description)}
                    </Grid>

                    {/* Roles and Responsibilities Section */}
                    {selectedJob.roles_and_resp && (
                        <Grid item xs={12}>
                            {renderDescriptionSection("Roles and Responsibilities", selectedJob.roles_and_resp)}
                        </Grid>
                    )}

                    {/* Apply Button Section */}
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" mt={3}>
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                onClick={handleApply}
                                sx={{
                                    px: 6,
                                    py: 1.5,
                                    borderRadius: 2,
                                    textTransform: 'none',
                                    fontSize: '1rem',
                                    transition: 'all 0.3s',
                                    '&:hover': {
                                        transform: 'translateY(-2px)',
                                        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)'
                                    }
                                }}
                            >
                                Apply Now
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        </Box>
        </DashboardLayout>
    );
};

export default InternalJobDetails;