import React, { useState, useEffect } from 'react';
import { Grid, Card, CircularProgress, Backdrop } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import * as AiIcons from "react-icons/ai";
import SoftBox from 'components/SoftBox';
import SoftTypography from 'components/SoftTypography';
import SoftButton from 'components/SoftButton';
import SoftInput from 'components/SoftInput';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Footer from 'examples/Footer';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';

const EditSpoc = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [roleData, setRoleData] = useState({
    supervisor: '',
    email_id: '',
    roles: '',
    id: ""
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (location.state?.roleData) {
      const { supervisor, email_id, roles, id } = location.state.roleData;
      setRoleData({ supervisor, email_id, roles, id });
    }
  }, [location.state]);
   
  const formdata = {
    id: roleData.id,
    roles: roleData.roles,
    supervisor: roleData.supervisor,
    email_id: roleData.email_id,
    org: org,
    opt: "YES"
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.put(
        APIData.api + `org-roles-head/?id=${roleData.id}`,
        formdata,
        { headers: APIData.headers }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Updated successfully",
          showConfirmButton: false,
          timer: 1500
        });
        setTimeout(() => {
          navigate('/depart-spoc');
        }, 1500);
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Update failed",
        });
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Update failed. Please try again.",
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "70vh" }}>
        <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={12} sm={8}>
          <Card sx={{ height: 'auto' }}>
            <SoftBox p={3} mb={1} display="flex" alignItems="center">
              <Link to="/depart-spoc">
                <AiIcons.AiOutlineCloseCircle />
              </Link>
              <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                Edit Department SPOC
              </SoftTypography>
            </SoftBox>

            <SoftBox pt={1} pb={3} px={3}>
              <SoftBox component="form" onSubmit={handleUpdateSubmit}>
                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Name *</SoftTypography>
                  <SoftInput
                    placeholder="Name"
                    value={roleData.supervisor}
                    onChange={(e) => setRoleData({...roleData, supervisor: e.target.value})}
                    error={!!errors.supervisor}
                    required
                  />
                  {errors.supervisor && (
                    <SoftTypography variant="caption" color="error">
                      {errors.supervisor}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Email *</SoftTypography>
                  <SoftInput
                    type="email"
                    placeholder="Email"
                    value={roleData.email_id}
                    onChange={(e) => setRoleData({...roleData, email_id: e.target.value})}
                    error={!!errors.email_id}
                    required
                  />
                  {errors.email_id && (
                    <SoftTypography variant="caption" color="error">
                      {errors.email_id}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mb={2}>
                  <SoftTypography variant="h6">Department *</SoftTypography>
                  <SoftInput
                    placeholder="Department"
                    value={roleData.roles}
                    onChange={(e) => setRoleData({...roleData, roles: e.target.value})}
                    error={!!errors.roles}
                    required
                  />
                  {errors.roles && (
                    <SoftTypography variant="caption" color="error">
                      {errors.roles}
                    </SoftTypography>
                  )}
                </SoftBox>

                <SoftBox mt={4} mb={1}>
                  <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                    Update
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
      <Footer />
    </DashboardLayout>
  );
};

export default EditSpoc;