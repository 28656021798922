// import React, { useState, useEffect } from 'react';
// import { Grid, Backdrop, CircularProgress } from '@mui/material';
// import { Card } from "@mui/material";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftInput from "components/SoftInput";
// import SoftButton from "components/SoftButton";
// import CustomSelect from 'assets/theme/components/Select/CustomSelect';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
// import { useNavigate } from 'react-router-dom';
// import DefaultNavbar from 'examples/Navbars/DefaultNavbar';

// const AccessRole = () => {
//   const [selectedDept, setSelectedDept] = useState('');
//   const [accessLevel, setAccessLevel] = useState('');
//   const [error, setError] = useState('');
//   const [loading, setLoading] = useState(false);
//   const navigate = useNavigate();

//   const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
//   const uniqueDepts = sessionDetails?.dept ? [...new Set(sessionDetails.dept)] : [];

//   useEffect(() => {

//     if (sessionDetails?.userType === "SUPERADMIN") {
//       localStorage.setItem("Access Level", "1111"); 
//       navigate("/dashboard", { replace: true });
//       return;
//     }
//     if (sessionDetails?.userType === "USER") {
//       localStorage.setItem("Access Level", "1000");
//       navigate("/dashboard", { replace: true });
//       return;
//     }
//     if (sessionDetails?.dept?.length === 1) {
//       handleDepartmentSelect({ value: sessionDetails.dept[0] });
//     }
//   }, []);

//   const determineAccessLevel = (dept) => {
//     if (!sessionDetails) return null;

//     if (sessionDetails.userType === "EMPLOYEE") {
//       if (Array.isArray(sessionDetails.privileges)) {
//         const deptPrivilege = sessionDetails.privileges.find(
//           privilege => privilege.dept === dept
//         );
//         return deptPrivilege?.privileges || null;
//       }
//     } else if (sessionDetails.userType === "USER") {
//       return "1000";
//     }
//     return null;
//   };

//   const handleDepartmentSelect = (selectedOption) => {
//     const value = selectedOption ? selectedOption.value : '';
//     setSelectedDept(value);
//     const privileges = determineAccessLevel(value);
//     setAccessLevel(privileges);
//   };

//   const handleSubmit = () => {
//     if (!selectedDept) {
//       setError('Please select a department');
//       return;
//     }

//     setLoading(true);
//     try {
//       localStorage.setItem("Depart Details", selectedDept);
//       localStorage.setItem("Access Level", accessLevel);
//       navigate("/dashboard", { replace: true });
//     } catch (err) {
//       setError('Error saving department details');
//     } finally {
//       setLoading(false);
//     }
//   };

//   const renderAccessInfo = () => {
//     if (!accessLevel) return null;

//     const accessMap = {
//       '1': 'View',
//       '2': 'Add',
//       '3': 'Update',
//       '4': 'Delete'
//     };

//     const permissions = accessLevel.split('').map((level, index) => 
//       level === '1' ? accessMap[index + 1] : null
//     ).filter(Boolean);

//     return permissions.length > 0 ? (
//       <SoftBox mt={2}>
//         <SoftTypography variant="h6" fontWeight="medium">
//           Access Level
//         </SoftTypography>
//         <SoftTypography variant="body2">
//           You have the following permissions: {permissions.join(', ')}
//         </SoftTypography>
//       </SoftBox>
//     ) : null;
//   };

//   const departmentOptions = uniqueDepts.map(dept => ({
//     value: dept,
//     label: dept
//   }));

//   // If user is SUPERADMIN, return null as component will redirect
//   if (sessionDetails?.userType === "SUPERADMIN") {
//     return (
//       <Backdrop open={true} style={{ zIndex: 9999, color: '#fff' }}>
//         <CircularProgress color="inherit" />
//       </Backdrop>
//     );
//   }

//   return (
//     <PageLayout>
//       <DefaultNavbar/>
//       <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
//         <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
//           <CircularProgress color="inherit" />
//         </Backdrop>
//         <Grid item xs={10} sm={4}>
//           <Card sx={{ height: 'auto' }}>
//             <SoftBox p={3} mb={1}>
//               <SoftTypography variant="h5" fontWeight="medium" textAlign="center">
//                 User Access Overview
//               </SoftTypography>
//             </SoftBox>

//             <SoftBox pt={1} pb={3} px={3}>
//               <SoftBox component="form" onSubmit={handleSubmit}>
//                 {sessionDetails?.dept?.length === 1 ? (
//                   <SoftBox mb={2}>
//                     <SoftTypography variant="h6">Department</SoftTypography>
//                     <SoftInput
//                       value={sessionDetails.dept[0]}
//                       disabled
//                       required
//                     />
//                   </SoftBox>
//                 ) : (
//                   <SoftBox mb={2}>
//                     <SoftTypography variant="h6">Select Department *</SoftTypography>
//                     <CustomSelect
//                       options={departmentOptions}
//                       placeholder="Select Department"
//                       name="department"
//                       onChange={handleDepartmentSelect}
//                       value={selectedDept}
//                       error={!!error}
//                       required
//                     />
//                     {error && (
//                       <SoftTypography variant="caption" color="error">
//                         {error}
//                       </SoftTypography>
//                     )}
//                   </SoftBox>
//                 )}

//                 {renderAccessInfo()}

//                 <SoftBox mt={4} mb={1}>
//                   <SoftButton 
//                     type="submit"
//                     variant="gradient" 
//                     color="info" 
//                     fullWidth
//                     onClick={handleSubmit}
//                     disabled={!selectedDept}
//                   >
//                     Continue
//                   </SoftButton>
//                 </SoftBox>
//               </SoftBox>
//             </SoftBox>
//           </Card>
//         </Grid>
//       </Grid>
//     </PageLayout>
//   );
// };
// export default AccessRole;

import React, { useState, useEffect } from 'react';
import { Grid, Backdrop, CircularProgress } from '@mui/material';
import { Card } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import { useNavigate } from 'react-router-dom';
import DefaultNavbar from 'examples/Navbars/DefaultNavbar';

const AccessRole = () => {
  const [selectedDept, setSelectedDept] = useState('');
  const [accessLevel, setAccessLevel] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
  const uniqueDepts = sessionDetails?.dept ? [...new Set(sessionDetails.dept)] : [];

  useEffect(() => {
    if (sessionDetails?.userType === "SUPERADMIN") {
      localStorage.setItem("Access Level", "1111"); 
      sessionStorage.setItem('newLogin', 'true');
      navigate("/dashboard", { replace: true });
      return;
    }
    if (sessionDetails?.userType === "USER") {
      localStorage.setItem("Access Level", "1000");
      sessionStorage.setItem('newLogin', 'true');
      navigate("/dashboard", { replace: true });
      return;
    }
    if (sessionDetails?.dept?.length === 1) {
      handleDepartmentSelect({ value: sessionDetails.dept[0] });
    }
  }, []);

  const determineAccessLevel = (dept) => {
    if (!sessionDetails) return null;

    if (sessionDetails.userType === "EMPLOYEE") {
      if (Array.isArray(sessionDetails.privileges)) {
        const deptPrivilege = sessionDetails.privileges.find(
          privilege => privilege.dept === dept
        );
        return deptPrivilege?.privileges || null;
      }
    } else if (sessionDetails.userType === "USER") {
      return "1000";
    }
    return null;
  };

  const handleDepartmentSelect = (selectedOption) => {
    const value = selectedOption ? selectedOption.value : '';
    setSelectedDept(value);
    const privileges = determineAccessLevel(value);
    setAccessLevel(privileges);
  };

  const handleSubmit = () => {
    if (!selectedDept) {
      setError('Please select a department');
      return;
    }

    setLoading(true);
    try {
      localStorage.setItem("Depart Details", selectedDept);
      localStorage.setItem("Access Level", accessLevel);
      sessionStorage.setItem('newLogin', 'true');
      navigate("/dashboard", { replace: true });
    } catch (err) {
      setError('Error saving department details');
    } finally {
      setLoading(false);
    }
  };

  const handleCancel = () => {
    // Clear local storage and navigate to sign-in page
    localStorage.clear();
    navigate("/authentication/org-sign-in", { replace: true });
  };

  const renderAccessInfo = () => {
    if (!accessLevel) return null;

    const accessMap = {
      '1': 'View',
      '2': 'Add',
      '3': 'Update',
      '4': 'Delete'
    };

    const permissions = accessLevel.split('').map((level, index) => 
      level === '1' ? accessMap[index + 1] : null
    ).filter(Boolean);

    return permissions.length > 0 ? (
      <SoftBox mt={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Access Level
        </SoftTypography>
        <SoftTypography variant="body2">
          You have the following permissions: {permissions.join(', ')}
        </SoftTypography>
      </SoftBox>
    ) : null;
  };

  const departmentOptions = uniqueDepts.map(dept => ({
    value: dept,
    label: dept
  }));

  // If user is SUPERADMIN, return null as component will redirect
  if (sessionDetails?.userType === "SUPERADMIN") {
    return (
      <Backdrop open={true} style={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  if (sessionDetails?.userType === "USER") {
    return (
      <Backdrop open={true} style={{ zIndex: 9999, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <PageLayout>
      <DefaultNavbar/>
      <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
        <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid item xs={10} sm={4}>
          <Card sx={{ height: 'auto' }}>
            <SoftBox p={3} mb={1}>
              <SoftTypography variant="h5" fontWeight="medium" textAlign="center">
                Login Details
              </SoftTypography>
            </SoftBox>

            <SoftBox pt={1} pb={3} px={3}>
              <SoftBox component="form" onSubmit={handleSubmit}>
                {sessionDetails?.dept?.length === 1 ? (
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6">Department</SoftTypography>
                    <SoftInput
                      value={sessionDetails.dept[0]}
                      disabled
                      required
                    />
                  </SoftBox>
                ) : (
                  <SoftBox mb={2}>
                    <SoftTypography variant="h6">Select Department *</SoftTypography>
                    <CustomSelect
                      options={departmentOptions}
                      placeholder="Select Department"
                      name="department"
                      onChange={handleDepartmentSelect}
                      value={selectedDept}
                      error={!!error}
                      required
                    />
                    {error && (
                      <SoftTypography variant="caption" color="error">
                        {error}
                      </SoftTypography>
                    )}
                  </SoftBox>
                )}

                {renderAccessInfo()}

                <SoftBox mt={4} mb={1}>
                  <SoftButton 
                    type="submit"
                    variant="gradient" 
                    color="info" 
                    fullWidth
                    onClick={handleSubmit}
                    disabled={!selectedDept}
                  >
                    Continue
                  </SoftButton>
                </SoftBox>

                {/* No button to clear localStorage and navigate to sign-in */}
                <SoftBox mt={2}>
                  <SoftButton 
                    variant="outlined" 
                    color="error" 
                    fullWidth
                    onClick={handleCancel}
                  >
                    No, Cancel
                  </SoftButton>
                </SoftBox>

              </SoftBox>
            </SoftBox>
          </Card>
        </Grid>
      </Grid>
    </PageLayout>
  );
};

export default AccessRole;
