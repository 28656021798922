import Landing2 from 'Home/Landing2';
import React from 'react'
import { Outlet } from 'react-router-dom'


export const UserCustomer = ()=>{
    const access = ["USER","CUSTOMER"]
    const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
  return sessionDetails && access.includes(sessionDetails.userType) ? true : false;
}

const UserLayout = () => {
  return (
    <>
    {
      UserCustomer()? 
      <>
        <Outlet/>
      </>
      :
      <>
        <Landing2/>
      </>
    }
    </>
  )
}

export default UserLayout;