import {
    TextInput,
    PasswordInput,
    Paper,
    Title,
    Container,
    Button
  } from '@mantine/core';
  import { useState} from 'react';
  import { postSigninRequest } from '../../Query/SigninQuery';
  import { useNavigate } from 'react-router-dom';
import { Appbar } from '../Appbar/Appbar';
import toast from 'react-hot-toast';
import { encrypt } from '../../ApiData/Encryption';
import { org } from 'authentication/APIData';
  
  
  export default function Login() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const navigate = useNavigate();
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      const newjson = {
        email_id: "",
        password: password,
        phone_number: "",
        org:org,
        role: "",
        status: "",
        updated_by: "",
        otp:"",
        otpExpirationTime:"",
        username: username
      }
      console.log(newjson);
      
      const access = ["EMPLOYEE", "superadmin"]
  
      const response = postSigninRequest(newjson)
        .then((resp) => {
          if (resp.status === "success") {
            // console.log(resp);
            if (access.includes(resp.userType)) {
              toast.success('Loggedin successfully')
              encrypt('EMPLOYEE', resp)
              navigate("/adminDashboard")
            }
            else{
              toast.error("You are not an admin you cannot access this resources");
              navigate("/")
            }
          }
          else {
            toast.error("Invalid username or password")
          }
        })
        .catch((err) => {
          toast.error("Unable to login kindly try again")
        })
    };
  
    return (
      <div>
        <Appbar/>
        <Container size={480} my={40}>
          <Title ta="center" c={"cyan.8"}  order={3}>
            Login to acces admin panel 
          </Title>
  
          <Paper withBorder shadow="md" p={50} mt={20} radius="10" >
            <form onSubmit={handleSubmit}>
            <TextInput
              label="Username"
              placeholder="username"
              name='username'
              required
              onChange={(e) => setUsername(e.target.value)}
            />
            <PasswordInput
              label="Password"
              placeholder="Your password"
              required
              mt="md"
              name='password'
              onChange={(e) => setPassword(e.target.value)}
            />
            <Button
                  type="submit"
                  mt="sm"
                  w={{ base: "100%" }}
                  variant="gradient"
                >
                  Log in
                </Button>
                </form>
          </Paper>
        </Container>
      </div>
    );
  }